import { Schema } from "jsonschema";

export const GET_META_SCHEMA = "GET_META_SCHEMA";
export const GET_META_SCHEMA_SUCCESS = "GET_META_SCHEMA_SUCCESS";
export const CLEAR_META_SCHEMA = "CLEAR_META_SCHEMA";

export interface GetMetaSchemaAction {
  type: typeof GET_META_SCHEMA;
}

export interface GetMetaSchemaSuccessAction {
  type: typeof GET_META_SCHEMA_SUCCESS;
  payload: Schema;
}

export interface ClearMetaSchemaAction {
  type: typeof CLEAR_META_SCHEMA;
}

function getMetaSchema(): GetMetaSchemaAction {
  return { type: GET_META_SCHEMA };
}

function getMetaSchemaSuccess(
  schemaResponse: Schema,
): GetMetaSchemaSuccessAction {
  return { type: GET_META_SCHEMA_SUCCESS, payload: schemaResponse };
}

export const metaSchemaActions = {
  getMetaSchema,
  getMetaSchemaSuccess,
};
