import { toast } from "react-toastify";
import { call, put, select, takeEvery, takeLeading } from "redux-saga/effects";
import { loggingService } from "services/hermes/logging";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  GetLabelImageAction,
  GET_LABEL_IMAGE,
  OPEN_LABEL_IMAGE,
  OpenLabelImageAction,
  labelImageActions,
} from "./labelImage.actions";
import { LabelImageLoggingResponse } from "./labelImage.reducer";

import { IRootStateType } from "types/IRootStateType";

const selectLabelImageByPackageId = (
  state: IRootStateType,
  packageId: string,
): LabelImageLoggingResponse | null => state.labelImage?.[packageId] || null;

function* getLabelImageWorker(action: GetLabelImageAction) {
  /**
   * This checks to see if we already
   * have a label image for a given package. It includes support for an optional
   * override to force fetching a new label image even when one already exists.
   */

  const existingLabelImage = (yield select(
    selectLabelImageByPackageId,
    action.payload.packageId,
  )) as unknown as LabelImageLoggingResponse | null;
  if (existingLabelImage && !action.payload.forceRefresh) {
    // Skip the request if label image already exists and forceRefresh is not specified
    return;
  }
  yield put(labelImageActions.clearLabelImage(action.payload.packageId));
  yield put(loadingActions.startLoad(action.type));

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof loggingService.getLabelImage> =
      yield call(loggingService.getLabelImage, action.payload.packageId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: LabelImageLoggingResponse = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(labelImageActions.getLabelImageSuccess(response));
  } catch (error) {
    yield put(labelImageActions.getLabelImageFailure(action.payload.packageId));
  }
  yield put(loadingActions.endLoad(action.type));
}

function* openLabelImageWorker(action: OpenLabelImageAction) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof loggingService.getLabelImage> =
      yield call(loggingService.getLabelImage, action.payload.packageId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: LabelImageLoggingResponse = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );

    if (!!response.imageUrl) {
      window.open(response.imageUrl, "_blank", "noopener,noreferrer");
    }
  } catch (error) {
    toast.error(
      `Unable to open Shipping Label for package ${action.payload.packageId}`,
    );
  }
}

export const labelImageSagas = function* () {
  yield takeEvery(GET_LABEL_IMAGE, getLabelImageWorker);
  yield takeLeading(OPEN_LABEL_IMAGE, openLabelImageWorker);
};
