import { call, takeLeading } from "redux-saga/effects";

import { networkActions } from "slices/authentication/network.actions";
import { GET_WFM_RESOURCES_TEMPLATE_WINDOWS } from "./actions";
import { service } from "./service";
import { ActionType } from "./types";

export function* worker(action: ActionType) {
  yield call(
    networkActions.makeAuthenticatedRequest,
    service(action.payload),
    true,
  );
}

export const saga = function* () {
  yield takeLeading(GET_WFM_RESOURCES_TEMPLATE_WINDOWS, worker);
};
