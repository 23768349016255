import { Reducer } from "redux";

import { defaultState } from "default-state";
import { IRootStateType } from "types/IRootStateType";
import {
  CancelScheduledDeliveriesSuccessActionType,
  IClearCancelScheduledDeliveriesActionType,
  CLEAR_CANCELLED_SCHEDULED_DELIVERIES,
  CANCEL_SCHEDULED_DELIVERIES_SUCCESS,
} from "./cancelledScheduledDeliveryOutcomes.actions";

type CancelledScheduledDeliveryOutcome = {
  id: number;
  outcome: string;
  reason: string;
};

export type CancelledScheduledDeliveryOutcomes = {
  outcomes: CancelledScheduledDeliveryOutcome[];
};

type ActionTypes =
  | CancelScheduledDeliveriesSuccessActionType
  | IClearCancelScheduledDeliveriesActionType;

export const cancelledScheduledDeliveryOutcomes: Reducer<
  IRootStateType["cancelledScheduledDeliveryOutcomes"]
> = (
  state = defaultState.cancelledScheduledDeliveryOutcomes,
  action: ActionTypes,
): IRootStateType["cancelledScheduledDeliveryOutcomes"] => {
  switch (action.type) {
    case CLEAR_CANCELLED_SCHEDULED_DELIVERIES:
      return defaultState.cancelledScheduledDeliveryOutcomes;
    case CANCEL_SCHEDULED_DELIVERIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
