import React from "react";

import FetchModal from "views/common/modals/FetchModal";
import LeaveLiabilityWaiver from "views/components/atoms/LeaveLiabilityWaiver";

export const LeaveLiabilityWaiverModal: React.FC = () => (
  <FetchModal className="LeaveLiabilityWaiverModal" id="leave_liability_waiver">
    <LeaveLiabilityWaiver />
  </FetchModal>
);
