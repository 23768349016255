import { toast } from "react-toastify";
import { call, ForkEffect, put, takeLeading } from "redux-saga/effects";
import { usersService } from "services/hermes/users";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  IGetWarehouseUserActionType,
  warehouseUserActions,
  ISaveWarehouseUserActionType,
  GET_WAREHOUSE_USER,
  SAVE_WAREHOUSE_USER,
  SAVE_WAREHOUSE_USER_SUCCESS,
} from "./warehouseUser.actions";
import { WarehouseUser } from "./warehouseUser.reducer";

function* getWarehouseUserWorker(action: IGetWarehouseUserActionType) {
  yield put(warehouseUserActions.clearWarehouseUser());
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof usersService.getWarehouseUser> =
      yield call(usersService.getWarehouseUser, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: WarehouseUser = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(warehouseUserActions.getWarehouseUserSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

function* saveWarehouseUserWorker(action: ISaveWarehouseUserActionType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: IAuthenticatedRequestDefinition = yield call(
    usersService.saveWarehouseUser,
    action.systemId,
    action.payload,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: WarehouseUser = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(warehouseUserActions.saveWarehouseUserSuccess(response));
}
function saveWarehouseUserSuccessWorker() {
  // display success message
  toast.success("Facility information updated successfully");
}

export const warehouseUserSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(GET_WAREHOUSE_USER, getWarehouseUserWorker);
  yield takeLeading(SAVE_WAREHOUSE_USER, saveWarehouseUserWorker);
  yield takeLeading(
    SAVE_WAREHOUSE_USER_SUCCESS,
    saveWarehouseUserSuccessWorker,
  );
};
