import React from "react";
import { BEM } from "helpers/BEM.helper";

const classes = new BEM({
  block: { name: "modals" },
  elements: [
    { name: "useOurMobileAppModal" },
    { name: "appleAppStoreIcon" },
    { name: "googlePlayStoreIcon" },
    { name: "iconContainer" },
    { name: "fetchDeliveryPartnerImg" },
  ],
  prefix: { name: "fetch" },
});

const iosResidentialUserAppUrl = "https://apple.co/3P9VOe4";
const appStoreImg =
  "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1555977600&h=e6251a46052275bbfba2a7c9b843f47c";
const androidResidentialUserAppUrl =
  "https://play.google.com/store/apps/details?id=com.fetchpackage.resident&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
const googlePlayStoreImg =
  "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png";

const getAppUrl = () => {
  return isIphoneUser ? iosResidentialUserAppUrl : androidResidentialUserAppUrl;
};

const userAgent = window.navigator.userAgent;
const isAndroidUser = userAgent.includes("Android");
const isIphoneUser = userAgent.includes("iPhone");

export const GetAppIcon: React.FC = () => {
  return (
    <div>
      {/* Apple app link */}
      {isIphoneUser && (
        // add app logo / art here
        <div className={classes.getElementClassNames("appleAppStoreIcon")}>
          <a href={getAppUrl()}>
            <img src={appStoreImg} alt="Download on the App Store" />
          </a>
        </div>
      )}

      {/* Google App */}
      {isAndroidUser && (
        <div className={classes.getElementClassNames("googlePlayStoreIcon")}>
          <a href={getAppUrl()}>
            <img alt="Get it on Google Play" src={googlePlayStoreImg} />
          </a>
        </div>
      )}
    </div>
  );
};
