import { FetchLocalStorage } from "./LocalStorage";

export function localStorageGetItemSafe<Key extends keyof FetchLocalStorage>(
  key: Key,
): FetchLocalStorage[Key] | null {
  try {
    const localStorageString: string | null = window.localStorage.getItem(key);

    if (typeof localStorageString === "string") {
      // https://github.com/typescript-eslint/typescript-eslint/issues/2118
      // It's possible but unlikely that this could be set to another type and parsing wouldn't result in the expected type.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(localStorageString) as FetchLocalStorage[Key];
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
