import { Reducer } from "redux";
import {
  ClearLabelAnalysisQueryResultsAction,
  CLEAR_LABEL_ANALYSIS_QUERY_RESULTS,
  GET_LABEL_ANALYSIS_BY_QUERY_FAILURE,
  GET_LABEL_ANALYSIS_BY_QUERY_SUCCESS,
  QueryLabelAnalysisAction,
  QueryLabelAnalysisFailureAction,
  QueryLabelAnalysisSuccessAction,
} from "./labelAnalysisQuery.actions";

// src/main/java/com/fetchpackage/domain/type/packageImageDataStatus/PackageImageDataStatus.java
enum PackageImageDataStatus {
  AWAITING_PROCESSING = 1,
  PROCESSING = 2,
  SUCCESS = 3,
  FAILED = 4,
}

type PackageImageDataMeta =
  | Partial<{
      OCR: {
        senders: string[];
        imageUrl: string;
        fetchPackageId: number;
        processingStatus: number;
      };
      HUMAN: {
        senders: string[];
        userIds: number[];
        imageUrl: string;
        fetchPackageId: number;
        carrierTracking: [
          {
            carrier: number;
            trackingNumber: string;
          },
        ];
        processingStatus: number;
      };
    }>
  | Record<string, unknown>;

// src/main/java/com/fetchpackage/dto/logging/PackageImageDataDTO.java
interface PackageImageData {
  fetchPackageId: number;
  imgUrl: string;
  packageImageDataStatus: PackageImageDataStatus;
  meta: PackageImageDataMeta;
  lastModifiedDate: string;
  lastModifiedBy: string;
  createdDate: string;
  createdBy: string;
}

// src/main/java/com/fetchpackage/domain/type/packageImageStatus/PackageImageStatus.java
export enum PackageImageStatus {
  AWAITING_OCR = 1,
  IN_PROCESS_OCR = 2,
  AWAITING_HUMAN = 3,
  IN_PROCESS_HUMAN = 4,
  NEW_PICTURE_REQUIRED = 5,
  SUCCESS_OCR = 6,
  SUCCESS_HUMAN = 7,
  LOST_PUPPY_ASSIGNED = 8,
}

// src/main/java/com/fetchpackage/dto/logging/ResidentialUserAbbreviatedDTO.java
interface ResidentialUserAbbreviated {
  userId: number;
  firstName: string;
  lastName: string;
  userCode: string;
}

type LabelAnalysisMeta =
  | Partial<{
      type: string;
      sender: string;
      userIds: number[];
      carrierTracking: {
        carrier: number;
        tracking: string;
      };
    }>
  | Record<string, unknown>;

// src/main/java/com/fetchpackage/dto/logging/LabelAnalysisDTO.java
interface LabelAnalysis {
  fetchPackageId: number;
  status: PackageImageStatus;
  warehouseId: number;
  lastUpdatedTimestamp: string;
  createdTimestamp: string;
  images: PackageImageData[];
  potentialUserIds: number[];
  potentialUsers: ResidentialUserAbbreviated[];
  potentialCarrier: number;
  potentialTracking: string;
  potentialSender: string;
  meta: LabelAnalysisMeta;
}

// src/main/java/com/fetchpackage/controllers/logging/LabelAnalysisController.java
export interface LabelAnalysisResponse {
  content: LabelAnalysis[];
  pageable: {
    sort: {
      empty: boolean;
      unsorted: boolean;
      sorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    unpaged: boolean;
    paged: boolean;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    unsorted: boolean;
    sorted: boolean;
  };
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export type LabelAnalysisStore = {
  queryResponse: LabelAnalysisResponse;
} | null;

type LabelAnalysisActions =
  | QueryLabelAnalysisAction
  | QueryLabelAnalysisSuccessAction
  | QueryLabelAnalysisFailureAction
  | ClearLabelAnalysisQueryResultsAction;

export const labelAnalysisQuery: Reducer<
  LabelAnalysisStore,
  LabelAnalysisActions
> = (state = null, action) => {
  switch (action.type) {
    case CLEAR_LABEL_ANALYSIS_QUERY_RESULTS:
      return null;
    case GET_LABEL_ANALYSIS_BY_QUERY_SUCCESS:
      return { queryResponse: action?.payload || null };
    case GET_LABEL_ANALYSIS_BY_QUERY_FAILURE:
      return null;
    default:
      return state;
  }
};
