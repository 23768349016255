import { PackageWarehouseLocationHistory } from "./packageWarehouseLocationHistory.reducer";

export const GET_PACKAGE_RELOCATION_AUDIT_TRAIL_SUCCESS =
  "GET_PACKAGE_RELOCATION_AUDIT_TRAIL_SUCCESS";
export const CLEAR_PACKAGE_RELOCATION_AUDIT_TRAIL =
  "CLEAR_PACKAGE_RELOCATION_AUDIT_TRAIL_SUCCESS";
export const GET_PACKAGE_RELOCATION_AUDIT_TRAIL =
  "GET_PACKAGE_RELOCATION_AUDIT_TRAIL";

export interface IGetPackageWarehouseLocationHistoryType {
  type: typeof GET_PACKAGE_RELOCATION_AUDIT_TRAIL_SUCCESS;
  payload: PackageWarehouseLocationHistory;
}

export interface IClearPackageWarehouseLocationHistoryType {
  type: typeof CLEAR_PACKAGE_RELOCATION_AUDIT_TRAIL;
}

export interface IGetPackageRelocationAuditTrailType {
  type: typeof GET_PACKAGE_RELOCATION_AUDIT_TRAIL;
  payload: {
    fetchPackageId: string;
  };
}

function getPackageRelocationAuditTrailSuccess(
  packageRelocationAuditTrailResponse: PackageWarehouseLocationHistory,
): IGetPackageWarehouseLocationHistoryType {
  return {
    payload: packageRelocationAuditTrailResponse,
    type: GET_PACKAGE_RELOCATION_AUDIT_TRAIL_SUCCESS,
  };
}

function clearPackageRelocationAuditTrail(): IClearPackageWarehouseLocationHistoryType {
  return { type: CLEAR_PACKAGE_RELOCATION_AUDIT_TRAIL };
}

function getPackageRelocationAuditTrail(
  fetchPackageId: string,
): IGetPackageRelocationAuditTrailType {
  return {
    payload: { fetchPackageId },
    type: GET_PACKAGE_RELOCATION_AUDIT_TRAIL,
  };
}

export const packageWarehouseLocationHistoryActions = {
  getPackageRelocationAuditTrailSuccess,
  clearPackageRelocationAuditTrail,
  getPackageRelocationAuditTrail,
};
