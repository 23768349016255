import {
  POST_GET_NEXT_LOGGING_ANALYSIS_JOB_SUCCESS,
  CLEAR_LOGGING_ANALYSIS_JOB,
  IClearLoggingAnalysisJobActionType,
  IGetLoggingAnalysisJobSuccessActionType,
  REDIRECT_AFTER_ANALYSIS,
  IRedirectAfterAnalysisJobActionType,
} from "slices/loggingAnalysisJob/loggingAnalysisJob.actions";
import { defaultState } from "default-state";

export interface ILoggingAnalysisJobType {
  fetchPackageId: number;
  warehouseId?: number;
  potentialCarrier: 1 | 2 | 3 | 4 | 5 | 6; // 1 - Amazon, 2 - Usps, 3 - Fedex, 4 - Ups, 5 - Dhl, 6 - other
  potentialSender: string;
  potentialTracking: string;
  potentialUsers?: {
    firstName: string;
    lastName: string;
    userCode: string;
    userId: number;
  }[];
  images?: {
    imgUrl: string;
  }[];
}

type LoggingAnalysisJobActionsTypes =
  | IGetLoggingAnalysisJobSuccessActionType
  | IClearLoggingAnalysisJobActionType
  | IRedirectAfterAnalysisJobActionType;

export function loggingAnalysisJob(
  state = defaultState.loggingAnalysisJob,
  action: LoggingAnalysisJobActionsTypes,
): ILoggingAnalysisJobType | Record<string, never> {
  switch (action.type) {
    case POST_GET_NEXT_LOGGING_ANALYSIS_JOB_SUCCESS:
      return { ...action.payload };
    case CLEAR_LOGGING_ANALYSIS_JOB:
      return defaultState.loggingAnalysisJob;
    case REDIRECT_AFTER_ANALYSIS:
      window.location.replace(action.payload);
      return defaultState.loggingAnalysisJob;
    default:
      return state;
  }
}
