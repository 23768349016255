import { IWarehouseStoreType } from "./warehouse.reducer";

export const GET_WAREHOUSE = "GET_WAREHOUSE";
export const GET_WAREHOUSE_SUCCESS = "GET_WAREHOUSE_SUCCESS";
export const CLEAR_WAREHOUSE = "CLEAR_WAREHOUSE";
export const SAVE_WAREHOUSE = "SAVE_WAREHOUSE";
export const SAVE_WAREHOUSE_SUCCESS = "SAVE_WAREHOUSE_SUCCESS";

function saveWarehouse(
  formData: IWarehouseStoreType,
  onSuccessConfig?: ISaveWarehouseActionType["onSuccessConfig"],
) {
  return { type: SAVE_WAREHOUSE, payload: formData, onSuccessConfig };
}
function saveWarehouseSuccess(
  warehouseResponse: IWarehouseStoreType,
  onSuccessConfig?: ISaveWarehouseActionType["onSuccessConfig"],
) {
  return {
    type: SAVE_WAREHOUSE_SUCCESS,
    payload: warehouseResponse,
    onSuccessConfig,
  };
}

export interface ISaveWarehouseActionType {
  type: typeof SAVE_WAREHOUSE;
  payload: IWarehouseStoreType;
  onSuccessConfig?: {
    resetForm?: boolean;
  };
}

export interface IGetWarehouseActionType {
  type: string;
  payload: {
    warehouseId: string | undefined;
  };
}

export interface IGetWarehouseSuccessActionType {
  type: typeof GET_WAREHOUSE_SUCCESS;
  payload: IWarehouseStoreType;
}

export interface IClearWarehouseActionType {
  type: typeof CLEAR_WAREHOUSE;
}

function clearWarehouse() {
  return {
    type: CLEAR_WAREHOUSE,
  };
}

function getWarehouse(warehouseId: string) {
  return { type: GET_WAREHOUSE, payload: { warehouseId } };
}

function getWarehouseSuccess(warehouseResponse: IWarehouseStoreType) {
  return { type: GET_WAREHOUSE_SUCCESS, payload: warehouseResponse };
}

export const warehouseActions = {
  clearWarehouse,
  getWarehouse,
  saveWarehouse,
  getWarehouseSuccess,
  saveWarehouseSuccess,
};
