import { WarehouseUser } from "./warehouseUser.reducer";

export const CLEAR_WAREHOUSE_USER = "CLEAR_WAREHOUSE_USER";
export const SAVE_WAREHOUSE_USER = "SAVE_WAREHOUSE_USER";
export const SAVE_WAREHOUSE_USER_SUCCESS = "SAVE_WAREHOUSE_USER_SUCCESS";
export const GET_WAREHOUSE_USER = "GET_WAREHOUSE_USER";
export const GET_WAREHOUSE_USER_SUCCESS = "GET_WAREHOUSE_USER_SUCCESS";

export interface IGetWarehouseUserActionType {
  type: string;
  payload: string;
}
export interface IGetWarehouseUserSuccessActionType {
  type: typeof GET_WAREHOUSE_USER_SUCCESS;
  payload: WarehouseUser;
}
export interface ISaveWarehouseUserSuccessActionType {
  type: typeof SAVE_WAREHOUSE_USER_SUCCESS;
  payload: WarehouseUser;
}
export interface IClearWarehouseUserActionType {
  type: typeof CLEAR_WAREHOUSE_USER;
}

export interface ISaveWarehouseUserActionType {
  type: typeof SAVE_WAREHOUSE_USER;
  payload: WarehouseUser;
  systemId: string;
}

function clearWarehouseUser(): IClearWarehouseUserActionType {
  return { type: CLEAR_WAREHOUSE_USER };
}

function saveWarehouseUser(
  systemId: string,
  formData: WarehouseUser,
): ISaveWarehouseUserActionType {
  return { type: SAVE_WAREHOUSE_USER, payload: formData, systemId };
}
function saveWarehouseUserSuccess(
  response: WarehouseUser,
): ISaveWarehouseUserSuccessActionType {
  return { type: SAVE_WAREHOUSE_USER_SUCCESS, payload: response };
}

function getWarehouseUser(systemId: string): IGetWarehouseUserActionType {
  return { type: GET_WAREHOUSE_USER, payload: systemId };
}
function getWarehouseUserSuccess(
  response: WarehouseUser,
): IGetWarehouseUserSuccessActionType {
  return { type: GET_WAREHOUSE_USER_SUCCESS, payload: response };
}

export const warehouseUserActions = {
  clearWarehouseUser,
  saveWarehouseUser,
  saveWarehouseUserSuccess,
  getWarehouseUser,
  getWarehouseUserSuccess,
};
