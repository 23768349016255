import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";

function getApplicationSettings(): IAuthenticatedRequestDefinition {
  return { requestUrl: "/general/settings", requestOptions: { method: "GET" } };
}

function getMetaSchema(): IAuthenticatedRequestDefinition {
  return {
    requestUrl: "/general/meta_schema",
    requestOptions: {
      method: "GET",
    },
  };
}

export const generalService = {
  getApplicationSettings,
  getMetaSchema,
};
