import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserRolePrivilegeDTO } from "services/hermes/userRoles";

export type UserRolePrivilegesStore = {
  privileges: UserRolePrivilegeDTO[];
  isLoading: boolean;
  error: string;
};

const initialState: UserRolePrivilegesStore = {
  privileges: [],
  isLoading: false,
  error: "",
};

export const userRolePrivilegesSlice = createSlice({
  name: "userRolePrivileges",
  initialState,
  reducers: {
    getUserRolePrivilegesFetch: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    getUserRolePrivilegesSuccess: (
      state,
      action: PayloadAction<UserRolePrivilegeDTO[]>,
    ) => {
      state.privileges = action.payload;
      state.isLoading = false;
      state.error = "";
    },
    getUserRolePrivilegesFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getUserRolePrivilegesFetch,
  getUserRolePrivilegesSuccess,
  getUserRolePrivilegesFailure,
} = userRolePrivilegesSlice.actions;

export default userRolePrivilegesSlice.reducer;
