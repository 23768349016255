import { Reducer } from "redux";

import {
  CLEAR_SCHEDULED_DELIVERY,
  GET_SCHEDULED_DELIVERY_SUCCESS,
  IClearScheduledDeliveryActionType,
  IGetScheduledDeliverySuccessActionType,
} from "slices/scheduledDelivery/scheduledDelivery.actions";

import { defaultState } from "default-state";

export interface IScheduledDeliveryStoreType {
  fetchPackageId: number;
  deliveryWindowId: number;
  deliveryStartDate: string;
  deliveryEndDate: string;
}

export const scheduledDelivery: Reducer<
  Partial<IScheduledDeliveryStoreType> | null
> = (
  state = defaultState.scheduledDelivery,
  action: ScheduledDeliveryActionTypes,
) => {
  switch (action.type) {
    case CLEAR_SCHEDULED_DELIVERY:
      return defaultState.scheduledDelivery;
    case GET_SCHEDULED_DELIVERY_SUCCESS:
      return action.payload;
    default:
      return { ...state };
  }
};

export type ScheduledDeliveryActionTypes =
  | IClearScheduledDeliveryActionType
  | IGetScheduledDeliverySuccessActionType;
