import React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, FormGroup, Input } from "reactstrap";

import { warehouseFilterActions } from "slices/warehouseFilter";
import { NavLink } from "react-router-dom";
import { Dispatch } from "redux";
import { IRootStateType } from "types/IRootStateType";
import { CurrentUserState } from "slices/currentUser/currentUser.reducer";

interface MyWarehouseProps extends RouteComponentProps {
  currentUser: CurrentUserState;
  warehouseFilter: boolean;
  dispatch: Dispatch;
}

const toggleWarehouseFilter = (props: MyWarehouseProps) => {
  if ("warehouseFilter" in props && props.warehouseFilter !== undefined) {
    props.dispatch(
      warehouseFilterActions.setWarehouseFilter(!props.warehouseFilter),
    );
  }
};

const MyWarehouse: React.FC<MyWarehouseProps> = (props: MyWarehouseProps) => {
  if (props.currentUser?.warehouseUser?.preferredWarehouseShortcode) {
    return (
      <>
        <Form className={"my-warehouse-form"}>
          <FormGroup check={true}>
            <Input
              type="checkbox"
              name="warehouseFilterEnable"
              id="warehouseFilterEnable"
              checked={props.warehouseFilter}
              onChange={() => toggleWarehouseFilter(props)}
            />{" "}
            <NavLink to="/my-warehouse" className={"my-warehouse-switch"}>
              {props.currentUser.warehouseUser.preferredWarehouseShortcode}{" "}
              Facility
            </NavLink>
          </FormGroup>
        </Form>
      </>
    );
  }
  return null;
};

const mapStateToProps = (store: IRootStateType) => {
  return {
    currentUser: store.currentUser,
    warehouseFilter: store.warehouseFilter,
  };
};

export const MyWarehouseContainer = connect(mapStateToProps)(
  withRouter(MyWarehouse),
);
