import { IScheduleHistoryStoreType } from "./scheduleHistory.reducer";

export const GET_PACKAGE_BY_ID_SCHEDULE_HISTORY =
  "GET_PACKAGE_BY_ID_SCHEDULE_HISTORY";
export const GET_PACKAGE_BY_ID_SCHEDULE_HISTORY_SUCCESS =
  "GET_PACKAGE_BY_ID_SCHEDULE_HISTORY_SUCCESS";

export interface IGetPackageByIdScheduleHistoryActionType {
  type: typeof GET_PACKAGE_BY_ID_SCHEDULE_HISTORY;
  payload: IGetPackageByIdScheduleHistoryPayloadType;
}
export interface IGetPackageByIdScheduleHistoryPayloadType {
  fetchPackageId: number;
}
export interface IGetPackageByIdScheduleHistorySuccessActionType {
  type: typeof GET_PACKAGE_BY_ID_SCHEDULE_HISTORY_SUCCESS;
  payload: IScheduleHistoryStoreType;
}

function getPackageByIdScheduleHistory(
  payload: IGetPackageByIdScheduleHistoryPayloadType,
): IGetPackageByIdScheduleHistoryActionType {
  return { type: GET_PACKAGE_BY_ID_SCHEDULE_HISTORY, payload };
}

function getPackageByIdScheduleHistorySuccess(
  payload: IScheduleHistoryStoreType,
): IGetPackageByIdScheduleHistorySuccessActionType {
  return { type: GET_PACKAGE_BY_ID_SCHEDULE_HISTORY_SUCCESS, payload };
}

export const scheduleHistoryActions = {
  getPackageByIdScheduleHistory,
  getPackageByIdScheduleHistorySuccess,
};
