/**
 * Returns originalString truncated to maxSize if too long
 */
export function truncateIfNecessary(
  originalString: string,
  maxSize: number,
): string {
  const postpendString = "...";
  if (originalString.length > maxSize) {
    return (
      originalString.substring(0, maxSize - postpendString.length) +
      postpendString
    );
  }
  return originalString;
}
