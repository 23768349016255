/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  takeLatest,
  takeLeading,
  ForkEffect,
  call,
  put,
  select,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import { submit } from "redux-form";

import { networkActions } from "slices/authentication/network.actions";
import { history } from "helpers/history";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { loadingActions } from "slices/loading/loading.actions";
import { IRootStateType } from "types/IRootStateType";
import {
  IPostResetPasswordActionType,
  IPostResetPasswordSuccessPayloadType,
  ISaveResidentialUserActionType,
  userActions,
  IGetUserActionType,
  ISaveUserActionType,
  IAdminGetUserAutoScheduleOptionsActionType,
  IDeleteUsersAdminByEncodedSystemIdActionType,
  GET_USER,
  GET_ADMIN_USER,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_RESIDENTIAL_USER,
  SAVE_RESIDENTIAL_USER_SUCCESS,
  POST_RESET_PASSWORD,
  POST_RESET_PASSWORD_SUCCESS,
  POST_RESET_PASSWORD_FAILURE,
  DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID,
  DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_SUCCESS,
  DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_FAILURE,
  ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS,
} from "./user.actions";
import { usersService } from "services/hermes/users";
import {
  AutoScheduleOptions,
  IResidentialUserStoreType,
  IUserStoreType,
} from "slices/currentUser/currentUser.reducer";

function* postResetPasswordWorker(action: IPostResetPasswordActionType) {
  try {
    const { userId } = yield select((state: IRootStateType) => state.user);
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      usersService.postResetPassword,
      {
        temporaryPassword: action.payload.temporaryPassword,
        userId,
      },
    );
    const response: IPostResetPasswordSuccessPayloadType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(userActions.postResetPasswordSuccess(response));
  } catch (error) {
    yield put(userActions.postResetPasswordFailure());
  }
}

function postResetPasswordSuccessWorker() {
  toast.success("Password reset!");
}

function postResetPasswordFailureWorker() {
  toast.error("There was an error resetting the user's password!");
}

function* saveResidentialUserWorker(action: ISaveResidentialUserActionType) {
  try {
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      usersService.saveResidentialUser,
      action.systemId,
      action.payload,
    );
    const response: IResidentialUserStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(userActions.saveResidentialUserSuccess(response));
  } catch (error) {
    toast.error("There was an error while submitting. Please try again.");
  }
}
function saveResidentialUserSuccessWorker() {
  // display success message
  toast.success("Apartment information updated successfully");
}

function* getUserWorker(action: IGetUserActionType) {
  yield put(userActions.clearUser());
  yield put(loadingActions.startLoad(action.type));
  try {
    const requestOptions: ReturnType<typeof usersService.getUser> = yield call(
      usersService.getUser,
      action.payload,
    );
    const response: IUserStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(userActions.getUserSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

function* saveUserWorker(action: ISaveUserActionType) {
  try {
    if (!!action.payload.autoScheduleOptions) {
      delete action.payload.autoScheduleOptions;
    }
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      usersService.saveUser,
      action.payload,
    );
    const response: IUserStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    // submit subforms
    if (response.hasRoleResident === true) {
      // if has resident role, submit residentialUser form
      yield put(submit("residentialUser"));
    }
    if (response.hasRoleWarehouse === true) {
      // if has warehouse role, submit warehouseUser form
      yield put(submit("warehouseUser"));
    }
    yield put(userActions.saveUserSuccess(response));
  } catch (error) {
    toast.error("An error occurred while saving this user.");
  }
}
function saveUserSuccessWorker() {
  // redirect to users table list after success
  history.push("/users");
  // display success message
  toast.success("User updated successfully");
}

function* adminGetUserAutoScheduleOptionsWorker(
  action: IAdminGetUserAutoScheduleOptionsActionType,
) {
  try {
    const requestOptions: ReturnType<
      typeof usersService.adminGetUserAutoScheduleOptions
    > = yield call(
      usersService.adminGetUserAutoScheduleOptions,
      action.encodedSystemId,
    );
    const response: AutoScheduleOptions = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(userActions.adminGetUserAutoScheduleOptionsSuccess(response));
  } catch (error) {}
}

function* deleteUsersAdminByEncodedSystemIdWorker({
  encodedSystemId,
}: IDeleteUsersAdminByEncodedSystemIdActionType) {
  try {
    const requestOptions: ReturnType<
      typeof usersService.deleteUsersAdminByEncodedSystemId
    > = yield call(
      usersService.deleteUsersAdminByEncodedSystemId,
      encodedSystemId,
    );
    yield call(networkActions.makeAuthenticatedRequest, requestOptions, true);
    yield put(userActions.deleteUsersAdminByEncodedSystemIdSuccess());
  } catch (error) {
    yield put(userActions.deleteUsersAdminByEncodedSystemIdFailure());
  }
}

function deleteUsersAdminByEncodedSystemIdSuccessWorker() {
  toast.success("User deleted!");
  history.push("/users");
}

function deleteUsersAdminByEncodedSystemIdFailureWorker() {
  toast.error("An error ocurred while trying to delete this user!");
}

function* getAdminUserWorker(action: IGetUserActionType) {
  try {
    const requestOptions: ReturnType<typeof usersService.getAdminUser> =
      yield call(usersService.getAdminUser, action.payload);
    const response: IUserStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(userActions.getAdminUserSuccess(response));
  } catch (error) {
    history.push("/users");
  }
}

export const userSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(GET_USER, getUserWorker);
  yield takeLeading(GET_ADMIN_USER, getAdminUserWorker);
  yield takeLeading(SAVE_USER, saveUserWorker);
  yield takeLeading(SAVE_USER_SUCCESS, saveUserSuccessWorker);
  yield takeLeading(SAVE_RESIDENTIAL_USER, saveResidentialUserWorker);
  yield takeLeading(
    SAVE_RESIDENTIAL_USER_SUCCESS,
    saveResidentialUserSuccessWorker,
  );
  yield takeLatest(POST_RESET_PASSWORD, postResetPasswordWorker);
  yield takeLatest(POST_RESET_PASSWORD_SUCCESS, postResetPasswordSuccessWorker);
  yield takeLatest(POST_RESET_PASSWORD_FAILURE, postResetPasswordFailureWorker);
  yield takeLatest(
    DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID,
    deleteUsersAdminByEncodedSystemIdWorker,
  );
  yield takeLatest(
    DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_SUCCESS,
    deleteUsersAdminByEncodedSystemIdSuccessWorker,
  );
  yield takeLatest(
    DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_FAILURE,
    deleteUsersAdminByEncodedSystemIdFailureWorker,
  );
  yield takeLatest(
    ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS,
    adminGetUserAutoScheduleOptionsWorker,
  );
};
