import { defaultState } from "default-state";
import { IResidentialUserStoreType } from "slices/currentUser/currentUser.reducer";
import {
  CLEAR_RESIDENTIAL_USER,
  GET_RESIDENTIAL_USER_SUCCESS,
  IClearResidentialUserActionType,
  IGetResidentialUserSuccessActionType,
} from "./residentialUser.actions";

type ResidentialUserActionsTypes =
  | IGetResidentialUserSuccessActionType
  | IClearResidentialUserActionType;

export function residentialUser(
  state = {},
  action: ResidentialUserActionsTypes,
): Partial<IResidentialUserStoreType> {
  switch (action.type) {
    case CLEAR_RESIDENTIAL_USER:
      return defaultState.residentialUser;
    case GET_RESIDENTIAL_USER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
