import { takeLeading, ForkEffect, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  FetchError,
  networkActions,
} from "slices/authentication/network.actions";
import { packageActions } from "slices/package/package.actions";
import { myPackageActions } from "slices/myPackage/myPackage.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  IScheduleMyPackageActionType,
  IScheduleMyPackageSuccessActionType,
  IAdminScheduleDeliveryActionType,
  IAdminScheduleDeliverySuccessActionType,
  IPostCancelScheduleDeliveryActionType,
  IPostCancelScheduleDeliverySuccessActionType,
  IGetScheduledDeliveryActionType,
  GET_SCHEDULED_DELIVERY,
  POST_CANCEL_SCHEDULE_DELIVERY,
  POST_CANCEL_SCHEDULE_DELIVERY_SUCCESS,
  POST_CANCEL_SCHEDULE_DELIVERY_FAILURE,
  SCHEDULE_MY_PACKAGE,
  SCHEDULE_MY_PACKAGE_SUCCESS,
  ADMIN_SCHEDULE_PACKAGE,
  ADMIN_SCHEDULE_PACKAGE_SUCCESS,
  scheduledDeliveryActions,
  IPostCancelScheduleDeliveryFailureActionType,
} from "./scheduledDelivery.actions";
import { myPackagesActions } from "slices/myPackages/myPackages.actions";
import { IScheduledDeliveryStoreType } from "./scheduledDelivery.reducers";
import { packagesService } from "services/hermes/packages";

function* scheduleMyPackageWorker({ request }: IScheduleMyPackageActionType) {
  const { fetchPackageId } = request;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof packagesService.postScheduledDelivery
    > = yield call(packagesService.postScheduledDelivery, request);
    yield call(networkActions.makeAuthenticatedRequest, requestOptions, true);
    yield put(
      scheduledDeliveryActions.scheduleMyPackageSuccess(fetchPackageId),
    );
  } catch (error) {
    toast.error(
      "It appears that there was an error scheduling the delivery! Please try again!",
    );
  }
}
function* scheduleMyPackageSuccessWorker({
  fetchPackageId,
}: IScheduleMyPackageSuccessActionType) {
  toast.success("Package successfully scheduled for delivery!");
  yield put(myPackageActions.getMyPackage(fetchPackageId));
  yield put(
    myPackagesActions.getMyPackages({
      pageSize: 100,
      page: 0,
      timeScope: "today",
    }),
  );
}

function* adminSchedulePackageWorker({
  request,
}: IAdminScheduleDeliveryActionType) {
  try {
    const { fetchPackageId } = request;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof packagesService.postScheduledDelivery
    > = yield call(packagesService.postScheduledDelivery, request);
    yield call(networkActions.makeAuthenticatedRequest, requestOptions, true);
    yield put(
      scheduledDeliveryActions.adminSchedulePackageSuccess(fetchPackageId),
    );
  } catch (error) {
    toast.error(
      "It appears that there was an error scheduling the delivery! Please try again!",
    );
  }
}

function* adminSchedulePackageSuccessWorker({
  fetchPackageId,
}: IAdminScheduleDeliverySuccessActionType) {
  toast.success("Package successfully scheduled for delivery!");
  yield put(packageActions.getPackage(`${fetchPackageId}`));
  yield put(
    scheduledDeliveryActions.getScheduledDelivery({
      fetchPackageId,
    }),
  );
}

function* postCancelScheduleDeliveryWorker(
  action: IPostCancelScheduleDeliveryActionType,
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      packagesService.postCancelScheduleDelivery,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: FetchError | undefined = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );

    yield put(
      scheduledDeliveryActions.postCancelScheduleDeliverySuccess(
        action.payload.fetchPackageId,
        action.options,
      ),
    );
  } catch (error) {
    yield put(
      scheduledDeliveryActions.postCancelScheduleDeliveryFailure(
        action.options?.toastMessages?.failure
          ? {
              message: action.options?.toastMessages?.failure,
            }
          : undefined,
      ),
    );
  }
}

function* postCancelScheduleDeliverySuccessWorker(
  action: IPostCancelScheduleDeliverySuccessActionType,
) {
  const { options, fetchPackageId } = action;

  if (!!options) {
    const { refresh, isPickup } = options;
    if (!!refresh) {
      const {
        getMyPackage: getMyPackageRequest,
        getMyPackages: getMyPackagesRequest,
        getPackage: getPackageRequest,
      } = refresh;
      if (getMyPackageRequest) {
        yield put(myPackageActions.getMyPackage(fetchPackageId));
      }
      if (getMyPackagesRequest) {
        yield put(
          myPackagesActions.getMyPackages({
            pageSize: 100,
            page: 0,
            timeScope: "today",
          }),
        );
      }
      if (getPackageRequest) {
        yield put(packageActions.getPackage(`${fetchPackageId}`));
      }
    }
    toast.success(
      action.options?.toastMessages?.success ||
        `${isPickup ? "Facility pickup" : "Delivery"} cancelled successfully!`,
    );
  }
}

function postCancelScheduleDeliveryFailureWorker(
  action: IPostCancelScheduleDeliveryFailureActionType,
) {
  const message =
    action.payload?.message ||
    action.payload?.fetchError?.message ||
    "It appears that there was an error cancelling the delivery! Please try again!";
  toast.error(message);
}

function* getScheduledDeliveryWorker(action: IGetScheduledDeliveryActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof packagesService.getScheduledDelivery
    > = yield call(packagesService.getScheduledDelivery, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IScheduledDeliveryStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(scheduledDeliveryActions.getScheduledDeliverySuccess(response));
  } catch (error) {
    yield put(scheduledDeliveryActions.getScheduledDeliveryFailure());
  }
}

export const scheduledDeliverySagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(GET_SCHEDULED_DELIVERY, getScheduledDeliveryWorker);
  yield takeLeading(
    POST_CANCEL_SCHEDULE_DELIVERY,
    postCancelScheduleDeliveryWorker,
  );
  yield takeLeading(
    POST_CANCEL_SCHEDULE_DELIVERY_SUCCESS,
    postCancelScheduleDeliverySuccessWorker,
  );
  yield takeLeading(
    POST_CANCEL_SCHEDULE_DELIVERY_FAILURE,
    postCancelScheduleDeliveryFailureWorker,
  );
  yield takeLeading(SCHEDULE_MY_PACKAGE, scheduleMyPackageWorker);
  yield takeLeading(
    SCHEDULE_MY_PACKAGE_SUCCESS,
    scheduleMyPackageSuccessWorker,
  );
  yield takeLeading(ADMIN_SCHEDULE_PACKAGE, adminSchedulePackageWorker);
  yield takeLeading(
    ADMIN_SCHEDULE_PACKAGE_SUCCESS,
    adminSchedulePackageSuccessWorker,
  );
};
