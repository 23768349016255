import React from "react";

import { BEM } from "helpers/BEM.helper";
import { InputGroup, Input, InputGroupAddon, InputGroupText } from "reactstrap";
import { FetchIcon } from "views/common/misc/FetchIcon";
import { InputType } from "reactstrap/es/Input";

const classes = new BEM({
  block: { name: "SearchField", extras: ["no-border"] },
  elements: [{ name: "button", extras: ["btn-magnify"] }, { name: "input" }],
});

export interface SearchFieldProps {
  input: unknown;
  placeholder: string;
  type?: InputType;
  submitHandler: () => void;
}

export const SearchField: React.FC<SearchFieldProps> = (
  props: SearchFieldProps,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { input, placeholder, submitHandler, type } = props;

  return (
    <InputGroup className={classes.getBlockClassNames()}>
      <Input
        placeholder={placeholder}
        type={type || "text"}
        {...(!!input ? input : "")}
        className={classes.getElementClassNames("input")}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <div
            onClick={submitHandler}
            className={classes.getElementClassNames("button")}
          >
            <FetchIcon iconType="search" />
          </div>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};
