import React from "react";

import { Dispatch } from "redux";

import { authenticationActions } from "slices/authentication/authentication.actions";
import { BEM } from "helpers/BEM.helper";
import { FetchConnect } from "helpers/FetchConnect";
import FetchModal from "views/common/modals/FetchModal";
import UserAttributeVerificationForm from "views/components/forms/UserAttributeVerificationForm";
import { IRootStateType } from "types/IRootStateType";

export interface VerifyUserEmailFormModalProps extends IRootStateType {
  dispatch: Dispatch;
}

export const VerifyUserEmailFormModal: React.FC<
  VerifyUserEmailFormModalProps
> = ({
  dispatch,
  authentication: { tokens },
}: VerifyUserEmailFormModalProps) => {
  const classes = new BEM({
    block: { name: "modals" },
    elements: [{ name: "verifyEmail" }],
    prefix: { name: "fetch" },
  });

  return (
    <FetchModal
      title="Verify Email Address"
      className={classes.getElementClassNames("verifyEmail")}
      id="verify_email"
    >
      <div className="user-attribute-modal-text-padding">
        A verification code was sent to your email address. Please enter it
        below.
      </div>
      <UserAttributeVerificationForm
        {...{
          onSubmit: ({ code }: { code?: string }) => {
            if (tokens && tokens.userToken && code) {
              dispatch(
                authenticationActions.verifyUserAttribute({
                  attributeName: "email",
                  code,
                  userToken: tokens.userToken,
                }),
              );
            }
          },
        }}
      />
    </FetchModal>
  );
};

export default FetchConnect({
  ConnectedComponent: VerifyUserEmailFormModal,
  state: { stores: ["authentication", "ui"] },
});
