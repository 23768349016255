import React from "react";

import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";

import { authenticationActions } from "slices/authentication/authentication.actions";
import { IPostSetPermenantPasswordPayloadType } from "slices/authentication/authentication.actions";
import { FetchConnect } from "helpers/FetchConnect";
import { FetchCard } from "views/common/cards/FetchCard";
import ResetPasswordForm, {
  IResetPasswordFormValues,
} from "views/components/forms/ResetPasswordForm";
import { IRootStateType } from "types/IRootStateType";

export interface ResetPasswordFormCardProps
  extends IRootStateType,
    RouteComponentProps {
  dispatch: Dispatch;
}

export const ResetPasswordFormCard: React.FC<ResetPasswordFormCardProps> = (
  props: ResetPasswordFormCardProps,
) => {
  const { dispatch } = props;

  return (
    <FetchCard
      preTitle="Reset Password"
      preTitleSubtext="Please provide a new password."
    >
      <ResetPasswordForm
        onSubmit={(values: Partial<IResetPasswordFormValues>) => {
          dispatch(
            authenticationActions.postSetPermanentPassword({
              email: values.email,
              password: values.newPassword,
              session: values.session,
            } as IPostSetPermenantPasswordPayloadType),
          );
        }}
      />
    </FetchCard>
  );
};

export default FetchConnect({
  ConnectedComponent: ResetPasswordFormCard,
  state: { stores: [] },
});
