import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserRoleDTO } from "services/hermes/userRoles";

export type UserRoleStore = {
  request?: UserRoleDTO["roleId"];
  userRole?: UserRoleDTO;
  isLoading: boolean;
  error: string;
};

const initialState: UserRoleStore = {
  isLoading: false,
  error: "",
};

export const userRoleSlice = createSlice({
  name: "userRole",
  initialState,
  reducers: {
    getUserRoleFetch: (state, action: PayloadAction<UserRoleDTO["roleId"]>) => {
      state.isLoading = true;
      state.error = "";
      state.request = action.payload;
    },
    getUserRoleSuccess: (state, action: PayloadAction<UserRoleDTO>) => {
      state.userRole = action.payload;
      state.isLoading = false;
      state.error = "";
    },
    getUserRoleFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUserRole: (state, action: PayloadAction<UserRoleDTO>) => {
      state.userRole = action.payload;
    },
    updateUserRolePost: (state, action: PayloadAction<UserRoleDTO>) => {
      state.userRole = action.payload;
      state.request = action.payload.roleId;
      state.isLoading = true;
      state.error = "";
    },
    updateUserRoleSuccess: (state, action: PayloadAction<UserRoleDTO>) => {
      state.userRole = action.payload;
      state.request = action.payload.roleId;
      state.isLoading = false;
      state.error = "";
    },
    updateUserRoleFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getUserRoleFetch,
  getUserRoleSuccess,
  getUserRoleFailure,
  setUserRole,
  updateUserRolePost,
  updateUserRoleSuccess,
  updateUserRoleFailure,
} = userRoleSlice.actions;

export default userRoleSlice.reducer;
