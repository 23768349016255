import { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import { NavRoute } from "routes/navRoutes";

// initialNavRoutes must be a constant or memo.
export const useNavCollapseState = (
  initialNavRoutes: NavRoute[],
): readonly [typeof state, typeof toggle] => {
  const location = useLocation();

  const parentNavRoutes = useMemo(() => {
    return initialNavRoutes.filter(
      ({ children }) => children && children.length > 0,
    );
  }, [initialNavRoutes]);

  const [state, setState] = useState<Record<string, boolean>>(() => {
    const initialState = parentNavRoutes.reduce<Record<string, boolean>>(
      (prev, route) => ({
        ...prev,
        [route.name]: false,
      }),
      {},
    );

    const openParent = parentNavRoutes.find(({ children }) =>
      children?.some(({ path }) => path === location.pathname),
    );

    if (openParent) {
      return {
        ...initialState,
        [openParent.name]: true,
      };
    }

    return initialState;
  });

  useEffect(() => {
    const openParent = parentNavRoutes.find(({ children }) =>
      children?.some(({ path }) => path === location.pathname),
    );

    if (openParent) {
      setState((prev) => {
        return {
          ...prev,
          [openParent.name]: true,
        };
      });
    }
  }, [location, parentNavRoutes]);

  const toggle = useCallback(({ name }: NavRoute) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: !prev[name],
      };
    });
  }, []);

  return [state, toggle] as const;
};
