import { IBatchStoreType } from "slices/batch/batch.reducer";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IBatchesStoreType } from "./batches.reducer";

export const CLEAR_BATCHES = "CLEAR_BATCHES";
export const SEARCH_BATCHES = "SEARCH_BATCHES";
export const SEARCH_BATCHES_SUCCESS = "SEARCH_BATCHES_SUCCESS";
export const SEARCH_BATCHES_FAILURE = "SEARCH_BATCHES_FAILURE";
export const SUBMIT_BATCHES = "SUBMIT_BATCHES";

export interface IClearBatchesActionType {
  type: typeof CLEAR_BATCHES;
}

export interface ISubmitBatchesActionType {
  type: typeof SUBMIT_BATCHES;
  batches: Partial<IBatchStoreType>[];
  refresh?: {
    batchablePackagesQuery: IPaginatedTableSearchRequestType;
    batchedPackagesQuery: IPaginatedTableSearchRequestType;
    driverBatches: IPaginatedTableSearchRequestType;
  };
  onResponse: () => void;
}

export interface ISearchBatchesActionType {
  type: string;
  payload: IPaginatedTableSearchRequestType;
}

export interface ISearchBatchesSuccessActionType {
  type: typeof SEARCH_BATCHES_SUCCESS;
  payload: IBatchesStoreType;
}

export interface ISearchBatchesFailureActionType {
  type: typeof SEARCH_BATCHES_FAILURE;
}

function clearBatches() {
  return { type: CLEAR_BATCHES };
}

function submitBatches(
  batches: Partial<IBatchStoreType>[],
  onResponse: () => void,
  options?: ISubmitBatchesActionType["refresh"],
): ISubmitBatchesActionType {
  return { type: SUBMIT_BATCHES, batches, refresh: options, onResponse };
}

function searchBatches(batchSearch: IPaginatedTableSearchRequestType) {
  return { type: SEARCH_BATCHES, payload: batchSearch };
}

function searchBatchesSuccess(batchResponse: IBatchesStoreType) {
  return { type: SEARCH_BATCHES_SUCCESS, payload: batchResponse };
}

function searchBatchesFailure() {
  return { type: SEARCH_BATCHES_FAILURE };
}

export const batchesActions = {
  clearBatches,
  searchBatches,
  submitBatches,
  searchBatchesSuccess,
  searchBatchesFailure,
};
