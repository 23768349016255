import { actions } from "./csatData.actions";
import { defaultState, reducer } from "./csatData.reducer";
import { saga } from "./csatData.saga";
import { useCSATData } from "./csatData.hook";

export const CSATData = {
  reducer,
  actions,
  saga,
  hook: useCSATData,
  defaultState,
};
