import { takeLatest, takeLeading, call, put } from "redux-saga/effects";
import { change, initialize } from "redux-form";
import { history } from "helpers/history";
import { toast } from "react-toastify";

import { loadingActions } from "slices/loading/loading.actions";
import { networkActions } from "slices/authentication/network.actions";
import { rootStore } from "rootStore";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  ISaveBuildingActionType,
  IGetBuildingActionType,
  buildingActions,
  IPreLoadBuildingActionType,
  GET_BUILDING,
  SAVE_BUILDING,
  SAVE_BUILDING_WITH_NEW_UNIT_MAP,
  SAVE_BUILDING_SUCCESS,
  PRE_LOAD_BUILDING,
  ICreateURLResponseType,
  IPutFileResponseType,
} from "./building.actions";
import {
  IBuildingsStoreType,
  IBuildingStoreContentType,
} from "slices/buildings/buildings.reducer";
import { IBuildingStoreType } from "./building.reducer";
import { buildingsService } from "services/hermes/buildings";
import { removeWarning } from "slices/navigateAway/navigateAwayState";
import { BUILDING_FORMNAME } from "constants/form-names";

function* saveBuildingWithNewUnitMapWorker(action: ISaveBuildingActionType) {
  try {
    const newUnitMapFile = action.payload.newUnitMapFile;

    if (!newUnitMapFile) {
      throw new Error("new unit map file not found");
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const createURLRequestOptions: ReturnType<
      typeof buildingsService.saveBuilding
    > = yield call(buildingsService.createUnitMapUploadURL, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const createURLResponse: ICreateURLResponseType = yield call(
      networkActions.makeAuthenticatedRequest,
      createURLRequestOptions,
    );

    if (!createURLResponse.url) {
      throw new Error("error creating S3 upload URL");
    }

    const putFileRequestOptions: RequestInit = {
      body: newUnitMapFile,
      headers: {},
      method: "PUT",
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const putFileResponse: IPutFileResponseType = yield fetch(
      createURLResponse.url,
      putFileRequestOptions,
    );

    if (!putFileResponse.url) {
      throw new Error("error using S3 upload URL");
    }
    if (putFileResponse.url.indexOf(".pdf") === -1) {
      throw new Error("S3 upload should return url with .pdf file");
    }
    const newUnitMapUrl = putFileResponse.url.split(".pdf")[0].concat(".pdf");
    const buildingToSave = action.payload;
    const onSuccessConfig = action.onSuccessConfig;

    // clean building before saving, update unitMapUrl
    delete buildingToSave.newUnitMapFile;
    buildingToSave.unitMapUrl = newUnitMapUrl;

    yield put(buildingActions.saveBuilding(buildingToSave, onSuccessConfig));
  } catch (err) {
    // redirect to buildings table with error tost if something's gone wrong
    history.push("/buildings");
    toast.error("Error updating building with new unit map file");
  }
}

function* saveBuildingWorker(action: ISaveBuildingActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof buildingsService.saveBuilding> =
      yield call(buildingsService.saveBuilding, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IBuildingsStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    const onSuccessConfig = action.onSuccessConfig;
    yield put(buildingActions.saveBuildingSuccess(response, onSuccessConfig));
  } catch (err) {
    toast.error("Error saving building");
  }
}

function* saveBuildingSuccessWorker(action: ISaveBuildingActionType) {
  if (!!action.onSuccessConfig?.resetForm) {
    // remove navigate away warning
    yield put(removeWarning(BUILDING_FORMNAME));
    // reinitialize building form
    yield put(initialize(BUILDING_FORMNAME, action.payload, false));
  }
  // redirect to buildings table list after success
  history.push("/buildings");
  // display success message
  if (action.payload && action.payload.name) {
    toast.success(action.payload.name + " updated successfully");
  }
}

function* getBuildingWorker(action: IGetBuildingActionType) {
  yield put(buildingActions.clearBuilding());
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof buildingsService.getBuilding> =
      yield call(buildingsService.getBuilding, action.payload.buildingId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IBuildingStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(buildingActions.getBuildingSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

function* preLoadBuildingWorker({ shortCode }: IPreLoadBuildingActionType) {
  try {
    const requestOptions: IAuthenticatedRequestDefinition =
      buildingsService.searchBuildings({
        filters: [
          {
            id: "shortCode",
            value: shortCode,
          },
        ],
      });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IBuildingsStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );

    const building = response.content.find(
      (building: IBuildingStoreContentType): boolean =>
        building.shortCode === shortCode,
    );

    if (!!building) {
      rootStore.dispatch(
        change("myResidentialUser", "buildingId", building.buildingId),
      );
      rootStore.dispatch(
        change("myResidentialUser", "market", building.market),
      );
    }
  } catch (error) {}
}

export const buildingSagas = function* () {
  yield takeLeading(GET_BUILDING, getBuildingWorker);
  yield takeLeading(SAVE_BUILDING, saveBuildingWorker);
  yield takeLeading(
    SAVE_BUILDING_WITH_NEW_UNIT_MAP,
    saveBuildingWithNewUnitMapWorker,
  );
  yield takeLeading(SAVE_BUILDING_SUCCESS, saveBuildingSuccessWorker);
  yield takeLatest(PRE_LOAD_BUILDING, preLoadBuildingWorker);
};
