import { UserAuditHistory } from "./userAuditHistory.reducer";

export const GET_USER_AUDIT_TRAIL = "GET_USER_AUDIT_TRAIL";
export const CLEAR_USER_AUDIT_TRAIL = "CLEAR_USER_AUDIT_TRAIL";
export const GET_USER_AUDIT_TRAIL_SUCCESS = "GET_USER_AUDIT_TRAIL_SUCCESS";

export interface IClearUserAuditHistoryType {
  type: typeof CLEAR_USER_AUDIT_TRAIL;
}

export interface IGetUserAuditHistoryType {
  type: typeof GET_USER_AUDIT_TRAIL;
  payload: string;
}

export interface IGetUserAuditHistorySuccessType {
  type: typeof GET_USER_AUDIT_TRAIL_SUCCESS;
  payload: UserAuditHistory;
}

function getUserAuditTrail(userId: string): IGetUserAuditHistoryType {
  return { type: GET_USER_AUDIT_TRAIL, payload: userId };
}
function getUserAuditTrailSuccess(
  response: UserAuditHistory,
): IGetUserAuditHistorySuccessType {
  return { type: GET_USER_AUDIT_TRAIL_SUCCESS, payload: response };
}

function clearUserAuditTrail(): IClearUserAuditHistoryType {
  return { type: CLEAR_USER_AUDIT_TRAIL };
}

export const userAuditHistoryActions = {
  getUserAuditTrail,
  getUserAuditTrailSuccess,
  clearUserAuditTrail,
};
