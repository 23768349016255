import { ResidentialUserAuditHistory } from "./residentialUserAuditHistory.reducer";

export const GET_RESIDENTIAL_USER_AUDIT_TRAIL =
  "GET_RESIDENTIAL_USER_AUDIT_TRAIL";

export const CLEAR_RESIDENTIAL_USER_AUDIT_TRAIL =
  "CLEAR_RESIDENTIAL_USER_AUDIT_TRAIL";
export const GET_RESIDENTIAL_USER_AUDIT_TRAIL_SUCCESS =
  "GET_RESIDENTIAL_USER_AUDIT_TRAIL_SUCCESS";

export interface IGetResidentialUserAuditHistoryType {
  type: typeof GET_RESIDENTIAL_USER_AUDIT_TRAIL;
  payload: string;
}

export interface IGetResidentialUserAuditHistorySuccessType {
  type: typeof GET_RESIDENTIAL_USER_AUDIT_TRAIL_SUCCESS;
  payload: ResidentialUserAuditHistory;
}

export interface IClearResidentialUserAuditHistoryType {
  type: typeof CLEAR_RESIDENTIAL_USER_AUDIT_TRAIL;
}

function getResidentialUserAuditTrail(
  userId: string,
): IGetResidentialUserAuditHistoryType {
  return { type: GET_RESIDENTIAL_USER_AUDIT_TRAIL, payload: userId };
}
function getResidentialUserAuditTrailSuccess(
  response: ResidentialUserAuditHistory,
): IGetResidentialUserAuditHistorySuccessType {
  return { type: GET_RESIDENTIAL_USER_AUDIT_TRAIL_SUCCESS, payload: response };
}

function clearResidentialUserAuditTrail(): IClearResidentialUserAuditHistoryType {
  return { type: CLEAR_RESIDENTIAL_USER_AUDIT_TRAIL };
}

export const residentialUserAuditHistoryActions = {
  getResidentialUserAuditTrail,
  getResidentialUserAuditTrailSuccess,
  clearResidentialUserAuditTrail,
};
