import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  GetFetchPackagesByIdLabelImageResponseBody,
  service,
} from "services/hermesEndpointSagas/getFetchPackagesByIdLabelImage/service";
import {
  FetchError,
  networkActions,
} from "slices/authentication/network.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { actions, actionTypes, RequestAction } from "./actions";
import { MyPackageLabelsByFetchPackageIdState } from "./reducer";

export const saga = function* () {
  yield takeEvery(actionTypes.request, worker);
};
const worker = function* (action: RequestAction) {
  const fetchPackageIds = action.payload?.fetchPackageIds;

  if (!!fetchPackageIds) {
    try {
      const myPackageLabels: GetFetchPackagesByIdLabelImageResponseBody[] = [];
      for (const fetchPackageId of fetchPackageIds) {
        const request: IAuthenticatedRequestDefinition = service({
          fetchPackageId,
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: GetFetchPackagesByIdLabelImageResponseBody = yield call(
          networkActions.makeAuthenticatedRequest,
          request,
          true,
        );
        myPackageLabels.push(response);
      }
      yield all(myPackageLabels);
      const myPackageLabelsByFetchPackageId: MyPackageLabelsByFetchPackageIdState["response"] =
        myPackageLabels.reduce(
          (
            myPackageLabelsByFetchPackageId: MyPackageLabelsByFetchPackageIdState["response"],
            myPackageLabel,
          ) => {
            if (!!myPackageLabelsByFetchPackageId) {
              myPackageLabelsByFetchPackageId[myPackageLabel.fetchPackageId] =
                myPackageLabel;
            }

            return myPackageLabelsByFetchPackageId;
          },
          {},
        );
      yield put(actions.fullfilled(myPackageLabelsByFetchPackageId));
    } catch (error) {
      yield put(actions.rejected(error as FetchError));
    }
  }
};
