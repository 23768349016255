import { IBuildingsStoreType } from "slices/buildings/buildings.reducer";
import { IBuildingFormType } from "views/components/forms/BuildingForm";
import { IBuildingStoreType } from "./building.reducer";

export const CLEAR_BUILDING = "CLEAR_BUILDING";
export const GET_BUILDING = "GET_BUILDING";
export const GET_BUILDING_SUCCESS = "GET_BUILDING_SUCCESS";
export const SAVE_BUILDING = "SAVE_BUILDING";
export const SAVE_BUILDING_WITH_NEW_UNIT_MAP =
  "SAVE_BUILDING_WITH_NEW_UNIT_MAP";
export const SAVE_BUILDING_SUCCESS = "SAVE_BUILDING_SUCCESS";

export const PRE_LOAD_BUILDING = "PRE_LOAD_BUILDING";

export interface IGetBuildingActionType {
  type: string;
  payload: {
    buildingId: string;
  };
}

export interface IGetMarketsActionType {
  type: string;
}

export interface ICreateURLResponseType {
  path: string;
  suffix: string;
  type: number;
  url: string;
}

export interface IPutFileResponseType {
  status: number;
  url: string;
}

export interface ISaveBuildingActionType {
  type: typeof SAVE_BUILDING;
  payload: IBuildingFormType;
  onSuccessConfig?: {
    resetForm?: boolean;
  };
}

export interface IPreLoadBuildingActionType {
  type: typeof PRE_LOAD_BUILDING;
  shortCode: string;
}

function getBuilding(buildingId: string) {
  return { type: GET_BUILDING, payload: { buildingId } };
}
function getBuildingSuccess(buildingResponse: IBuildingStoreType) {
  return { type: GET_BUILDING_SUCCESS, payload: buildingResponse };
}

function saveBuilding(
  formData: IBuildingStoreType,
  onSuccessConfig?: ISaveBuildingActionType["onSuccessConfig"],
) {
  return { type: SAVE_BUILDING, payload: formData, onSuccessConfig };
}
function saveBuildingWithNewUnitMap(
  formData: IBuildingStoreType,
  onSuccessConfig?: ISaveBuildingActionType["onSuccessConfig"],
) {
  return {
    type: SAVE_BUILDING_WITH_NEW_UNIT_MAP,
    payload: formData,
    onSuccessConfig,
  };
}
function saveBuildingSuccess(
  buildingResponse: IBuildingsStoreType,
  onSuccessConfig?: ISaveBuildingActionType["onSuccessConfig"],
) {
  return {
    type: SAVE_BUILDING_SUCCESS,
    payload: buildingResponse,
    onSuccessConfig,
  };
}

function preLoadBuilding(shortCode: string): IPreLoadBuildingActionType {
  return { type: PRE_LOAD_BUILDING, shortCode };
}

function clearBuilding() {
  return { type: CLEAR_BUILDING };
}

export const buildingActions = {
  clearBuilding,
  getBuilding,
  saveBuilding,
  saveBuildingWithNewUnitMap,
  preLoadBuilding,
  getBuildingSuccess,
  saveBuildingSuccess,
};
