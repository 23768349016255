import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { NavRoute } from "routes/navRoutes";
import { generateBemClassNames } from "helpers/generateBemClassNames.helper";
import warningCircle from "assets/img/warning-circle.svg";
import { FetchIcon } from "views/common/misc/FetchIcon";

const classes = generateBemClassNames({
  block: { name: "Sidebar" },
  elements: [{ name: "link" }, { name: "viewSubmenuCaret", extras: ["caret"] }],
});

interface FetchNavLinkCollapseProps {
  route: NavRoute;
}

const Component: React.FC<FetchNavLinkCollapseProps> = ({
  route,
}: FetchNavLinkCollapseProps) => {
  const location = useLocation();
  const active = location.pathname === route.path;

  return (
    <li
      className={classes.elements.link({
        extras: [active ? "active" : ""],
      })}
    >
      <NavLink to={route.path} data-toggle="collapse">
        {route.icon !== undefined ? (
          <>
            <FetchIcon
              iconType={route.icon}
              size="lg"
              className="fa-pull-left"
            />
            <p>{route.name}</p>
            {route.inErrorState && (
              <img
                className="sidebar-error"
                style={{ marginLeft: "10px" }}
                src={warningCircle}
              />
            )}
          </>
        ) : (
          <>
            <span className="sidebar-mini-icon" />
            <span className="sidebar-normal">{route.name}</span>
          </>
        )}
      </NavLink>
    </li>
  );
};

export const FetchNavLink = React.memo(Component);
