import { IMarketType } from "./markets.reducer";

export const CLEAR_MARKETS = "CLEAR_MARKETS";
export const GET_MARKETS = "GET_MARKETS";
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS";

export interface IGetMarketsSuccessActionType {
  type: typeof GET_MARKETS_SUCCESS;
  payload: IMarketType[];
}

export interface IClearMarketsActionType {
  type: typeof CLEAR_MARKETS;
}

function getMarkets() {
  return { type: GET_MARKETS };
}
function getMarketsSuccess(response: IMarketType[]) {
  return { type: GET_MARKETS_SUCCESS, payload: response };
}

export const marketsActions = {
  getMarkets,
  getMarketsSuccess,
};
