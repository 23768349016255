import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import FetchModalService from "views/common/modals/FetchModalService";
import { LayoutSwitch } from "views/components/LayoutSwitch";
import { useChatBot } from "helpers/hooks/useChatBot.hook";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { setSentryUser } from "services/sentry";
import useGlobalNavigationGuard from "helpers/hooks/useGlobalNavigationGuard";

const App: React.FC = () => {
  useChatBot();
  useGlobalNavigationGuard();

  const currentUser = useFetchSelector("currentUser");

  useEffect(() => {
    if (currentUser) {
      setSentryUser(currentUser);
    }
  }, [currentUser]);

  return (
    <>
      <FetchModalService />
      <LayoutSwitch />
      <ToastContainer autoClose={4000} position={toast.POSITION.TOP_CENTER} />
    </>
  );
};

export default Sentry.withProfiler(App);
