import { ValidateCouponResponse } from "./coupon.reducer";

export const CLEAR_COUPON = "CLEAR_COUPON";
export const VALIDATE_COUPON = "VALIDATE_COUPON";
export const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS";

export interface ClearCouponAction {
  type: typeof CLEAR_COUPON;
}

export interface ValidateCouponAction {
  type: typeof VALIDATE_COUPON;
  payload: {
    couponId: string;
  };
}

export interface ValidateCouponSuccessAction {
  type: typeof VALIDATE_COUPON_SUCCESS;
  payload: ValidateCouponResponse;
}
function clearCoupon(): ClearCouponAction {
  return { type: CLEAR_COUPON };
}

function validateCoupon(couponId: string): ValidateCouponAction {
  return { type: VALIDATE_COUPON, payload: { couponId } };
}

function validateCouponSuccess(
  response: ValidateCouponResponse,
): ValidateCouponSuccessAction {
  return { type: VALIDATE_COUPON_SUCCESS, payload: response };
}

export const couponActions = {
  validateCoupon,
  clearCoupon,
  validateCouponSuccess,
};
