export enum UserPrivileges {
  _READ_ANY_PACKAGE = 5002,
  _READ_HIDDEN_BUILDING_UNITS = 5004,
  _SCHEDULE_ANY_PACKAGE = 5000,
  _SCHEDULE_ANY_TIME = 5001,
  _CREATE_OR_DELETE_SCHEDULEOVERRIDE_MODE_UNION = 5006,
  BUILDINGS__BUILDINGID__DELIVERY_TIMES_GET = 1015,
  BUILDINGS__BUILDINGID__GET = 1016,
  BUILDINGS_BYMARKET__MARKETID__GET = 1010,
  BUILDINGS_GET = 1011,
  BUILDINGS_MARKETS_GET = 1012,
  BUILDINGS_POST = 1013,
  BUILDINGS_SEARCH_GET = 1014,
  DELIVERYWINDOWS__DELIVERYWINDOWID__GET = 1033,
  DELIVERYWINDOWS_BUILDINGS__BUILDINGID__GET = 1022,
  DELIVERYWINDOWS_BUILDINGS_CUTOFFOVERRIDES__BUILDINGID__GET = 1019,
  DELIVERYWINDOWS_BUILDINGS_CUTOFFOVERRIDES_DELETE = 1017,
  DELIVERYWINDOWS_BUILDINGS_CUTOFFOVERRIDES_POST = 1018,
  DELIVERYWINDOWS_BUILDINGS_GET = 1020,
  DELIVERYWINDOWS_BUILDINGS_POST = 1021,
  DELIVERYWINDOWS_GET = 1023,
  DELIVERYWINDOWS_GROUPS__DELIVERYWINDOWGROUPID__DELETE = 1026,
  DELIVERYWINDOWS_GROUPS__DELIVERYWINDOWGROUPID__GET = 1027,
  DELIVERYWINDOWS_GROUPS_GET = 1024,
  DELIVERYWINDOWS_GROUPS_POST = 1025,
  DELIVERYWINDOWS_POST = 1028,
  DELIVERYWINDOWS_SCHEDULE_GET = 1032,
  DELIVERYWINDOWS_SCHEDULEOVERRIDES_DELETE = 1029,
  DELIVERYWINDOWS_SCHEDULEOVERRIDES_GET = 1030,
  DELIVERYWINDOWS_SCHEDULEOVERRIDES_PUT = 1031,
  DRIVER_BATCHES__DRIVERBATCHID__GET = 1044,
  DRIVER_BATCHES_ADHOC__FETCHPACKAGEID__GET = 1034,
  DRIVER_BATCHES_AUD__DRIVERBATCHID__GET = 1035,
  DRIVER_BATCHES_MY_AUDIT_COMPLETE_POST = 1036,
  DRIVER_BATCHES_MY_AUDIT_INITIATE_POST = 1037,
  DRIVER_BATCHES_MY_AUDIT_PROCESS_POST = 1038,
  DRIVER_BATCHES_MY_GET = 1039,
  DRIVER_BATCHES_POST = 1040,
  DRIVER_BATCHES_RECORDS__DRIVERBATCHID__EVICT_GET = 1041,
  DRIVER_BATCHES_RECORDS__DRIVERBATCHID__GET = 1042,
  DRIVER_BATCHES_SEARCH_GET = 1043,
  FEATUREFLAGS_DELETE = 1045,
  FEATUREFLAGS_GET = 1046,
  FEATUREFLAGS_PUT = 1047,
  FETCHPACKAGES__FETCHPACKAGEID__AUDIT_GET = 1062,
  FETCHPACKAGES__FETCHPACKAGEID__DELIVERY_RECEIPT_GET = 1063,
  FETCHPACKAGES__FETCHPACKAGEID__DELIVERY_TIMES_GET = 1064,
  FETCHPACKAGES__FETCHPACKAGEID__GET = 1065,
  FETCHPACKAGES__FETCHPACKAGEID__PICKUP_REQUESTED_POST = 1066,
  FETCHPACKAGES__FETCHPACKAGEID__RELOCATION_AUDIT_GET = 1067,
  FETCHPACKAGES__FETCHPACKAGEID__REMOVEFROMBATCH_POST = 1068,
  FETCHPACKAGES__FETCHPACKAGEID__SCHEDULE_DELIVERY_GET = 1070,
  FETCHPACKAGES__FETCHPACKAGEID__SCHEDULEHISTORY_GET = 1069,
  FETCHPACKAGES_AUTO_SCHEDULE_OPTIONS_GET = 1048,
  FETCHPACKAGES_CANCEL_SCHEDULE_DELIVERY_POST = 1049,
  FETCHPACKAGES_DELIVER_PACKAGE_POST = 1050,
  FETCHPACKAGES_EXCEPTION_PACKAGES_POST = 1051,
  FETCHPACKAGES_MY__FETCHPACKAGEID__GET = 1055,
  FETCHPACKAGES_MY_GET = 1052,
  FETCHPACKAGES_MY_PENDINGRATING_GET = 1053,
  FETCHPACKAGES_MY_RATING_POST = 1054,
  FETCHPACKAGES_POST = 1056,
  FETCHPACKAGES_REPRINT_LABEL_POST = 1057,
  FETCHPACKAGES_SCHEDULE_DELIVERY_POST = 1058,
  FETCHPACKAGES_SEARCH_DELIVERY_SCHEDULED_GET = 1059,
  FETCHPACKAGES_SEARCH_GET = 1060,
  FETCHPACKAGES_TODAY__WAREHOUSEID__GET = 1061,
  GENERAL_DIRECT_UPLOAD_POST = 1071,
  GENERAL_SETTINGS_GET = 1073,
  INTEGRATION_BUILDING_DELETE = 1077,
  INTEGRATION_BUILDING_PUT = 1078,
  INTEGRATION_BUILDINGS_GET = 1076,
  INTEGRATION_INFO__INTEGRATIONPARTNER__GET = 1079,
  INTEGRATION_RESIDENTS_DELETE = 1080,
  INTEGRATION_RESIDENTS_PUT = 1081,
  PRINTERS__PRINTERSETTINGSID__GET = 1084,
  PRINTERS_GET = 1082,
  PRINTERS_POST = 1083,
  USERS_ADMIN__SYSTEMID__DELETE = 1097,
  USERS_ADMIN__SYSTEMID__GET = 1098,
  USERS_ADMIN_AUD__SYSTEMID__GET = 1085,
  USERS_ADMIN_AUTO_SCHEDULE_OPTIONS__SYSTEMID__GET = 1086,
  USERS_ADMIN_CLEARCACHES_POST = 1087,
  USERS_ADMIN_DRIVER_AUD__SYSTEMID__GET = 1088,
  USERS_ADMIN_POST = 1089,
  USERS_ADMIN_RESETPASSWORD_POST = 1090,
  USERS_ADMIN_RESIDENTIAL__SYSTEMID__GET = 1092,
  USERS_ADMIN_RESIDENTIAL__SYSTEMID__POST = 1093,
  USERS_ADMIN_RESIDENTIAL_AUD__SYSTEMID__GET = 1091,
  USERS_ADMIN_SETTINGS_AUD__SYSTEMID__GET = 1094,
  USERS_ADMIN_WAREHOUSE__SYSTEMID__GET = 1095,
  USERS_ADMIN_WAREHOUSE__SYSTEMID__POST = 1096,
  USERS_EVENT_POST = 1099,
  USERS_MIGRATION__ID__GET = 1103,
  USERS_MIGRATION_BATCH_POST = 1100,
  USERS_MIGRATION_GET = 1101,
  USERS_MIGRATION_POST = 1102,
  USERS_MY_GET = 1106,
  USERS_MYPROFILEIMAGE_PUT = 1104,
  USERS_MYSETTINGS_PATCH = 1105,
  USERS_PROFILE_GET = 1108,
  USERS_PROFILE_POST = 1109,
  USERS_RESIDENTIAL_GET = 1110,
  USERS_RESIDENTIAL_PATCH = 1111,
  USERS_RESIDENTIAL_POST = 1112,
  USERS_SEARCH__USERID__GET = 1114,
  USERS_SEARCH_GET = 1113,
  USERS_SUPPORT_POST = 1115,
  USERS_WAREHOUSE_GET = 1116,
  USERS_WAREHOUSE_POST = 1117,
  WAREHOUSES__WAREHOUSEID__GET = 1132,
  WAREHOUSES_AUDIT_COMPLETE_POST = 1118,
  WAREHOUSES_AUDIT_INITIATE_POST = 1119,
  WAREHOUSES_AUDIT_RESET_PUT = 1121,
  WAREHOUSES_GET = 1122,
  WAREHOUSES_LOCATION__WAREHOUSELOCATIONID__GET = 1127,
  WAREHOUSES_LOCATION_POST = 1123,
  WAREHOUSES_LOCATION_PUT = 1124,
  WAREHOUSES_LOCATION_REPRINT_LABEL_POST = 1125,
  WAREHOUSES_LOCATION_SEARCH_GET = 1126,
  WAREHOUSES_POST = 1128,
  WAREHOUSES_RELOCATE_PACKAGE__PACKAGEID__GET = 1130,
  WAREHOUSES_RELOCATE_PACKAGE_PUT = 1129,
  WAREHOUSES_SEARCH_GET = 1131,
  FETCHPACKAGES_CANCEL_SCHEDULE_DELIVERY_MULTIPLE_POST = 1138,
  FETCHPACKAGES_FETCHPACKAGEID_LABEL_IMAGE_GET = 1163,
  FETCHPACKAGES_LABELS_ANALYZE_SEARCH_GET = 1159,
  FETCHPACKAGES_LABELS_ANALYZE_SUMMARY_GET = 1166,
  _DEVELOPER = 9999,
  ACCOUNTS_CHANGEPASSWORD_POST = 1000,
  ACCOUNTS_CONFIRMFORGOTPASSWORD_POST = 1001,
  ACCOUNTS_FORGOTPASSWORD_POST = 1002,
  ACCOUNTS_GETUSERATTRIBUTEVERIFICATIONCODE_POST = 1003,
  ACCOUNTS_LOGIN_POST = 1004,
  ACCOUNTS_POST = 1005,
  ACCOUNTS_REFRESH_POST = 1006,
  ACCOUNTS_RESENDTEMPPASSWORD_POST = 1007,
  ACCOUNTS_SETPERMANENTPASSWORD_POST = 1008,
  ACCOUNTS_VERIFYUSERATTRIBUTE_POST = 1009,
  FEATUREFLAGS__FEATUREFLAGNAME__DELETE = 1045,
  GENERAL_HEALTH_OK_GET = 1072,
  ADMIN_ROLES_POST = 1133,
  ADMIN_ROLES_SEARCH_GET = 1135,
  ADMIN_ROLES__USERROLEID__GET = 1136,
  ADMIN_ROLES__USERROLEID__DELETE = 1134,
  ADMIN_ROLES_MEMBERSHIP__USERID__POST = 1137,
  GENERAL_SETTINGS_PRETTYSENDERS_GET = 1139,
  ADMIN_ROLES_MEMBERSHIP__USERID__GET = 1140,
  WAREHOUSES_RELOCATE_PACKAGE_AUDIT_POST = 1141,
  WAREHOUSES_UNDELIVERED_PACKAGES_POST = 1142,
  PAYMENTS_PRICES_MY_GET = 1143,
  GENERAL_META_SCHEMA_GET = 1144,
  PAYMENTS_PRODUCT__PRODUCTID__GET = 1145,
  PAYMENTS_SUBSCRIPTIONS_MY_POST = 1146,
  PAYMENTS_SUBSCRIPTIONS_MY_GET = 1147,
  LABELS__LABELID__GET = 1148,
  LABELS_BUNDLES__BUNDLEID__GET = 1149,
  LABELS_BUNDLES_POST = 1150,
  FETCHPACKAGES_LABELS__LABELID__GET = 1151,
  FETCHPACKAGES_LABELS_ANALYZE_POST = 1152,
  FETCHPACKAGES_LABELS_POST = 1153,
  FETCHPACKAGES_LABELS_ANALYZE_RESULT_POST = 1154,
  LABELS_PRINT__LABELID__POST = 1155,
  LABELS_BUNDLES_PRINT__BUNDLEID__POST = 1156,
  GENERAL_META_SCHEMA_CLEAR_CACHE_POST = 1157,
  TRAINING_MY_EDUME_D_GET = 1158,
  PAYMENTS_COUPON__COUPONID__GET = 1160,
  GENERAL_SETTINGS_LOGGINGTIMEOUT_GET = 1161,
  GENERAL_SETTINGS_LOGGINGTIMEOUT_POST = 1162,
  FETCHPACKAGES__FETCHPACKAGEID__LABEL_IMAGE_GET = 1163,
  FEATUREFLAGS__FEATUREFLAGNAME__GET = 1164,
  FEATUREFLAGS_RESOLVE__USERID__GET = 1165,
  DELIVERYWINDOWS_WAREHOUSES__WAREHOUSEID__CUTOFFVOLUMES_GET = 1167,
  DELIVERYWINDOWS_WAREHOUSES__WAREHOUSEID__CUTOFFVOLUMES_POST = 1168,
  DELIVERYWINDOWS_WAREHOUSES__WAREHOUSEID__CUTOFFVOLUMES_DELETE = 1169,
  DELIVERYWINDOWS_WAREHOUSES_CUTOFFVOLUMES_SEARCH_GET = 1170,
  FETCHPACKAGES_LABELS_REPROCESS_POST = 1171,
  MAP_ROUTES_MATRIX_GET = 1172,
  FETCHPACKAGES_LABELS_FULLTEXTSEARCH_GET = 1173,
  WFM_RESOURCES_TEMPLATE_WINDOWS__WFMTEMPLATEWINDOWID__GET = 1174,
  WFM_RESOURCES_TEMPLATE_WINDOWS_POST = 1175,
  WFM_RESOURCES_TEMPLATE_WINDOWS__WFMTEMPLATEWINDOWID__PUT = 1176,
  WFM_RESOURCES_TEMPLATE_WINDOWS__WFMTEMPLATEWINDOWID__DELETE = 1177,
  WFM_RESOURCES_TEMPLATE_WINDOWS_GET = 1178,
  WFM_RESOURCES_TEMPLATE_WINDOWS__WFMTEMPLATEWINDOWID__OVERRIDES__OCCURSONDATE__GET = 1179,
  WFM_RESOURCES_TEMPLATE_WINDOWS__WFMTEMPLATEWINDOWID__OVERRIDES__OCCURSONDATE__POST = 1180,
  WFM_RESOURCES_TEMPLATE_WINDOWS__WFMTEMPLATEWINDOWID__OVERRIDES__OCCURSONDATE__PUT = 1181,
  WFM_RESOURCES_TEMPLATE_WINDOWS__WFMTEMPLATEWINDOWID__OVERRIDES__OCCURSONDATE__DELETE = 1182,
  WFM_RESOURCES_TEMPLATE_WINDOWS_OVERRIDES_GET = 1183,
  WFM_RESOURCES_BLOCKS__WFMBLOCKID__GET = 1184,
  WFM_RESOURCES_BLOCKS_POST = 1185,
  WFM_RESOURCES_BLOCKS__WFMBLOCKID__PUT = 1186,
  WFM_RESOURCES_BLOCKS_GET = 1187,
  WFM_RESOURCES_BLOCKS_SUMMARY_GET = 1188,
  WFM_RESOURCES_BLOCKS__WFMBLOCKID__NOTES_GET = 1189,
  WFM_RESOURCES_BLOCKS_PUBLISH_FOR_WEEK_POST = 1190,
  WFM_RESOURCES_BLOCKS_NOTES__WFMBLOCKNOTEID__GET = 1191,
  WFM_RESOURCES_BLOCKS_NOTES_POST = 1192,
  WFM_RESOURCES_BLOCKS_NOTES__WFMBLOCKNOTEID__PUT = 1193,
  WFM_RESOURCES_BLOCKS_NOTES__WFMBLOCKNOTEID__DELETE = 1194,
  WFM_DRIVER_BLOCKS_MY_AVAILABLE_GET = 1195,
  WFM_DRIVER_BLOCKS_MY_GET = 1196,
  WFM_DRIVER_BLOCKS_CLAIM_POST = 1197,
  WFM_DRIVER_BLOCKS__WFMBLOCKID__CANCEL_PUT = 1198,
  WFM_DRIVER_SETTINGS_MY_GET = 1199,
  WFM_DRIVER_SETTINGS_MY_PUT = 1200,
  WFM_ADMIN__USERID__SETTINGS_GET = 1201,
  WFM_ADMIN__USERID__SETTINGS_PUT = 1202,
  WFM_ADMIN__USERID__BLOCKS_CLAIM_POST = 1203,
  WFM_ADMIN__USERID__BLOCKS__WFMBLOCKID__CANCEL_PUT = 1204,
  WFM_ADMIN__USERID__BLOCKS__WFMBLOCKID__OVERFILL_PUT = 1205,
  WFM_ADMIN__USERID__BLOCKS__WFMBLOCKID__ABANDONED_PUT = 1206,
  WFM_ADMIN_PAYMENTS_ADJUSTMENTS_UPLOAD_POST = 1207,
  WFM_ADMIN_PAYMENTS_ADJUSTMENTS_UPLOADASTDF_POST = 1208,
  WFM_ADMIN_BLOCKS_SURGE_PUT = 1209,
  WFM_ADMIN__USERID__BLOCKS__WFMBLOCKID__COMPLETED_PUT = 1210,
  WFM_ADMIN__USERID__BLOCKS__WFMBLOCKID__INCOMPLETED_PUT = 1211,
  WFM_DRIVER_PAYMENTS_MY_GET = 1212,
  WFM_DRIVER_PAYMENTS_STATEMENT_MY_GET = 1213,
  WFM_ADMIN__USERID__PAYMENTS_GET = 1214,
  WFM_ADMIN__USERID__PAYMENTS_STATEMENT_GET = 1215,
  WFM_ADMIN_WINDOWS_PUT = 1216,
  WFM_ADMIN_PAYMENTS_SEARCH_GET = 1217,
  WFM_ADMIN_PAYMENTS_SEARCH_EXPORT_GET = 1225,
  ADMIN_ROLES_PRIVILEGES_GET = 1218,
  WFM_ADMIN_BLOCKS_SEARCH_GET = 1219,
  WFM_ADMIN_BLOCKS__WFMBLOCKID__CANCEL_PUT = 1220,
  WFM_ADMIN_BLOCKS__WFMBLOCKID__OVERFILL_PUT = 1221,
  WFM_ADMIN_BLOCKS__WFMBLOCKID__ABANDONED_PUT = 1222,
  WFM_ADMIN_BLOCKS__WFMBLOCKID__COMPLETED_PUT = 1223,
  WFM_ADMIN_BLOCKS__WFMBLOCKID__INCOMPLETED_PUT = 1224,
  AUTOMATEDROUTING_GET = 1228,
  WFM_ADMIN_WINDOWS_BLOCKS_ADD_POST = 1230,
  WFM_ADMIN__USERID__BLOCKS_ADD_POST = 1231,
  _COG_USER_ROLE_MEMBERSHIP_ASSIGNMENT_OVERRIDE = 5005,
  _SYSTEM_USER_IMMUTABLE_OVERRIDE = 5007,
  _USER_ROLE_MEMBERSHIP_ASSIGNMENT_OVERRIDE = 5008,
  _READ_NONPUBLIC_DATA = 5009,
  _SUPER_ROUTER_ACCESS = 5010,
  _DEVELOPER_DOC_ACCESS = 5011,
  _VOLUME_CUTOFF_ACCESS = 5012,
  _WFM_DRIVER_SETTINGS_MODIFY_PREFERRED = 5013,
  _WFM_CREATE_CUSTOM_DRIVER_WINDOW = 5014,
  BEANS_APIKEY_GET = 1235,
  V3_FEATURE_FLAGS_POST = 1249,
}

export const userPrivileges = {
  approvals: [UserPrivileges.WFM_ADMIN_BLOCKS_SEARCH_GET],
  blockSchedule: [
    UserPrivileges.WFM_RESOURCES_BLOCKS_SUMMARY_GET,
    UserPrivileges.WFM_RESOURCES_TEMPLATE_WINDOWS_GET,
  ],
  payments: [UserPrivileges.WFM_ADMIN_PAYMENTS_SEARCH_GET],
  recurringSchedule: [
    UserPrivileges.WFM_RESOURCES_TEMPLATE_WINDOWS_GET,
    UserPrivileges.WFM_RESOURCES_TEMPLATE_WINDOWS_POST,
    UserPrivileges.WFM_RESOURCES_TEMPLATE_WINDOWS__WFMTEMPLATEWINDOWID__PUT,
  ],
  windowDetail: [
    UserPrivileges.WFM_RESOURCES_BLOCKS_SUMMARY_GET,
    UserPrivileges.WFM_ADMIN__USERID__BLOCKS_CLAIM_POST,
  ],
  automatedRouting: [UserPrivileges.AUTOMATEDROUTING_GET],
  buildings: {
    search: [UserPrivileges.BUILDINGS_SEARCH_GET],
  },
  building: {
    readonly: [UserPrivileges.BUILDINGS__BUILDINGID__GET],
    write: [
      UserPrivileges.BUILDINGS__BUILDINGID__GET,
      UserPrivileges.BUILDINGS_POST,
    ],
  },
  featureFlags: {
    all: [UserPrivileges.FEATUREFLAGS_GET],
    readonly: [UserPrivileges.FEATUREFLAGS__FEATUREFLAGNAME__GET],
    create: [UserPrivileges.V3_FEATURE_FLAGS_POST],
    write: [
      UserPrivileges.FEATUREFLAGS__FEATUREFLAGNAME__GET,
      UserPrivileges.FEATUREFLAGS_PUT,
    ],
  },
};
