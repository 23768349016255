import { IPackageStoreType } from "slices/package/package.reducer";
import { IRootStateType } from "types/IRootStateType";

export const CLEAR_CANCELLED_SCHEDULED_DELIVERIES =
  "CLEAR_CANCELLED_SCHEDULED_DELIVERIES";
export const CANCEL_SCHEDULED_DELIVERIES = "CANCEL_SCHEDULED_DELIVERIES";
export const CANCEL_SCHEDULED_DELIVERIES_SUCCESS =
  "CANCEL_SCHEDULED_DELIVERIES_SUCCESS";

export interface IClearCancelScheduledDeliveriesActionType {
  type: typeof CLEAR_CANCELLED_SCHEDULED_DELIVERIES;
}

export interface CancelScheduledDeliveriesSuccessActionType {
  type: typeof CANCEL_SCHEDULED_DELIVERIES_SUCCESS;
  payload: IRootStateType["cancelledScheduledDeliveryOutcomes"];
}

export interface CancelScheduledDeliveriesActionType {
  type: typeof CANCEL_SCHEDULED_DELIVERIES;
  payload: {
    ids: IPackageStoreType["fetchPackageId"][];
  };
}

function cancelScheduledDeliveries(
  payload: CancelScheduledDeliveriesActionType["payload"],
): CancelScheduledDeliveriesActionType {
  return { type: CANCEL_SCHEDULED_DELIVERIES, payload };
}

function clearCancelScheduledDeliveries(): IClearCancelScheduledDeliveriesActionType {
  return { type: CLEAR_CANCELLED_SCHEDULED_DELIVERIES };
}

function cancelScheduledDeliveriesSuccess(
  payload: CancelScheduledDeliveriesSuccessActionType["payload"],
): CancelScheduledDeliveriesSuccessActionType {
  return { type: CANCEL_SCHEDULED_DELIVERIES_SUCCESS, payload };
}

export const cancelledScheduledDeliveryOutcomesActions = {
  cancelScheduledDeliveries,
  clearCancelScheduledDeliveries,
  cancelScheduledDeliveriesSuccess,
};
