import { Reducer } from "redux";
import { FetchError } from "slices/authentication/network.actions";

import {
  actionTypes,
  ClearAction,
  FullfilledAction,
  RejectedAction,
  RequestAction,
} from "./actions";

export type ResponseBody = unknown;

export type RequestBody = {
  query: {
    warehouseId: number;
    startDate: string;
    numberOfDays: number;
  } | null;
};

export type WfmDeliveryWindowScheduleState = {
  error: FetchError | null;
  loading: boolean;
  response: ResponseBody | null;
  request: RequestBody;
};

export const defaultState: WfmDeliveryWindowScheduleState = {
  error: null,
  loading: false,
  response: null,
  request: { query: null },
};

export const reducer: Reducer<
  WfmDeliveryWindowScheduleState,
  RequestAction | FullfilledAction | RejectedAction | ClearAction
> = (state = defaultState, action): WfmDeliveryWindowScheduleState => {
  switch (action.type) {
    case actionTypes.request:
      return {
        ...state,
        loading: true,
        request: action.payload,
        response: null,
        error: null,
      };
    case actionTypes.fullfilled:
      return {
        ...state,
        loading: false,
        response: action.payload.response,
      };
    case actionTypes.rejected:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.clear:
      return defaultState;
    default:
      return state;
  }
};
