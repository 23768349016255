import { defaultState } from "default-state";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";
import {
  ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS_SUCCESS,
  CLEAR_USER,
  GET_ADMIN_USER_SUCCESS,
  GET_USER_SUCCESS,
  IAdminGetUserAutoScheduleOptionsSuccessActionType,
} from "slices/user/user.actions";

function adminGetUserAutoScheduleOptionsSuccess(
  state: Partial<IUserStoreType>,
  action: IAdminGetUserAutoScheduleOptionsSuccessActionType,
): Partial<IUserStoreType> {
  return {
    ...state,
    autoScheduleOptions: action.response,
  };
}

export function user(
  state = defaultState.user,
  action: UserActionsTypes,
): Partial<IUserStoreType> {
  switch (action.type) {
    case CLEAR_USER:
      return defaultState.user;
    case GET_ADMIN_USER_SUCCESS:
      return action.payload;
    case GET_USER_SUCCESS:
      return action.payload;
    case ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS_SUCCESS:
      return adminGetUserAutoScheduleOptionsSuccess(state, action);
    default:
      return state;
  }
}

// type definitions
export type UserActionsTypes =
  | IAdminGetUserSuccessActionType
  | IGetUserSuccessActionType
  | IClearUserActionType
  | IAdminGetUserAutoScheduleOptionsSuccessActionType;

interface IAdminGetUserSuccessActionType {
  type: typeof GET_ADMIN_USER_SUCCESS;
  payload: IUserStoreType;
}
interface IGetUserSuccessActionType {
  type: typeof GET_USER_SUCCESS;
  payload: IUserStoreType;
}
interface IClearUserActionType {
  type: typeof CLEAR_USER;
}
