import moment, { Duration } from "moment";
import { FetchDesignDateSelectProps } from "views/fetch-design/FetchDesignDateSelect/FetchDesignDateSelect";

export function getPrettyDetailedTimeString(
  momentTime: moment.Moment,
  timezone?: string,
): string {
  // should pass through desired timezone, i.e. warehouseTimezone, else default to system
  if (timezone) {
    momentTime = momentTime.tz(timezone);
  }
  return momentTime.isValid() ? momentTime.format("MM/DD/YY HH:mm:ss z") : "";
}

export function getDateString(dateTime: Date): string {
  return moment(dateTime).format("MM-DD-YYYY");
}

export function getVerboseDateString(
  dateTime: Date,
  timezone?: string,
): string {
  return !!timezone
    ? moment(dateTime).tz(timezone).format("ddd, MMM Do")
    : moment(dateTime).format("ddd, MMM Do");
}

export function getTodaysDateString(): string {
  const today = new Date();
  return getDateString(today);
}

export function formatWindow(
  startTimeString: string,
  endTimeString: string,
  warehouseTimezone: string,
): string {
  if (
    !startTimeString ||
    startTimeString === "" ||
    !endTimeString ||
    endTimeString === ""
  ) {
    return "";
  }
  return (
    moment.utc(startTimeString).tz(warehouseTimezone).format("MM/DD HH") +
    "-" +
    moment.utc(endTimeString).tz(warehouseTimezone).format("HH")
  );
}

export function getPrettyDateString(
  momentTime: moment.Moment,
  timezone?: string,
): string {
  // should pass through desired timezone, i.e. warehouseTimezone, else default to system
  if (timezone) {
    momentTime = momentTime.tz(timezone);
  }
  return momentTime.format("dddd M/DD");
}

export function getPrettyHourOfDayString(
  momentTime: moment.Moment,
  timezone?: string,
): string {
  // should pass through desired timezone, i.e. warehouseTimezone, else default to system
  if (timezone) {
    momentTime = momentTime.tz(timezone);
  }
  return momentTime.format("h A");
}

export function getPrettyTimeOfDayString(
  momentTime: moment.Moment,
  timezone?: string,
): string {
  // should pass through desired timezone, i.e. warehouseTimezone, else default to system
  if (timezone) {
    momentTime = momentTime.tz(timezone);
  }
  return momentTime.format("h:mm A");
}

export function dualFormatWindowString(
  deliveryStartTime: moment.Moment,
  deliveryEndTime: moment.Moment,
): string {
  return (
    deliveryStartTime.format("HH") +
    "-" +
    deliveryEndTime.format("HH") +
    " (" +
    deliveryStartTime.format("ha") +
    "-" +
    deliveryEndTime.format("ha") +
    ")"
  );
}

export function formatDeliveryWindowString(
  startTimeString: string,
  endTimeString: string,
  warehouseTimezone: string,
): string {
  if (
    !startTimeString ||
    startTimeString === "" ||
    !endTimeString ||
    endTimeString === ""
  ) {
    return "";
  }
  return (
    moment.tz(startTimeString, warehouseTimezone).format("MM/DD HH") +
    "-" +
    moment.tz(endTimeString, warehouseTimezone).format("HH")
  );
}

export function twentyFourToTwelveHour(time: number): string {
  const inputTimeFormat = "HH";
  const prettyTimeFormat = "ha";
  return moment(time, inputTimeFormat).format(prettyTimeFormat);
}

export function apiStringToDateTime(apiDateTimeString: string): moment.Moment {
  return moment(apiDateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSSZ");
}

// receives datetime from graphql without a timezone, and converts to correct UTC-based moment
export function graphQLStringToDateTime(
  apiDateTimeString: string,
): moment.Moment {
  return moment.utc(apiDateTimeString);
}

export function formatApiTimeToString(
  apiDateTimeString: string | null,
): string | null {
  if (!apiDateTimeString) {
    return null;
  }
  const date = apiStringToDateTime(apiDateTimeString);
  return date.format("MM/DD/YY HH:mm:ss");
}

export function getNowInApiDateTimeString(): string {
  return new Date().toISOString();
}

export function formatDeliveredDateString(
  deliveredDate: string | undefined,
): string {
  const momentDate = moment(deliveredDate, "YYYY-MM-DDTHH:mm:ss.SSSSZ");

  return !momentDate.isValid() ? "" : momentDate.format("MM/DD/YY HH:mm:ss");
}

export function formatStartDateString(
  deliveredDate: string | undefined,
  timezone?: string,
): string {
  const momentDate = !!timezone
    ? moment(deliveredDate, "YYYY-MM-DDTHH:mm:ss.SSSSZ").tz(timezone)
    : moment(deliveredDate, "YYYY-MM-DDTHH:mm:ss.SSSSZ");

  return !momentDate.isValid() ? "" : momentDate.format("dddd MMM DD, YYYY");
}

export const formatDuration = (duration: Duration) => {
  const prepend = duration.asMilliseconds() < 0 ? "-" : "";
  const hours = Math.abs(duration.hours());
  const minutes = String(Math.abs(duration.minutes())).padStart(2, "0");
  const seconds = String(Math.abs(duration.seconds())).padStart(2, "0");
  return `${prepend}${hours}:${minutes}:${seconds}`;
};

// use as comparator for two date strings i.e. "2023-03-08T17:01:38.754"
export const compareDateStrings = (
  dateStringA: string,
  dateStringB: string,
): number => {
  const momentA = apiStringToDateTime(dateStringA);
  const momentB = apiStringToDateTime(dateStringB);
  return momentA.valueOf() - momentB.valueOf();
};

/**
 * The actual 8601 spec is leniant when it comes to milliseconds in the date-time string
 * However, on our backend, we REQUIRE the milliseconds to be present when formatting date-time strings.
 */
export function get8601SpecDateTimeString(date: moment.Moment) {
  return date?.format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
}

export function deliveryWindowOptionFormatter(
  startTime: string,
  endTime: string,
  selectedDeliveryDate: moment.Moment,
  timezone?: string,
) {
  const startString = moment(startTime).format("h A");
  const endString = moment(endTime).format("h A");

  return `${startString} - ${endString} ${dateTextRelativeToToday(
    selectedDeliveryDate,
    timezone,
  )}`;
}

export function dateTextRelativeToToday(
  selectedDate: FetchDesignDateSelectProps["value"],
  timezone?: string,
) {
  if (!selectedDate) return "";
  if (typeof selectedDate === "string") return "";
  const today = timezone ? moment().tz(timezone) : moment();
  const targetDate = timezone
    ? moment(selectedDate).tz(timezone)
    : moment(selectedDate);
  if (targetDate.isSame(today, "day")) return "Today";
  if (targetDate.isSame(today.add(1, "day"), "day")) return "Tomorrow";
  else return targetDate.format("dddd, MMMM D");
}
