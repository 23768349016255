import { FeatureFlags } from "constants/feature-flags";
import { CurrentUserState } from "slices/currentUser/currentUser.reducer";

export const getCurrentTreatBundleUrls = (
  currentUser?: CurrentUserState,
): string[] => {
  const treatsTargetedList =
    currentUser?.features?.[FeatureFlags["treats-targeted-list"]];
  if (
    treatsTargetedList &&
    typeof treatsTargetedList === "object" &&
    "data" in treatsTargetedList &&
    "warehouse-meta-treats" in treatsTargetedList.data
  ) {
    const warehouseMetaTreats =
      treatsTargetedList.data["warehouse-meta-treats"];

    if (Array.isArray(warehouseMetaTreats)) {
      const current = new Date().toISOString();

      return warehouseMetaTreats
        .filter((metadata: unknown) => {
          if (
            metadata &&
            typeof metadata === "object" &&
            "startDate" in metadata &&
            typeof metadata.startDate === "string" &&
            "endDate" in metadata &&
            typeof metadata.endDate === "string"
          ) {
            try {
              return (
                metadata.startDate &&
                metadata.endDate &&
                current >= new Date(metadata.startDate).toISOString() &&
                current < new Date(metadata.endDate).toISOString()
              );
            } catch (error) {
              return false;
            }
          } else {
            return false;
          }
        })
        .map(({ treatBundleUrl }): string => treatBundleUrl as string);
    }
  }

  return [];
};
