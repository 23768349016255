import { Reducer } from "redux";
import { IPaginatedResponseType } from "types/IPaginatedResponseType";
import {
  GetFetchPackagesLabelsFullTextSearchAction,
  GetFetchPackagesLabelsFullTextSearchSuccessAction,
  GetFetchPackagesLabelsFullTextSearchFailureAction,
  ClearFetchPackagesLabelsFullTextSearchAction,
  GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_SUCCESS,
  GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_FAILURE,
  CLEAR_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH,
} from "./FullTextSearch.actions";

export interface IFullTextSearchContentType {
  fetchPackageId: number;
  warehouseId: number;
  ocrWords: string;
  barcodeWords: string;
  imgUrl: string;
  createdDate: string;
}

export interface IFullTextSearchStore extends IPaginatedResponseType {
  content: IFullTextSearchContentType[];
}

type fullTextSearchActions =
  | GetFetchPackagesLabelsFullTextSearchAction
  | GetFetchPackagesLabelsFullTextSearchSuccessAction
  | GetFetchPackagesLabelsFullTextSearchFailureAction
  | ClearFetchPackagesLabelsFullTextSearchAction;

export const fullTextSearchReducer: Reducer<
  IFullTextSearchStore | null,
  fullTextSearchActions
> = (state = null, action: fullTextSearchActions) => {
  switch (action.type) {
    case CLEAR_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH:
      return null;
    case GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_SUCCESS:
      return action?.payload;
    case GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_FAILURE:
      return null;
    default:
      return state;
  }
};
