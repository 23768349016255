import React, { useEffect } from "react";

import { Spinner } from "reactstrap";

import { BEM } from "helpers/BEM.helper";
import imageSrc from "assets/img/fetch-package-logo-full-white.svg";

const classes = new BEM({
  block: { name: "LoadingPage", extras: ["fade-in-animation"] },
  elements: [{ name: "container" }, { name: "logo" }, { name: "spinner" }],
});

export const LoadingPage: React.FC = () => {
  useEffect(() => {
    document.body.classList.toggle("loading-page");
    return () => {
      document.body.classList.toggle("loading-page");
    };
  }, []);

  return (
    <div className={classes.getBlockClassNames()}>
      <div className={classes.getElementClassNames("logo")}>
        <img src={imageSrc} alt="Fetch Package" width="100%" />
      </div>
      <div className={classes.getElementClassNames("spinner")}>
        <Spinner animation="border" color="light" size="large" />
      </div>
    </div>
  );
};
