import { FetchError } from "slices/authentication/network.actions";
import { GET_WFM_RESOURCES_TEMPLATE_WINDOWS } from "./actions";

const sliceName = "wfmResourcesTemplateWindows";

export const actionTypes = {
  request: `${sliceName}/request`,
  fullfilled: `${sliceName}/fullfilled`,
  rejected: `${sliceName}/rejected`,
  clear: `${sliceName}/clear`,
} as const;

export type Payload = {
  query: IWfmResourcesTemplateWindowQuery;
};
export type ResponseBody = unknown;
export type ResponseError = null;

export type ActionType = {
  type: typeof GET_WFM_RESOURCES_TEMPLATE_WINDOWS;
  payload: Payload;
};

export type IWfmResourcesTemplateWindowQuery = {
  warehouseId: number;
  forWeekOfDate?: string;
};

export type WfmResourcesTemplateWindowState = {
  error: FetchError | null;
  loading: boolean;
  response: unknown | null;
  request: {
    query?: IWfmResourcesTemplateWindowQuery;
  } | null;
};

export type WfmTemplateWindowSearchResponse = {
  wfmTemplateWindows: WfmTemplateWindow[];
};

export type WfmTemplateWindow = {
  wfmTemplateWindowId: number;
  warehouseId: number;
  isoDayOfWeek: number;
  startTime: string;
  endTime: string;
  driverClassification: number;
  fetchOfferingType: number;
  driverQuantity: number;
  arrivalPriorMinutes?: number;
  pay: number;
  overridden?: {
    wfmTemplateWindowId: number;
    occursOnDate: Date;
    driverQuantity: number;
    arrivalPriorMinutes: number;
    pay: number;
  };
};

type RequestPayload = {
  query?: IWfmResourcesTemplateWindowQuery;
};

type FullfilledPayload = Pick<WfmResourcesTemplateWindowState, "response">;
type RejectedPayload = Pick<WfmResourcesTemplateWindowState, "error">;

export type ClearAction = {
  type: typeof actionTypes.clear;
};
export type RequestAction = {
  type: typeof actionTypes.request;
  payload: RequestPayload;
};
export type FullfilledAction = {
  type: typeof actionTypes.fullfilled;
  payload: FullfilledPayload;
};
export type RejectedAction = {
  type: typeof actionTypes.rejected;
  payload: RejectedPayload;
};
