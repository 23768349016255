import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { NavRoute } from "routes/navRoutes";
import { generateBemClassNames } from "helpers/generateBemClassNames.helper";
import warningCircle from "assets/img/warning-circle.svg";
import { FetchIcon } from "views/common/misc/FetchIcon";

const classes = generateBemClassNames({
  block: { name: "Sidebar" },
  elements: [{ name: "subLink" }],
});

interface FetchNavSubLinkProps {
  route: NavRoute;
}

const Component: React.FC<FetchNavSubLinkProps> = ({
  route,
}: FetchNavSubLinkProps) => {
  const location = useLocation();
  const active = location.pathname === route.path;

  return (
    <li className={active ? "active" : "" + "d-flex justify-content-between"}>
      <NavLink
        data-cy={`navLink-${route.name}`}
        to={route.path}
        activeClassName=""
        className={classes.elements.subLink()}
      >
        {route.icon !== undefined ? (
          <>
            <FetchIcon
              iconType={route.icon}
              size="lg"
              className="fa-pull-left"
            />
            <p>{route.name}</p>
          </>
        ) : (
          <>
            <span className="sidebar-mini-icon" />
            <span className="sidebar-normal d-flex justify-content-between align-items-center">
              <span>
                {route.name}{" "}
                {route.inErrorState && (
                  <img
                    className="sidebar-sublink-error"
                    style={{ marginLeft: "10px" }}
                    src={warningCircle}
                  />
                )}
              </span>
            </span>
          </>
        )}
      </NavLink>
    </li>
  );
};

export const FetchNavSubLink = React.memo(Component);
