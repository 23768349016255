import { Reducer } from "@reduxjs/toolkit";
import {
  LOGIN_SUCCESS,
  LOGOUT,
} from "slices/authentication/authentication.actions";
import { UserPrivileges } from "types/UserPrivileges";
import {
  ILoadMyUserSuccessType,
  IPatchMySettingsSuccessActionType,
  IGetAutoScheduleOptionsSuccessActionType,
  LOAD_MY_USER_SUCCESS,
  SAVE_MY_USER_SUCCESS,
  SAVE_MY_RESIDENTIAL_USER_SUCCESS,
  SAVE_MY_WAREHOUSE_USER_SUCCESS,
  PATCH_MY_SETTINGS_SUCCESS,
  GET_AUTO_SCHEDULE_OPTIONS_SUCCESS,
  ISaveMyUserSuccessActionType,
  ILoginSuccessType,
  ILogoutType,
  ISaveMyResidentialUserSuccessActionType,
  ISaveMyWarehouseUserSuccessActionType,
} from "./currentUser.actions";
import { FeatureFlags } from "constants/feature-flags";

export interface IResidentNotificationSettings {
  residentGlobalNotificationsEmailEnabled: boolean;
  residentGlobalNotificationsSMSEnabled: boolean;
  residentGlobalNotificationsPushEnabled: boolean;
}

export interface IDriverNotificationSettings {
  retrieverGlobalNotificationsEmailEnabled: boolean;
  retrieverGlobalNotificationsSMSEnabled: boolean;
  retrieverGlobalNotificationsPushEnabled: boolean;
}

export interface IUserSettings
  extends IResidentNotificationSettings,
    IDriverNotificationSettings {
  residentAutoScheduleSaturdays: number;
  residentAutoScheduleSundays: number;
  residentAutoScheduleWeekdays: number;
}

type CurrentUserActionTypes =
  | ILoginSuccessType
  | ILoadMyUserSuccessType
  | ILogoutType
  | ISaveMyUserSuccessActionType
  | IPatchMySettingsSuccessActionType
  | IGetAutoScheduleOptionsSuccessActionType
  | ISaveMyResidentialUserSuccessActionType
  | ISaveMyWarehouseUserSuccessActionType;

export interface IResidentialUserStoreType {
  residentialUserId: number;
  market: number;
  buildingId: number;
  buildingUnitId: number;
  packageDeliveryPreference: number;
  buildingPackageDeliveryPolicy: number;
  userCode: string;
  warehouseId: number;
  warehouseTimezone: string;
  warehouseAddressLine1: string;
  warehouseAddressCity: string;
  warehouseAddressState: string;
  warehouseAddressPostalCode: string;
  leaveLiabilityAccepted?: string;
  termsAccepted: string;
}

export interface IWarehouseUserStoreType {
  warehouseUserId: number;
  preferredWarehouseId: number;
  preferredWarehouseName: string;
  preferredPrinter?: string;
  preferredPrinterId?: number;
  preferredWarehouseShortcode: string;
}

export type AutoScheduleOptionsIndex =
  | "weekdayOptions"
  | "saturdayOptions"
  | "sundayOptions";

export interface WindowOptions {
  fetchOfferingType: number;
  deliveryWindowId: number;
  startTime: string;
  endTime: string;
}

export type AutoScheduleOptions = {
  [key in AutoScheduleOptionsIndex]: WindowOptions[];
};

interface TreatsFeatureFlagMetadata {
  endDate: string;
  startDate: string;
  treatBundleUrl: string;
}

export interface StorageFeaturePaymentsData {
  enabled: boolean;
  couponId: string;
  trialDays: number;
  trialEnabled: boolean;
  couponEnabled: boolean;
  couponPercentOff: number;
  uiVersion: number;
}

// TO DO: separate UserSearchDTO (in user search) from UserDTO (in login)
export interface IUserStoreType {
  active: boolean;
  supportId: number;
  cognitoStatus?: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string | null;
  systemId: string;
  userId: number;
  settings: IUserSettings;
  hasRoleAdministrator?: boolean;
  hasRoleDriver?: boolean;
  hasRoleResident?: boolean;
  hasRoleWarehouse?: boolean;
  isAdminUser?: boolean;
  residentialUser?: IResidentialUserStoreType;
  driverUser?: {
    driverUserId: number;
    driverStatus: string;
    marketIds?: number[];
    warehouseIds?: number[];
    preferred: boolean;
    vendorPaymentUserId: string;
    vendorPaymentUserIdSource: string;
  };
  warehouseUser?: IWarehouseUserStoreType;
  autoScheduleOptions?: AutoScheduleOptions;
  features?: {
    [featureFlagName in FeatureFlags]?: {
      featureFlagName?: FeatureFlags;
      data: any;
    };
  };
  privileges: UserPrivileges[];
}

export type CurrentUserState = IUserStoreType | null | undefined;

export type UserUpdateType = Pick<
  IUserStoreType,
  "email" | "firstName" | "lastName" | "phone" | "userId"
>;

export const currentUser: Reducer<CurrentUserState, CurrentUserActionTypes> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.payload.user;
    case LOGOUT:
      return null;
    case LOAD_MY_USER_SUCCESS:
      return action.payload;
    case SAVE_MY_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_MY_RESIDENTIAL_USER_SUCCESS:
      if (state) {
        return {
          ...state,
          residentialUser: action.payload,
        };
      }
      return state;
    case SAVE_MY_WAREHOUSE_USER_SUCCESS:
      if (state) {
        return {
          ...state,
          warehouseUser: action.payload,
        };
      }
      return state;
    case PATCH_MY_SETTINGS_SUCCESS:
      if (state) {
        return { ...state, settings: action.payload };
      }
      return state;
    case GET_AUTO_SCHEDULE_OPTIONS_SUCCESS:
      if (state) {
        return {
          ...state,
          autoScheduleOptions: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
};
