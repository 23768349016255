import React, { useEffect } from "react";

import { Col, Container } from "reactstrap";

import { BEM } from "helpers/BEM.helper";
import ResetPasswordFormCard from "views/components/cards/ResetPasswordFormCard";

export const ResetPasswordPage: React.FC = () => {
  useEffect(() => {
    document.body.classList.toggle("reset-password-page");

    return () => {
      document.body.classList.toggle("reset-password-page");
    };
  });

  const classes = new BEM({
    block: {
      extras: ["d-flex", "justify-content-center"],
      name: "ResetPasswordPage",
    },
    elements: [{ name: "column", extras: [] }],
  });

  return (
    <div className="beautiful-background">
      <Container className={classes.getBlockClassNames()} fluid={true}>
        <Col
          className={classes.getElementClassNames("column")}
          {...{
            lg: "4",
            md: "6",
            sm: "8",
          }}
        >
          <ResetPasswordFormCard />
        </Col>
      </Container>
    </div>
  );
};
