import React from "react";

import { BEM } from "helpers/BEM.helper";
import { FetchConnect } from "helpers/FetchConnect";
export interface FetchPolicyLinkProps {
  url: string;
}

export const FetchPolicyLink: React.FC<FetchPolicyLinkProps> = (
  props: FetchPolicyLinkProps,
) => {
  const { url } = props;

  const classes = new BEM({
    block: { name: "FetchPolicyLink" },
    elements: [{ name: "link" }],
    prefix: { name: "fetch" },
  });

  return (
    <a
      className={classes.getElementClassNames("link")}
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    >
      Privacy Policy
    </a>
  );
};

export default FetchConnect({
  ConnectedComponent: FetchPolicyLink,
  state: { stores: ["applicationSettings"] },
});
