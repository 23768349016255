import {
  IGetWarehouseLocationSuccessActionType,
  IClearWarehouseLocationActionType,
  CLEAR_WAREHOUSE_LOCATION,
  GET_WAREHOUSE_LOCATION_SUCCESS,
} from "slices/warehouseLocation/warehouseLocation.actions";
import { defaultState } from "default-state";

export interface IWarehouseLocationStoreType {
  warehouseLocationId: number;
  designator: string;
  packageType: number;
  buildingId: number;
  locationType: number;
  deliveryWindowId: number;
  locationCode: string;
  buildingName: string;
  buildingShortCode: string;
  warehouseShortCode: string;
  deliveryWindowStartHour: number;
  warehouseId: number;
}

export function warehouseLocation(
  state = {},
  action: WarehouseLocationActionsTypes,
): Partial<IWarehouseLocationStoreType> {
  switch (action.type) {
    case CLEAR_WAREHOUSE_LOCATION:
      return defaultState.warehouseLocation;
    case GET_WAREHOUSE_LOCATION_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

// type definitions
type WarehouseLocationActionsTypes =
  | IGetWarehouseLocationSuccessActionType
  | IClearWarehouseLocationActionType;
