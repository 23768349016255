import { takeLatest, ForkEffect, put, select } from "redux-saga/effects";
import { IAuthenticationStateType } from "slices/authentication/authentication.reducer";

import { IRootStateType } from "types/IRootStateType";
import {
  uiActions,
  TRIGGER_NEW_SESSION_ALERTS,
  ALERT_UNVERIFIED_PHONE,
  ALERT_UNVERIFIED_EMAIL,
} from "./ui.actions";
import { IUIStoreType, INotificationType } from "./ui.reducer";

function* triggerNewSessionAlertsWorker() {
  yield put(uiActions.alertUnverifiedPhone());
  yield put(uiActions.alertUnverifiedEmail());
}

function* alertUnverifiedPhoneWorker() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const authentication: IAuthenticationStateType = yield select(
    (state: IRootStateType) => state.authentication,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { notifications }: IUIStoreType = yield select(
    (state: IRootStateType) => state.ui,
  );

  if (
    !authentication.tokens ||
    (authentication.tokens &&
      !authentication.tokens.phoneVerified &&
      notifications.filter(
        ({ id }: INotificationType) => id === "alertUnverifiedPhone",
      ).length === 0)
  ) {
    yield put(
      uiActions.addNotification({
        id: "alertUnverifiedPhone",
        message: "Please click here to verify your phone number",
        title: "Unverified Phone Number",
      }),
    );
  }
}

function* alertUnverifiedEmailWorker() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const authentication: IAuthenticationStateType = yield select(
    (state: IRootStateType) => state.authentication,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { notifications }: IUIStoreType = yield select(
    (state: IRootStateType) => state.ui,
  );

  if (
    !authentication.tokens ||
    (authentication.tokens &&
      !authentication.tokens.emailVerified &&
      notifications.filter(
        ({ id }: INotificationType) => id === "alertUnverifiedEmail",
      ).length === 0)
  ) {
    yield put(
      uiActions.addNotification({
        id: "alertUnverifiedEmail",
        message: "Please click here to verify your email address.",
        title: "Unverified Email Address",
      }),
    );
  }
}

export const uiSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(TRIGGER_NEW_SESSION_ALERTS, triggerNewSessionAlertsWorker);
  yield takeLatest(ALERT_UNVERIFIED_PHONE, alertUnverifiedPhoneWorker);
  yield takeLatest(ALERT_UNVERIFIED_EMAIL, alertUnverifiedEmailWorker);
};
