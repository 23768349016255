import {
  CLEAR_WAREHOUSE,
  GET_WAREHOUSE_SUCCESS,
  IClearWarehouseActionType,
  IGetWarehouseSuccessActionType,
} from "slices/warehouse/warehouse.actions";
import { defaultState } from "default-state";
import { ICoordinateType } from "slices/building/building.reducer";

export interface IDeliveryWindowType {
  deliveryWindowId: number;
  warehouseId: number;
  availableOnMonday: boolean;
  availableOnTuesday: boolean;
  availableOnWednesday: boolean;
  availableOnThursday: boolean;
  availableOnFriday: boolean;
  availableOnSaturday: boolean;
  availableOnSunday: boolean;
  startHour: number;
  endHour: number;
  cutOffTime?: string;
  active: boolean;
}

export interface IWarehouseHoursType {
  date: string;
  open: string;
  close: string;
  message: string | null;
}

export type StandardHoursIndex =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export interface IWarehouseStoreType {
  warehouseId: number;
  name: string;
  shortcode: string;
  timezone: string;
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  coordinates: ICoordinateType;
  deliveryWindows: IDeliveryWindowType[];
  hours: IWarehouseHoursType[];
  standardHours: Record<StandardHoursIndex, { open: string; close: string }>;
  market: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any;
}

export function warehouse(
  state = {},
  action: WarehouseActionsTypes,
): Partial<IWarehouseStoreType> {
  switch (action.type) {
    case CLEAR_WAREHOUSE:
      return defaultState.warehouse;
    case GET_WAREHOUSE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

// type definitions
type WarehouseActionsTypes =
  | IGetWarehouseSuccessActionType
  | IClearWarehouseActionType;
