import React from "react";

interface FetchFieldLabelProps {
  label?: string | { text: string; tag: string };
  className: string;
}

export const FetchFieldLabel: React.FC<FetchFieldLabelProps> = (
  props: FetchFieldLabelProps,
) => {
  const { label, className } = props;

  switch (typeof label) {
    case "string":
      return <div className={className}>{label}</div>;
    case "object":
      const { text, tag } = label;
      return (
        <div className={className}>
          {text}: {tag}
        </div>
      );
    default:
      return <div className={className}>{label}</div>;
  }
};
