type ClassNameOptions = {
  extras?: string[];
  modifiers?: string[];
};

export type ClassNameCalculator = (
  classNameOptions?: ClassNameOptions,
) => string;

interface NodeOptions<Name extends string> extends ClassNameOptions {
  name: Name;
}

export function generateBemClassNames<Elements extends string>({
  prefix,
  block,
  elements,
}: {
  prefix?: string;
  block: NodeOptions<string>;
  elements: NodeOptions<Elements>[];
}): {
  block: ClassNameCalculator;
  elements: {
    [elementName in (typeof elements)[number]["name"]]: ClassNameCalculator;
  };
} {
  const blockName = block.name;
  const blockBase = !!prefix ? `${prefix}-${blockName}` : blockName;
  const calculatedElements = elements.reduce(
    (
      elementClassNamesByElementName: {
        block: ClassNameCalculator;
        elements: {
          [elementName in (typeof elements)[number]["name"]]: ClassNameCalculator;
        };
      }["elements"],
      element: (typeof elements)[number],
    ) => {
      const elementName = element.name;
      const elementBase = `${blockBase}__${elementName}`;
      elementClassNamesByElementName[elementName] = getClassNameCalculator(
        elementBase,
        {
          extras: element.extras,
          modifiers: element.modifiers,
        },
      );
      return elementClassNamesByElementName;
    },
    {} as {
      block: ClassNameCalculator;
      elements: {
        [elementName in (typeof elements)[number]["name"]]: ClassNameCalculator;
      };
    }["elements"],
  );

  return {
    block: getClassNameCalculator(blockBase, {
      extras: block.extras,
      modifiers: block.modifiers,
    }),
    elements: calculatedElements,
  };
}

function getClassNameCalculator(
  nodeBase: string,
  defaults: ClassNameOptions,
): ClassNameCalculator {
  return (changes) => {
    const defaultExtras = defaults?.extras;
    const addedExtras = changes?.extras;
    const defaultModifiers = defaults?.modifiers;
    const addedModifiers = changes?.modifiers;

    const nodeExtras: string =
      getExtras(defaultExtras) + getExtras(addedExtras) || "";
    const nodeModifiers: string =
      getModifiers(nodeBase, defaultModifiers) +
        getModifiers(nodeBase, addedModifiers) || "";

    return `${nodeBase}${nodeModifiers}${nodeExtras}`;
  };
}

function getExtras(extras: string[] | undefined): string {
  return !!extras ? ` ${extras.join(" ")}` : "";
}

function getModifiers(base: string, modifiers: string[] | undefined): string {
  return !!modifiers
    ? modifiers.reduce((modifiedNodeClassNames: string, modifier: string) => {
        return `${modifiedNodeClassNames} ${base}--${modifier}`;
      }, "")
    : "";
}

/*
  //SAMPLE USAGE
  import React from "react";

  import { generateBemClassNames } from "helpers/generateBemClassNames.helper";

  export const {
    block,
    elements: { clickMeButton },
  } = generateBemClassNames({
    prefix: "Prefix",
    block: {
      name: "TestComponent",
      extras: ["defaultExtra"],
      modifiers: ["defaultModifier"],
    },
    elements: [
      {
        name: "clickMeButton",
        modifiers: ["defaultModifier"],
        extras: ["defaultExtra"],
      },
    ],
  });

  export function TestComponent(): JSX.Element {
    return (
      <div
        className={block({
          extras: ["addedExtra"],
          modifiers: ["addedModifier"],
        })}
      >
        Example
        <button
          className={clickMeButton({
            extras: ["addedExtra"],
            modifiers: ["addedModifier"],
          })}
        >
          Click Me
        </button>
      </div>
    );
  }
*/
