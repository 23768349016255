import { Reducer } from "redux";

import {
  GetAllMyPricesV2Action,
  GetAllMyPricesV2SuccessAction,
  ClearPricesAction,
  CLEAR_PRICES,
  GET_ALL_MY_PRICES_V2_SUCCESS,
} from "slices/prices/prices.actions";

export interface Price {
  id: string;
  priceName: string;
  unitAmountDecimal: number;
  productId: string;
  productName: string;
  productDescription: string;
  priceMeta: {
    category: string;
    default: boolean;
    version: number;
  };
  productMeta: {
    productNameShort: string;
    productDetailDescription: string;
    iconUrl: string;
  };
  imgUrl: Array<string>;
  bundleDiscount: string;
  unitLabel: string;
}

export type PricesStore = Price[];

type PricesActions =
  | ClearPricesAction
  | GetAllMyPricesV2Action
  | GetAllMyPricesV2SuccessAction;

export const prices: Reducer<PricesStore, PricesActions> = (
  state = [],
  action,
) => {
  switch (action.type) {
    case CLEAR_PRICES:
      return [];
    case GET_ALL_MY_PRICES_V2_SUCCESS:
      return action.payload.prices;
    default:
      return state;
  }
};
