import React from "react";

interface CardLoadingComponentProps {
  display: boolean;
}

export const CardLoadingComponent: React.FC<CardLoadingComponentProps> = ({
  display,
}: CardLoadingComponentProps) => {
  if (!display) {
    return null;
  }
  return (
    <div className={"loading-card-overlay"}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
