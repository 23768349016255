import moment from "moment-timezone";
import { generateQueryString } from "helpers/generateQueryString.helper";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IBuildingFormType } from "views/components/forms/BuildingForm";
import { IBuildingStoreType } from "slices/building/building.reducer";

function saveBuilding(
  parameters: IBuildingStoreType,
): IAuthenticatedRequestDefinition {
  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/buildings",
  };
}

function getMarkets(): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/buildings/markets",
  };
}

function getBuildingsByMarket(
  marketId: string,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/buildings/byMarket/" + marketId,
  };
}

function getBuildingDeliveryTimes(
  buildingId: string,
  timezone?: string,
): IAuthenticatedRequestDefinition {
  const timestamp = !!timezone
    ? moment().tz(timezone).toDate().toISOString()
    : new Date().toISOString();
  return {
    requestOptions: { method: "GET" },
    requestUrl:
      "/buildings/" +
      buildingId +
      "/delivery-times?deliveryStartDate=" +
      timestamp,
  };
}

function getBuilding(buildingId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/buildings/" + buildingId,
  };
}

function createUnitMapUploadURL(
  parameters: IBuildingFormType,
): IAuthenticatedRequestDefinition {
  let map_file_name = "";

  if (!!parameters.newUnitMapFile?.name) {
    map_file_name = parameters.newUnitMapFile.name.split(".pdf")[0];
  } else {
    map_file_name = `${parameters.shortCode}.pdf`;
  }

  const body = {
    type: 3,
    suffix: "pdf",
    path: `unit-maps/${map_file_name}`,
  };

  return {
    body: body,
    requestOptions: { method: "POST" },
    requestUrl: "/general/direct_upload",
  };
}

function searchBuildings(
  parameters: IPaginatedTableSearchRequestType,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/buildings/search" + generateQueryString(parameters),
  };
}

export const buildingsService = {
  getBuilding,
  getBuildingDeliveryTimes,
  getBuildingsByMarket,
  getMarkets,
  saveBuilding,
  createUnitMapUploadURL,
  searchBuildings,
};
