import { loadingActions } from "slices/loading/loading.actions";
import {
  getUserRoleFetch,
  getUserRoleFailure,
  getUserRoleSuccess,
  updateUserRolePost,
  updateUserRoleSuccess,
  updateUserRoleFailure,
} from "./userRoleState";
import { call, put, takeLatest } from "redux-saga/effects";
import { UserRoleDTO, userRolesService } from "services/hermes/userRoles";
import { networkActions } from "slices/authentication/network.actions";
import { toast } from "react-toastify";

function* workerGetUserRoleFetch(action: ReturnType<typeof getUserRoleFetch>) {
  try {
    yield put(loadingActions.startLoad(action.type));
    const requestOptions = (yield call(
      userRolesService.getUserRole,
      action.payload,
    )) as ReturnType<typeof userRolesService.getUserRole>;
    const response = (yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    )) as UserRoleDTO;

    yield put(getUserRoleSuccess(response));
  } catch (e) {
    let msg = "Error while loading User Role " + action.payload.toString();
    if (e instanceof Error || typeof e === "string") {
      msg = e.toString();
    }
    yield put(getUserRoleFailure(msg));
  } finally {
    yield put(loadingActions.endLoad(action.type));
  }
}

function* workerUpdateUserRolePost(
  action: ReturnType<typeof updateUserRolePost>,
) {
  try {
    yield put(loadingActions.startLoad(action.type));
    const requestOptions = (yield call(
      userRolesService.saveUserRole,
      action.payload,
    )) as ReturnType<typeof userRolesService.saveUserRole>;
    const response = (yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    )) as UserRoleDTO;

    yield put(updateUserRoleSuccess(response));
    toast.success("Role saved successfully!");
  } catch (e) {
    let msg = "Error while updating User Role";
    if (e instanceof Error || typeof e === "string") {
      msg = e.toString();
    }
    yield put(updateUserRoleFailure(msg));
  } finally {
    yield put(loadingActions.endLoad(action.type));
  }
}

export function* userRoleSaga() {
  yield takeLatest(getUserRoleFetch, workerGetUserRoleFetch);
  yield takeLatest(updateUserRolePost, workerUpdateUserRolePost);
}
