import { Reducer } from "redux";
import {
  ClearSubscriptionIntentAction,
  CLEAR_SUBSCRIPTION_INTENT,
  PostMySubscriptionIntentV2Action,
  PostMySubscriptionIntentV2SuccessAction,
  POST_MY_SUBSCRIPTION_INTENT_V2_SUCCESS,
} from "slices/subscriptionIntent/subscriptionIntent.actions";

export interface SubscriptionIntent {
  subscriptionId: string;
  ephemeralKey: string;
  setupIntent: null | string;
  paymentIntent: null | string;
  clientSecret: string;
  customerId: string;
}

export type SubscriptionIntentStore = SubscriptionIntent | null;

type SubscriptionIntentActions =
  | ClearSubscriptionIntentAction
  | PostMySubscriptionIntentV2Action
  | PostMySubscriptionIntentV2SuccessAction;

export const subscriptionIntent: Reducer<
  SubscriptionIntentStore,
  SubscriptionIntentActions
> = (state = null, action) => {
  switch (action.type) {
    case CLEAR_SUBSCRIPTION_INTENT:
      return null;
    case POST_MY_SUBSCRIPTION_INTENT_V2_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
