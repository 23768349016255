import {
  START_LOAD,
  END_LOAD,
  IEndLoadActionType,
  IStartLoadActionType,
} from "slices/loading/loading.actions";

export interface ILoadingStateType {
  [i: string]: boolean;
}

// type definitions
type LoadingActionsTypes = IStartLoadActionType | IEndLoadActionType;

export function loading(
  state: { [i: string]: boolean } = {},
  action: LoadingActionsTypes,
): ILoadingStateType {
  switch (action.type) {
    case START_LOAD:
      // append action if doesn't exist
      if (!state.hasOwnProperty(action.payload)) {
        return {
          ...state,
          [action.payload]: true,
        };
      }
      return state;
    case END_LOAD:
      // remove action if exists
      if (state.hasOwnProperty(action.payload)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [action.payload]: value, ...newState } = state;
        return newState;
      }
      return state;
    default:
      return state;
  }
}
