import { IBuildingStoreType } from "slices/building/building.reducer";
import { IBuildingByMarketStoreContentType } from "./buildingsByMarket.reducer";

export const GET_BUILDINGS_BY_MARKET = "GET_BUILDINGS_BY_MARKET";
export const CLEAR_BUILDINGS_BY_MARKET = "CLEAR_BUILDINGS_BY_MARKET";
export const GET_BUILDINGS_BY_MARKET_SUCCESS =
  "GET_BUILDINGS_BY_MARKET_SUCCESS";

export interface IGetBuildingsByMarketSuccessActionType {
  type: typeof GET_BUILDINGS_BY_MARKET_SUCCESS;
  payload: IBuildingByMarketStoreContentType[];
}

export interface IClearBuildingsByMarketActionType {
  type: typeof CLEAR_BUILDINGS_BY_MARKET;
}

export interface IGetBuildingsByMarketActionType {
  type: string;
  payload: {
    marketId: string;
  };
}

function getBuildingsByMarket(marketId: string) {
  return { type: GET_BUILDINGS_BY_MARKET, payload: { marketId } };
}
function getBuildingsByMarketSuccess(response: IBuildingStoreType) {
  return { type: GET_BUILDINGS_BY_MARKET_SUCCESS, payload: response };
}

export const buildingsByMarketActions = {
  getBuildingsByMarket,
  getBuildingsByMarketSuccess,
};
