import { IBatchesStoreType } from "slices/batches/batches.reducer";
import { IBuildingsStoreType } from "slices/buildings/buildings.reducer";
import { IPackagesStoreType } from "slices/packages/packages.reducer";
import { IUsersStoreType } from "slices/users/users.reducer";
import { IWarehouseLocationsStoreType } from "slices/warehouseLocations/warehouseLocations.reducer";
import { IWarehousesStoreType } from "slices/warehouses/warehouses.reducer";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { SearchHermesStore } from "./searchHermes.reducers";

export const SEARCH_HERMES = "SEARCH_HERMES";
export const SEARCH_HERMES_SUCCESS = "SEARCH_HERMES_SUCCESS";

export interface ISearchHermesActionType {
  type: typeof SEARCH_HERMES;
  location: keyof SearchHermesStore;
  identifier: string;
  request: IPaginatedTableSearchRequestType;
  onSuccess?: () => void;
  onFailure?: () => void;
}

export interface ISearchHermesSuccessActionType {
  type: typeof SEARCH_HERMES_SUCCESS;
  location: keyof SearchHermesStore;
  identifier: keyof SearchHermesStore[keyof SearchHermesStore];
  response: SearchHermesResponseValues | undefined;
  status: "failed" | "successful";
}

export type SearchHermesResponseValues =
  | IPackagesStoreType
  | IBuildingsStoreType
  | IUsersStoreType
  | IWarehousesStoreType
  | IWarehouseLocationsStoreType
  | IBatchesStoreType;

function searchHermes({
  location,
  identifier,
  request,
  onSuccess,
  onFailure,
}: {
  location: keyof SearchHermesStore;
  identifier: string;
  request: IPaginatedTableSearchRequestType;
  onSuccess?: ISearchHermesActionType["onSuccess"];
  onFailure?: ISearchHermesActionType["onFailure"];
}): ISearchHermesActionType {
  return {
    type: SEARCH_HERMES,
    location,
    identifier,
    request,
    onFailure,
    onSuccess,
  };
}

function searchHermesSuccess(
  location: keyof SearchHermesStore,
  identifier: string,
  response: ISearchHermesSuccessActionType["response"],
  status: ISearchHermesSuccessActionType["status"],
): ISearchHermesSuccessActionType {
  return {
    type: SEARCH_HERMES_SUCCESS,
    response,
    location,
    identifier,
    status,
  };
}

export const searchHermesActions = { searchHermes, searchHermesSuccess };
