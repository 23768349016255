import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IRootStateType } from "types/IRootStateType";
import { clear } from "slices/navigateAway/navigateAwayState";

// Included at app top level to interrupt navigation with warnings from store.navigateAway
// See useNavigateAwayWarning.ts to add warnings from within your component
const useGlobalNavigationGuard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const warnings = useSelector(
    (state: IRootStateType) => state.navigateAway.warningsToShow,
  );

  useEffect(() => {
    const combinedMessage = warnings
      .map((warning) => warning.message)
      .join("\n")
      .concat("\n\n")
      .concat("Are you sure you want to leave?");

    // Handle page refresh/tab close warnings
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (warnings.length > 0) {
        event.preventDefault();
        // beforeUnload message is ignored in modern browsers;
        // refresh dialog will be overwritten for most users
        event.returnValue = combinedMessage;
      }
    };

    // Block navigation and handle confirmation
    const unblock = history.block(() => {
      if (warnings.length > 0) {
        const confirmLeave = window.confirm(combinedMessage);
        if (confirmLeave) {
          dispatch(clear());
          return undefined; // Allow navigation and clear warnings after user confirm
        }
        return false; // Prevent navigation after user cancel
      }
      return undefined; // Allow navigation if no warnings
    });

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      unblock();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [warnings, history, dispatch]);
};

export default useGlobalNavigationGuard;
