import { call, put, ForkEffect, takeLeading } from "redux-saga/effects";
import { usersService } from "services/hermes/users";

import { networkActions } from "slices/authentication/network.actions";
import {
  IGetUserAuditHistoryType,
  userAuditHistoryActions,
  GET_USER_AUDIT_TRAIL,
} from "./userAuditHistory.actions";
import { UserAuditHistory } from "./userAuditHistory.reducer";

function* getUserAuditTrailWorker(action: IGetUserAuditHistoryType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<typeof usersService.getUserAuditTrail> =
    yield call(usersService.getUserAuditTrail, action.payload);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: UserAuditHistory = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(userAuditHistoryActions.getUserAuditTrailSuccess(response));
}

export const userAuditHistorySagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(GET_USER_AUDIT_TRAIL, getUserAuditTrailWorker);
};
