/* eslint-disable */
import { lazy, ComponentType } from "react";
import { localStorageGetItemSafe } from "./localStorage/localStorageGetItemSafe";
import { localStorageSetItemSafe } from "./localStorage/localStorageSetItemSafe";

export const lazyWithRefresh = (
  componentImport: () => Promise<{ default: ComponentType<any> }>,
) =>
  // @ts-ignore
  lazy(async () => {
    const refreshForced = localStorageGetItemSafe("refreshForced");

    try {
      const component = await componentImport();
      localStorageSetItemSafe("refreshForced", false);

      return component;
    } catch (error) {
      if (!refreshForced) {
        localStorageSetItemSafe("refreshForced", true);
        window.location.reload();
      }
    }
  });
