import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { networkActions } from "slices/authentication/network.actions";
import {
  IGetMyPackageActionType,
  GET_MY_PACKAGE,
  myPackageActions,
} from "./myPackage.actions";
import { IMyPackageStoreType } from "./myPackage.reducers";
import { packagesService } from "services/hermes/packages";

function* getMyPackageWorker(action: IGetMyPackageActionType) {
  yield put(myPackageActions.clearMyPackage());
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof packagesService.getMyPackage> =
      yield call(packagesService.getMyPackage, action.fetchPackageId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IMyPackageStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(myPackageActions.getMyPackageSuccess(response));
  } catch (error) {
    toast.error("An error occurred while retreiving your package data.");
  }
}

export const myPackageSagas = function* () {
  yield takeLatest(GET_MY_PACKAGE, getMyPackageWorker);
};
