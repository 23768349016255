import { IUserStoreType } from "slices/currentUser/currentUser.reducer";
import { GladlyEmbedAPI } from "services/chat/chat.types";

declare global {
  interface Window {
    gladlyConfig: {
      appId: string;
    };
    Gladly: GladlyEmbedAPI;
  }
}

export const startChat = (): void => {
  const Gladly = window.Gladly;
  if (!!fetch && window?.Gladly) {
    Gladly.init({
      appId: "fetchpackage.com",
      autoShowButton: true,
    })
      .then(function () {
        // any post init actions here
      })
      .catch(function () {
        // If anything goes wrong when Sidekick is being initialized, this gets called.
      });
  }
};

export const setChatUser = (currentUser: IUserStoreType): void => {
  if (window?.Gladly?.setUser) {
    window.Gladly.setUser({
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
    });
  }
};

export const clearChatUser = (): void => {
  if (window?.Gladly?.clearUser) {
    window.Gladly.clearUser();
  }
};

export const stopChat = (): void => {
  // no ability to disable chat yet
};
