import { put, call, ForkEffect, takeLeading } from "redux-saga/effects";
import { usersService } from "services/hermes/users";
import { networkActions } from "slices/authentication/network.actions";
import {
  IGetResidentialUserAuditHistoryType,
  residentialUserAuditHistoryActions,
  GET_RESIDENTIAL_USER_AUDIT_TRAIL,
} from "./residentialUserAuditHistory.actions";
import { ResidentialUserAuditHistory } from "./residentialUserAuditHistory.reducer";

function* getResidentialUserAuditTrailWorker(
  action: IGetResidentialUserAuditHistoryType,
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<
    typeof usersService.getResidentialUserAuditTrail
  > = yield call(usersService.getResidentialUserAuditTrail, action.payload);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: ResidentialUserAuditHistory = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(
    residentialUserAuditHistoryActions.getResidentialUserAuditTrailSuccess(
      response,
    ),
  );
}

export const residentialUserAuditHistorySagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(
    GET_RESIDENTIAL_USER_AUDIT_TRAIL,
    getResidentialUserAuditTrailWorker,
  );
};
