import { takeLeading, ForkEffect, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { networkActions } from "slices/authentication/network.actions";
import {
  IGetPackageDeliveryReceiptActionType,
  GET_PACKAGE_DELIVERY_RECEIPT,
  packageDeliveryReceiptsActions,
  IPackageDeliveryReceipt,
} from "./packageDeliveryReceipts.actions";
import { packagesService } from "services/hermes/packages";

function* getPackageDeliveryReceiptWorker(
  action: IGetPackageDeliveryReceiptActionType,
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof packagesService.getPackageDeliveryReceipt
    > = yield call(packagesService.getPackageDeliveryReceipt, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IPackageDeliveryReceipt = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(
      packageDeliveryReceiptsActions.getPackageDeliveryReceiptSuccess(response),
    );
  } catch (error) {
    toast.error("There was a problem loading the receipt.");
    yield put(
      packageDeliveryReceiptsActions.getPackageDeliveryReceiptFailure(),
    );
  }
}

export const packageDeliveryReceiptsSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(
    GET_PACKAGE_DELIVERY_RECEIPT,
    getPackageDeliveryReceiptWorker,
  );
};
