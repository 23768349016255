export const prettifyWord = (section: string): string => {
  const characterArray = section.split("");
  return characterArray.length > 0
    ? characterArray[0].toUpperCase() +
        characterArray.slice(1).join("").toLowerCase()
    : "";
};

export const enumFormatter = {
  allLowerSnakeCase: (value: string | undefined) => value?.toLowerCase() || "",
  firstLetterCapitalizedWithSpaces: (value: string | undefined) =>
    value?.split("_").map(prettifyWord).join(" ") || "",
} as const;
