import {
  CLEAR_PACKAGES,
  IClearPackagesActionType,
  ISearchPackageFailureActionType,
  ISearchPackagesSuccessActionType,
  SEARCH_PACKAGES_FAILURE,
  SEARCH_PACKAGES_SUCCESS,
} from "slices/packages/packages.actions";
import { defaultState } from "default-state";
import { IPackageStoreType } from "slices/package/package.reducer";
import { IPaginatedResponseType } from "types/IPaginatedResponseType";

export interface IPackagesStoreType extends IPaginatedResponseType {
  content: IPackageStoreType[];
}

export function packages(
  state = defaultState.packages,
  action: PackagesActionsTypes,
): Partial<IPackagesStoreType> {
  switch (action.type) {
    case CLEAR_PACKAGES:
      return defaultState.packages;
    case SEARCH_PACKAGES_SUCCESS:
      return action.payload;
    case SEARCH_PACKAGES_FAILURE:
      return defaultState.packages;
    default:
      return state;
  }
}

// type definitions
type PackagesActionsTypes =
  | ISearchPackagesSuccessActionType
  | ISearchPackageFailureActionType
  | IClearPackagesActionType;
