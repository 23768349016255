import React from "react";

import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router";
import {
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
} from "reactstrap";
import { Field, InjectedFormProps, formValueSelector } from "redux-form";

import { BEM } from "helpers/BEM.helper";
import { FetchFormConnect } from "helpers/FetchFormConnect";
import { FetchIcon } from "views/common/misc/FetchIcon";
import { IconTypes } from "constants/icons";
import { SearchField } from "views/components/navbars/SearchField";
import { IRootStateType } from "types/IRootStateType";

const getSearchPlaceholder = (type?: SearchTypes): string => {
  switch (type) {
    case "package":
      return "Package Id";
    case "batch":
      return "Batch Id";
    case "user":
      return "User Code";
    default:
      return "Select a search type";
  }
};

const getSearchIcon = (type?: SearchTypes): IconTypes => {
  switch (type) {
    case "package":
      return "package";
    case "batch":
      return "edit";
    case "user":
      return "user";
    default:
      return "search";
  }
};

export interface QuickSearchFormProps
  extends RouteComponentProps,
    InjectedFormProps<IQuickSearchFormValues, QuickSearchFormProps> {
  dispatch: Dispatch;
  isOpen: boolean;
  searchValue?: string;
  resourceType?: SearchTypes;
  onSubmit: () => void;
}
type QuickSearchFormValuesIndex = "searchValue" | "resourceType";
export interface IQuickSearchFormValues {
  searchValue?: string;
  resourceType?: SearchTypes;
}
interface IQuickSearchFormErrors {
  searchValue?: string;
  resourceType?: string;
}
type SearchTypes = "package" | "user" | "batch";

export const QuickSearchForm: React.FC<QuickSearchFormProps> = (
  props: QuickSearchFormProps,
) => {
  const { resourceType, isOpen, onSubmit, handleSubmit } = props;

  const menuOptions: {
    icon: IconTypes;
    text: string;
    type: SearchTypes;
  }[] = [
    { icon: "user", text: "User", type: "user" },
    { icon: "package", text: "Package", type: "package" },
    { icon: "edit", text: "Batch", type: "batch" },
  ];
  const searchPlaceholder: string = getSearchPlaceholder(resourceType);
  const searchIcon: IconTypes = getSearchIcon(resourceType);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const submitHandler = handleSubmit(onSubmit);

  const classes = new BEM({
    block: {
      name: "QuickSearchForm",
      extras: ["menu-search", "justify-content-end"],
    },
    elements: [
      { name: "categorySelect", extras: [] },
      { name: "categoryOption", extras: [] },
    ],
  });

  return (
    <Collapse
      className={classes.getBlockClassNames()}
      navbar={true}
      isOpen={isOpen}
    >
      <Nav navbar={true}>
        <UncontrolledDropdown className="btn-magnify" nav={true}>
          <DropdownToggle
            aria-haspopup={true}
            caret={true}
            color="default"
            data-toggle="dropdown"
            nav={true}
          >
            <FetchIcon
              iconType={searchIcon}
              className={classes.getElementClassNames("categorySelect")}
            />
          </DropdownToggle>
          <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right={true}>
            {menuOptions.map(({ icon, text, type }) => (
              <DropdownItem
                key={icon}
                onClick={() => props.change("resourceType", type)}
                className={classes.getElementClassNames("categoryOption")}
              >
                <FetchIcon iconType={icon} /> {text}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      <Form
        className={classes.getBlockClassNames()}
        onSubmit={props.handleSubmit}
      >
        <Field
          component={SearchField}
          name="searchValue"
          type={resourceType === "user" ? "text" : "number"}
          placeholder={searchPlaceholder}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          submitHandler={submitHandler}
        />
        <Field type="hidden" name="resourceType" component="input" />
      </Form>
    </Collapse>
  );
};

const selector = formValueSelector("QuickSearchForm");

export default FetchFormConnect({
  FormComponent: QuickSearchForm,
  formOptions: {
    form: "QuickSearchForm",
    validate: (values: IQuickSearchFormValues) => {
      const errors: IQuickSearchFormErrors = {};
      const requiredFields: QuickSearchFormValuesIndex[] = ["searchValue"];

      requiredFields.forEach((fieldName: QuickSearchFormValuesIndex) => {
        if (!values[fieldName]) {
          errors[fieldName] = "Required";
        }
      });

      return errors;
    },
  },
  state: {
    mapStateToProps: (rootState: IRootStateType) => {
      return {
        initialValues: {
          resourceType: "package",
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        searchValue: selector(rootState, "searchValue"),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        resourceType: selector(rootState, "resourceType"),
      };
    },
  },
});
