import { takeLeading, ForkEffect, call, put } from "redux-saga/effects";
import { packagesService } from "services/hermes/packages";
import { networkActions } from "slices/authentication/network.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  IGetPackageAuditTrailActionType,
  GET_PACKAGE_AUDIT_TRAIL,
  packageAuditTrailActions,
} from "./packageAuditTrail.actions";
import { IPackageAudit } from "./packageAuditTrail.reducer";

function* getPackageAuditTrailWorker(action: IGetPackageAuditTrailActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      packagesService.getPackageAuditTrail,
      action.payload.packageId,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IPackageAudit[] = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(packageAuditTrailActions.getPackageAuditTrailSuccess(response));
  } catch (error) {
    yield put(packageAuditTrailActions.clearPackageAuditTrail());
  }
}

export const packageAuditTrailSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(GET_PACKAGE_AUDIT_TRAIL, getPackageAuditTrailWorker);
};
