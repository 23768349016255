import { IPackageDeliveryTimesStoreType } from "./packageDeliveryTimes.reducer";

export const GET_BUILDING_DELIVERY_TIMES = "GET_BUILDING_DELIVERY_TIMES";
export const GET_BUILDING_DELIVERY_TIMES_SUCCESS =
  "GET_BUILDING_DELIVERY_TIMES_SUCCESS";

export interface IGetBuildingDeliveryWindowsActionType {
  type: string;
  payload: {
    buildingId: string;
    timezone?: string;
  };
}

export interface IGetPackageDeliveryTimesSuccessActionType {
  type: typeof GET_BUILDING_DELIVERY_TIMES_SUCCESS;
  payload: IPackageDeliveryTimesStoreType;
}

function getBuildingDeliveryTimes(buildingId: string, timezone?: string) {
  return {
    type: GET_BUILDING_DELIVERY_TIMES,
    payload: { buildingId, timezone },
  };
}
function getBuildingDeliveryTimesSuccess(
  response: IPackageDeliveryTimesStoreType,
) {
  return { type: GET_BUILDING_DELIVERY_TIMES_SUCCESS, payload: response };
}

export const packageDeliveryTimesActions = {
  getBuildingDeliveryTimes,
  getBuildingDeliveryTimesSuccess,
};
