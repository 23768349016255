import { IResidentialUserStoreType } from "slices/currentUser/currentUser.reducer";

export const CLEAR_RESIDENTIAL_USER = "CLEAR_RESIDENTIAL_USER";
export const GET_RESIDENTIAL_USER = "GET_RESIDENTIAL_USER";
export const GET_RESIDENTIAL_USER_SUCCESS = "GET_RESIDENTIAL_USER_SUCCESS";

export interface IGetResidentialUserActionType {
  type: string;
  payload: string;
}

export interface IGetResidentialUserSuccessActionType {
  type: typeof GET_RESIDENTIAL_USER_SUCCESS;
  payload: IResidentialUserStoreType;
}

export interface IClearResidentialUserActionType {
  type: typeof CLEAR_RESIDENTIAL_USER;
}

function clearResidentialUser() {
  return { type: CLEAR_RESIDENTIAL_USER };
}

function getResidentialUser(systemId: string) {
  return { type: GET_RESIDENTIAL_USER, payload: systemId };
}
function getResidentialUserSuccess(response: IResidentialUserStoreType) {
  return { type: GET_RESIDENTIAL_USER_SUCCESS, payload: response };
}

export const residentialUserActions = {
  clearResidentialUser,
  getResidentialUser,
  getResidentialUserSuccess,
};
