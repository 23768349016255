import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { IPostSetPermenantPasswordPayloadType } from "slices/authentication/authentication.actions";
import { IPasswordResetRequestBodyType } from "slices/authentication/authentication.actions";
import { IVerifyUserAttributePayloadType } from "slices/authentication/authentication.actions";
import { IGetUserAttributeVerificationCodePayloadType } from "slices/authentication/authentication.actions";
import { config } from "constants/config";
import { IRecoverPasswordRequestBodyType } from "slices/authentication/authentication.actions";
import { toast } from "react-toastify";
import { RegisterFormValues } from "views/pages/Register/RegisterForm";
import { localStorageRemoveItemSafe } from "helpers/localStorage/localStorageRemoveItemSafe";

function handleRequestResponse(
  response: Response,
): Promise<Record<string, unknown> | undefined> {
  return response
    .text()
    .then((text) => {
      let data: Record<string, unknown> | undefined;
      if (!!text) {
        data = JSON.parse(text) as Record<string, unknown>;
      }
      if (!response.ok) {
        return Promise.reject(data);
      }

      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function handleLoginResponse(
  response: Response,
): Promise<Record<string, unknown> | undefined> {
  return response
    .text()
    .then((text) => {
      let data: Record<string, unknown>;
      if (!!text) {
        data = JSON.parse(text) as Record<string, unknown>;
      } else {
        throw new Error("Login failed");
      }
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          logout();
        }
        if (response.status === 403) {
          // Could be an expired JWT, attempt to re-authenticate
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function login(
  username: string,
  password: string,
): Promise<Record<string, unknown> | undefined> {
  const requestOptions = {
    body: JSON.stringify({ email: username, password }),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  };

  if (!!config.apiUrl) {
    return fetch(`${config.apiUrl}/accounts/login`, requestOptions)
      .then(handleLoginResponse)
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    throw new Error("No environment");
  }
}

function getUserAttributeVerificationCode(
  requestBody: IGetUserAttributeVerificationCodePayloadType,
): Promise<Record<string, unknown> | undefined> {
  const requestOptions = {
    body: JSON.stringify(requestBody),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  };
  if (!!config.apiUrl) {
    return fetch(
      `${config.apiUrl}/accounts/getUserAttributeVerificationCode`,
      requestOptions,
    )
      .then(handleRequestResponse)
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    throw new Error("No environment");
  }
}

function logout(): void {
  // remove user from local storage to log user out
  localStorageRemoveItemSafe("auth");
  localStorageRemoveItemSafe("user");
}

function postChangePassword(requestBody: {
  oldPassword: string;
  newPassword: string;
  userToken: string;
}): IAuthenticatedRequestDefinition {
  return {
    body: requestBody,
    requestOptions: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: "/accounts/changePassword",
  };
}

function postSetPermanentPassword(
  requestBody: IPostSetPermenantPasswordPayloadType,
): IAuthenticatedRequestDefinition {
  return {
    body: requestBody,
    requestOptions: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: "/accounts/setPermanentPassword",
  };
}

function recoverPassword(
  requestBody: IRecoverPasswordRequestBodyType,
): Promise<Record<string, unknown> | undefined> {
  const requestOptions = {
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  };
  if (!!config.apiUrl) {
    return fetch(
      `${config.apiUrl}/accounts/confirmForgotPassword`,
      requestOptions,
    )
      .then(handleRequestResponse)
      .catch(() => {
        toast.error(
          "There was an error attempting to recover your password. Please try again!",
        );
        throw new Error("Failed to recover password");
      });
  } else {
    throw new Error("No environment");
  }
}

function registerAccount(
  newUser: RegisterFormValues,
): Promise<Record<string, unknown> | undefined> {
  const requestOptions = {
    body: JSON.stringify(newUser),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  };

  if (!!config.apiUrl) {
    return fetch(`${config.apiUrl}/accounts`, requestOptions)
      .then(handleRequestResponse)
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    throw new Error("no environment");
  }
}

function requestNewPassword(
  requestBody: IPasswordResetRequestBodyType,
): Promise<Record<string, unknown> | undefined> {
  const requestOptions = {
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  };
  if (!!config.apiUrl) {
    return fetch(`${config.apiUrl}/accounts/forgotPassword`, requestOptions)
      .then(handleRequestResponse)
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    throw new Error("No environment");
  }
}

function verifyUserAttribute(
  requestBody: IVerifyUserAttributePayloadType,
): Promise<Record<string, unknown> | undefined> {
  const requestOptions = {
    body: JSON.stringify(requestBody),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  };
  if (!!config.apiUrl) {
    return fetch(
      `${config.apiUrl}/accounts/verifyUserAttribute`,
      requestOptions,
    )
      .then(handleRequestResponse)
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    throw new Error("No environment");
  }
}

export const accountsService = {
  getUserAttributeVerificationCode,
  login,
  logout,
  postChangePassword,
  postSetPermanentPassword,
  recoverPassword,
  registerAccount,
  requestNewPassword,
  verifyUserAttribute,
};
