import { defaultState } from "default-state";
import {
  CLEAR_BUILDINGS_BY_MARKET,
  GET_BUILDINGS_BY_MARKET_SUCCESS,
  IClearBuildingsByMarketActionType,
  IGetBuildingsByMarketSuccessActionType,
} from "./buildingsByMarket.actions";

export interface IBuildingByMarketStoreContentType {
  buildingId: number;
  name: string;
  shortCode: string;
  packageDeliveryPolicy: number;
  warehouseId: number;
  deliveryWindowGroupId: number | null;
}

// type definitions
type BuildingsByMarketActionsTypes =
  | IGetBuildingsByMarketSuccessActionType
  | IClearBuildingsByMarketActionType;

export function buildingsByMarket(
  state: IBuildingByMarketStoreContentType[] | null = null,
  action: BuildingsByMarketActionsTypes,
): IBuildingByMarketStoreContentType[] | null {
  switch (action.type) {
    case CLEAR_BUILDINGS_BY_MARKET:
      return defaultState.buildingsByMarket;
    case GET_BUILDINGS_BY_MARKET_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
