import { defaultState } from "default-state";
import {
  IGetWarehouseUserSuccessActionType,
  ISaveWarehouseUserSuccessActionType,
  IClearWarehouseUserActionType,
  CLEAR_WAREHOUSE_USER,
  GET_WAREHOUSE_USER_SUCCESS,
  SAVE_WAREHOUSE_USER_SUCCESS,
} from "./warehouseUser.actions";

export interface WarehouseUser {
  warehouseUserId: number;
  preferredWarehouseId: number;
  preferredWarehouseName: string;
  preferredPrinter?: string;
  preferredPrinterId?: number;
  preferredWarehouseShortcode: string;
}

type WarehouseUserActions =
  | IGetWarehouseUserSuccessActionType
  | ISaveWarehouseUserSuccessActionType
  | IClearWarehouseUserActionType;

export function warehouseUser(
  state = {},
  action: WarehouseUserActions,
): Partial<WarehouseUser> {
  switch (action.type) {
    case CLEAR_WAREHOUSE_USER:
      return defaultState.warehouseUser;
    case GET_WAREHOUSE_USER_SUCCESS:
      return action.payload;
    case SAVE_WAREHOUSE_USER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
