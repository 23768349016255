import React from "react";

import { BEM } from "helpers/BEM.helper";
import FetchModal from "views/common/modals/FetchModal";
import fetchDeliveryPartnerImg from "assets/img/fetch-delivery-partner.png";
import { GetAppIcon } from "../GetAppIcon";

const classes = new BEM({
  block: { name: "modals" },
  elements: [
    { name: "useOurMobileAppModal" },
    { name: "iconContainer" },
    { name: "fetchDeliveryPartnerImg" },
  ],
  prefix: { name: "fetch" },
});

export const UseOurMobileAppModal: React.FC = () => {
  return (
    <FetchModal
      title="There's an App for that!"
      className={classes.getElementClassNames("useOurMobileAppModal")}
      id="use_our_mobile_app"
    >
      <div className={classes.getElementClassNames("iconContainer")}>
        <img
          className={classes.getElementClassNames("fetchDeliveryPartnerImg")}
          src={fetchDeliveryPartnerImg}
          alt="Fetch, delivery drives us"
        />
      </div>
      <div className={classes.getElementClassNames("iconContainer")}>
        <GetAppIcon />
      </div>
    </FetchModal>
  );
};
export default UseOurMobileAppModal;
