import { WfmDeliveryWindowScheduleState } from "./reducer";

const sliceName = "wfmDeliveryWindowSchedule";
/*
  This section may not fill properly due to snippet character limitations.
  You should use `template literals` in order to get the strongest typing.
  Example:
    Generic String: sliceName + '/request' -> string
    Specific String: `sliceName/request` -> 'sliceName/request'
 */
export const actionTypes = {
  request: `${sliceName}/request`,
  fullfilled: `${sliceName}/fullfilled`,
  rejected: `${sliceName}/rejected`,
  clear: `${sliceName}/clear`,
} as const;

type RequestPayload = {
  query: {
    warehouseId: number;
    startDate: string;
    numberOfDays: number;
  };
};

type FullfilledPayload = Pick<WfmDeliveryWindowScheduleState, "response">;
type RejectedPayload = Pick<WfmDeliveryWindowScheduleState, "error">;

export type ClearAction = {
  type: typeof actionTypes.clear;
};
/**
 * @usage clear();
 */
function clear(): ClearAction {
  return {
    type: actionTypes.clear,
  };
}

export type RequestAction = {
  type: typeof actionTypes.request;
  payload: RequestPayload;
};
/**
 * @usage request({ request: { ... } });
 */
function request(payload: RequestAction["payload"]): RequestAction {
  return {
    type: actionTypes.request,
    payload,
  };
}

export type FullfilledAction = {
  type: typeof actionTypes.fullfilled;
  payload: FullfilledPayload;
};
/**
 * @usage fullfilled({  response: { ... },  status: 200, });
 */
function fullfilled(payload: FullfilledAction["payload"]): FullfilledAction {
  return {
    type: actionTypes.fullfilled,
    payload,
  };
}

export type RejectedAction = {
  type: typeof actionTypes.rejected;
  payload: RejectedPayload;
};
/**
 * @usage rejected({  error: {    fetchErrorCode: 5000,    message: 'Something Happened',    name: 'SOMETHING',  },  status: 500, });
 */
function rejected(payload: RejectedAction["payload"]): RejectedAction {
  return {
    type: actionTypes.rejected,
    payload,
  };
}

export const actions = {
  request,
  fullfilled,
  rejected,
  clear,
};
