import { Reducer } from "redux";

import {
  ValidateCouponAction,
  ValidateCouponSuccessAction,
  ClearCouponAction,
  CLEAR_COUPON,
  VALIDATE_COUPON_SUCCESS,
} from "slices/coupon/coupon.actions";

export interface ValidateCouponResponse {
  id: string;
  name: string;
  percentOff: number;
  duration: "forever" | "once" | "repeating";
  durationInMonths?: number;
}

export type CouponStore = ValidateCouponResponse | null;

type CouponActions =
  | ClearCouponAction
  | ValidateCouponAction
  | ValidateCouponSuccessAction;

export const coupon: Reducer<CouponStore, CouponActions> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case CLEAR_COUPON:
      return null;
    case VALIDATE_COUPON_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
