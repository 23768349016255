import { actions } from "./actions";
import { defaultState, reducer } from "./reducer";
import { saga } from "./saga";
import { service } from "./service";

export const getWfmResourcesTemplateWindows = {
  reducer,
  actions,
  saga,
  defaultState,
  service,
};

/*
USAGE:
  const [
    { error, loading, response, request: requestOptions },
    { request },
  ] = useMyPackageLabelsByFetchPackageId();
 */
