import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Container } from "reactstrap";

import { BEM } from "helpers/BEM.helper";
import { getNowInApiDateTimeString } from "helpers/date-time.helper";
import { FetchCard } from "views/common/cards/FetchCard";
import { BackgroundOverlays } from "views/components/atoms/BackgroundOverlays";
import { MyResidentialUserReduxFormContainer } from "views/components/forms/ResidentialUserProfile";
import { currentUserActions } from "slices/currentUser/currentUser.actions";
import { IResidentialUserStoreType } from "slices/currentUser/currentUser.reducer";

const classes = new BEM({
  block: {
    extras: ["d-flex", "justify-content-center"],
    name: "AddressSetupPage",
  },
  elements: [{ name: "logo" }, { name: "spinner" }],
});

export interface AddressSetupPageProps {
  showVerifyContactOptions: () => void;
}

export const AddressSetupPage: React.FC<AddressSetupPageProps> = ({
  showVerifyContactOptions,
}: AddressSetupPageProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.toggle("address-setup-page");
    return () => {
      document.body.classList.toggle("address-setup-page");
    };
  }, []);

  const handleFormSubmit = useCallback(
    ({
      buildingUnitId,
      packageDeliveryPreference,
      leaveLiabilityAccepted,
    }: Partial<IResidentialUserStoreType>) => {
      const residentialUser: Partial<IResidentialUserStoreType> = {
        buildingUnitId,
        packageDeliveryPreference,
        termsAccepted: getNowInApiDateTimeString(),
      };
      if (leaveLiabilityAccepted) {
        residentialUser.leaveLiabilityAccepted = getNowInApiDateTimeString();
      }
      dispatch(currentUserActions.saveMyResidentialUser(residentialUser));
      showVerifyContactOptions();
    },
    [dispatch, showVerifyContactOptions],
  );

  return (
    <div className={"beautiful-background"}>
      <Container className={classes.getBlockClassNames()} fluid={true}>
        <Col lg="4" md="6" sm="8">
          <FetchCard
            preTitle={"Address Setup"}
            preTitleSubtext={"Select your community and unit."}
            className="submit-bottom"
          >
            <MyResidentialUserReduxFormContainer onSubmit={handleFormSubmit} />
          </FetchCard>
        </Col>
      </Container>
      <BackgroundOverlays />
    </div>
  );
};
