import { FetchOfferingTypes } from "types/generalSettingsEnums";
import { IAutomatedRoutesStoreType } from "./automatedRoutes.reducer";

export const CLEAR_AUTOMATED_ROUTES = "CLEAR_AUTOMATED_ROUTES";
export const GET_AUTOMATED_ROUTES = "GET_AUTOMATED_ROUTES";
export const GET_AUTOMATED_ROUTES_SUCCESS = "GET_AUTOMATED_ROUTES_SUCCESS";
export const GET_AUTOMATED_ROUTES_FAILURE = "GET_AUTOMATED_ROUTES_FAILURE";

export type IAutomatedRoutingRequestType = {
  warehouseId: number;
  deliveryWindowId: number;
  fetchOfferingType: (typeof FetchOfferingTypes)[keyof typeof FetchOfferingTypes];
};

export interface IClearAutomatedRoutesActionType {
  type: typeof CLEAR_AUTOMATED_ROUTES;
}

export interface IGetAutomatedRoutesActionType {
  type: typeof GET_AUTOMATED_ROUTES;
  payload: IAutomatedRoutingRequestType;
}

export interface IGetAutomatedRoutesSuccessActionType {
  type: typeof GET_AUTOMATED_ROUTES_SUCCESS;
  payload: IAutomatedRoutesStoreType;
}

export interface IGetAutomatedRoutesFailureActionType {
  type: typeof GET_AUTOMATED_ROUTES_FAILURE;
}

function clearAutomatedRoutes() {
  return { type: CLEAR_AUTOMATED_ROUTES };
}

function getAutomatedRoutes(routeSearch: IAutomatedRoutingRequestType) {
  return { type: GET_AUTOMATED_ROUTES, payload: routeSearch };
}

function getAutomatedRoutesSuccess(routeResponse: IAutomatedRoutesStoreType) {
  return { type: GET_AUTOMATED_ROUTES_SUCCESS, payload: routeResponse };
}

function getAutomatedRoutesFailure() {
  return { type: GET_AUTOMATED_ROUTES_FAILURE };
}

export const automatedRoutesActions = {
  getAutomatedRoutes,
  getAutomatedRoutesSuccess,
  getAutomatedRoutesFailure,
  clearAutomatedRoutes,
};
