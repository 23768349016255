import React from "react";

import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import { Dispatch } from "redux";
import { Field, formValueSelector, InjectedFormProps } from "redux-form";

import { BEM } from "helpers/BEM.helper";
import { FetchFormConnect } from "helpers/FetchFormConnect";
import { FetchInput } from "views/common/forms/fields/FetchInput";
import { PasswordValidationMessages } from "views/components/forms/PasswordValidationMessages";
import { assertPasswordIsValid } from "helpers/inputValidators/text/password";
import { IRootStateType } from "types/IRootStateType";
import { FetchFormErrors } from "types/FetchFormErrors";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";

const classes = new BEM({
  block: { name: "ResetPasswordForm" },
  elements: [
    { name: "newPassword", extras: [] },
    { name: "confirmNewPassword", extras: [] },
    { name: "submitButton", extras: ["w-100"] },
  ],
});

interface IAuthDetails {
  initialValues?: {
    email: string;
    session: string;
    userId: string;
  };
}
type ResetPasswordFormProps = InjectedFormProps<
  IAuthDetails,
  IResetPasswordFormProps
> &
  IResetPasswordFormProps;
export interface IResetPasswordFormProps {
  currentUser: IUserStoreType;
  dispatch: Dispatch;
  newPassword: string | undefined;
}
export interface IResetPasswordFormValues {
  newPassword?: string;
  confirmNewPassword?: string;
  email?: string;
  session?: string;
}
type ResetPasswordFormErrors = FetchFormErrors<IResetPasswordFormValues>;

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (
  props: ResetPasswordFormProps,
) => {
  const { invalid, newPassword } = props;

  return (
    <Form
      className={classes.getBlockClassNames()}
      onSubmit={props.handleSubmit}
    >
      <Field
        className={classes.getElementClassNames("newPassword")}
        component={FetchInput}
        label="New Password"
        name="newPassword"
        type="password"
      />
      <Field
        className={classes.getElementClassNames("confirmNewPassword")}
        component={FetchInput}
        label="Confirm New Password"
        name="confirmNewPassword"
        type="password"
      />
      <PasswordValidationMessages password={newPassword} />
      <Button
        type="submit"
        color="info"
        disabled={invalid}
        className={classes.getElementClassNames("submitButton")}
      >
        Submit
      </Button>
    </Form>
  );
};

const selector = formValueSelector("resetPassword");

export default FetchFormConnect({
  FormComponent: ResetPasswordForm,
  formOptions: {
    form: "resetPassword",
    onSubmitFail: () => {
      toast.error("Please fix the error(s) below and try again");
    },
    touchOnChange: true,
    validate: (values: IResetPasswordFormValues) => {
      const errors: ResetPasswordFormErrors = {};
      const requiredFields: (keyof IResetPasswordFormValues)[] = [
        "newPassword",
        "confirmNewPassword",
      ];
      requiredFields.forEach((fieldName: keyof IResetPasswordFormValues) => {
        if (!values[fieldName]) {
          errors[fieldName] = "Required";
        }
      });
      if (!assertPasswordIsValid(values.newPassword)) {
        errors.newPassword = "Enter a valid password";
      }
      if (values.newPassword !== values.confirmNewPassword) {
        errors.confirmNewPassword = "Passwords must match.";
      }

      return errors;
    },
  },
  state: {
    mapStateToProps: (rootState: IRootStateType) => {
      const {
        currentUser,
        authentication: { tokens },
      } = rootState;

      return {
        initialValues: {
          email: currentUser?.email,
          session: tokens?.session,
          userId: currentUser?.userId,
        },
        newPassword: selector(rootState, "newPassword") as string | undefined,
      };
    },
  },
});
