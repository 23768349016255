import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type NavigateWarning = {
  warningId: string;
  message: string;
};

export type NavigateAwayStore = {
  warningsToShow: NavigateWarning[];
};

export const initialState: NavigateAwayStore = {
  warningsToShow: [],
};

const navigateAwaySlice = createSlice({
  name: "navigateAway",
  initialState,
  reducers: {
    addWarning: (
      state,
      action: PayloadAction<{ warningId: string; message: string }>,
    ) => {
      const warningIdIndex = state.warningsToShow.findIndex(
        (warning) => warning.warningId === action.payload.warningId,
      );
      // If the warningId is not already in the list, add it
      if (warningIdIndex === -1) {
        state.warningsToShow = state.warningsToShow.concat(action.payload);
      } else {
        // Otherwise reassign the warning at that index to update the message
        state.warningsToShow[warningIdIndex] = {
          warningId: action.payload.warningId,
          message: action.payload.message,
        };
      }
    },
    removeWarning: (state, action: PayloadAction<string>) => {
      const warningIdIndex = state.warningsToShow.findIndex(
        (warning) => warning.warningId === action.payload,
      );
      // If the warningId is in the list, remove it, otherwise leave state as is
      if (warningIdIndex !== -1) {
        state.warningsToShow = state.warningsToShow.filter(
          (warning) => warning.warningId !== action.payload,
        );
      }
    },
    clear: (state) => {
      state.warningsToShow = [];
    },
  },
});

export const { addWarning, removeWarning, clear } = navigateAwaySlice.actions;
export default navigateAwaySlice.reducer;
