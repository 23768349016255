import { Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";
import { config } from "constants/config";
import { history } from "helpers/history";

// enable sentry reporting if this is production
const shouldReport = config.apiUrl === process.env.REACT_APP_API_URL_PROD;

export const initializeSentry = (): void => {
  if (shouldReport) {
    Sentry.init({
      dsn: config.sentryDSN,
      environment: config.environment,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      release: `web-application@${process.env.REACT_APP_GIT_SHA}`,
      // Set replay sample rate to 1% of all sessions
      replaysSessionSampleRate: 0.01,
      // Set replay sample rate to 100% when an error occurs
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Send 1% of transactions to sentry
      tracesSampleRate: 0.01,
      ignoreErrors: ["AdaEmbedError", "FirebaseError"],
      denyUrls: [
        // chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // safari extensions
        /safari-extension\//i,
      ],
    });
  }
};

// longest user segment: "admin,resident,warehouse,driver"
const getUserSegment = (currentUser: IUserStoreType) => {
  const userSegment = [];

  if (currentUser?.isAdminUser) {
    userSegment.push("admin");
  }

  if (currentUser?.residentialUser?.residentialUserId !== undefined) {
    userSegment.push("resident");
  }

  if (currentUser?.warehouseUser?.warehouseUserId !== undefined) {
    userSegment.push("warehouse");
  }

  if (currentUser?.driverUser?.driverUserId !== undefined) {
    userSegment.push("driver");
  }

  return userSegment.join(",");
};

const getSentryUser = (currentUser: IUserStoreType): Sentry.User | null => {
  const segment = getUserSegment(currentUser);

  if (segment) {
    return {
      email: currentUser?.email,
      id: currentUser?.userId?.toString(),
      ip_address: "{{auto}}",
      segment,
      username: `${currentUser?.firstName} ${currentUser?.lastName}`,
    };
  }

  return null;
};

export const setSentryUser = (currentUser: IUserStoreType): void => {
  if (shouldReport) {
    const user = getSentryUser(currentUser);

    Sentry.setUser(user);
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureSentryException = (e: any) => {
  if (shouldReport) {
    Sentry.captureException(e);
  }
};

// HOC for react-router-dom
export const SentryRoute = Sentry.withSentryRouting(Route);

// enhancer for redux
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const sentryReduxEnhancer = Sentry.createReduxEnhancer();
