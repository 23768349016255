import { Reducer } from "redux";

import { defaultState } from "default-state";
import {
  ISearchHermesSuccessActionType,
  SEARCH_HERMES_SUCCESS,
} from "slices/searchHermes/searchHermes.actions";
import { IWarehouseLocationsStoreType } from "slices/warehouseLocations/warehouseLocations.reducer";
import { IBatchesStoreType } from "slices/batches/batches.reducer";
import { IWarehousesStoreType } from "slices/warehouses/warehouses.reducer";
import { IPackagesStoreType } from "slices/packages/packages.reducer";
import { IBuildingsStoreType } from "slices/buildings/buildings.reducer";
import { IUsersStoreType } from "slices/users/users.reducer";

type SearchHermesStoreLocation<LocationShape> = {
  [identifier in string]?: {
    lastRequested: string;
    status: "failed" | "successful";
    value: LocationShape | undefined;
  };
};

export type SearchHermesStore = {
  packages: SearchHermesStoreLocation<IPackagesStoreType>;
  users: SearchHermesStoreLocation<IUsersStoreType>;
  batches: SearchHermesStoreLocation<IBatchesStoreType>;
  warehouses: SearchHermesStoreLocation<IWarehousesStoreType>;
  buildings: SearchHermesStoreLocation<IBuildingsStoreType>;
  warehouseLocations: SearchHermesStoreLocation<IWarehouseLocationsStoreType>;
};
type SearchHermesTypes = ISearchHermesSuccessActionType;

export const searchHermes: Reducer<SearchHermesStore, SearchHermesTypes> = (
  state = defaultState.searchHermes,
  action: SearchHermesTypes,
): SearchHermesStore => {
  switch (action.type) {
    case SEARCH_HERMES_SUCCESS:
      const newStore: SearchHermesStore = {
        ...state,
        [action.location]: {
          ...state[action.location],
          [action.identifier]: {
            status: action.status,
            value: action.response,
            lastRequested: new Date().toUTCString(),
          },
        },
      };
      return newStore;
    default:
      return state;
  }
};
