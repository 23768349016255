import { IWarehouseStoreType } from "slices/warehouse/warehouse.reducer";
import { generateQueryString } from "helpers/generateQueryString.helper";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";

export interface ISaveWarehouseLocationRequestType {
  packageType: number;
  buildingId?: number;
  warehouseId?: number;
  locationType: number;
  deliveryWindowId?: number;
}

function searchWarehouses(
  parameters: IPaginatedTableSearchRequestType,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/warehouses/search" + generateQueryString(parameters),
  };
}

function getWarehouse(warehouseId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/warehouses/${warehouseId}`,
  };
}

function saveWarehouse(
  parameters: IWarehouseStoreType,
): IAuthenticatedRequestDefinition {
  // copy to new Partial<> type so we can remove hours
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestParameters: Partial<IWarehouseStoreType> = JSON.parse(
    JSON.stringify(parameters),
  );
  delete requestParameters.hours;
  return {
    body: requestParameters,
    requestOptions: { method: "POST" },
    requestUrl: "/warehouses",
  };
}

function searchWarehouseLocations(
  parameters: IPaginatedTableSearchRequestType,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/warehouses/location/search" + generateQueryString(parameters),
  };
}

function saveWarehouseLocation(
  parameters: ISaveWarehouseLocationRequestType,
): IAuthenticatedRequestDefinition {
  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/warehouses/location",
  };
}

function reprintWarehouseLocation(
  warehouseLocationId: number,
): IAuthenticatedRequestDefinition {
  return {
    body: {
      warehouseLocationId,
    },
    requestOptions: { method: "POST" },
    requestUrl: "/warehouses/location/reprint-label",
  };
}

function getWarehouseLocation(
  warehouseLocationId: string,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/warehouses/location/" + warehouseLocationId,
  };
}

export const warehousesService = {
  searchWarehouses,
  getWarehouse,
  saveWarehouse,
  searchWarehouseLocations,
  getWarehouseLocation,
  reprintWarehouseLocation,
  saveWarehouseLocation,
};
