import React, { ReactNode, KeyboardEvent } from "react";

import { Input, InputGroup, InputGroupAddon, InputProps } from "reactstrap";
import { WrappedFieldProps } from "redux-form";
import { InputType } from "reactstrap/lib/Input";

import {
  FetchFieldValidationWrapper,
  FetchFieldValidationWrapperProps,
} from "views/common/forms/fields/FetchFieldValidationWrapper";
import {
  generateBemClassNames,
  ClassNameCalculator,
} from "helpers/generateBemClassNames.helper";
import { enumFormatter } from "helpers/text-formatting/enumFormatter";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { ApplicationSettingsLocationIndex } from "types/IRootStateType";

export type FetchInputType = InputType & "displayAsText" & "inputGroup";

export type FetchInputProps = FetchFieldValidationWrapperProps &
  InputProps &
  Partial<WrappedFieldProps> & {
    appendComponent?: ReactNode;
    placeholder?: string;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    displayAsText?: boolean;
    inputGroup?: boolean;
    type: FetchInputType;
    enumType?: ApplicationSettingsLocationIndex;
  };

export const FetchInput: React.FC<FetchInputProps> = (
  props: FetchInputProps,
) => {
  const {
    appendComponent,
    type,
    input,
    label,
    innerRef,
    meta,
    placeholder,
    className,
    onKeyDown,
    enumType,
  } = props;
  let inputField;
  const applicationSettings = useFetchSelector("applicationSettings");

  const classNames = generateBemClassNames({
    block: { name: "input" },
    elements: [{ name: type }, { name: "error" }, { name: "label" }],
    prefix: "fetch",
  });
  const {
    block,
    elements: { error, label: labelClassNames },
  } = classNames;
  const typeClassNames: ClassNameCalculator = classNames.elements[type];

  if (!!input && !!meta) {
    if (
      (type === "displayAsText" && typeof input.value === "string") ||
      (type === "displayAsText" && typeof input.value === "number")
    ) {
      const displayValue = input.value
        ? !!enumType
          ? enumFormatter.firstLetterCapitalizedWithSpaces(
              applicationSettings?.[enumType]?.[input.value],
            )
          : `${input.value}`
        : "N/A";
      inputField = (
        <div
          className={typeClassNames({ extras: ["form-field-display-as-text"] })}
        >
          {displayValue}
        </div>
      );
    } else if (type === "inputGroup") {
      inputField = (
        <InputGroup>
          <Input
            {...input}
            type={type}
            label={label}
            autoComplete="off"
            innerRef={innerRef}
            placeholder={placeholder}
            className={typeClassNames()}
            onKeyDown={onKeyDown}
          />
          {appendComponent && (
            <InputGroupAddon addonType="append">
              {appendComponent}
            </InputGroupAddon>
          )}
        </InputGroup>
      );
    } else {
      inputField = (
        <Input
          {...input}
          type={type}
          label={label}
          autoComplete="off"
          innerRef={innerRef}
          placeholder={placeholder}
          className={typeClassNames()}
          onKeyDown={onKeyDown}
        />
      );
    }
    return (
      <FetchFieldValidationWrapper
        {...{
          input,
          meta,
          label,
          type,
          classNames: {
            block: block({ extras: [className ? className : ""] }),
            label: labelClassNames(),
            error: error(),
          },
        }}
      >
        {inputField}
      </FetchFieldValidationWrapper>
    );
  } else {
    return null;
  }
};
