import {
  GET_WAREHOUSE_PRINTERS_SUCCESS,
  IGetWarehousePrintersSuccessActionType,
} from "slices/warehousePrinters/warehousePrinters.actions";
import { IPaginatedResponseType } from "types/IPaginatedResponseType";

interface IWarehousePrinterContentType {
  warehouseId: number;
  printerType: string;
  printerIp: string;
  printerDetails: {
    name: string;
  };
  printerSettingsId: number;
}

export interface IWarehousePrintersStoreType extends IPaginatedResponseType {
  content: IWarehousePrinterContentType[];
}

export function warehousePrinters(
  state = {},
  action: WarehousePrintersActionsTypes,
): Partial<IWarehousePrintersStoreType> {
  switch (action.type) {
    case GET_WAREHOUSE_PRINTERS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

// type definitions
type WarehousePrintersActionsTypes = IGetWarehousePrintersSuccessActionType;
