export function getSystemIdFromEmail(email: string): string {
  return email.replace(/@/g, "-").toLowerCase();
}

export function getEncodedSystemIdFromEmail(email: string): string {
  return getEncodedSystemIdFromSystemId(getSystemIdFromEmail(email));
}

export function getEncodedSystemIdFromSystemId(systemId: string): string {
  return window.btoa(systemId);
}
