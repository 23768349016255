import {
  takeLatest,
  takeLeading,
  ForkEffect,
  call,
  put,
} from "redux-saga/effects";
import { toast } from "react-toastify";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { history } from "helpers/history";
import {
  IReprintWarehouseLocationActionType,
  IGetWarehouseLocationActionType,
  ISaveWarehouseBulkLocationsActionType,
  IWarehouseBulkLocationSuccess,
  ISaveWarehouseBulkLocationsSuccessActionType,
  ISaveWarehouseLocationActionType,
  REPRINT_WAREHOUSE_LOCATION,
  GET_WAREHOUSE_LOCATION,
  SAVE_WAREHOUSE_BULK_LOCATIONS,
  SAVE_WAREHOUSE_BULK_LOCATIONS_SUCCESS,
  SAVE_WAREHOUSE_LOCATION,
  SAVE_WAREHOUSE_LOCATION_SUCCESS,
  warehouseLocationActions,
} from "./warehouseLocation.actions";
import { IWarehouseLocationStoreType } from "./warehouseLocation.reducer";
import {
  ISaveWarehouseLocationRequestType,
  warehousesService,
} from "services/hermes/warehouses";

function* reprintWarehouseLocationWorker(
  action: IReprintWarehouseLocationActionType,
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: IAuthenticatedRequestDefinition = yield call(
    warehousesService.reprintWarehouseLocation,
    action.payload.warehouseLocationId,
  );
  yield call(networkActions.makeAuthenticatedRequest, requestOptions);
  // display success message
  toast.success("Facility location label has been resent to the printer.");
}

function* getWarehouseLocationWorker(action: IGetWarehouseLocationActionType) {
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      warehousesService.getWarehouseLocation,
      action.payload.warehouseLocationId,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IWarehouseLocationStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(warehouseLocationActions.getWarehouseLocationSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

function* saveWarehouseBulkLocationsWorker(
  action: ISaveWarehouseBulkLocationsActionType,
) {
  // display success message
  toast.success(
    "Adding location(s) is in progress.  Please wait while we complete your request.",
  );
  let locationsUpdated = 0;
  const warehouseLocationBatchCategory =
    action.payload.warehouseLocationBatchCategory;
  const buildingId = action.payload.buildingId
    ? parseInt(action.payload.buildingId, 10)
    : null;
  const warehouseId = action.payload.warehouseId
    ? parseInt(action.payload.warehouseId, 10)
    : null;
  // iterate through new locations types and create
  for (const [locationTypeKey, locationCount] of Object.entries(
    action.payload.locations,
  )) {
    const locationParts = locationTypeKey.split("-");
    const locationType: number = parseInt(locationParts[0], 10);
    const packageType: number = parseInt(locationParts[2], 10);

    const locationRequestBody: ISaveWarehouseLocationRequestType = {
      locationType,
      packageType,
    };
    if (warehouseLocationBatchCategory === "building" && buildingId) {
      locationRequestBody.buildingId = buildingId;
    } else if (warehouseLocationBatchCategory === "warehouse" && warehouseId) {
      locationRequestBody.warehouseId = warehouseId;
    } else if (
      warehouseLocationBatchCategory === "transition" &&
      buildingId &&
      warehouseId
    ) {
      locationRequestBody.buildingId = buildingId;
      locationRequestBody.warehouseId = warehouseId;
    }
    // if this is a window location, parse the deliveryWindowId
    const hasDeliveryWindowId = [5, 6].includes(locationType);
    if (hasDeliveryWindowId) {
      locationRequestBody.deliveryWindowId = parseInt(locationParts[1], 10);
    }
    for (let i = 0; i < locationCount; i++) {
      // make request for every individual location we need to add
      locationsUpdated += 1;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const requestOptions: IAuthenticatedRequestDefinition = yield call(
        warehousesService.saveWarehouseLocation,
        locationRequestBody,
      );
      yield call(networkActions.makeAuthenticatedRequest, requestOptions);
    }
  }
  const details: IWarehouseBulkLocationSuccess = {
    newLocations: locationsUpdated,
  };
  yield put(
    warehouseLocationActions.saveWarehouseBulkLocationsSuccess(details),
  );
}

function saveWarehouseBulkLocationsSuccessWorker(
  action: ISaveWarehouseBulkLocationsSuccessActionType,
) {
  // redirect to locations table list after success
  history.push("/warehouse/locations");
  // display success message
  toast.success(
    "Successfully added " +
      action.payload.newLocations.toString() +
      " new warehouse location(s).",
  );
}

function* saveWarehouseLocationWorker(
  action: ISaveWarehouseLocationActionType,
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: IAuthenticatedRequestDefinition = yield call(
    warehousesService.saveWarehouseLocation,
    action.payload,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IWarehouseLocationStoreType = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(warehouseLocationActions.saveWarehouseLocationSuccess(response));
}

function saveWarehouseLocationSuccessWorker(
  action: ISaveWarehouseLocationActionType,
) {
  // redirect to locations table list after success
  history.push("/warehouse/locations");
  // display success message
  if (action.payload && action.payload.warehouseLocationId) {
    toast.success("Facility Location updated successfully");
  }
}

export const warehouseLocationSagas = function* sagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(REPRINT_WAREHOUSE_LOCATION, reprintWarehouseLocationWorker);
  yield takeLeading(GET_WAREHOUSE_LOCATION, getWarehouseLocationWorker);
  yield takeLeading(
    SAVE_WAREHOUSE_BULK_LOCATIONS,
    saveWarehouseBulkLocationsWorker,
  );
  yield takeLeading(
    SAVE_WAREHOUSE_BULK_LOCATIONS_SUCCESS,
    saveWarehouseBulkLocationsSuccessWorker,
  );
  yield takeLeading(SAVE_WAREHOUSE_LOCATION, saveWarehouseLocationWorker);
  yield takeLeading(
    SAVE_WAREHOUSE_LOCATION_SUCCESS,
    saveWarehouseLocationSuccessWorker,
  );
};
