import { takeEvery, call, put } from "redux-saga/effects";
import { buildingsService } from "services/hermes/buildings";
import { driverBatchesServices } from "services/hermes/driverBatches";
import { packagesService } from "services/hermes/packages";
import { usersService } from "services/hermes/users";
import { warehousesService } from "services/hermes/warehouses";

import {
  keystrokeDelay,
  networkActions,
} from "slices/authentication/network.actions";
import { loadingActions } from "../loading/loading.actions";
import {
  ISearchHermesActionType,
  searchHermesActions,
  SearchHermesResponseValues,
  SEARCH_HERMES,
} from "./searchHermes.actions";

function* searchHermesWorker({
  type,
  location,
  identifier,
  request,
  onSuccess,
  onFailure,
}: ISearchHermesActionType) {
  yield keystrokeDelay();
  yield put(loadingActions.startLoad(type));
  let requestOptionsService:
    | typeof driverBatchesServices.searchBatches
    | typeof packagesService.searchPackages
    | typeof usersService.searchUsers
    | typeof warehousesService.searchWarehouses
    | typeof buildingsService.searchBuildings
    | typeof warehousesService.searchWarehouseLocations;
  switch (location) {
    case "batches":
      requestOptionsService = driverBatchesServices.searchBatches;
      break;
    case "packages":
      requestOptionsService = packagesService.searchPackages;
      break;
    case "users":
      requestOptionsService = usersService.searchUsers;
      break;
    case "warehouses":
      requestOptionsService = warehousesService.searchWarehouses;
      break;
    case "buildings":
      requestOptionsService = buildingsService.searchBuildings;
      break;
    case "warehouseLocations":
      requestOptionsService = warehousesService.searchWarehouseLocations;
      break;
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: SearchHermesResponseValues = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptionsService(request),
      true,
    );

    yield put(
      searchHermesActions.searchHermesSuccess(
        location,
        identifier,
        response,
        "successful",
      ),
    );
    if (!!onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(
      searchHermesActions.searchHermesSuccess(
        location,
        identifier,
        undefined,
        "failed",
      ),
    );
    if (!!onFailure) {
      onFailure();
    }
  }
  yield put(loadingActions.endLoad(type));
}

export const searchHermesSagas = function* () {
  yield takeEvery(SEARCH_HERMES, searchHermesWorker);
};
