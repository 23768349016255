import React from "react";

import Switch, { ReactSwitchProps } from "react-switch";

import { BEM } from "helpers/BEM.helper";

const classes = new BEM({
  block: {
    name: "FetchToggleSwitch",
    extras: ["d-flex", "justify-content-between", "mb-2"],
  },
  elements: [{ name: "switch" }],
});

type FetchToggleSwitchProps = {
  className?: string;
  onChange: (checked: boolean) => void;
  isChecked: boolean;
  label?: string;
  uncheckedHandleIcon?: ReactSwitchProps["uncheckedHandleIcon"];
  uncheckedIcon?: ReactSwitchProps["uncheckedIcon"];
  checkedHandleIcon?: ReactSwitchProps["checkedHandleIcon"];
  checkedIcon?: ReactSwitchProps["checkedIcon"];
  disabled?: boolean;
  "data-cy"?: string;
  onColor?: string;
  offColor?: string;
  labelClassName?: string;
};

export const FetchToggleSwitch: React.FC<FetchToggleSwitchProps> = (
  props: FetchToggleSwitchProps,
) => {
  const {
    className,
    onChange,
    label,
    isChecked,
    uncheckedHandleIcon,
    uncheckedIcon,
    checkedHandleIcon,
    checkedIcon,
    disabled,
    "data-cy": dataCy, // hyphen not allowed in javascript variable names
  } = props;
  const blockExtras: string[] = ["text-center"];
  if (!!className) {
    blockExtras.push(className);
  }

  return (
    <div
      className={classes.getBlockClassNames(
        !!label ? { extras: blockExtras } : {},
      )}
      data-ischecked={props.isChecked}
    >
      {label && (
        <div className="d-flex">
          <span className={props.labelClassName || "fds-emphasis-100"}>
            {label}:
          </span>
        </div>
      )}
      <Switch
        className={`${classes.getElementClassNames(
          "switch",
        )} ${classes.getBlockClassNames({ extras: blockExtras })}`}
        onColor={props.onColor || "#38B4DC"}
        offColor={props.offColor || "#CCC"}
        uncheckedHandleIcon={uncheckedHandleIcon || undefined}
        uncheckedIcon={uncheckedIcon || false}
        checkedHandleIcon={checkedHandleIcon || undefined}
        checkedIcon={checkedIcon || false}
        onChange={onChange}
        checked={isChecked}
        disabled={disabled}
        data-cy={dataCy}
      />
    </div>
  );
};
