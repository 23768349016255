import { INotificationType, ModalStoreIndexType } from "./ui.reducer";

export const ALERT_UNVERIFIED_PHONE = "ALERT_UNVERIFIED_PHONE";
export const ALERT_UNVERIFIED_EMAIL = "ALERT_UNVERIFIED_EMAIL";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const TRIGGER_NEW_SESSION_ALERTS = "TRIGGER_NEW_SESSION_ALERTS";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const TRIGGER_MODAL = "TRIGGER_MODAL";

export interface ITriggerNewActionAlertsActionType {
  type: typeof TRIGGER_NEW_SESSION_ALERTS;
}
export interface IClearNotificationsActionType {
  type: typeof CLEAR_NOTIFICATIONS;
}

export type ClearNotificationPayloadType = string;

export interface IClearNotificationActionType {
  type: typeof CLEAR_NOTIFICATION;
  payload: ClearNotificationPayloadType;
}

export interface IAlertUnverifiedPhoneActionType {
  type: typeof ALERT_UNVERIFIED_PHONE;
}

export interface IAlertUnverifiedEmailActionType {
  type: typeof ALERT_UNVERIFIED_EMAIL;
}

export interface IAddNotificationActionType {
  type: typeof ADD_NOTIFICATION;
  notification: INotificationType;
}

export interface IClearModalActionType {
  type: typeof CLEAR_MODAL;
  payload: string;
}

export interface ITriggerModalActionType {
  type: typeof TRIGGER_MODAL;
  modalId: ModalStoreIndexType;
}

export const clearModal = () => {
  return { type: CLEAR_MODAL };
};

export function triggerModal(modalId: ModalStoreIndexType) {
  return { type: TRIGGER_MODAL, modalId };
}

function addNotification(notification: INotificationType) {
  return { type: ADD_NOTIFICATION, notification };
}

function triggerNewSessionAlerts() {
  return { type: TRIGGER_NEW_SESSION_ALERTS };
}

function clearNotifications() {
  return { type: CLEAR_NOTIFICATIONS };
}

function clearNotification(payload: ClearNotificationPayloadType) {
  return { type: CLEAR_NOTIFICATION, payload };
}

function alertUnverifiedPhone() {
  return {
    type: ALERT_UNVERIFIED_PHONE,
  };
}

function alertUnverifiedEmail() {
  return { type: ALERT_UNVERIFIED_EMAIL };
}

export const uiActions = {
  addNotification,
  alertUnverifiedEmail,
  alertUnverifiedPhone,
  clearNotification,
  clearNotifications,
  triggerNewSessionAlerts,
  clearModal,
  triggerModal,
};
