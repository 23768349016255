import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IWarehouseLocationsStoreType } from "./warehouseLocations.reducer";

export const CLEAR_WAREHOUSE_LOCATIONS = "CLEAR_WAREHOUSE_LOCATIONS";
export const SEARCH_WAREHOUSE_LOCATIONS = "SEARCH_WAREHOUSE_LOCATIONS";
export const SEARCH_WAREHOUSE_LOCATIONS_SUCCESS =
  "SEARCH_WAREHOUSE_LOCATIONS_SUCCESS";
export const SEARCH_WAREHOUSE_LOCATIONS_FAILURE =
  "SEARCH_WAREHOUSE_LOCATIONS_FAILURE";

export interface ISearchWarehouseLocationsActionType {
  type: string;
  payload: IPaginatedTableSearchRequestType;
}
export interface ISearchWarehouseLocationsSuccessActionType {
  type: typeof SEARCH_WAREHOUSE_LOCATIONS_SUCCESS;
  payload: IWarehouseLocationsStoreType;
}
export interface ISearchWarehouseLocationsFailureActionType {
  type: typeof SEARCH_WAREHOUSE_LOCATIONS_FAILURE;
}

export interface IClearWarehouseLocationsActionType {
  type: typeof CLEAR_WAREHOUSE_LOCATIONS;
}

function clearWarehouseLocations(): IClearWarehouseLocationsActionType {
  return { type: CLEAR_WAREHOUSE_LOCATIONS };
}

function searchWarehouseLocations(
  warehouseLocationSearch: IPaginatedTableSearchRequestType,
): ISearchWarehouseLocationsActionType {
  return { type: SEARCH_WAREHOUSE_LOCATIONS, payload: warehouseLocationSearch };
}

function searchWarehouseLocationsSuccess(
  warehouseLocationResponse: IWarehouseLocationsStoreType,
): ISearchWarehouseLocationsSuccessActionType {
  return {
    payload: warehouseLocationResponse,
    type: SEARCH_WAREHOUSE_LOCATIONS_SUCCESS,
  };
}

function searchWarehouseLocationsFailure(): ISearchWarehouseLocationsFailureActionType {
  return { type: SEARCH_WAREHOUSE_LOCATIONS_FAILURE };
}

export const warehouseLocationsActions = {
  clearWarehouseLocations,
  searchWarehouseLocations,
  searchWarehouseLocationsSuccess,
  searchWarehouseLocationsFailure,
};
