import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IBuildingsStoreType } from "./buildings.reducer";

export const CLEAR_BUILDINGS = "CLEAR_BUILDINGS";
export const SEARCH_BUILDINGS = "SEARCH_BUILDINGS";
export const SEARCH_BUILDINGS_SUCCESS = "SEARCH_BUILDINGS_SUCCESS";
export const SEARCH_BUILDINGS_FAILURE = "SEARCH_BUILDINGS_FAILURE";

export interface IClearBuildingsActionType {
  type: typeof CLEAR_BUILDINGS;
}

export interface ISearchBuildingsActionType {
  type: string;
  payload: IPaginatedTableSearchRequestType;
}

export interface ISearchBuildingsSuccessActionType {
  type: typeof SEARCH_BUILDINGS_SUCCESS;
  payload: IBuildingsStoreType;
}

export interface ISearchBuildingsFailureActionType {
  type: typeof SEARCH_BUILDINGS_FAILURE;
}

function clearBuildings() {
  return { type: CLEAR_BUILDINGS };
}

function searchBuildings(buildingSearch: IPaginatedTableSearchRequestType) {
  return { type: SEARCH_BUILDINGS, payload: buildingSearch };
}

function searchBuildingsSuccess(buildingResponse: IBuildingsStoreType) {
  return { type: SEARCH_BUILDINGS_SUCCESS, payload: buildingResponse };
}

function searchBuildingsFailure() {
  return { type: SEARCH_BUILDINGS_FAILURE };
}

export const buildingsActions = {
  clearBuildings,
  searchBuildings,
  searchBuildingsSuccess,
  searchBuildingsFailure,
};
