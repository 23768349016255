import { NavRoute } from "routes/navRoutes";
import { getCurrentTreatBundleUrls } from "./getCurrentTreatBundleUrls.helper";
import {
  userHasFeatureFlags,
  userHasPermission,
  userHasPrivileges,
} from "./permissions.helper";
import { navRoutes } from "routes/navRoutes";
import { CurrentUserState } from "slices/currentUser/currentUser.reducer";

const includeNavRoute = (
  currentUser: CurrentUserState | undefined,
  { path, permissions, featureFlags, privileges }: NavRoute,
): boolean => {
  if (path === "/treats") {
    const currentTreatsUrls = getCurrentTreatBundleUrls(currentUser);

    return (
      currentTreatsUrls.length > 0 &&
      userHasPermission(currentUser, permissions) &&
      userHasFeatureFlags(currentUser, featureFlags) &&
      userHasPrivileges(currentUser, privileges)
    );
  }

  return (
    userHasPermission(currentUser, permissions) &&
    userHasFeatureFlags(currentUser, featureFlags) &&
    userHasPrivileges(currentUser, privileges)
  );
};

export const getUserNavRoutes = (
  currentUser?: CurrentUserState,
): NavRoute[] => {
  if (!currentUser) {
    return [];
  }

  // if parent is not included, children are not included
  const filteredParents = navRoutes.filter((navRoute) =>
    includeNavRoute(currentUser, navRoute),
  );

  return filteredParents.map((navRoute) => {
    if (navRoute.children && navRoute.children.length > 0) {
      const filteredChildren = navRoute.children.filter((navRoute) =>
        includeNavRoute(currentUser, navRoute),
      );

      return {
        ...navRoute,
        children: filteredChildren.length > 0 ? filteredChildren : undefined,
      };
    }

    return navRoute;
  });
};
