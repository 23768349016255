import {
  ILoggedPackagesStoreType,
  ILoggedPackageStoreType,
} from "./loggedPackages.reducer";

export function appendOrUpdateLoggedPackage(
  loggedPackages: ILoggedPackagesStoreType,
  packageToUpdate: ILoggedPackageStoreType,
): ILoggedPackagesStoreType {
  const packageIsPendingResponse = !packageToUpdate.fetchPackageId;

  return loggedPackages.reduce(
    (
      newLoggedPackages: ILoggedPackagesStoreType,
      currentLoggedPackage: ILoggedPackageStoreType,
    ): ILoggedPackagesStoreType => {
      const matchingPackageWasFound =
        packageToUpdate.fetchPackageId === currentLoggedPackage.fetchPackageId;

      if (newLoggedPackages.length < 20) {
        if (matchingPackageWasFound) {
          // Use updated package
          newLoggedPackages.push(packageToUpdate);
        } else {
          // Keep unrelated packages
          newLoggedPackages.push(currentLoggedPackage);
        }
      }

      return newLoggedPackages;
    },
    packageIsPendingResponse ? [packageToUpdate] : [],
  );
}
