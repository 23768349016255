import { IDriverUserAuditHistoryStoreType } from "./driverUserAuditHistory.reducer";

export const GET_DRIVER_USER_AUDIT_HISTORY = "GET_DRIVER_USER_AUDIT_HISTORY";
export const GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS =
  "GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS";

export interface IGetDriverUserAuditHistoryActionType {
  type: typeof GET_DRIVER_USER_AUDIT_HISTORY;
  systemId: string;
}

export interface IGetDriverUserAuditHistorySuccessActionType {
  type: typeof GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS;
  payload: IDriverUserAuditHistoryStoreType;
}

// ACTION TRIGGER
function getDriverUserAuditHistory(
  systemId: string,
): IGetDriverUserAuditHistoryActionType {
  return { type: GET_DRIVER_USER_AUDIT_HISTORY, systemId };
}

// SUCCESS HANDLER
function getDriverUserAuditHistorySuccess(
  payload: IDriverUserAuditHistoryStoreType,
): IGetDriverUserAuditHistorySuccessActionType {
  return { type: GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS, payload };
}

export const driverUserAuditHistoryActions = {
  getDriverUserAuditHistory,
  getDriverUserAuditHistorySuccess,
};
