import { call, put, takeEvery } from "redux-saga/effects";
import { networkActions } from "slices/authentication/network.actions";
import { packagesService } from "services/hermes/packages";
import { IGetPackageByIdActionType } from "./packageById.reducer";
import { GET_PACKAGE_BY_ID, packageByIdActions } from "./packageById.actions";
import { loadingActions } from "slices/loading/loading.actions";
import { IPackageStoreType } from "./package.reducer";

function* packageByIdWorker(action: IGetPackageByIdActionType) {
  yield put(loadingActions.startLoad(action.type));

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof packagesService.getPackage> =
      yield call(
        packagesService.getPackage,
        `${action.payload.fetchPackageId}`,
      );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IPackageStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(packageByIdActions.getPackageByIdSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

export const packageByIdSaga = function* () {
  yield takeEvery(GET_PACKAGE_BY_ID, packageByIdWorker);
};
