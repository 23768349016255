/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Take an object and convert any values that are numbers into strings
// Useful for setting the initial values of forms,
// so that isDirty doesn't think a reverted field is dirty

// ie, packageType 10 !== "10"

export function objectNumbersToStrings(obj: any): any {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return obj;
  }

  const result: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "number") {
        result[key] = obj[key].toString();
      } else {
        result[key] = obj[key];
      }
    }
  }

  return result;
}
