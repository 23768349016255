import { IWarehouseLocationStoreType } from "./warehouseLocation.reducer";

export const CLEAR_WAREHOUSE_LOCATION = "CLEAR_WAREHOUSE_LOCATION";
export const GET_WAREHOUSE_LOCATION = "GET_WAREHOUSE_LOCATION";
export const GET_WAREHOUSE_LOCATION_SUCCESS = "GET_WAREHOUSE_LOCATION_SUCCESS";
export const REPRINT_WAREHOUSE_LOCATION = "REPRINT_WAREHOUSE_LOCATION";
export const SAVE_WAREHOUSE_LOCATION = "SAVE_WAREHOUSE_LOCATION";
export const SAVE_WAREHOUSE_LOCATION_SUCCESS =
  "SAVE_WAREHOUSE_LOCATION_SUCCESS";
export const SAVE_WAREHOUSE_BULK_LOCATIONS = "SAVE_WAREHOUSE_BULK_LOCATIONS";
export const SAVE_WAREHOUSE_BULK_LOCATIONS_SUCCESS =
  "SAVE_WAREHOUSE_BULK_LOCATIONS_SUCCESS";

export interface ISaveWarehouseLocationActionType {
  type: typeof SAVE_WAREHOUSE_LOCATION;
  payload: IWarehouseLocationStoreType;
}
export interface ISaveWarehouseLocationSuccessActionType {
  type: typeof SAVE_WAREHOUSE_LOCATION_SUCCESS;
  payload: IWarehouseLocationStoreType;
}

export interface ISaveWarehouseBulkLocationsActionType {
  type: typeof SAVE_WAREHOUSE_BULK_LOCATIONS;
  payload: ISaveWarehouseBulkLocationsDispatchValues;
}

export interface ISaveWarehouseBulkLocationsSuccessActionType {
  type: typeof SAVE_WAREHOUSE_BULK_LOCATIONS_SUCCESS;
  payload: IWarehouseBulkLocationSuccess;
}

export interface IWarehouseBulkLocationSuccess {
  newLocations: number;
}

export interface ISaveWarehouseBulkLocationsDispatchValues {
  warehouseLocationBatchCategory?: string;
  warehouseId?: string;
  buildingId?: string;
  locations: { [locationName: string]: number };
}

export interface IReprintWarehouseLocationActionType {
  type: typeof REPRINT_WAREHOUSE_LOCATION;
  payload: {
    warehouseLocationId: number;
  };
}

export interface IGetWarehouseLocationActionType {
  type: string;
  payload: {
    warehouseLocationId: string;
  };
}

export interface IGetWarehouseLocationSuccessActionType {
  type: typeof GET_WAREHOUSE_LOCATION_SUCCESS;
  payload: IWarehouseLocationStoreType;
}

export interface IClearWarehouseLocationActionType {
  type: typeof CLEAR_WAREHOUSE_LOCATION;
}

function clearWarehouseLocation() {
  return { type: CLEAR_WAREHOUSE_LOCATION };
}

function getWarehouseLocation(
  warehouseLocationId: string,
): IGetWarehouseLocationActionType {
  return { type: GET_WAREHOUSE_LOCATION, payload: { warehouseLocationId } };
}

function getWarehouseLocationSuccess(
  warehouseLocationResponse: IWarehouseLocationStoreType,
): IGetWarehouseLocationSuccessActionType {
  return {
    payload: warehouseLocationResponse,
    type: GET_WAREHOUSE_LOCATION_SUCCESS,
  };
}
function reprintWarehouseLocationLabel(
  warehouseLocationId: number,
): IReprintWarehouseLocationActionType {
  return { type: REPRINT_WAREHOUSE_LOCATION, payload: { warehouseLocationId } };
}

function saveWarehouseBulkLocations(
  formData: ISaveWarehouseBulkLocationsDispatchValues,
): ISaveWarehouseBulkLocationsActionType {
  return { type: SAVE_WAREHOUSE_BULK_LOCATIONS, payload: formData };
}

function saveWarehouseBulkLocationsSuccess(
  details: IWarehouseBulkLocationSuccess,
): ISaveWarehouseBulkLocationsSuccessActionType {
  return { type: SAVE_WAREHOUSE_BULK_LOCATIONS_SUCCESS, payload: details };
}

function saveWarehouseLocation(
  formData: IWarehouseLocationStoreType,
): ISaveWarehouseLocationActionType {
  return { type: SAVE_WAREHOUSE_LOCATION, payload: formData };
}

function saveWarehouseLocationSuccess(
  warehouseLocationResponse: IWarehouseLocationStoreType,
): ISaveWarehouseLocationSuccessActionType {
  return {
    payload: warehouseLocationResponse,
    type: SAVE_WAREHOUSE_LOCATION_SUCCESS,
  };
}

export const warehouseLocationActions = {
  clearWarehouseLocation,
  getWarehouseLocation,
  reprintWarehouseLocationLabel,
  saveWarehouseBulkLocations,
  saveWarehouseLocation,
  saveWarehouseLocationSuccess,
  saveWarehouseBulkLocationsSuccess,
  getWarehouseLocationSuccess,
};
