import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IFullTextSearchStore } from "./FullTextSearch.reducer";

export const GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH =
  "GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH";
export const GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_SUCCESS =
  "GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_SUCCESS";
export const GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_FAILURE =
  "GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_FAILURE";
export const CLEAR_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH =
  "CLEAR_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH";

// Interfaces
export interface GetFetchPackagesLabelsFullTextSearchAction {
  type: typeof GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH;
  payload: {
    paginationQuery: IPaginatedTableSearchRequestType;
  };
}

export interface ClearFetchPackagesLabelsFullTextSearchAction {
  type: typeof CLEAR_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH;
}

export interface GetFetchPackagesLabelsFullTextSearchResponse {
  fetchPackageId: number;
  warehouseId: number;
  ocrWords: string;
  barcodeWords: string;
  imgUrl: string;
  createdDate: string;
}

export interface GetFetchPackagesLabelsFullTextSearchSuccessAction {
  type: typeof GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_SUCCESS;
  payload: IFullTextSearchStore;
}

export interface GetFetchPackagesLabelsFullTextSearchFailureAction {
  type: typeof GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_FAILURE;
}

// Functions
function getFetchPackagesLabelsFullTextSearchSuccess(
  response: IFullTextSearchStore,
): GetFetchPackagesLabelsFullTextSearchSuccessAction {
  return {
    type: GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_SUCCESS,
    payload: response,
  };
}

function getFetchPackagesLabelsFullTextSearch(
  paginationQuery: IPaginatedTableSearchRequestType,
): GetFetchPackagesLabelsFullTextSearchAction {
  return {
    type: GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH,
    payload: { paginationQuery },
  };
}

function clearFetchPackagesLabelsFullTextSearch(): ClearFetchPackagesLabelsFullTextSearchAction {
  return {
    type: CLEAR_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH,
  };
}

function getFetchPackagesLabelsFullTextSearchFailure(): GetFetchPackagesLabelsFullTextSearchFailureAction {
  return { type: GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH_FAILURE };
}

export const fullTextSearchActions = {
  getFetchPackagesLabelsFullTextSearchSuccess,
  getFetchPackagesLabelsFullTextSearch,
  getFetchPackagesLabelsFullTextSearchFailure,
  clearFetchPackagesLabelsFullTextSearch,
};
