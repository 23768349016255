import { call, put, takeLatest } from "redux-saga/effects";
import {
  UserRolePrivilegeDTO,
  userRolesService,
} from "services/hermes/userRoles";
import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  getUserRolePrivilegesFailure,
  getUserRolePrivilegesFetch,
  getUserRolePrivilegesSuccess,
} from "./userRolePrivilegesState";

function* workerGetUserRolePrivilegesFetch() {
  try {
    yield put(loadingActions.startLoad(getUserRolePrivilegesFetch.toString()));
    const requestOptions = (yield call(
      userRolesService.getUserRolePrivileges,
    )) as ReturnType<typeof userRolesService.getUserRolePrivileges>;
    const response = (yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    )) as UserRolePrivilegeDTO[];

    yield put(getUserRolePrivilegesSuccess(response));
  } catch (e) {
    let msg = "Error while loading User Role Privileges";
    if (e instanceof Error || typeof e === "string") {
      msg = e.toString();
    }
    yield put(getUserRolePrivilegesFailure(msg));
  } finally {
    yield put(loadingActions.endLoad(getUserRolePrivilegesFetch.toString()));
  }
}

export function* userRolePrivilegesSaga() {
  yield takeLatest(
    getUserRolePrivilegesFetch,
    workerGetUserRolePrivilegesFetch,
  );
}
