import { takeLatest, takeLeading, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import { loadingActions } from "slices/loading/loading.actions";
import {
  keystrokeDelay,
  networkActions,
} from "slices/authentication/network.actions";
import { searchHermesActions } from "slices/searchHermes/searchHermes.actions";
import {
  BATCHED_PACKAGES,
  EXISTING_BATCHES,
  SCHEDULED_PACKAGES,
} from "views/pages/CourierRouter/constants/searchIdentifiers";
import {
  ISearchBatchesActionType,
  batchesActions,
  ISubmitBatchesActionType,
  SEARCH_BATCHES,
  SUBMIT_BATCHES,
} from "./batches.actions";
import { IBatchesStoreType } from "./batches.reducer";
import { driverBatchesServices } from "services/hermes/driverBatches";

function* searchBatchesWorker(action: ISearchBatchesActionType) {
  yield put(loadingActions.startLoad(action.type));
  yield put(batchesActions.clearBatches());
  try {
    yield keystrokeDelay();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof driverBatchesServices.searchBatches
    > = yield call(driverBatchesServices.searchBatches, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IBatchesStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(batchesActions.searchBatchesSuccess(response));
  } catch (error) {
    yield put(batchesActions.searchBatchesFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

function* submitBatchesWorker(action: ISubmitBatchesActionType) {
  yield put(loadingActions.startLoad(action.type));
  try {
    yield all(
      action.batches.map((batch) => {
        return call(
          networkActions.makeAuthenticatedRequest,
          driverBatchesServices.saveBatch(batch),
          true,
        );
      }),
    );
    toast.success("All batches submitted successfully!");
  } catch (error) {
    toast.error("Some batches failed to submit!");
  }
  action.onResponse();

  if (!!action.refresh) {
    yield put(
      searchHermesActions.searchHermes({
        identifier: SCHEDULED_PACKAGES,
        location: "packages",
        request: action.refresh.batchablePackagesQuery,
      }),
    );
    yield put(
      searchHermesActions.searchHermes({
        identifier: BATCHED_PACKAGES,
        location: "packages",
        request: action.refresh.batchedPackagesQuery,
      }),
    );
    yield put(
      searchHermesActions.searchHermes({
        identifier: EXISTING_BATCHES,
        location: "batches",
        request: action.refresh.driverBatches,
      }),
    );
  }
  yield put(loadingActions.endLoad(action.type));
}

export const batchesSagas = function* sagas() {
  yield takeLatest(SEARCH_BATCHES, searchBatchesWorker);
  yield takeLeading(SUBMIT_BATCHES, submitBatchesWorker);
};
