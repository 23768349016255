import { PendingRating } from "./pendingRating.reducers";

export const CLEAR_PENDING_RATING = "CLEAR_PENDING_RATING";
export const POST_RATING = "POST_RATING";
export const GET_PENDING_RATING = "GET_PENDING_RATING";
export const GET_PENDING_RATING_SUCCESS = "GET_PENDING_RATING_SUCCESS";

export interface IClearPendingRatingActionType {
  type: typeof CLEAR_PENDING_RATING;
}
export interface IGetPendingRatingActionType {
  type: typeof GET_PENDING_RATING;
}
export interface IGetPendingRatingSuccessActionType {
  type: typeof GET_PENDING_RATING_SUCCESS;
  pendingRating: PendingRating;
}

export type CSATRatingTypes = "THUMBS_DOWN" | "THUMBS_UP" | "DISMISSED";

export type CSATRating = {
  driverBatchFetchPackageId: number;
  reasonCode: number | null;
  rating: CSATRatingTypes;
  notes: string | null;
};

export interface IPostRatingActionType {
  type: typeof POST_RATING;
  rating: CSATRating;
}

function clearPendingRating(): IClearPendingRatingActionType {
  return { type: CLEAR_PENDING_RATING };
}

function getPendingRating(): IGetPendingRatingActionType {
  return { type: GET_PENDING_RATING };
}
function getPendingRatingSuccess(
  pendingRating: PendingRating,
): IGetPendingRatingSuccessActionType {
  return { type: GET_PENDING_RATING_SUCCESS, pendingRating };
}

function postRating(rating: CSATRating): IPostRatingActionType {
  return { type: POST_RATING, rating };
}

export const pendingRatingActions = {
  clearPendingRating,
  getPendingRating,
  postRating,
  getPendingRatingSuccess,
};
