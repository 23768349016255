export const BATCH_FORMNAME = "batch";
export const BUILDING_FORMNAME = "building";
export const LOCAL_ROUTES_FORMNAME = "localRoutes";
export const MY_RESIDENTIAL_USER_FORMNAME = "myResidentialUser";
export const USER_PROFILE_FORMNAME = "userProfile";
export const WAREHOUSE_FORMNAME = "warehouse";

export type ReduxFormname =
  | typeof BATCH_FORMNAME
  | typeof BUILDING_FORMNAME
  | typeof LOCAL_ROUTES_FORMNAME
  | typeof MY_RESIDENTIAL_USER_FORMNAME
  | typeof USER_PROFILE_FORMNAME
  | typeof WAREHOUSE_FORMNAME;
