import { takeLeading, ForkEffect, call, put } from "redux-saga/effects";
import { printersService } from "services/hermes/printers";

import { networkActions } from "slices/authentication/network.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  GET_WAREHOUSE_PRINTERS,
  IGetWarehousePrintersActionType,
  warehousePrintersActions,
} from "./warehousePrinters.actions";
import { IWarehousePrintersStoreType } from "./warehousePrinters.reducer";

function* getWarehousePrintersWorker(action: IGetWarehousePrintersActionType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: IAuthenticatedRequestDefinition = yield call(
    printersService.getWarehousePrinters,
    action.payload.warehouseId,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IWarehousePrintersStoreType = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(warehousePrintersActions.getWarehousePrintersSuccess(response));
}

export const warehousePrintersSagas = function* sagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(GET_WAREHOUSE_PRINTERS, getWarehousePrintersWorker);
};
