import { Reducer } from "redux";

import {
  CLEAR_PACKAGE_DELIVERY_RECEIPT,
  GET_PACKAGE_DELIVERY_RECEIPT_FAILURE,
  GET_PACKAGE_DELIVERY_RECEIPT_SUCCESS,
  IClearPackageDeliveryReceiptActionType,
  IGetPackageDeliveryReceiptFailureActionType,
  IGetPackageDeliveryReceiptSuccessActionType,
} from "slices/packageDeliveryReceipts/packageDeliveryReceipts.actions";
import { defaultState } from "default-state";

export interface IPackageDeliveryReceipt {
  deliveryReceiptMethod: number;
  image: string;
}

export interface IPackageDeliveryReceiptsStoreType {
  [fetchPackageId: string]: IPackageDeliveryReceipt;
}

export const packageDeliveryReceipts: Reducer<
  IPackageDeliveryReceiptsStoreType
> = (
  state = defaultState.packageDeliveryReceipts,
  action: PackageDeliveryReceiptActionTypes,
) => {
  switch (action.type) {
    case CLEAR_PACKAGE_DELIVERY_RECEIPT:
      return defaultState.packageDeliveryReceipts;

    case GET_PACKAGE_DELIVERY_RECEIPT_SUCCESS:
      return {
        ...state,
        [action.payload.fetchPackageId]: {
          deliveryReceiptMethod: action.payload.deliveryReceiptMethod,
          image: action.payload.image,
        },
      };

    case GET_PACKAGE_DELIVERY_RECEIPT_FAILURE:
      return { ...state };

    default:
      return { ...state };
  }
};

export type PackageDeliveryReceiptActionTypes =
  | IClearPackageDeliveryReceiptActionType
  | IGetPackageDeliveryReceiptSuccessActionType
  | IGetPackageDeliveryReceiptFailureActionType;
