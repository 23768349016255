import { PackageDeliveryWindowOptionsStoreType } from "./packageDeliveryWindowOptions.reducers";

export const CLEAR_PACKAGE_DELIVERY_WINDOW_OPTIONS =
  "CLEAR_PACKAGE_DELIVERY_WINDOW_OPTIONS";
export const GET_PACKAGE_DELIVERY_WINDOW_OPTIONS =
  "GET_PACKAGE_DELIVERY_WINDOW_OPTIONS";
export const GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_SUCCESS =
  "GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_SUCCESS";
export const GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_FAILURE =
  "GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_FAILURE";

export interface IGetPackageDeliveryWindowOptionsPayloadType {
  fetchPackageId: number;
}

export interface IGetPackageDeliveryWindowOptionsActionType {
  type: typeof GET_PACKAGE_DELIVERY_WINDOW_OPTIONS;
  payload: IGetPackageDeliveryWindowOptionsPayloadType;
}
export interface IGetPackageDeliveryWindowOptionsSuccessActionType {
  type: typeof GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_SUCCESS;
  payload: PackageDeliveryWindowOptionsStoreType;
}
export interface IGetPackageDeliveryWindowOptionsFailureActionType {
  type: typeof GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_FAILURE;
}

export interface IClearPackageDeliveryWindowOptionsActionType {
  type: typeof CLEAR_PACKAGE_DELIVERY_WINDOW_OPTIONS;
}

export interface IClearPackageDeliveryWindowOptionsActionType {
  type: typeof CLEAR_PACKAGE_DELIVERY_WINDOW_OPTIONS;
}

function clearPackageDeliveryWindowOptions(): IClearPackageDeliveryWindowOptionsActionType {
  return { type: CLEAR_PACKAGE_DELIVERY_WINDOW_OPTIONS };
}

function getPackageDeliveryWindowOptions(
  payload: IGetPackageDeliveryWindowOptionsPayloadType,
): IGetPackageDeliveryWindowOptionsActionType {
  return { type: GET_PACKAGE_DELIVERY_WINDOW_OPTIONS, payload };
}
function getPackageDeliveryWindowOptionsSuccess(
  response: PackageDeliveryWindowOptionsStoreType,
): IGetPackageDeliveryWindowOptionsSuccessActionType {
  return {
    payload: response,
    type: GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_SUCCESS,
  };
}
function getPackageDeliveryWindowOptionsFailure(): IGetPackageDeliveryWindowOptionsFailureActionType {
  return { type: GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_FAILURE };
}

export const packageDeliveryWindowOptionsActions = {
  clearPackageDeliveryWindowOptions,
  getPackageDeliveryWindowOptions,
  getPackageDeliveryWindowOptionsSuccess,
  getPackageDeliveryWindowOptionsFailure,
};
