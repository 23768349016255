import { IPatchMySettingsPayloadType } from "slices/currentUser/currentUser.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { getEncodedSystemIdFromSystemId } from "helpers/system-id.helper";
import {
  IResidentialUserStoreType,
  UserUpdateType,
} from "slices/currentUser/currentUser.reducer";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";
import { WarehouseUser } from "slices/warehouseUser/warehouseUser.reducer";
import { getSystemIdFromEmail } from "helpers/system-id.helper";
import { generateQueryString } from "helpers/generateQueryString.helper";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";

function searchUsersById(userId: number): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/users/search/${userId}`,
  };
}

function searchUsers(
  parameters: IPaginatedTableSearchRequestType,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/users/search" + generateQueryString(parameters),
  };
}

function saveWarehouseUser(
  encodedSystemId: string,
  parameters: WarehouseUser,
): IAuthenticatedRequestDefinition {
  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/users/admin/warehouse/" + encodedSystemId,
  };
}

function saveUser(parameters: IUserStoreType): IAuthenticatedRequestDefinition {
  // If we don't have a systemId, set from email
  if (!parameters.hasOwnProperty("systemId") || parameters.systemId === null) {
    parameters.systemId = getSystemIdFromEmail(parameters.email);
  }
  // If any of the roles are undefined, pass as false
  const requiredPermissionFlags: (
    | "hasRoleAdministrator"
    | "hasRoleWarehouse"
    | "hasRoleDriver"
    | "hasRoleResident"
  )[] = [
    "hasRoleAdministrator",
    "hasRoleWarehouse",
    "hasRoleDriver",
    "hasRoleResident",
  ];
  for (const requiredPermissionFlag of requiredPermissionFlags) {
    if (
      !parameters.hasOwnProperty(requiredPermissionFlag) ||
      parameters[requiredPermissionFlag] === null
    ) {
      parameters[requiredPermissionFlag] = false;
    }
  }
  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/users/admin",
  };
}

function saveResidentialUser(
  encodedSystemId: string,
  parameters: Partial<IResidentialUserStoreType>,
): IAuthenticatedRequestDefinition {
  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/users/admin/residential/" + encodedSystemId,
  };
}

function saveMyWarehouseUser(
  parameters: WarehouseUser,
): IAuthenticatedRequestDefinition {
  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/users/warehouse",
  };
}

function adminGetUserAutoScheduleOptions(
  encodedSystemId: string,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: {
      headers: { "Content-Type": "application/json" },
      method: "GET",
    },
    requestUrl: `/users/admin/auto-schedule-options/${encodedSystemId}`,
  };
}

function deleteUsersAdminByEncodedSystemId(
  encodedSystemId: string,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "DELETE" },
    requestUrl: `/users/admin/${encodedSystemId}`,
  };
}

function getAdminUser(userId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/users/admin/" + userId,
  };
}

function getAutoScheduleOptions(): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/fetchPackages/auto-schedule-options",
  };
}

const getDriverUserAuditHistory = (
  systemId: string,
): IAuthenticatedRequestDefinition => ({
  requestOptions: {
    method: "GET",
  },
  requestUrl: `/users/admin/driver/aud/${getEncodedSystemIdFromSystemId(
    systemId,
  )}`,
});

function getMyUser(): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/users/my",
  };
}

function getResidentialUser(userId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/users/admin/residential/" + userId,
  };
}

function getResidentialUserAuditTrail(
  userId: string,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/users/admin/residential/aud/" + userId,
  };
}

function getUser(userId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/users/search/" + userId,
  };
}

function getUserAuditTrail(userId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/users/admin/aud/" + userId,
  };
}

function getWarehouseUser(userId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/users/admin/warehouse/" + userId,
  };
}

function patchMySettings(
  requestBody: IPatchMySettingsPayloadType,
): IAuthenticatedRequestDefinition {
  return {
    body: requestBody,
    requestOptions: {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    },
    requestUrl: "/users/mySettings",
  };
}

function postResetPassword(requestBody: {
  temporaryPassword: string;
  userId: number;
}): IAuthenticatedRequestDefinition {
  return {
    body: requestBody,

    requestOptions: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: "/users/admin/resetPassword",
  };
}

function saveMyResidentialUser(
  parameters: Partial<IResidentialUserStoreType>,
): IAuthenticatedRequestDefinition {
  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/users/residential",
  };
}

function patchMyResidentialUser(
  parameters: Partial<IResidentialUserStoreType>,
): IAuthenticatedRequestDefinition {
  return {
    body: parameters,
    requestOptions: { method: "PATCH" },
    requestUrl: "/users/residential",
  };
}

function saveMyUser(
  parameters: UserUpdateType,
): IAuthenticatedRequestDefinition {
  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/users/profile",
  };
}

export const usersService = {
  getAdminUser,
  getAutoScheduleOptions,
  getMyUser,
  getResidentialUser,
  getResidentialUserAuditTrail,
  getDriverUserAuditHistory,
  getUser,
  getUserAuditTrail,
  getWarehouseUser,
  patchMySettings,
  postResetPassword,
  saveMyResidentialUser,
  patchMyResidentialUser,
  saveMyUser,
  saveMyWarehouseUser,
  saveResidentialUser,
  saveUser,
  saveWarehouseUser,
  searchUsers,
  searchUsersById,
  deleteUsersAdminByEncodedSystemId,
  adminGetUserAutoScheduleOptions,
};
