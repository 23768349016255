import { IPackageStoreType } from "./package.reducer";
import {
  CLEAR_PACKAGE_IDS,
  GET_PACKAGE_BY_ID_SUCCESS,
  GET_PACKAGE_BY_ID,
} from "./packageById.actions";

export interface IGetPackageByIdActionType {
  type: typeof GET_PACKAGE_BY_ID;
  payload: { fetchPackageId: string };
}

type PackageByIdActionsTypes =
  | IGetPackageByIdSuccessActionType
  | IClearPackageByIdActionType;

interface IGetPackageByIdSuccessActionType {
  type: typeof GET_PACKAGE_BY_ID_SUCCESS;
  payload: IPackageStoreType;
}
interface IClearPackageByIdActionType {
  type: typeof CLEAR_PACKAGE_IDS;
}

export function packageByIdReducer(
  state: IPackageStoreType[] = [],
  action: PackageByIdActionsTypes,
): IPackageStoreType[] {
  switch (action.type) {
    case CLEAR_PACKAGE_IDS:
      return [];
    case GET_PACKAGE_BY_ID_SUCCESS:
      // wanted to make the store like a dictionary for faster lookup
      return { ...state, [action.payload.fetchPackageId]: action.payload };
    default:
      return state;
  }
}
