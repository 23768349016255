import { PostMySubscriptionIntentRequest } from "services/hermes/payments";
import { SubscriptionIntent } from "./subscriptionIntent.reducer";

export const CLEAR_SUBSCRIPTION_INTENT = "CLEAR_SUBSCRIPTION_INTENT";
export const POST_MY_SUBSCRIPTION_INTENT_V2 = "POST_MY_SUBSCRIPTION_INTENT_V2";
export const POST_MY_SUBSCRIPTION_INTENT_V2_SUCCESS =
  "POST_MY_SUBSCRIPTION_INTENT_V2_SUCCESS";

export interface ClearSubscriptionIntentAction {
  type: typeof CLEAR_SUBSCRIPTION_INTENT;
}

export interface PostMySubscriptionIntentV2Action {
  type: typeof POST_MY_SUBSCRIPTION_INTENT_V2;
  payload: PostMySubscriptionIntentRequest;
}

export interface PostMySubscriptionIntentV2SuccessAction {
  type: typeof POST_MY_SUBSCRIPTION_INTENT_V2_SUCCESS;
  payload: SubscriptionIntent;
}

function clearSubscriptionIntent(): ClearSubscriptionIntentAction {
  return { type: CLEAR_SUBSCRIPTION_INTENT };
}

function postMySubscriptionIntentV2(
  request: PostMySubscriptionIntentRequest,
): PostMySubscriptionIntentV2Action {
  return {
    type: POST_MY_SUBSCRIPTION_INTENT_V2,
    payload: request,
  };
}

function postMySubscriptionIntentV2Success(
  response: SubscriptionIntent,
): PostMySubscriptionIntentV2SuccessAction {
  return {
    type: POST_MY_SUBSCRIPTION_INTENT_V2_SUCCESS,
    payload: response,
  };
}

export const subscriptionIntentActions = {
  postMySubscriptionIntentV2,
  clearSubscriptionIntent,
  postMySubscriptionIntentV2Success,
};
