import { takeLeading, call, put } from "redux-saga/effects";
import { paymentsService } from "services/hermes/payments";
import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  PostMySubscriptionIntentV2Action,
  POST_MY_SUBSCRIPTION_INTENT_V2,
  subscriptionIntentActions,
} from "./subscriptionIntent.actions";
import { SubscriptionIntent } from "./subscriptionIntent.reducer";

function* postMySubscriptionIntentV2Worker(
  action: PostMySubscriptionIntentV2Action,
) {
  yield put(subscriptionIntentActions.clearSubscriptionIntent());
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof paymentsService.postMySubscriptionIntentV2
    > = yield call(paymentsService.postMySubscriptionIntentV2, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: SubscriptionIntent = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(
      subscriptionIntentActions.postMySubscriptionIntentV2Success(response),
    );
  } catch (error) {
  } finally {
    yield put(loadingActions.endLoad(action.type));
  }
}

export const subscriptionIntentSagas = function* () {
  yield takeLeading(
    POST_MY_SUBSCRIPTION_INTENT_V2,
    postMySubscriptionIntentV2Worker,
  );
};
