import { call, put, takeLeading } from "redux-saga/effects";
import { loadingActions } from "slices/loading/loading.actions";
import { networkActions } from "slices/authentication/network.actions";
import {
  GET_AUTOMATED_ROUTES,
  IAutomatedRoutingRequestType,
  IGetAutomatedRoutesActionType,
  automatedRoutesActions,
} from "./automatedRoutes.actions";
import { IAutomatedRoutesStoreType } from "./automatedRoutes.reducer";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { generateGenericQueryString } from "helpers/generateQueryString.helper";

function getAutomatedRoutes(
  parameters: IAutomatedRoutingRequestType,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/automatedRouting" + generateGenericQueryString(parameters),
  };
}

function* getAutomatedRoutesWorker(action: IGetAutomatedRoutesActionType) {
  yield put(loadingActions.startLoad(action.type));
  yield put(automatedRoutesActions.clearAutomatedRoutes());
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof getAutomatedRoutes> = yield call(
      getAutomatedRoutes,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IAutomatedRoutesStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(automatedRoutesActions.getAutomatedRoutesSuccess(response));
  } catch (error) {
    yield put(automatedRoutesActions.getAutomatedRoutesFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

export const automatedRoutesSagas = function* sagas() {
  yield takeLeading(GET_AUTOMATED_ROUTES, getAutomatedRoutesWorker);
};
