import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { State } from "./csatData.reducer";
import { CSATData } from ".";

export function useCSATData(): [
  State,
  {
    request: (
      ...requestValues: Parameters<typeof CSATData.actions.request>
    ) => void;
    clear: () => void;
  },
] {
  const dispatch = useDispatch();
  const csatData: State = useFetchSelector("csatData");

  const returnValue: ReturnType<typeof useCSATData> = useMemo(() => {
    const returnValue: ReturnType<typeof useCSATData> = [
      csatData,
      {
        request: (
          ...requestValues: Parameters<typeof CSATData.actions.request>
        ) => {
          dispatch(CSATData.actions.request(...requestValues));
        },
        clear: () => {
          dispatch(CSATData.actions.clear());
        },
      },
    ];

    return returnValue;
  }, [csatData, dispatch]);

  return returnValue;
}
