export const GET_PACKAGE_DELIVERY_RECEIPT = "GET_PACKAGE_DELIVERY_RECEIPT";
export const GET_PACKAGE_DELIVERY_RECEIPT_SUCCESS =
  "GET_PACKAGE_DELIVERY_RECEIPT_SUCCESS";
export const GET_PACKAGE_DELIVERY_RECEIPT_FAILURE =
  "GET_PACKAGE_DELIVERY_RECEIPT_FAILURE";
export const CLEAR_PACKAGE_DELIVERY_RECEIPT = "CLEAR_PACKAGE_DELIVERY_RECEIPT";

export interface IClearPackageDeliveryReceiptActionType {
  type: typeof CLEAR_PACKAGE_DELIVERY_RECEIPT;
}

export interface IGetPackageDeliveryReceiptPayloadType {
  fetchPackageId: string;
}

export interface IGetPackageDeliveryReceiptActionType {
  type: typeof GET_PACKAGE_DELIVERY_RECEIPT;
  payload: IGetPackageDeliveryReceiptPayloadType;
}

export interface IPackageDeliveryReceipt {
  fetchPackageId: number;
  deliveryReceiptMethod: number;
  image: string;
}

export interface IGetPackageDeliveryReceiptSuccessActionType {
  type: typeof GET_PACKAGE_DELIVERY_RECEIPT_SUCCESS;
  payload: IPackageDeliveryReceipt;
}
export interface IGetPackageDeliveryReceiptFailureActionType {
  type: typeof GET_PACKAGE_DELIVERY_RECEIPT_FAILURE;
}

function clearPackageDeliveryReceipt() {
  return { type: CLEAR_PACKAGE_DELIVERY_RECEIPT };
}

function getPackageDeliveryReceipt(
  payload: IGetPackageDeliveryReceiptPayloadType,
) {
  return { type: GET_PACKAGE_DELIVERY_RECEIPT, payload };
}

function getPackageDeliveryReceiptSuccess(response: IPackageDeliveryReceipt) {
  return { type: GET_PACKAGE_DELIVERY_RECEIPT_SUCCESS, payload: response };
}

function getPackageDeliveryReceiptFailure() {
  return { type: GET_PACKAGE_DELIVERY_RECEIPT_FAILURE };
}

export const packageDeliveryReceiptsActions = {
  clearPackageDeliveryReceipt,
  getPackageDeliveryReceipt,
  getPackageDeliveryReceiptSuccess,
  getPackageDeliveryReceiptFailure,
};
