import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { IBatchStoreType } from "slices/batch/batch.reducer";
import { generateQueryString } from "helpers/generateQueryString.helper";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { getNowInApiDateTimeString } from "helpers/date-time.helper";

function searchBatches(
  parameters: IPaginatedTableSearchRequestType,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/driver-batches/search" + generateQueryString(parameters),
  };
}

function getBatch(batchId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/driver-batches/" + batchId,
  };
}

function saveBatch(
  parameters: Partial<IBatchStoreType>,
): IAuthenticatedRequestDefinition {
  if (!!parameters.packages) {
    // If we don't have a driver batchId, set as active
    if (
      !parameters.hasOwnProperty("driverBatchId") ||
      parameters.driverBatchId === null
    ) {
      parameters.active = true;
    }
    // only include active packages
    parameters.packages = parameters.packages.filter(
      (fetchPackage) => !!fetchPackage.excludedFromBatch === false,
    );
    return {
      body: parameters,
      requestOptions: { method: "POST" },
      requestUrl: "/driver-batches",
    };
  } else {
    throw new Error("Incomplete batch");
  }
}

function getMyDriverBatches(): IAuthenticatedRequestDefinition {
  return {
    requestOptions: {
      headers: { "Content-Type": "application/json", "X-API-VERSION": "2" },
      method: "GET",
    },
    requestUrl:
      "/driver-batches/my?deliveryStartDate=" + getNowInApiDateTimeString(),
  };
}

export const driverBatchesServices = {
  getBatch,
  saveBatch,
  searchBatches,
  getMyDriverBatches,
};
