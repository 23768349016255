import React from "react";

import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import { Field, formValueSelector, InjectedFormProps } from "redux-form";

import { BEM } from "helpers/BEM.helper";
import { FetchFormConnect } from "helpers/FetchFormConnect";
import { FetchInput } from "views/common/forms/fields/FetchInput";
import { PasswordValidationMessages } from "views/components/forms/PasswordValidationMessages";
import { assertPasswordIsValid } from "helpers/inputValidators/text/password";
import { IRootStateType } from "types/IRootStateType";
import { FetchFormErrors } from "types/FetchFormErrors";

const classes = new BEM({
  block: { name: "ChangePasswordForm" },
  elements: [
    { name: "oldPassword", extras: [] },
    { name: "newPassword", extras: [] },
    { name: "confirmNewPassword", extras: [] },
    { name: "submitButton", extras: ["w-100"] },
  ],
});

export type ChangePasswordFormProps =
  InjectedFormProps<IChangePasswordFormValues> & IChangePasswordFormValues;

export interface IChangePasswordFormValues {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}
type ChangePasswordFormErrors = FetchFormErrors<IChangePasswordFormValues>;

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (
  props: ChangePasswordFormProps,
) => {
  const { invalid, dirty, newPassword } = props;
  return (
    <Form
      className={classes.getBlockClassNames()}
      onSubmit={props.handleSubmit}
    >
      <Field
        className={classes.getElementClassNames("oldPassword")}
        component={FetchInput}
        label="Old Password"
        name="oldPassword"
        type="password"
      />
      <Field
        className={classes.getElementClassNames("newPassword")}
        component={FetchInput}
        label="New Password"
        name="newPassword"
        type="password"
      />
      <Field
        className={classes.getElementClassNames("confirmNewPassword")}
        component={FetchInput}
        label="Confirm New Password"
        name="confirmNewPassword"
        type="password"
      />
      <PasswordValidationMessages password={newPassword} />
      <Button
        type="submit"
        color="info"
        disabled={invalid || !dirty}
        className={classes.getElementClassNames("submitButton")}
      >
        Submit
      </Button>
    </Form>
  );
};

const selector = formValueSelector("ChangePasswordForm");

export default FetchFormConnect({
  FormComponent: ChangePasswordForm,
  formOptions: {
    form: "ChangePasswordForm",
    touchOnChange: true,
    onSubmitFail: () => {
      toast.error("Please fix the error(s) below and try again");
    },
    validate: (values: IChangePasswordFormValues) => {
      const errors: ChangePasswordFormErrors = {};
      const requiredFields: (keyof IChangePasswordFormValues)[] = [
        "oldPassword",
        "newPassword",
        "confirmNewPassword",
      ];
      requiredFields.forEach((fieldName: keyof IChangePasswordFormValues) => {
        if (!values[fieldName]) {
          errors[fieldName] = "Required";
        }
      });
      if (!assertPasswordIsValid(values.newPassword)) {
        errors.newPassword = "Enter valid password";
      }
      if (values.newPassword !== values.confirmNewPassword) {
        errors.confirmNewPassword = "Passwords must match";
      }
      return errors;
    },
  },
  state: {
    mapStateToProps: (rootState: IRootStateType) => {
      return {
        initialValues: {},
        newPassword: selector(rootState, "newPassword") as string | undefined,
      };
    },
  },
});
