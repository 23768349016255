import { Reducer } from "redux";
import {
  actionTypes,
  ClearAction,
  FullfilledAction,
  RejectedAction,
  RequestAction,
  WfmResourcesTemplateWindowState,
} from "./types";

export const defaultState: WfmResourcesTemplateWindowState = {
  error: null,
  loading: false,
  response: null,
  request: null,
};

export const reducer: Reducer<
  WfmResourcesTemplateWindowState,
  RequestAction | FullfilledAction | RejectedAction | ClearAction
> = (state = defaultState, action): WfmResourcesTemplateWindowState => {
  switch (action.type) {
    case actionTypes.request:
      return {
        ...state,
        loading: true,
        request: action.payload,
        response: null,
        error: null,
      };
    case actionTypes.fullfilled:
      return {
        ...state,
        loading: false,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response: action.payload.response,
      };
    case actionTypes.rejected:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.clear:
      return defaultState;
    default:
      return state;
  }
};
