import { Reducer } from "redux";

import { defaultState } from "default-state";
import {
  GET_DRIVER_USER_AUDIT_HISTORY,
  GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS,
  IGetDriverUserAuditHistorySuccessActionType,
  IGetDriverUserAuditHistoryActionType,
} from "./driverUserAuditHistory.actions";

export interface FetchDriverUserAuditHistoryEntry {
  driverStatus: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  systemId: string;
  userId: number;
}

export type IDriverUserAuditHistoryStoreType =
  FetchDriverUserAuditHistoryEntry[];

export const driverUserAuditHistory: Reducer<
  IDriverUserAuditHistoryStoreType,
  DriverUserAuditHistoryActionTypes
> = (
  state = defaultState.driverUserAuditHistory,
  action: DriverUserAuditHistoryActionTypes,
): IDriverUserAuditHistoryStoreType => {
  switch (action.type) {
    case GET_DRIVER_USER_AUDIT_HISTORY:
      return defaultState.driverUserAuditHistory;
    case GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
export type DriverUserAuditHistoryActionTypes =
  | IGetDriverUserAuditHistorySuccessActionType
  | IGetDriverUserAuditHistoryActionType;
