import React from "react";

import { RouteComponentProps } from "react-router";
import { CardTitle, Col, Row } from "reactstrap";
import { Dispatch } from "redux";

import { uiActions } from "slices/ui/ui.actions";
import { userActions } from "slices/user/user.actions";
import { BEM } from "helpers/BEM.helper";
import { FetchConnect } from "helpers/FetchConnect";
import { FetchIcon } from "views/common/misc/FetchIcon";
import FetchModal from "views/common/modals/FetchModal";
import ConfirmResetPasswordForm, {
  IConfirmResetPasswordFormValues,
} from "views/components/forms/ConfirmResetPasswordForm";
import { IRootStateType } from "types/IRootStateType";

export interface ConfirmResetPasswordModalProps
  extends IRootStateType,
    RouteComponentProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  dispatch: Dispatch;
}

export const ConfirmResetPasswordModal: React.FC<
  ConfirmResetPasswordModalProps
> = (props: ConfirmResetPasswordModalProps) => {
  const { dispatch } = props;
  const classes = new BEM({
    block: { name: "ConfirmResetPasswordModal" },
    elements: [
      {
        extras: ["d-flex", "justify-content-center", "align-items-center"],
        name: "close",
      },
    ],
  });

  return (
    <FetchModal
      className={classes.getBlockClassNames()}
      id="confirm_reset_password"
    >
      <Row>
        <Col>
          <CardTitle tag="h4">Confirm Password Reset</CardTitle>
        </Col>
        <Col
          xs="2"
          onClick={() => {
            props.dispatch(uiActions.clearModal());
          }}
          className={classes.getElementClassNames("close")}
        >
          <FetchIcon iconType="windowClose" />
        </Col>
      </Row>
      <ConfirmResetPasswordForm
        onSubmit={(values: IConfirmResetPasswordFormValues) => {
          if (values.temporaryPassword) {
            props.dispatch(uiActions.clearModal());
            dispatch(
              userActions.postResetPassword({
                temporaryPassword: values.temporaryPassword,
              }),
            );
          }
        }}
      />
    </FetchModal>
  );
};

export default FetchConnect({
  ConnectedComponent: ConfirmResetPasswordModal,
  state: { stores: [] },
});
