import React, { FC, useEffect, useState } from "react";

import classnames from "classnames";
import { Container, Navbar } from "reactstrap";

import { userHasPermission } from "helpers/permissions.helper";
import { MyWarehouseContainer } from "views/components/MyWarehouse";
import QuickSearchForm, {
  IQuickSearchFormValues,
} from "views/components/navbars/QuickSearchForm";
import { quickSearchActions } from "slices/quickSearch";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";

// type definitions
interface AdminNavbarProps {
  handleMiniClick?: () => void;
}
const sidebarOpen = false;

export const AdminNavbar: FC<AdminNavbarProps> = (props: AdminNavbarProps) => {
  const currentUser = useFetchSelector("currentUser");
  const dispatch = useDispatch();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const shouldToggleClassName =
      window.outerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1;
    if (shouldToggleClassName) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };
  return (
    <>
      <Navbar
        className={classnames("navbar-absolute fixed-top", "bg-white")}
        expand="lg"
      >
        <Container fluid={true}>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <button
                className="navbar-minimize-toggler"
                type="button"
                onClick={props.handleMiniClick}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <div
              className={classnames("navbar-toggle", {
                toggled: sidebarOpen,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            // data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <MyWarehouseContainer />
          {userHasPermission(currentUser, {
            warehouseUser: true,
            adminUser: true,
          }) && (
            <QuickSearchForm
              isOpen={collapseOpen}
              onSubmit={({
                resourceType,
                searchValue,
              }: Partial<IQuickSearchFormValues>) => {
                if (!!resourceType && !!searchValue) {
                  dispatch(
                    quickSearchActions.performSearch({
                      resourceType,
                      searchValue,
                    }),
                  );
                }
              }}
            />
          )}
        </Container>
      </Navbar>
    </>
  );
};
