import { takeLatest, call, put } from "redux-saga/effects";

import { loadingActions } from "slices/loading/loading.actions";
import {
  keystrokeDelay,
  networkActions,
} from "slices/authentication/network.actions";
import {
  buildingsActions,
  ISearchBuildingsActionType,
  SEARCH_BUILDINGS,
} from "./buildings.actions";
import { buildingsService } from "services/hermes/buildings";
import { IBuildingsStoreType } from "./buildings.reducer";

function* searchBuildingsWorker(action: ISearchBuildingsActionType) {
  yield put(loadingActions.startLoad(action.type));
  yield put(buildingsActions.clearBuildings());
  try {
    yield keystrokeDelay();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof buildingsService.searchBuildings> =
      yield call(buildingsService.searchBuildings, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IBuildingsStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(buildingsActions.searchBuildingsSuccess(response));
  } catch (error) {
    yield put(buildingsActions.searchBuildingsFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

export const buildingsSagas = function* () {
  yield takeLatest(SEARCH_BUILDINGS, searchBuildingsWorker);
};
