import {
  LOGIN_SUCCESS,
  LOGOUT,
} from "slices/authentication/authentication.actions";
import { setAnalyticsUser } from "services/analytics";
import { WarehouseUser } from "slices/warehouseUser/warehouseUser.reducer";
import {
  AutoScheduleOptions,
  IResidentialUserStoreType,
  IUserSettings,
  IUserStoreType,
  UserUpdateType,
} from "./currentUser.reducer";
import { localStorageSetItemSafe } from "helpers/localStorage/localStorageSetItemSafe";

export const SAVE_MY_WAREHOUSE_USER = "SAVE_MY_WAREHOUSE_USER";
export const SAVE_MY_WAREHOUSE_USER_SUCCESS = "SAVE_MY_WAREHOUSE_USER_SUCCESS";

export const PATCH_MY_SETTINGS = "PATCH_MY_SETTINGS";
export const PATCH_MY_SETTINGS_SUCCESS = "PATCH_MY_SETTINGS_SUCCESS";

export const LOAD_MY_USER = "LOAD_MY_USER";
export const LOAD_MY_USER_SUCCESS = "LOAD_MY_USER_SUCCESS";

export const GET_AUTO_SCHEDULE_OPTIONS = "GET_AUTO_SCHEDULE_OPTIONS";
export const GET_AUTO_SCHEDULE_OPTIONS_SUCCESS =
  "GET_AUTO_SCHEDULE_OPTIONS_SUCCESS";

export const SAVE_MY_RESIDENTIAL_USER = "SAVE_MY_RESIDENTIAL_USER";
export const SAVE_MY_RESIDENTIAL_USER_SUCCESS =
  "SAVE_MY_RESIDENTIAL_USER_SUCCESS";

export const PATCH_MY_RESIDENTIAL_USER = "PATCH_MY_RESIDENTIAL_USER";
export const PATCH_MY_RESIDENTIAL_USER_SUCCESS =
  "PATCH_MY_RESIDENTIAL_USER_SUCCESS";

export const SAVE_MY_USER = "SAVE_MY_USER";
export const SAVE_MY_USER_SUCCESS = "SAVE_MY_USER_SUCCESS";

export interface ISaveMyResidentialUserActionType {
  type: typeof SAVE_MY_RESIDENTIAL_USER;
  payload: Partial<IResidentialUserStoreType>;
  options?: Partial<{
    shouldTriggerDriverNotification: boolean;
    onSuccess: () => void;
  }>;
  onSuccessConfig?: {
    resetForm?: boolean;
  };
}
export interface ISaveMyResidentialUserSuccessActionType {
  type: typeof SAVE_MY_RESIDENTIAL_USER_SUCCESS;
  payload: IResidentialUserStoreType;
  onSuccessConfig?: {
    resetForm?: boolean;
  };
}

export interface IPatchMyResidentialUserActionType {
  type: typeof PATCH_MY_RESIDENTIAL_USER;
  payload: Partial<IResidentialUserStoreType>;
  options?: { shouldTriggerDriverNotification?: boolean };
}
export interface IPatchMyResidentialUserSuccessActionType {
  type: typeof PATCH_MY_RESIDENTIAL_USER_SUCCESS;
  payload: IResidentialUserStoreType;
}

export interface IPatchMySettingsPayloadType {
  residentAutoScheduleSaturdays?: number;
  residentAutoScheduleSundays?: number;
  residentAutoScheduleWeekdays?: number;
  residentGlobalNotificationsEmailEnabled?: boolean;
  residentGlobalNotificationsSMSEnabled?: boolean;
  residentGlobalNotificationsPushEnabled?: boolean;
  retrieverGlobalNotificationsEmailEnabled?: boolean;
  retrieverGlobalNotificationsSMSEnabled?: boolean;
  retrieverGlobalNotificationsPushEnabled?: boolean;
}

export interface IPatchMySettingsActionType {
  type: typeof PATCH_MY_SETTINGS;
  payload: IPatchMySettingsPayloadType;
}
export interface IPatchMySettingsSuccessActionType {
  type: typeof PATCH_MY_SETTINGS_SUCCESS;
  payload: IUserSettings;
}

export interface ISaveMyWarehouseUserActionType {
  type: typeof SAVE_MY_WAREHOUSE_USER;
  payload: WarehouseUser;
}
export interface ISaveMyWarehouseUserSuccessActionType {
  type: typeof SAVE_MY_WAREHOUSE_USER_SUCCESS;
  payload: WarehouseUser;
}

export interface ILoginSuccessType {
  type: typeof LOGIN_SUCCESS;
  payload: { user: IUserStoreType };
}
export interface ILoadMyUserSuccessType {
  type: typeof LOAD_MY_USER_SUCCESS;
  payload: IUserStoreType;
}
export interface ILogoutType {
  type: typeof LOGOUT;
}
export interface ISaveResidentialUserSuccessActionType {
  type: typeof SAVE_MY_RESIDENTIAL_USER_SUCCESS;
  payload: IResidentialUserStoreType;
}
export interface ISaveWarehouseUserSuccessActionType {
  type: typeof SAVE_MY_WAREHOUSE_USER_SUCCESS;
  payload: WarehouseUser;
}

export interface IGetAutoScheduleOptionsActionType {
  type: typeof GET_AUTO_SCHEDULE_OPTIONS;
}

export interface IGetAutoScheduleOptionsSuccessActionType {
  type: typeof GET_AUTO_SCHEDULE_OPTIONS_SUCCESS;
  payload: AutoScheduleOptions;
}

export interface ISaveMyUserActionType {
  type: typeof SAVE_MY_USER;
  payload: UserUpdateType;
  onSuccessConfig?: {
    resetForm?: boolean;
  };
}
export interface ISaveMyUserSuccessActionType {
  type: typeof SAVE_MY_USER_SUCCESS;
  payload: IUserStoreType;
  onSuccessConfig?: {
    resetForm?: boolean;
  };
}
export const GET_MY_USER = "GET_MY_USER";

function getMyUser() {
  return { type: GET_MY_USER };
}

function loadMyUser() {
  return { type: LOAD_MY_USER };
}
function loadMyUserSuccess(
  loginResponse: IUserStoreType,
): ILoadMyUserSuccessType {
  return { type: LOAD_MY_USER_SUCCESS, payload: loginResponse };
}

function saveMyWarehouseUser(
  formData: WarehouseUser,
): ISaveMyWarehouseUserActionType {
  return { type: SAVE_MY_WAREHOUSE_USER, payload: formData };
}
function saveMyWarehouseUserSuccess(
  response: WarehouseUser,
): ISaveMyWarehouseUserSuccessActionType {
  return { type: SAVE_MY_WAREHOUSE_USER_SUCCESS, payload: response };
}

function patchMySettings(
  payload: IPatchMySettingsPayloadType,
): IPatchMySettingsActionType {
  return { type: PATCH_MY_SETTINGS, payload };
}

function patchMySettingsSuccess(
  response: IUserSettings,
): IPatchMySettingsSuccessActionType {
  return { type: PATCH_MY_SETTINGS_SUCCESS, payload: response };
}

function getAutoScheduleOptions(): IGetAutoScheduleOptionsActionType {
  return { type: GET_AUTO_SCHEDULE_OPTIONS };
}
function getAutoScheduleOptionsSuccess(
  response: AutoScheduleOptions,
): IGetAutoScheduleOptionsSuccessActionType {
  return { type: GET_AUTO_SCHEDULE_OPTIONS_SUCCESS, payload: response };
}

function saveMyUser(
  formData: UserUpdateType,
  onSuccessConfig?: ISaveMyUserActionType["onSuccessConfig"],
): ISaveMyUserActionType {
  return { type: SAVE_MY_USER, payload: formData, onSuccessConfig };
}
function saveMyUserSuccess(
  response: IUserStoreType,
  onSuccessConfig?: ISaveMyUserSuccessActionType["onSuccessConfig"],
): ISaveMyUserSuccessActionType {
  return { type: SAVE_MY_USER_SUCCESS, payload: response, onSuccessConfig };
}

function saveMyResidentialUser(
  formData: Partial<IResidentialUserStoreType>,
  options?: ISaveMyResidentialUserActionType["options"],
  onSuccessConfig?: ISaveMyResidentialUserActionType["onSuccessConfig"],
): ISaveMyResidentialUserActionType {
  return {
    type: SAVE_MY_RESIDENTIAL_USER,
    payload: formData,
    options,
    onSuccessConfig,
  };
}
function saveMyResidentialUserSuccess(
  response: IResidentialUserStoreType,
  onSuccessConfig?: ISaveMyResidentialUserSuccessActionType["onSuccessConfig"],
): ISaveMyResidentialUserSuccessActionType {
  return {
    type: SAVE_MY_RESIDENTIAL_USER_SUCCESS,
    payload: response,
    onSuccessConfig,
  };
}

function patchMyResidentialUser(
  formData: Partial<IResidentialUserStoreType>,
  options?: { shouldTriggerDriverNotification?: boolean },
): IPatchMyResidentialUserActionType {
  return { type: PATCH_MY_RESIDENTIAL_USER, payload: formData, options };
}
function patchMyResidentialUserSuccess(
  response: IResidentialUserStoreType,
): IPatchMyResidentialUserSuccessActionType {
  return { type: PATCH_MY_RESIDENTIAL_USER_SUCCESS, payload: response };
}

// side effect services
function setCurrentUser(userResponse: IUserStoreType): void {
  localStorageSetItemSafe("user", userResponse);
  setAnalyticsUser(userResponse);
}

export const currentUserActions = {
  setCurrentUser,
  saveMyWarehouseUser,
  saveMyWarehouseUserSuccess,
  patchMySettings,
  patchMySettingsSuccess,
  loadMyUser,
  loadMyUserSuccess,
  getAutoScheduleOptions,
  getAutoScheduleOptionsSuccess,
  saveMyUser,
  saveMyUserSuccess,
  saveMyResidentialUser,
  saveMyResidentialUserSuccess,
  patchMyResidentialUser,
  patchMyResidentialUserSuccess,
  getMyUser,
};
