import { toast } from "react-toastify";
import { call, put, take } from "redux-saga/effects";
import { captureSentryException } from "services/sentry";
import {
  authenticationActions,
  REFRESH_TOKEN_SUCCESS,
} from "slices/authentication/authentication.actions";
import { searchKeystrokeDelay } from "constants/general";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { networkService } from "services/hermes/config";

export const networkActions = { makeAuthenticatedRequest };

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
export const keystrokeDelay = (): Promise<unknown> =>
  delay(searchKeystrokeDelay);

function* makeAuthenticatedRequest(
  requestOptions: IAuthenticatedRequestDefinition,
  rethrowError?: boolean,
) {
  if (networkService.tokenIsExpired()) {
    // Refresh token
    yield put(authenticationActions.refreshToken());
    // Wait for successful refresh
    yield take(REFRESH_TOKEN_SUCCESS);
  }
  try {
    // uncomment to introduce random delay between 0.2 to 2s
    // yield delay(Math.floor(Math.random() * 1800) + 200);
    // Make request
    const response: unknown = yield call(
      networkService.makeAuthenticatedRequest,
      requestOptions,
    );
    return response;
  } catch (_e) {
    const e = _e as FetchError;
    // If we have a likely token error
    if (e.hasOwnProperty("name") && e.name === "ErrorAuthenticating") {
      // Refresh token
      yield put(authenticationActions.refreshToken());
      // Wait for successful refresh
      yield take(REFRESH_TOKEN_SUCCESS);
      // Retry original request
      const response: unknown = yield call(
        networkService.makeAuthenticatedRequest,
        requestOptions,
      );
      return response;
    } else {
      // Otherwise, display error
      let errorString = "Network error";
      if (e.message && typeof e.fetchErrorCode === "number") {
        errorString += `: ${e.message} [code: ${e.fetchErrorCode}]`;
      } else {
        captureSentryException(_e);
      }
      toast.error(errorString);
      if (rethrowError) {
        throw e;
      }
    }
  }
}

export interface FetchError extends Error {
  fetchErrorCode: number;
}
