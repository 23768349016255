import { takeLeading, ForkEffect, call, put } from "redux-saga/effects";
import { packagesService } from "services/hermes/packages";

import { networkActions } from "slices/authentication/network.actions";
import {
  GET_PACKAGE_RELOCATION_AUDIT_TRAIL,
  IGetPackageRelocationAuditTrailType,
  packageWarehouseLocationHistoryActions,
} from "./packageWarehouseLocationHistory.actions";
import { PackageWarehouseLocationHistory } from "./packageWarehouseLocationHistory.reducer";

function* getPackageRelocationAuditTrailWorker(
  action: IGetPackageRelocationAuditTrailType,
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<
    typeof packagesService.getPackageRelocationAuditTrail
  > = yield call(
    packagesService.getPackageRelocationAuditTrail,
    action.payload.fetchPackageId,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: PackageWarehouseLocationHistory = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(
    packageWarehouseLocationHistoryActions.getPackageRelocationAuditTrailSuccess(
      response,
    ),
  );
}

export const packageWarehouseLocationHistorySagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(
    GET_PACKAGE_RELOCATION_AUDIT_TRAIL,
    getPackageRelocationAuditTrailWorker,
  );
};
