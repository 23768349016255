import moment from "moment";
import { DeliveryWindow } from "slices/availableDeliveryWindows/availableDeliveryWindows.actions";

export function addDurationToDeliveryWindows(
  allDeliveryWindows: DeliveryWindow[] = [],
): DeliveryWindow[] {
  return allDeliveryWindows.reduce(
    (selectableDeliveryWindows: DeliveryWindow[], deliveryWindow) => {
      const { startTime, endTime } = deliveryWindow;

      const duration = moment(endTime, "HH:mm").diff(
        moment(startTime, "HH:mm"),
        "hours",
      );
      selectableDeliveryWindows.push({
        ...deliveryWindow,
        duration,
      });

      return selectableDeliveryWindows;
    },
    [],
  );
}
