import { takeLeading, call, put } from "redux-saga/effects";

import { history } from "helpers/history";
import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  GetMySubscriptionsAction,
  GetMySubscriptionsResponse,
  GetMySubscriptionsSuccessAction,
  GET_MY_SUBSCRIPTIONS,
  GET_MY_SUBSCRIPTIONS_SUCCESS,
  subscriptionsActions,
} from "./subscriptions.actions";
import { paymentsService } from "services/hermes/payments";

function* getMySubscriptionsWorker(action: GetMySubscriptionsAction) {
  yield put(subscriptionsActions.clearSubscriptions());
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof paymentsService.getMySubscriptions
    > = yield call(paymentsService.getMySubscriptions);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: GetMySubscriptionsResponse = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(subscriptionsActions.getMySubscriptionsSuccess(response));
  } catch (error) {
  } finally {
    yield put(loadingActions.endLoad(action.type));
  }
}

function* getMySubscriptionsSuccessWorker(
  action: GetMySubscriptionsSuccessAction,
) {
  if (action.payload.subscriptions.length === 0) {
    history.replace("storage/add");
  }
}

export const subscriptionsSagas = function* () {
  yield takeLeading(GET_MY_SUBSCRIPTIONS, getMySubscriptionsWorker);
  yield takeLeading(
    GET_MY_SUBSCRIPTIONS_SUCCESS,
    getMySubscriptionsSuccessWorker,
  );
};
