import { actions } from "./actions";
import { defaultState, reducer } from "./reducer";
import { saga } from "./saga";
import { useMyPackageLabelsByFetchPackageId } from "./hook";

export const myPackageLabelsByFetchPackageId = {
  reducer,
  actions,
  saga,
  hook: useMyPackageLabelsByFetchPackageId,
  defaultState,
};

/*
USAGE:
  const [
    { error, loading, response, request: requestOptions },
    { request },
  ] = useMyPackageLabelsByFetchPackageId();
 */
