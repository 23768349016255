import React from "react";

import { FetchIcon } from "views/common/misc/FetchIcon";
import { BEM } from "helpers/BEM.helper";
import { assertPasswordCondition } from "helpers/inputValidators/text/password";

const classes = new BEM({
  block: { name: "PasswordValidationMessages", extras: ["text-left"] },
  elements: [
    {
      name: "message",
      extras: ["mr-2"],
    },
    {
      name: "validationIcon",
      extras: ["mr-2", "text-warning"],
    },
  ],
});

interface PasswordValidationMessagesProps {
  password: string | undefined;
}

export const PasswordValidationMessages: React.FunctionComponent<
  PasswordValidationMessagesProps
> = ({ password }: PasswordValidationMessagesProps) => {
  const {
    meetsMinimumLength,
    doesNotContainWhiteSpace,
    containsALowerCaseLetter,
    containsAnUpperCaseLetter,
    containsANumber,
    containsASpecialCharacter,
  } = assertPasswordCondition(password);

  return (
    <div className={classes.getBlockClassNames()}>
      {[
        { message: "Minimum 8 characters", shouldShow: !meetsMinimumLength },
        { message: "Requires Numbers", shouldShow: !containsANumber },
        {
          message: "Uppercase letters",
          shouldShow: !containsAnUpperCaseLetter,
        },
        { message: "Lowercase letters", shouldShow: !containsALowerCaseLetter },
        {
          message: "Special character",
          shouldShow: !containsASpecialCharacter,
        },
        { message: "No spaces", shouldShow: !doesNotContainWhiteSpace },
      ].map(
        (
          {
            message,
            shouldShow,
          }: {
            message: string;
            shouldShow: boolean;
          },
          index,
        ): JSX.Element | boolean =>
          shouldShow && (
            <div
              className={classes.getElementClassNames("message")}
              key={`validationMessage-${index}`}
            >
              <FetchIcon
                className={classes.getElementClassNames("validationIcon")}
                iconType="notice"
              />
              {message}
            </div>
          ),
      )}
    </div>
  );
};
