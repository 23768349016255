import React from "react";
import pageNotFound from "assets/img/404ErrorPage-OpenDoor.png";
import { Col, Row } from "reactstrap";

export const FourZeroFourPage: React.FC = () => {
  return (
    <div className="content" style={{ backgroundColor: "white" }}>
      <Row>
        <Col
          xl={{ size: 8, offset: 2 }}
          lg={{ size: 10, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          sm={{ size: 10, offset: 1 }}
        >
          <img
            src={pageNotFound}
            alt="page not found"
            data-cy={"404_Error_Img"}
          />
        </Col>
      </Row>
    </div>
  );
};
