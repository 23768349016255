import React, { ReactChild, ReactNode, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { IconTypes } from "constants/icons";
import { FetchIcon, FetchIconProps } from "views/common/misc/FetchIcon";
import { CardLoadingComponent } from "views/components/cards/helpers/CardLoadingComponent";
import { generateBemClassNames } from "helpers/generateBemClassNames.helper";

const {
  block,
  elements: { openButton, subTitle: subTitleClasses },
} = generateBemClassNames({
  block: { name: "FetchCard" },
  elements: [
    {
      extras: ["text-right"],
      name: "openButton",
    },

    { name: "subTitle", extras: ["text-muted", "pb-3"] },
  ],
  prefix: "fetch",
});

export type CardAction = {
  Component?: React.FC;
  clickHandler?: () => void;
  icon?: IconTypes | FetchIconProps;
  id: string;
  text?: string;
  tooltip?: string;
  shouldShow?: boolean;
  color?: string;
  hoverColor?: string;
};

export interface FetchCardProps {
  actions?: CardAction[];
  actionsColumnClassName?: string;
  children?: ReactChild | ReactChild[] | ReactNode;
  className?: string;
  collapsible?: boolean;
  defaultOpen?: boolean;
  titleAutoWidth?: boolean;
  loadingState?: boolean;
  preTitle?: string;
  preTitleSubtext?: string;
  tableToggleOnClick?: () => void;
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  ["data-cy"]?: string;
}

const columnAutoWidthProps = {
  xs: "auto",
  sm: "auto",
  md: "auto",
  lg: "auto",
  xl: "auto",
};

export const FetchCard: React.FC<FetchCardProps> = (props: FetchCardProps) => {
  const {
    actionsColumnClassName = "",
    actions,
    children,
    className,
    collapsible,
    defaultOpen,
    loadingState,
    preTitle,
    preTitleSubtext,
    tableToggleOnClick,
    title,
    titleAutoWidth = true,
    subTitle,
  } = props;

  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const isLoading = !!loadingState;
  const shouldShowPreTitle = !!preTitle;
  const titleExists = !!title;
  const actionsExist: boolean = !!actions && actions.length > 0;
  const tableOptionsExist = !!tableToggleOnClick;
  const isCollapsible: boolean = collapsible === true ? true : false;
  const shouldShowActions: boolean =
    actionsExist || tableOptionsExist || isCollapsible;

  const cardBody = (
    <>
      {children}
      <CardLoadingComponent display={isLoading} />
    </>
  );

  return (
    <div data-cy={props["data-cy"] || block()}>
      {shouldShowPreTitle && (
        <div className="card-pre-title">
          <h1>{preTitle}</h1>
          {preTitleSubtext && <p className={"subtext"}>{preTitleSubtext}</p>}
        </div>
      )}
      <Card
        className={block({ extras: !!className ? [className] : undefined })}
      >
        <CardHeader>
          <Row>
            {titleExists && (
              <Col
                className="d-flex justify-content-left align-items-center"
                {...(titleAutoWidth && columnAutoWidthProps)}
              >
                <CardTitle
                  tag="h4"
                  onClick={(event) => {
                    event.preventDefault();
                    setIsOpen(!isOpen);
                  }}
                  className="font-weight-bold"
                >
                  {title}
                </CardTitle>
              </Col>
            )}
            {shouldShowActions && (
              <Col
                className={`d-flex align-items-center justify-content-end ${actionsColumnClassName}`}
              >
                {actionsExist && (
                  <span className="d-flex align-items-center flex-row">
                    {actions &&
                      actions.map(
                        ({
                          icon,
                          text,
                          clickHandler,
                          id,
                          tooltip,
                          shouldShow,
                          color,
                          hoverColor,
                          Component,
                        }) => {
                          if (!Component) {
                            const isControlled: boolean =
                              typeof shouldShow === "boolean";

                            const Action = (
                              <span
                                id={id}
                                onClick={clickHandler}
                                key={id}
                                className={`fetch-FetchCard__${id} fetch-FetchCard__action ml-3`}
                                style={{ cursor: "pointer" }}
                              >
                                {icon && (
                                  <FetchIcon
                                    iconType={
                                      typeof icon === "string"
                                        ? icon
                                        : icon.iconType
                                    }
                                    size={
                                      typeof icon === "object"
                                        ? icon.size
                                        : undefined
                                    }
                                    options={{
                                      hoverColor,
                                      message: text,
                                    }}
                                    color={color}
                                  />
                                )}
                                {tooltip && (
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={id}
                                  >
                                    {tooltip}
                                  </UncontrolledTooltip>
                                )}
                              </span>
                            );

                            return isControlled
                              ? shouldShow
                                ? Action
                                : null
                              : Action;
                          } else {
                            return <Component key={id} />;
                          }
                        },
                      )}
                  </span>
                )}
                {isCollapsible && (
                  <span
                    className={openButton()}
                    onClick={(event) => {
                      event.preventDefault();
                      setIsOpen(!isOpen);
                    }}
                  >
                    <FetchIcon
                      iconType={isOpen ? "collapse" : "expand"}
                      aria-expanded={isOpen}
                      size="2x"
                    />
                  </span>
                )}
              </Col>
            )}
          </Row>
          {!!subTitle && (
            <Row>
              <Col>
                <div className={subTitleClasses()}>{subTitle}</div>
              </Col>
            </Row>
          )}
        </CardHeader>
        {collapsible ? (
          <Collapse role="tabpanel" isOpen={isOpen}>
            {isOpen && <CardBody>{cardBody}</CardBody>}
          </Collapse>
        ) : (
          <CardBody>{cardBody}</CardBody>
        )}
      </Card>
    </div>
  );
};
