import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { generateGenericQueryString } from "helpers/generateQueryString.helper";
import { ActionType } from "./types";
import { asyncMakeAuthenticatedRequest } from "services/hermes/config";

export function service(
  payload: ActionType["payload"],
): IAuthenticatedRequestDefinition {
  return {
    requestUrl:
      `/wfm/resources/template-windows` +
      generateGenericQueryString(payload.query),
    requestOptions: {
      method: "GET",
    },
  };
}

export const fetchWindowTemplates = (preferredWarehouseId: number) => {
  return asyncMakeAuthenticatedRequest({
    authenticatedRequest: service({
      query: {
        warehouseId: preferredWarehouseId,
      },
    }),
  });
};
