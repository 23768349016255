import { generateQueryString } from "helpers/generateQueryString.helper";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";

function getFetchPackagesLabelsFullTextSearch(
  paginationQuery: IPaginatedTableSearchRequestType,
): IAuthenticatedRequestDefinition {
  const queryString = generateQueryString(paginationQuery);
  return {
    requestUrl: "/fetchPackages/labels/fullTextSearch" + queryString,
    requestOptions: {
      method: "GET",
    },
  };
}

export const fullTextSearchService = {
  getFetchPackagesLabelsFullTextSearch,
};
