import {
  takeLeading,
  takeLatest,
  ForkEffect,
  call,
  put,
} from "redux-saga/effects";
import { toast } from "react-toastify";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { history } from "helpers/history";
import {
  GET_WAREHOUSE,
  IGetWarehouseActionType,
  ISaveWarehouseActionType,
  SAVE_WAREHOUSE,
  SAVE_WAREHOUSE_SUCCESS,
  warehouseActions,
} from "./warehouse.actions";

import { IWarehouseStoreType } from "./warehouse.reducer";
import { warehousesService } from "services/hermes/warehouses";
import { removeWarning } from "slices/navigateAway/navigateAwayState";
import { WAREHOUSE_FORMNAME } from "constants/form-names";
import { initialize } from "redux-form";

function* saveWarehouseSuccessWorker(action: ISaveWarehouseActionType) {
  if (!!action.onSuccessConfig?.resetForm) {
    // reinitialize redux form
    yield put(initialize(WAREHOUSE_FORMNAME, action.payload, false));
    // remove navigate away warning
    yield put(removeWarning(WAREHOUSE_FORMNAME));
  }

  // redirect to warehouses table list after success
  history.push("/warehouses");
  // display success message
  if (action.payload && action.payload.name) {
    toast.success(action.payload.name + " updated successfully");
  }
}

function* saveWarehouseWorker(action: ISaveWarehouseActionType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<typeof warehousesService.saveWarehouse> =
    yield call(warehousesService.saveWarehouse, action.payload);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IWarehouseStoreType = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(
    warehouseActions.saveWarehouseSuccess(response, action.onSuccessConfig),
  );
}

function* getWarehouseWorker(action: IGetWarehouseActionType) {
  yield put(warehouseActions.clearWarehouse());
  yield put(loadingActions.startLoad(action.type));
  try {
    if (
      !!action.payload.warehouseId &&
      action.payload.warehouseId !== "undefined"
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const requestOptions: IAuthenticatedRequestDefinition = yield call(
        warehousesService.getWarehouse,
        action.payload.warehouseId,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response: IWarehouseStoreType = yield call(
        networkActions.makeAuthenticatedRequest,
        requestOptions,
      );
      yield put(warehouseActions.getWarehouseSuccess(response));
    }
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

export const warehouseSagas = function* sagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(GET_WAREHOUSE, getWarehouseWorker);
  yield takeLeading(SAVE_WAREHOUSE, saveWarehouseWorker);
  yield takeLeading(SAVE_WAREHOUSE_SUCCESS, saveWarehouseSuccessWorker);
};
