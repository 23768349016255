import { loadingActions } from "slices/loading/loading.actions";
import {
  getUserRolesFailure,
  getUserRolesFetch,
  getUserRolesSuccess,
} from "./userRolesState";
import { call, put, takeLatest } from "redux-saga/effects";
import { UserRoleDTO, userRolesService } from "services/hermes/userRoles";
import { networkActions } from "slices/authentication/network.actions";

function* workerGetUserRolesFetch() {
  try {
    yield put(loadingActions.startLoad(getUserRolesFetch.toString()));
    const requestOptions = (yield call(
      userRolesService.searchUserRoles,
      {},
    )) as ReturnType<typeof userRolesService.searchUserRoles>;
    const response = (yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    )) as UserRoleDTO[];

    yield put(getUserRolesSuccess(response));
  } catch (e) {
    let msg = "Error while loading User Roles";
    if (e instanceof Error || typeof e === "string") {
      msg = e.toString();
    }
    yield put(getUserRolesFailure(msg));
  } finally {
    yield put(loadingActions.endLoad(getUserRolesFetch.toString()));
  }
}

export function* userRolesSaga() {
  yield takeLatest(getUserRolesFetch, workerGetUserRolesFetch);
}
