import { call, put, ForkEffect, takeLatest } from "redux-saga/effects";
import { packagesService } from "services/hermes/packages";

import { networkActions } from "slices/authentication/network.actions";
import {
  IGetPackageByIdScheduleHistoryActionType,
  scheduleHistoryActions,
  GET_PACKAGE_BY_ID_SCHEDULE_HISTORY,
} from "./scheduleHistory.actions";
import { IScheduleHistoryStoreType } from "./scheduleHistory.reducer";

function* getPackageByIdScheduleHistoryWorker(
  action: IGetPackageByIdScheduleHistoryActionType,
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof packagesService.getPackageByIdScheduleHistory
    > = yield call(
      packagesService.getPackageByIdScheduleHistory,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IScheduleHistoryStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(
      scheduleHistoryActions.getPackageByIdScheduleHistorySuccess(response),
    );
  } catch (error) {}
}

export const scheduleHistorySagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    GET_PACKAGE_BY_ID_SCHEDULE_HISTORY,
    getPackageByIdScheduleHistoryWorker,
  );
};
