// Font Awesome Duotone icons
import {
  faGearComplexCode,
  faAt,
  faBan,
  faBell,
  faBookmark,
  faBolt,
  faBoneBreak,
  faBoxFragile,
  faBrainCircuit,
  faBriefcase,
  faBuilding,
  faCalendarPlus,
  faCamera,
  faCheck,
  faCheckCircle,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleEllipsis,
  faPlusCircle,
  faCouch,
  faCube,
  faCubes,
  faClock,
  faCocktail,
  faDoorOpen,
  faDoNotEnter,
  faDownload,
  faEdit,
  faEllipsisH,
  faExclamationCircle,
  faEnvelope,
  faGlass,
  faHandHoldingBox,
  faHistory,
  faHouse,
  faHeadset,
  faCommentDots,
  faArrowLeft,
  faInventory,
  faKey,
  faLink,
  faMapMarked,
  faMinusSquare,
  faPeopleCarry,
  faPlus,
  faPlusSquare,
  faPrint,
  faRedo,
  faRedoAlt,
  faRepeat,
  faRepeat1,
  faArrowRight,
  faSearch,
  faShareSquare,
  faShippingTimed,
  faShippingFast,
  faSlidersVSquare,
  faSun,
  faSyncAlt,
  faTable,
  faTachometerAlt,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTruck,
  faTruckLoading,
  faUser,
  faUserEdit,
  faUserFriends,
  faUserTimes,
  faUserPlus,
  faUtensils,
  faWarehouse,
  faWindowClose,
  faUserCircle,
  faPhoneSquare,
  faEnvelopeSquare,
  faCopy,
  faQuestionCircle,
  faInfoCircle,
  faLock,
  faLockOpen,
  faCaretDown,
  faCaretUp,
  faMinus,
  faChartBar,
  faEye,
  faEyeSlash,
  faBone,
  faShoppingCart,
  faCartPlus,
  faTag,
  faBox,
  faImage,
  faHourglassHalf,
  faCogs,
  faSnowflake,
  faCloudShowersHeavy,
  faStore,
  faFlag,
  faFile,
  faFileAlt,
  faXmark,
  faShelves,
  faUpload,
  faCalendarClock,
  faUserClock,
  faMoneyCheckDollarPen,
  faMoneyBillWave,
  faCalendarDays,
  faPencilAlt,
} from "@fortawesome/pro-duotone-svg-icons";

// Font Awesome Regular (outline only) icons
import {
  faBell as faBellRegular,
  faTruck as faTruckRegular,
  faUser as faUserRegular,
} from "@fortawesome/pro-regular-svg-icons"; // Import regular bell icon

// Font Awesome Solid monotone icons
import {
  faPhone as faPhoneSolid,
  faEnvelope as faEnvelopeSolid,
  faCommentDots as faCommentDotsSolid,
  faPen,
} from "@fortawesome/pro-solid-svg-icons";

// custom SVG icons
import {
  customShieldLock,
  customDeliveryStatusCalendar,
  customInvertedColorsShieldLock,
  customStackedFilesIcon,
  customShareIcon,
  customLightningBolt,
  customShieldLockWithoutOuterCircle,
  customCalendarIcon,
  customBoxOutline,
  customFacility,
} from "./customIcons";

export const icons = {
  faCalendarDays,
  faMoneyBillWave,
  faMoneyCheckDollarPen,
  faUserClock,
  faCalendarClock,
  ban: faBan,
  batches: faCubes,
  bell: faBell,
  bellRegular: faBellRegular,
  boneBreak: faBoneBreak,
  bone: faBone,
  bolt: faBolt,
  bookmark: faBookmark,
  boxFragile: faBoxFragile,
  brainCircuit: faBrainCircuit,
  briefcase: faBriefcase,
  building: faBuilding,
  camera: faCamera,
  check: faCheck,
  checkCircle: faCheckCircle,
  chevronDoubleLeft: faChevronDoubleLeft,
  chevronDoubleRight: faChevronDoubleRight,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronUp: faChevronUp,
  circleEllipsis: faCircleEllipsis,
  collapse: faMinusSquare,
  copy: faCopy,
  couch: faCouch,
  clock: faClock,
  cocktail: faCocktail,
  cube: faCube,
  cubes: faCubes,
  xmark: faXmark,
  dashboard: faTachometerAlt,
  delete: faTrash,
  doorOpen: faDoorOpen,
  doNotEnter: faDoNotEnter,
  download: faDownload,
  edit: faEdit,
  ellipsisH: faEllipsisH,
  email: faAt,
  envelope: faEnvelope,
  envelopeSolid: faEnvelopeSolid,
  envelopeSquare: faEnvelopeSquare,
  expand: faPlusSquare,
  glass: faGlass,
  handHoldingBox: faHandHoldingBox,
  history: faHistory,
  headset: faHeadset,
  infoCircle: faInfoCircle,
  key: faKey,
  link: faLink,
  leftArrow: faArrowLeft,
  loadingTruck: faTruckLoading,
  lock: faLock,
  lockOpen: faLockOpen,
  mapMarked: faMapMarked,
  notice: faExclamationCircle,
  package: faCube,
  peopleCarry: faPeopleCarry,
  plus: faPlus,
  minus: faMinus,
  plusCircle: faPlusCircle,
  print: faPrint,
  phoneSolid: faPhoneSolid,
  phoneSquare: faPhoneSquare,
  profileBlank: faUser,
  profileRegular: faUserRegular,
  question: faQuestionCircle,
  redo: faRedo,
  redoAlt: faRedoAlt,
  resident: faHouse,
  repeat: faRepeat,
  repeat1: faRepeat1,
  rightArrow: faArrowRight,
  save: faCheck,
  schedule: faCalendarPlus,
  search: faSearch,
  share: faShareSquare,
  shelves: faShelves,
  shippingTimed: faShippingTimed,
  driver: faShippingFast,
  sliders: faSlidersVSquare,
  sun: faSun,
  sms: faCommentDots,
  smsSolid: faCommentDotsSolid,
  sync: faSyncAlt,
  table: faTable,
  thumbsUp: faThumbsUp,
  thumbsDown: faThumbsDown,
  truck: faTruck,
  truckRegular: faTruckRegular,
  times: faTimes,
  timesCircle: faTimesCircle,
  user: faUser,
  userCircle: faUserCircle,
  userEdit: faUserEdit,
  userFriends: faUserFriends,
  userRemove: faUserTimes,
  userAdd: faUserPlus,
  utensils: faUtensils,
  upload: faUpload,
  warehouse: faWarehouse,
  warehouseLocation: faInventory,
  windowClose: faWindowClose,
  caretDown: faCaretDown,
  caretUp: faCaretUp,
  chartBar: faChartBar,
  eye: faEye,
  eyeSlash: faEyeSlash,
  cartShopping: faShoppingCart,
  cartPlus: faCartPlus,
  tag: faTag,
  box: faBox,
  image: faImage,
  hourglassHalf: faHourglassHalf,
  cogs: faCogs,
  AcUnit: faSnowflake,
  Thunderstorm: faCloudShowersHeavy,
  LocalShipping: faTruck,
  Store: faStore,
  Flag: faFlag,
  File: faFile,
  FileAlt: faFileAlt,
  shieldLock: customShieldLock,
  shieldLockWithoutOuterCircle: customShieldLockWithoutOuterCircle,
  invertedColorsShieldLock: customInvertedColorsShieldLock,
  deliveryStatusCalendar: customDeliveryStatusCalendar,
  stackedFiles: customStackedFilesIcon,
  customShare: customShareIcon,
  lightningBolt: customLightningBolt,
  customCalendar: customCalendarIcon,
  boxOutline: customBoxOutline,
  facility: customFacility,
  fancyPen: faPen,
  gearComplexCode: faGearComplexCode,
};

export type IconTypes = keyof typeof icons;
