import { toast } from "react-toastify";
import { call, put, takeLeading } from "redux-saga/effects";
import { buildingsService } from "services/hermes/buildings";

import { networkActions } from "slices/authentication/network.actions";
import { GET_MARKETS, marketsActions } from "./markets.actions";
import { IMarketType } from "./markets.reducer";
import { loadingActions } from "slices/loading/loading.actions";

function* getMarketsWorker() {
  yield put(loadingActions.startLoad(GET_MARKETS));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof buildingsService.getMarkets> =
      yield call(buildingsService.getMarkets);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IMarketType[] = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(marketsActions.getMarketsSuccess(response));
  } catch (error) {
    toast.error(
      "There was an error loading the page. Please refresh and try again.",
    );
  }
  yield put(loadingActions.endLoad(GET_MARKETS));
}

export const marketsSagas = function* () {
  yield takeLeading(GET_MARKETS, getMarketsWorker);
};
