import {
  IAuthType,
  ILoginChangingActionType,
  ILoginFailureActionType,
  ILoginRequestActionType,
  ILoginSuccessActionType,
  ILogoutActionType,
  IRefreshTokenSuccessActionType,
  IRegisterAccountSuccessActionType,
  ISetSessionActionType,
  LOGIN_CHANGING,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
  REGISTER_ACCOUNT_SUCCESS,
  SET_SESSION,
} from "slices/authentication/authentication.actions";

function setSession({
  payload: { auth },
}: ISetSessionActionType): Partial<IAuthenticationStateType> {
  return {
    loggedIn: true,
    loginStatus: "loggedIn",
    tokens: auth,
  };
}

export interface IAuthenticationStateType {
  loggedIn: boolean;
  tokens: IAuthType;
  loginStatus: string;
}

export function authentication(
  state = {},
  action: AuthenticationActionTypes,
): Partial<IAuthenticationStateType> {
  switch (action.type) {
    case SET_SESSION:
      return setSession(action);
    case LOGIN_REQUEST:
      return { ...state, loginStatus: "attempting" };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loginStatus: "loggedIn",
        tokens: action.newTokens,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loginStatus: "loggedIn",
        tokens: action.payload.auth,
      };
    case REGISTER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loginStatus: "loggedIn",
        tokens: action.payload.auth,
      };
    case LOGIN_FAILURE:
      return { ...state, loginStatus: "invalid" };
    case LOGIN_CHANGING:
      return { ...state, loginStatus: "changing" };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        tokens: {} as IAuthType,
        loginStatus: "",
      };
    default:
      return state;
  }
}

// type definitions
type AuthenticationActionTypes =
  | ISetSessionActionType
  | ILoginSuccessActionType
  | ILogoutActionType
  | ILoginRequestActionType
  | ILoginFailureActionType
  | ILoginChangingActionType
  | IRegisterAccountSuccessActionType
  | IRefreshTokenSuccessActionType;
