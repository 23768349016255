import { useEffect, useState } from "react";
import { useFetchSelector } from "./useFetchSelector.hook";
import {
  clearChatUser,
  setChatUser,
  startChat,
  stopChat,
} from "services/chat/chat.service";
import { captureSentryException } from "services/sentry";

export const useChatBot = (): void => {
  const authentication = useFetchSelector("authentication");
  const currentUser = useFetchSelector("currentUser");
  const [hasStartedChat, setHasStartedChat] = useState(false);

  useEffect(() => {
    if (!hasStartedChat) {
      try {
        setHasStartedChat(true);
        startChat();
      } catch (error) {
        captureSentryException(error);
      }
    }
  }, [hasStartedChat]);

  useEffect(() => {
    try {
      if (authentication?.loggedIn && currentUser) {
        // if logged in and resident set chat user
        if (currentUser.residentialUser) {
          clearChatUser();
          setChatUser(currentUser);
        } else {
          // if not resident, stop chat
          stopChat();
        }
      }
      if (!authentication?.loggedIn) {
        // clear user if not logged in
        clearChatUser();
      }
    } catch (error) {
      captureSentryException(error);
    }
  }, [authentication, currentUser]);
};
