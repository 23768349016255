import {
  GET_BUILDING_DELIVERY_TIMES_SUCCESS,
  IGetPackageDeliveryTimesSuccessActionType,
} from "./packageDeliveryTimes.actions";

export interface IDeliveryTimeType {
  deliveryWindowId: number;
  startTime: string;
  endTime: string;
  cutoOffTime: string;
  fetchOfferingType: number;
}

export interface IPackageDeliveryTimesStoreType {
  fetchPackageId: number | null;
  warehouseId: number;
  warehouseTimezone: string;
  deliveryDate: string;
  deliveryTimes: IDeliveryTimeType[];
}

type PackageDeliveryTimesActionsTypes =
  IGetPackageDeliveryTimesSuccessActionType;

export function packageDeliveryTimes(
  state = {},
  action: PackageDeliveryTimesActionsTypes,
): Partial<IPackageDeliveryTimesStoreType> {
  switch (action.type) {
    case GET_BUILDING_DELIVERY_TIMES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
