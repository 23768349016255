import { Price } from "./prices.reducer";

export const CLEAR_PRICES = "CLEAR_PRICES";
export const GET_ALL_MY_PRICES_V2 = "GET_ALL_MY_PRICES_V2";
export const GET_ALL_MY_PRICES_V2_SUCCESS = "GET_ALL_MY_PRICES_V2_SUCCESS";

export interface ClearPricesAction {
  type: typeof CLEAR_PRICES;
}

export interface GetAllMyPricesV2Action {
  type: typeof GET_ALL_MY_PRICES_V2;
}

export interface GetAllMyPricesV2Response {
  prices: Price[];
}

export interface GetAllMyPricesV2SuccessAction {
  type: string;
  payload: GetAllMyPricesV2Response;
}

function clearPrices(): ClearPricesAction {
  return { type: CLEAR_PRICES };
}

function getAllMyPricesV2() {
  return { type: GET_ALL_MY_PRICES_V2 };
}

function getAllMyPricesV2Success(
  response: GetAllMyPricesV2Response,
): GetAllMyPricesV2SuccessAction {
  return {
    type: GET_ALL_MY_PRICES_V2_SUCCESS,
    payload: response,
  };
}

export const pricesActions = {
  getAllMyPricesV2,
  clearPrices,
  getAllMyPricesV2Success,
};
