import React from "react";

interface FetchControlledFlowProps {
  requirements: IRequirement[];
  goal: JSX.Element | null;
}

export type IRequirement = {
  name?: string;
  isSatisfied: boolean;
  component: JSX.Element;
};

export const FetchControlledFlow: React.FC<FetchControlledFlowProps> = (
  props: FetchControlledFlowProps,
): JSX.Element | null => {
  const { requirements, goal } = props;
  const pendingRequirements = requirements.filter(
    (requirement: IRequirement) => {
      return !requirement.isSatisfied;
    },
  );

  if (pendingRequirements.length) {
    const Requirement = pendingRequirements[0].component;
    return Requirement;
  } else {
    return goal;
  }
};
