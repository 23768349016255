import { Reducer } from "redux";

import { defaultState } from "default-state";

import {
  CLEAR_DELIVERY_WINDOWS,
  IClearDeliveryWindowsActionType,
  ISearchDeliveryWindowsSuccessActionType,
  SEARCH_DELIVERY_WINDOWS_SUCCESS,
} from "slices/deliveryWindows/deliveryWindows.actions";

export type ScheduleOverrideMode =
  | "EXISTING"
  | "UNION"
  | "INTERSECTION"
  | "EMPTY";

export type ScheduleOverrideScope = "WAREHOUSE" | "BUILDING" | "GLOBAL";

export type OverrideType = "SCHEDULE_OVERRIDE" | "BUILDING_CUT_OFF_OVERRIDE";

interface CutOffBuildingOverride {
  overrideType: OverrideType;
  scope: ScheduleOverrideScope;
  mode?: ScheduleOverrideMode;
  cutOffTime?: string;
  cutOffVolume?: number;
}

interface CutOffBuilding {
  buildingId: number;
  name: string;
  shortCode: string;
  packageDeliveryPolicy: number;
  warehouseId: number;
  deliveryWindowGroupId: number | null;
  removed: boolean; // specifies whether this schedule is no longer available
  overrides?: CutOffBuildingOverride[];
}

export interface CutOff {
  cutOffTime: string;
  cutOffVolume?: number;
  buildingOverride?: boolean;
  buildings: CutOffBuilding[];
}

export interface DeliverySearchWindow {
  deliveryWindowId: number;
  startTime: string;
  endTime: string;
  fetchOfferingType: number;
  cutOffs: CutOff[];
}

export interface IDeliveryWindowSearchResponse {
  [key: string]: DeliverySearchWindow[];
}

export type DeliveryWindowsActions =
  | IClearDeliveryWindowsActionType
  | ISearchDeliveryWindowsSuccessActionType;

export const deliveryWindows: Reducer<
  IDeliveryWindowSearchResponse | null,
  DeliveryWindowsActions
> = (state, action): IDeliveryWindowSearchResponse | null => {
  switch (action.type) {
    case CLEAR_DELIVERY_WINDOWS:
      return defaultState.deliveryWindows;
    case SEARCH_DELIVERY_WINDOWS_SUCCESS:
      return action.response;
    default:
      return state || null;
  }
};
