import React, { ReactChild } from "react";

import { Dispatch } from "redux";

import { uiActions } from "slices/ui/ui.actions";
import { BEM } from "helpers/BEM.helper";
import { FetchConnect } from "helpers/FetchConnect";
import { RouteComponentProps } from "react-router";
import { CardTitle, Col, Modal, ModalBody, Row } from "reactstrap";
import { FetchIcon } from "views/common/misc/FetchIcon";
import { IRootStateType } from "types/IRootStateType";
import { ModalStoreIndexType } from "slices/ui/ui.reducer";

interface FetchModalProps extends IRootStateType, RouteComponentProps {
  title?: string;
  id: ModalStoreIndexType;
  dispatch: Dispatch;
  children?: ReactChild | ReactChild[];
  className?: string;
}

export const FetchModal: React.FC<FetchModalProps> = (
  props: FetchModalProps,
) => {
  const {
    dispatch,
    ui: { modals },
    id,
    title,
    className,
  } = props;
  const titleExists = !!title;

  const classes = new BEM({
    block: { name: "Modal" },
    elements: [
      {
        extras: ["d-flex", "justify-content-center", "align-items-center"],
        name: "close",
      },
    ],
    prefix: { name: "fetch" },
  });
  const classNameExtras: string[] = [];
  if (!!className) {
    classNameExtras.push(className);
  }

  return (
    <Modal
      {...{
        className: classes.getBlockClassNames({ extras: classNameExtras }),
        isOpen: modals[id],
        toggle: () => {
          dispatch(uiActions.clearModal());
        },
      }}
    >
      <ModalBody>
        {titleExists && (
          <Row>
            <Col>
              <CardTitle tag="h4" className="m-0">
                {title}
              </CardTitle>
            </Col>
            <Col
              xs="2"
              onClick={() => {
                dispatch(uiActions.clearModal());
              }}
              className={classes.getElementClassNames("close")}
            >
              <FetchIcon iconType="windowClose" size="lg" />
            </Col>
          </Row>
        )}
        {props.children}
      </ModalBody>
    </Modal>
  );
};

export default FetchConnect({
  ConnectedComponent: FetchModal,
  state: { stores: ["ui"] },
});
