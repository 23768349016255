import { call, put, takeLeading } from "redux-saga/effects";
import { loggingService } from "services/hermes/logging";
import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  QueryLabelAnalysisAction,
  labelAnalysisQueryActions,
  GET_LABEL_ANALYSIS_BY_QUERY,
} from "./labelAnalysisQuery.actions";
import { LabelAnalysisResponse } from "./labelAnalysisQuery.reducer";

function* queryLabelAnalysisWorker(action: QueryLabelAnalysisAction) {
  yield put(loadingActions.startLoad(action.type));

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof loggingService.queryLabelAnalysis> =
      yield call(
        loggingService.queryLabelAnalysis,
        action.payload.paginationQuery,
      );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: LabelAnalysisResponse = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );

    yield put(labelAnalysisQueryActions.queryLabelAnalysisSuccess(response));
  } catch (error) {
    yield put(labelAnalysisQueryActions.queryLabelAnalysisFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

export const labelAnalysisQuerySagas = function* () {
  yield takeLeading(GET_LABEL_ANALYSIS_BY_QUERY, queryLabelAnalysisWorker);
};
