import { generateQueryString } from "helpers/generateQueryString.helper";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { RequestGetFetchPackagesByIdLabelImage } from "./action";

export const service = (
  action: Omit<RequestGetFetchPackagesByIdLabelImage, "type">,
): IAuthenticatedRequestDefinition => {
  return {
    body: action.body,
    requestOptions: { method: "GET" },
    requestUrl: `/fetchPackages/${action.fetchPackageId}/label-image${
      !!action.query ? generateQueryString(action.query) : ""
    }`,
  };
};

export type GetFetchPackagesByIdLabelImageRequestBody = {
  //
};

export type GetFetchPackagesByIdLabelImageResponseBody = {
  fetchPackageId: number;
  imageUrl: string;
  warehouseId: number;
  packageType: number | null;
};
