import { GetFetchPackagesByIdLabelImageRequestBody } from "./service";
import { RequestValues } from "../../../types/RequestValues";

export const action = (
  values: Omit<RequestGetFetchPackagesByIdLabelImage, "type">,
): RequestGetFetchPackagesByIdLabelImage => {
  return {
    type: REQUEST_GET_FETCH_PACKAGES_BY_ID_LABEL_IMAGE,
    ...values,
  };
};

export const REQUEST_GET_FETCH_PACKAGES_BY_ID_LABEL_IMAGE =
  "REQUEST_GET_FETCH_PACKAGES_BY_ID_LABEL_IMAGE";

export type RequestGetFetchPackagesByIdLabelImage =
  RequestValues<GetFetchPackagesByIdLabelImageRequestBody> & {
    type: typeof REQUEST_GET_FETCH_PACKAGES_BY_ID_LABEL_IMAGE;
    fetchPackageId: number;
  };
