import { takeLatest, ForkEffect } from "redux-saga/effects";

import { call, put } from "redux-saga/effects";

import { loadingActions } from "slices/loading/loading.actions";
import {
  keystrokeDelay,
  networkActions,
} from "slices/authentication/network.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  ISearchWarehousesActionType,
  SEARCH_WAREHOUSES,
  warehousesActions,
} from "./warehouses.actions";
import { warehousesService } from "services/hermes/warehouses";
import { IWarehousesStoreType } from "./warehouses.reducer";

function* searchWarehousesWorker(action: ISearchWarehousesActionType) {
  yield put(loadingActions.startLoad(action.type));
  yield put(warehousesActions.clearWarehouses());
  try {
    yield keystrokeDelay();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      warehousesService.searchWarehouses,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IWarehousesStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(warehousesActions.searchWarehousesSuccess(response));
  } catch (error) {
    yield put(warehousesActions.searchWarehousesFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

export const warehousesSagas = function* sagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(SEARCH_WAREHOUSES, searchWarehousesWorker);
};
