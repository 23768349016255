import { IMyPackageStoreType } from "./myPackage.reducers";

export const CLEAR_MY_PACKAGE = "CLEAR_MY_PACKAGE";
export const GET_MY_PACKAGE = "GET_MY_PACKAGE";
export const GET_MY_PACKAGE_SUCCESS = "GET_MY_PACKAGE_SUCCESS";

export interface IGetMyPackageActionType {
  type: typeof GET_MY_PACKAGE;
  fetchPackageId: number;
}

export interface IGetMyPackageSuccessActionType {
  type: typeof GET_MY_PACKAGE_SUCCESS;
  packageResponse: IMyPackageStoreType;
}

function clearMyPackage(): IClearMyPackageActionType {
  return { type: CLEAR_MY_PACKAGE };
}

export interface IClearMyPackageActionType {
  type: typeof CLEAR_MY_PACKAGE;
}

function getMyPackage(fetchPackageId: number): IGetMyPackageActionType {
  return { type: GET_MY_PACKAGE, fetchPackageId };
}

function getMyPackageSuccess(packageResponse: IMyPackageStoreType) {
  return { type: GET_MY_PACKAGE_SUCCESS, packageResponse };
}

export const myPackageActions = {
  clearMyPackage,
  getMyPackage,
  getMyPackageSuccess,
};
