import { IPackageAudit } from "./packageAuditTrail.reducer";

export const GET_PACKAGE_AUDIT_TRAIL = "GET_PACKAGE_AUDIT_TRAIL";
export const GET_PACKAGE_AUDIT_TRAIL_SUCCESS =
  "GET_PACKAGE_AUDIT_TRAIL_SUCCESS";
export const CLEAR_PACKAGE_AUDIT_TRAIL = "CLEAR_PACKAGE_AUDIT_TRAIL";

export interface IGetPackageAuditTrailPayloadType {
  packageId: string;
}

export interface IGetPackageAuditTrailActionType {
  type: typeof GET_PACKAGE_AUDIT_TRAIL;
  payload: IGetPackageAuditTrailPayloadType;
}
export interface IGetPackageAuditTrailSuccessActionType {
  type: typeof GET_PACKAGE_AUDIT_TRAIL_SUCCESS;
  payload: IPackageAudit[];
}

export interface IClearPackageAuditTrailActionType {
  type: typeof CLEAR_PACKAGE_AUDIT_TRAIL;
}

function getPackageAuditTrail(
  payload: IGetPackageAuditTrailPayloadType,
): IGetPackageAuditTrailActionType {
  return { type: GET_PACKAGE_AUDIT_TRAIL, payload };
}
function getPackageAuditTrailSuccess(
  response: IPackageAudit[],
): IGetPackageAuditTrailSuccessActionType {
  return { type: GET_PACKAGE_AUDIT_TRAIL_SUCCESS, payload: response };
}

function clearPackageAuditTrail() {
  return { type: CLEAR_PACKAGE_AUDIT_TRAIL };
}

export const packageAuditTrailActions = {
  clearPackageAuditTrail,
  getPackageAuditTrail,
  getPackageAuditTrailSuccess,
};
