const containsALowerCaseLetter = "(?=.*[a-z])";
const containsAnUpperCaseLetter = "(?=.*[A-Z])";
const containsANumber = "(?=.*[0-9])";
const containsASpecialCharacter = "(?=.*[!@#$%^&*.])";
const minimumLength = 8;
const meetsMinimumLength = `(?=.{${minimumLength},})`;
const validPasswordRegexPattern = `^${containsALowerCaseLetter}${containsAnUpperCaseLetter}${containsANumber}${containsASpecialCharacter}${meetsMinimumLength}`;

export type PasswordCondition = {
  meetsMinimumLength: boolean;
  doesNotContainWhiteSpace: boolean;
  containsALowerCaseLetter: boolean;
  containsAnUpperCaseLetter: boolean;
  containsANumber: boolean;
  containsASpecialCharacter: boolean;
};

export function assertPasswordCondition(
  password: string | undefined,
): PasswordCondition {
  return {
    meetsMinimumLength:
      !!password && new RegExp(meetsMinimumLength).test(password),
    doesNotContainWhiteSpace: !!password && !password.includes(" "),
    containsALowerCaseLetter:
      !!password && new RegExp(containsALowerCaseLetter).test(password),
    containsAnUpperCaseLetter:
      !!password && new RegExp(containsAnUpperCaseLetter).test(password),
    containsANumber: !!password && new RegExp(containsANumber).test(password),
    containsASpecialCharacter:
      !!password && new RegExp(containsASpecialCharacter).test(password),
  };
}

export function assertPasswordIsValid(password: string | undefined): boolean {
  const {
    meetsMinimumLength,
    doesNotContainWhiteSpace,
    containsALowerCaseLetter,
    containsAnUpperCaseLetter,
    containsANumber,
    containsASpecialCharacter,
  } = assertPasswordCondition(password);

  return (
    meetsMinimumLength &&
    doesNotContainWhiteSpace &&
    containsALowerCaseLetter &&
    containsAnUpperCaseLetter &&
    containsANumber &&
    containsASpecialCharacter
  );
}
