import React from "react";

import { UncontrolledAlert } from "reactstrap";
import { Dispatch } from "redux";

import { uiActions } from "slices/ui/ui.actions";
import { FetchConnect } from "helpers/FetchConnect";
import { authenticationActions } from "slices/authentication/authentication.actions";
import { IRootStateType } from "types/IRootStateType";

export interface NotificationPanelProps extends IRootStateType {
  dispatch: Dispatch;
}

export const NotificationPanel: React.FC<NotificationPanelProps> = (
  props: NotificationPanelProps,
) => {
  const {
    dispatch,
    authentication: { tokens },
    ui: { notifications },
  } = props;

  if (notifications.length) {
    return (
      <div style={{ padding: "70px 15px 0px 15px", marginBottom: "-70px" }}>
        {notifications.map(({ id, title, message }) => {
          switch (id) {
            case "alertUnverifiedPhone":
              return (
                <UncontrolledAlert className={id} color="danger" key={id}>
                  <div
                    onClick={() => {
                      dispatch(uiActions.triggerModal("verify_phone"));
                      if (tokens && tokens.userToken) {
                        dispatch(
                          authenticationActions.getUserAttributeVerificationCode(
                            {
                              attributeName: "phone_number",
                              userToken: tokens.userToken,
                            },
                          ),
                        );
                      }
                      dispatch(uiActions.clearNotification(id));
                    }}
                  >
                    <b>{title}</b>: {message}
                  </div>
                </UncontrolledAlert>
              );

            case "alertUnverifiedEmail":
              return (
                <UncontrolledAlert className={id} color="danger" key={id}>
                  <div
                    onClick={() => {
                      dispatch(uiActions.triggerModal("verify_email"));
                      if (tokens && tokens.userToken) {
                        dispatch(
                          authenticationActions.getUserAttributeVerificationCode(
                            {
                              attributeName: "email",
                              userToken: tokens.userToken,
                            },
                          ),
                        );
                      }
                      dispatch(uiActions.clearNotification(id));
                    }}
                  >
                    <b>{title}</b>: {message}
                  </div>
                </UncontrolledAlert>
              );

            default:
              return null;
          }
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default FetchConnect({
  ConnectedComponent: NotificationPanel,
  state: { stores: ["authentication", "ui"] },
});
