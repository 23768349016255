import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { generateQueryString } from "helpers/generateQueryString.helper";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";

type PostPublishLoggingAnalysisResultRequestType = {
  fetchPackageId: number;
  imageUrl: string;
  carrierTracking: { carrier: number; trackingNumber: string }[];
  senders: string[];
  processingStatus: number; // 5 -> NEW_PICTURE_REQUIRED, 7 -> SUCCESS_HUMAN
  data: null;
};

type PostGetNextLoggingAnalysisJobRequestType = {
  labelId: number | null;
  includeOCR?: boolean;
};

const getLabelAnalysisSummary = (
  warehouseShortCode: string | undefined,
): IAuthenticatedRequestDefinition => {
  return {
    requestOptions: { method: "GET" },
    requestUrl: warehouseShortCode
      ? "/fetchPackages/labels/analyze/summary?warehouseShortCode=" +
        `${warehouseShortCode}`
      : "/fetchPackages/labels/analyze/summary",
  };
};

const getLabelImage = (packageId: string): IAuthenticatedRequestDefinition => {
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/fetchPackages/${packageId}/label-image`,
  };
};

function postGetNextLoggingAnalysisJob(
  parameters: PostGetNextLoggingAnalysisJobRequestType,
): IAuthenticatedRequestDefinition {
  const requestDefinition: IAuthenticatedRequestDefinition = {
    requestOptions: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: "/fetchPackages/labels/analyze",
  };

  if (parameters.labelId !== null) {
    requestDefinition.requestOptions.body = JSON.stringify({
      labelId: parameters.labelId,
    });
  }

  if (parameters.includeOCR === true) {
    requestDefinition.requestUrl =
      requestDefinition.requestUrl.concat("?includeOCR=true");
  }

  return requestDefinition;
}

function postPublishLoggingAnalysisResult(
  parameters: PostPublishLoggingAnalysisResultRequestType,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: {
      body: JSON.stringify(parameters),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: "/fetchPackages/labels/analyze/result",
  };
}

const queryLabelAnalysis = (
  paginationQuery: IPaginatedTableSearchRequestType,
): IAuthenticatedRequestDefinition => {
  const queryString = generateQueryString(paginationQuery);

  return {
    requestOptions: { method: "GET" },
    requestUrl: "/fetchPackages/labels/analyze/search" + queryString,
  };
};

export const loggingService = {
  postGetNextLoggingAnalysisJob,
  postPublishLoggingAnalysisResult,
  getLabelImage,
  queryLabelAnalysis,
  getLabelAnalysisSummary,
  // postInitializeLabelProcessing,
  // postGetAnalysisJobById,
  // getSearchLabelAnalysis,
};
