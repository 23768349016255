import {
  CLEAR_BUILDING,
  GET_BUILDING_SUCCESS,
} from "slices/building/building.actions";
import { defaultState } from "default-state";

export interface IUnitType {
  buildingUnitId: number;
  buildingId: number;
  unitNumber: string;
  active: boolean;
  hidden: boolean;
}

export interface ICoordinateType {
  center: {
    lat: number;
    lng: number;
    buffer: number;
    radius: number;
  };
}

export interface IBuildingStoreType {
  active: boolean;
  unitMapUrl: string | null;
  buildingId: number;
  warehouseId: number;
  name: string;
  shortCode: string;
  packageDeliveryPolicy: number;
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  market: number;
  units: IUnitType[];
  coordinates: ICoordinateType;
  residentPrivacyPolicyUrl: string;
  residentTermsAndConditionsUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any;
}

export function building(
  state = {},
  action: BuildingActionsTypes,
): Partial<IBuildingStoreType> {
  switch (action.type) {
    case CLEAR_BUILDING:
      return defaultState.building;
    case GET_BUILDING_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

// type definitions
type BuildingActionsTypes =
  | IGetBuildingSuccessActionType
  | IClearBuildingActionType;

export interface IGetBuildingSuccessActionType {
  type: typeof GET_BUILDING_SUCCESS;
  payload: IBuildingStoreType;
}
interface IClearBuildingActionType {
  type: typeof CLEAR_BUILDING;
}
