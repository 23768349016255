import React from "react";

export const BackgroundOverlays: React.FC = () => {
  return (
    <div className="background-overlay-wrapper">
      <div className="cloud-wrapper">
        <div className="x1">
          <div className="cloud" />
        </div>
        <div className="x2">
          <div className="cloud" />
        </div>
        <div className="x3">
          <div className="cloud" />
        </div>
        <div className="x4">
          <div className="cloud" />
        </div>
        <div className="x5">
          <div className="cloud" />
        </div>
        <div className="x6">
          <div className="cloud" />
        </div>
        <div className="x7">
          <div className="cloud" />
        </div>
        <div className="x8">
          <div className="cloud" />
        </div>
        <div className="x9">
          <div className="cloud" />
        </div>
        <div className="x10">
          <div className="cloud" />
        </div>
      </div>
      <div className="hot-air-balloon-container lift-off">
        <div className="rotate">
          <div className="translateX">
            <div className="translateY">
              <div className="hot-air-balloon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
