import { call, put, takeLatest } from "redux-saga/effects";
import { fullTextSearchService } from "services/hermes/fullTextSearch";
import { networkActions } from "../authentication/network.actions";
import { loadingActions } from "../loading/loading.actions";
import {
  GetFetchPackagesLabelsFullTextSearchAction,
  GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH,
  fullTextSearchActions,
} from "./FullTextSearch.actions";
import { IFullTextSearchStore } from "./FullTextSearch.reducer";

// Worker / Generator
function* getFetchPackagesLabelsFullTextSearchWorker(
  action: GetFetchPackagesLabelsFullTextSearchAction,
) {
  yield put(loadingActions.startLoad(action.type));

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof fullTextSearchService.getFetchPackagesLabelsFullTextSearch
    > = yield call(
      fullTextSearchService.getFetchPackagesLabelsFullTextSearch,
      action.payload.paginationQuery,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IFullTextSearchStore = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );

    yield put(
      fullTextSearchActions.getFetchPackagesLabelsFullTextSearchSuccess(
        response,
      ),
    );
  } catch (error) {
    yield put(
      fullTextSearchActions.getFetchPackagesLabelsFullTextSearchFailure(),
    );
  }
  yield put(loadingActions.endLoad(action.type));
}

export const getFetchPackagesLabelsFullTextSearchSagas = function* () {
  yield takeLatest(
    GET_FETCH_PACKAGES_LABELS_FULL_TEXT_SEARCH,
    getFetchPackagesLabelsFullTextSearchWorker,
  );
};
