import { FeatureFlags } from "constants/feature-flags";
import { lazyWithRefresh } from "helpers/lazyWithRefresh.helper";
import { UserPermissions } from "types/UserPermissions";
import { UserPrivileges, userPrivileges } from "types/UserPrivileges";
import { navRoutePathConstants } from "./navRoutes";

// each path must be unique
export interface PageRoute {
  path: string;
  component?: React.ComponentType;
  permissions: UserPermissions;
  featureFlags?: FeatureFlags[];
  privileges?: UserPrivileges[];
  permitIfOneOrMoreFeatureFlagsPresent?: boolean;
}

const FetchDocumentationPage = lazyWithRefresh(
  () => import("views/pages/FetchDocumentationPage"),
);
const FeatureFlagFormController = lazyWithRefresh(
  () => import("views/pages/FeatureFlagForm/FeatureFlagFormController"),
);
const NewFeatureFlagFormController = lazyWithRefresh(
  () => import("views/pages/FeatureFlagForm/NewFeatureFlagController"),
);
const BatchFormContainer = lazyWithRefresh(
  () => import("views/components/forms/BatchForm"),
);
const BuildingDetailsPage = lazyWithRefresh(
  () => import("views/pages/BuildingDetailsPage"),
);
const PackageFormPage = lazyWithRefresh(
  () => import("views/pages/PackageFormPage"),
);
const BulkLabelFormPage = lazyWithRefresh(
  () => import("views/pages/BulkLabelFormPage"),
);
const LoggingFormPage = lazyWithRefresh(
  () => import("views/pages/LoggingFormPage"),
);
const UserFormContainer = lazyWithRefresh(
  () => import("views/components/forms/UserForm"),
);
const WarehouseFormContainer = lazyWithRefresh(
  () => import("views/components/forms/WarehouseForm"),
);
const WarehouseLocationBatchFormContainer = lazyWithRefresh(
  () => import("views/components/forms/WarehouseLocationBatchCreateForm"),
);
const WarehouseLocationFormContainer = lazyWithRefresh(
  () => import("views/components/forms/WarehouseLocationForm"),
);
const ForgotPasswordPage = lazyWithRefresh(
  () => import("views/pages/ForgotPassword"),
);
const LockScreenPage = lazyWithRefresh(() => import("views/pages/LockScreen"));
const LoginPage = lazyWithRefresh(() => import("views/pages/Login"));
const MyBatchDetailPage = lazyWithRefresh(
  () => import("views/pages/MyBatchDetailPage"),
);
const MyBatchesPage = lazyWithRefresh(
  () => import("views/pages/MyBatchesPage"),
);
const MyPackagesPageController = lazyWithRefresh(
  () => import("views/pages/MyDailyPackagesPage/MyPackagesPageController"),
);
const TreatsPage = lazyWithRefresh(() => import("views/pages/TreatsPage"));

const MyWarehouseSettingsContainer = lazyWithRefresh(
  () => import("views/pages/MyWarehouseSettingsPage"),
);
const RecoverPasswordPage = lazyWithRefresh(
  () => import("views/pages/RecoverPassword"),
);
const RegisterPage = lazyWithRefresh(
  () => import("views/pages/Register/RegisterPage"),
);

const UserProfileController = lazyWithRefresh(
  () => import("views/pages/UserProfile/UserProfilePageController"),
);
const UserRoleDetailsPage = lazyWithRefresh(
  () => import("views/pages/UserRoleDetailsPage/UserRoleDetailsPage"),
);
const BatchesTablePage = lazyWithRefresh(
  () => import("views/pages/BatchesTablePage"),
);
const BuildingsTablePage = lazyWithRefresh(
  () => import("views/pages/BuildingsTablePage"),
);
const PackagesTablePage = lazyWithRefresh(
  () => import("views/pages/PackagesTablePage"),
);

const UsersTablePage = lazyWithRefresh(
  () => import("views/pages/UsersTablePage"),
);
const WarehouseDailyWindowsDetailContainer = lazyWithRefresh(
  () => import("views/tables/WarehouseDailyWindowsDetail"),
);
const WarehouseLocationsTablePage = lazyWithRefresh(
  () => import("views/pages/WarehouseLocationsTablePage"),
);
const WarehousesTableContainer = lazyWithRefresh(
  () => import("views/pages/WarehousesTablePage"),
);
const WarehouseDailyBatchProgress = lazyWithRefresh(
  () => import("views/tables/WarehouseDailyBatchProgress"),
);
const WarehouseDailyBatchAuditProgress = lazyWithRefresh(
  () => import("views/tables/WarehouseDailyBatchAuditProgress"),
);
const MyPackageHistoryPageController = lazyWithRefresh(
  () =>
    import("views/pages/MyPackageHistoryPage/MyPackageHistoryPageController"),
);
const CourierRouterPage = lazyWithRefresh(
  () => import("views/pages/CourierRouter"),
);
const StorageProductsPage = lazyWithRefresh(
  () => import("views/pages/StorageProductsPage"),
);
const StorageSubscriptionsPage = lazyWithRefresh(
  () => import("views/pages/StorageSubscriptionsPage"),
);

const LoggingDashboardPage = lazyWithRefresh(
  () => import("views/pages/LoggingDashboardPage"),
);

const FeatureFlagsTablePageController = lazyWithRefresh(
  () =>
    import(
      "views/pages/FeatureFlagsTablePageController/FeatureFlagsTablePageController"
    ),
);
const FullTextSearchPage = lazyWithRefresh(
  () => import("views/pages/FullTextSearchPage"),
);
const RecurringSchedulePageController = lazyWithRefresh(
  () =>
    import(
      "views/pages/WorkforceManagement/RecurringSchedulePage/RecurringSchedulePageController"
    ),
);
const BlockSchedulePageController = lazyWithRefresh(
  () =>
    import(
      "views/pages/WorkforceManagement/BlockSchedulePage/BlockSchedulePageController"
    ),
);
const PaymentsHistoryPageController = lazyWithRefresh(
  () =>
    import(
      "views/pages/WorkforceManagement/PaymentsHistoryPage/PaymentsHistoryController"
    ),
);
const ApprovalsListPageController = lazyWithRefresh(
  () =>
    import(
      "views/pages/WorkforceManagement/ApprovalsListPageController/ApprovalsListPageController"
    ),
);
const WfmWindowDetailPageController = lazyWithRefresh(
  () =>
    import(
      "views/pages/WorkforceManagement/WindowDetailPage/WindowDetailPageController"
    ),
);

const HowDoesFetchWork = lazyWithRefresh(
  () => import("views/pages/ResidentOnboardingPage/ResidentOnboardingPage"),
);

export const dashboardRoutes: PageRoute[] = [
  {
    component: FetchDocumentationPage,
    path: "/docs",
    permissions: {
      adminUser: true,
    },
  },
  {
    component: MyPackagesPageController,
    path: "/myPackages",
    permissions: {
      residentialUser: true,
    },
  },
  {
    component: MyPackageHistoryPageController,
    path: "/myPackageHistory",
    permissions: {
      residentialUser: true,
    },
  },
  {
    component: HowDoesFetchWork,
    path: "/howDoesFetchWork",
    permissions: {
      residentialUser: true,
    },
  },
  {
    component: TreatsPage,
    path: "/treats",
    permissions: {
      residentialUser: true,
    },
  },
  {
    component: StorageSubscriptionsPage,
    path: "/storage",
    permissions: {
      residentialUser: true,
    },
    featureFlags: [
      FeatureFlags["storage-by-building-v2"],
      FeatureFlags["storage-studentmovein"],
    ],
    permitIfOneOrMoreFeatureFlagsPresent: true,
  },
  {
    component: StorageProductsPage,
    path: "/storage/add",
    permissions: {
      residentialUser: true,
    },
    featureFlags: [
      FeatureFlags["storage-by-building-v2"],
      FeatureFlags["storage-studentmovein"],
    ],
    permitIfOneOrMoreFeatureFlagsPresent: true,
  },
  {
    component: BuildingsTablePage,
    path: "/buildings",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [...userPrivileges.buildings.search],
  },
  {
    component: BuildingDetailsPage,
    path: "/building/:buildingId(\\d+)?",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [...userPrivileges.building.readonly],
  },
  {
    component: WarehousesTableContainer,
    path: "/warehouses",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: WarehouseFormContainer,
    path: "/warehouse/:warehouseId(\\d+)?",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: PackagesTablePage,
    path: "/packages",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: PackagesTablePage,
    path: "/pickups",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    /* for managing an existing package */
    component: PackageFormPage,
    path: "/package/:fetchPackageId(\\d+)",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    /* for creating a new package */
    component: PackageFormPage,
    path: "/package",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    featureFlags: [FeatureFlags["enable-manual-logging"]],
  },
  {
    component: BulkLabelFormPage,
    path: "/bulk-label-print",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: LoggingFormPage,
    path: "/logging",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: LoggingDashboardPage,
    path: "/logging-dashboard",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [UserPrivileges.FETCHPACKAGES_LABELS_ANALYZE_SEARCH_GET],
  },
  {
    component: FullTextSearchPage,
    path: "/full-text-search",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [UserPrivileges.FETCHPACKAGES_LABELS_FULLTEXTSEARCH_GET],
  },
  {
    component: UsersTablePage,
    path: "/users",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: UserFormContainer,
    path: "/user/:userId(.+)?",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: WarehouseLocationsTablePage,
    path: "/warehouse/locations",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: WarehouseLocationFormContainer,
    path: "/warehouse/location/:warehouseLocationId(\\d+)?",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: WarehouseLocationBatchFormContainer,
    path: "/warehouse/batchLocations",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: BatchesTablePage,
    path: "/batches",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: BatchFormContainer,
    path: "/batch/:batchId(\\d+)?",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
  },
  {
    component: WarehouseDailyWindowsDetailContainer,
    path: "/todayDetail",
    permissions: {
      warehouseUser: true,
    },
  },
  {
    component: WarehouseDailyBatchProgress,
    path: "/batchProgress",
    permissions: {
      warehouseUser: true,
    },
  },
  {
    component: WarehouseDailyBatchAuditProgress,
    path: "/batchAuditProgress",
    permissions: {
      warehouseUser: true,
    },
  },
  {
    component: UserProfileController,
    path: "/user-profile",
    permissions: {
      adminUser: true,
      residentialUser: true,
      driverUser: true,
      warehouseUser: true,
    },
  },
  {
    component: UserRoleDetailsPage,
    path: "/roles/:userRoleId(\\d+)?",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [UserPrivileges.ADMIN_ROLES__USERROLEID__GET],
  },
  {
    component: MyBatchesPage,
    path: "/myDeliveries",
    permissions: {
      driverUser: true,
    },
  },
  {
    component: MyBatchDetailPage,
    path: "/myDeliveries/:driverBatchId(\\d+)?",
    permissions: {
      driverUser: true,
    },
  },
  {
    component: CourierRouterPage,
    path: "/superRouter",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [UserPrivileges._SUPER_ROUTER_ACCESS],
  },
  {
    component: CourierRouterPage,
    path: "/courierRouter",
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    privileges: [UserPrivileges._SUPER_ROUTER_ACCESS],
  },
  {
    component: MyWarehouseSettingsContainer,
    path: "/my-warehouse",
    permissions: {
      adminUser: true,
      warehouseUser: true,
      driverUser: true,
      residentialUser: true,
    },
  },
  {
    component: RecurringSchedulePageController,
    path: navRoutePathConstants.wfm.recurringSchedule,
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    featureFlags: [FeatureFlags["wfm-warehouse"]],
    privileges: userPrivileges.recurringSchedule,
  },
  {
    component: WfmWindowDetailPageController,
    path: navRoutePathConstants.wfm.windowDetail,
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    featureFlags: [FeatureFlags["wfm-warehouse"]],
    privileges: userPrivileges.windowDetail,
  },
  {
    component: BlockSchedulePageController,
    path: navRoutePathConstants.wfm.blockSchedule,
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    featureFlags: [FeatureFlags["wfm-warehouse"]],
    privileges: userPrivileges.blockSchedule,
  },
  {
    component: PaymentsHistoryPageController,
    path: navRoutePathConstants.wfm.payments,
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    featureFlags: [FeatureFlags["wfm-warehouse"]],
    privileges: userPrivileges.payments,
  },
  {
    component: ApprovalsListPageController,
    path: navRoutePathConstants.wfm.approvals,
    permissions: {
      adminUser: true,
      warehouseUser: true,
    },
    featureFlags: [FeatureFlags["wfm-warehouse"]],
    privileges: userPrivileges.approvals,
  },
  {
    component: FeatureFlagsTablePageController,
    path: navRoutePathConstants.internal.featureFlagsTable,
    permissions: {
      adminUser: true,
    },
    privileges: [UserPrivileges.FEATUREFLAGS_GET],
  },
  {
    component: FeatureFlagFormController,
    path: navRoutePathConstants.internal.featureFlagForm,
    permissions: {
      adminUser: true,
    },
    privileges: userPrivileges.featureFlags.readonly,
  },
  {
    component: NewFeatureFlagFormController,
    path: navRoutePathConstants.internal.createFeatureFlag,
    permissions: {
      adminUser: true,
    },
    privileges: userPrivileges.featureFlags.create,
  },
];

export const authRoutes: PageRoute[] = [
  {
    component: LoginPage,
    path: "/login",
    permissions: {
      adminUser: true,
      warehouseUser: true,
      driverUser: true,
      residentialUser: true,
    },
  },
  {
    component: ForgotPasswordPage,
    path: "/forgot-password",
    permissions: {
      adminUser: true,
      warehouseUser: true,
      driverUser: true,
      residentialUser: true,
    },
  },
  {
    component: RecoverPasswordPage,
    path: "/recover-password",
    permissions: {
      adminUser: true,
      warehouseUser: true,
      driverUser: true,
      residentialUser: true,
    },
  },
  {
    component: RegisterPage,
    path: "/register",
    permissions: {
      adminUser: true,
      warehouseUser: true,
      driverUser: true,
      residentialUser: true,
    },
  },
  {
    component: LockScreenPage,
    path: "/lock-screen",
    permissions: {
      adminUser: true,
      warehouseUser: true,
      driverUser: true,
      residentialUser: true,
    },
  },
];
