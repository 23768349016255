import { CallEffect, call, takeEvery } from "redux-saga/effects";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { transformQueryParametersToString } from "helpers/transformQueryParametersToString";
import { networkActions } from "slices/authentication/network.actions";

export type RouteParameters = Record<string, string>;
export type QueryParameters = Record<string, string>;

export type Body = {
  warehouseId: number; //  3,
  isoDayOfWeek: number; //  1,
  startTime: string; //  "12:00",
  endTime: string; //  "14:00",
  driverClassification: number; //  1,
  fetchOfferingType: number; //  1,
  driverQuantity: number; //  3,
  arrivalPriorMinutes: number; //  30,
  pay: number; //  0, // postive
};

export type Payload = {
  body?: Partial<Body>;
  queryParameters?: Partial<QueryParameters>;
  routeParameters?: Partial<RouteParameters>;
  // Enter your types here
  callbacks?: {
    [key in "onSuccess" | "onFailure"]?: () => void;
  };
};

export type Response = Body & {
  wfmTemplateWindowId: number;
};

export type ResponseError = null;

export type ActionType = {
  type: typeof POST_WFM_RESOURCES_TEMPLATEWINDOWS_;
  payload: Payload;
};

export const POST_WFM_RESOURCES_TEMPLATEWINDOWS_ =
  "POST_WFM_RESOURCES_TEMPLATEWINDOWS_";

export function action(payload: ActionType["payload"]): ActionType {
  return {
    type: POST_WFM_RESOURCES_TEMPLATEWINDOWS_,
    payload,
  };
}

const service = function (
  payload: ActionType["payload"],
): IAuthenticatedRequestDefinition {
  return {
    requestUrl:
      `/wfm/resources/template-windows/` +
      transformQueryParametersToString<
        ActionType["payload"]["queryParameters"]
      >(payload.queryParameters),
    body: payload.body,
    requestOptions: {
      method: "POST",
    },
  };
};

const worker = function* (
  action: ActionType,
): Generator<CallEffect, Response | undefined, Response | undefined> {
  let response: Response | undefined;
  try {
    response = yield call(
      networkActions.makeAuthenticatedRequest,
      service(action.payload),
      true,
    );
    if (!!action.payload.callbacks?.onSuccess) {
      action.payload.callbacks?.onSuccess();
    }
  } catch (error) {
    response = undefined;
    if (!!action.payload.callbacks?.onFailure) {
      action.payload.callbacks?.onFailure();
    }
  }
  return response;
};

const saga = function* () {
  yield takeEvery(POST_WFM_RESOURCES_TEMPLATEWINDOWS_, worker);
};

export const postWfmResourcesTemplateWindows = {
  action,
  service,
  saga,
  worker,
};
