import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";

export interface PostMySubscriptionIntentRequest {
  couponId?: string;
  items: Array<{
    priceId: string;
    quantity: number;
  }>;
}

function getMySubscriptions(): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/payments/subscriptions/my",
  };
}

function postMySubscriptionIntentV2(
  body: PostMySubscriptionIntentRequest,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "POST", headers: { "X-API-VERSION": "2" } },
    requestUrl: "/payments/subscriptions/my",
    body,
  };
}

function getAllMyPricesV2(): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET", headers: { "X-API-VERSION": "2" } },
    requestUrl: "/payments/prices/my",
  };
}

function validateCoupon(couponId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/payments/coupon/" + couponId,
  };
}

export const paymentsService = {
  validateCoupon,
  getAllMyPricesV2,
  postMySubscriptionIntentV2,
  getMySubscriptions,
};
