import {
  CLEAR_MY_DRIVER_BATCHES,
  GET_MY_DRIVER_BATCHES_FAILURE,
  GET_MY_DRIVER_BATCHES_SUCCESS,
  IClearMyDriverBatchesActionType,
  IGetMyDriverBatchesActionType,
  IGetMyDriverBatchesFailureActionType,
  IGetMyDriverBatchesSuccessActionType,
} from "slices/myDriverBatches/myDriverBatches.actions";

import { Reducer } from "redux";
import { defaultState } from "default-state";
import { IPackageStoreType } from "slices/package/package.reducer";
import { Notes } from "slices/pendingRating/pendingRating.reducers";

export interface IDriverBatchWindow {
  deliveryStartDate: string;
  formattedDeliveryStartDate: string;
  formattedDeliveryEndDate: string;
  batches: IDriverBatch[];
}

export interface IDriverBatch {
  warehouseTimezone: string;
  driverBatchId: number;
  buildingId: number;
  warehouseId: number;
  buildingName: string;
  buildingShortCode: string;
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
  unitMapUrl?: string;
  deliveryStartDate: string;
  deliveryEndDate: string;
  stops: Stop[];
}

export interface Stop {
  packages: IPackageStoreType[];
  packageDeliveryPreference: number;
  deliveryMethod: number;
  status: number;
  firstName: string;
  lastName: string;
  unitNumber: string;
  deliveryNotes: Notes;
  lastModifiedDate: string;
}

export type MyDriverBatchesStoreType = IDriverBatch[];

function clearMyDriverBatches(): MyDriverBatchesStoreType {
  return defaultState.myDriverBatches;
}

function getMyDriverBatchesSuccess(
  action: IGetMyDriverBatchesSuccessActionType,
): MyDriverBatchesStoreType {
  return action.payload;
}
function getMyDriverBatchesFailure(): MyDriverBatchesStoreType {
  return defaultState.myDriverBatches;
}

export const myDriverBatches: Reducer<IDriverBatch[]> = (
  state = defaultState.myDriverBatches,
  action: MyDriverBatchesActionTypes,
) => {
  switch (action.type) {
    case CLEAR_MY_DRIVER_BATCHES:
      return clearMyDriverBatches();
    case GET_MY_DRIVER_BATCHES_SUCCESS:
      return getMyDriverBatchesSuccess(action);
    case GET_MY_DRIVER_BATCHES_FAILURE:
      return getMyDriverBatchesFailure();
    default:
      return state;
  }
};

export type MyDriverBatchesActionTypes =
  | IClearMyDriverBatchesActionType
  | IGetMyDriverBatchesActionType
  | IGetMyDriverBatchesSuccessActionType
  | IGetMyDriverBatchesFailureActionType;
