export const START_LOAD = "START_LOAD";
export const END_LOAD = "END_LOAD";

export interface IStartLoadActionType {
  type: typeof START_LOAD;
  payload: string;
}

export interface IEndLoadActionType {
  type: typeof END_LOAD;
  payload: string;
}

function endLoad(name: string) {
  return {
    payload: name,
    type: END_LOAD,
  };
}
function startLoad(name: string) {
  return {
    payload: name,
    type: START_LOAD,
  };
}

export const loadingActions = {
  endLoad,
  startLoad,
};
