import { takeLatest, takeLeading } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { networkActions } from "slices/authentication/network.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  IPostRatingActionType,
  pendingRatingActions,
  GET_PENDING_RATING,
  POST_RATING,
} from "./pendingRating.actions";
import { PendingRating } from "./pendingRating.reducers";
import { packagesService } from "services/hermes/packages";

function* getPendingRatingWorker() {
  yield put(pendingRatingActions.clearPendingRating());
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      packagesService.getPendingRating,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const pendingRating: PendingRating = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    if (Object.keys(pendingRating).length > 0) {
      yield put(pendingRatingActions.getPendingRatingSuccess(pendingRating));
    }
  } catch (error) {}
}

function* postRatingWorker(action: IPostRatingActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      packagesService.postRating,
      action.rating,
    );
    yield call(networkActions.makeAuthenticatedRequest, requestOptions, true);
    yield put(pendingRatingActions.clearPendingRating());
  } catch (error) {
    toast.error("An error occurred. Please try again!");
    yield put(pendingRatingActions.getPendingRating());
  }
}

export const pendingRatingSagas = function* () {
  yield takeLatest(GET_PENDING_RATING, getPendingRatingWorker);
  yield takeLeading(POST_RATING, postRatingWorker);
};
