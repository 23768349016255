import { LabelImageLoggingResponse } from "./labelImage.reducer";

export const CLEAR_LABEL_IMAGE = "CLEAR_LABEL_IMAGE";
export const GET_LABEL_IMAGE = "GET_LABEL_IMAGE";
export const GET_LABEL_IMAGE_SUCCESS = "GET_LABEL_IMAGE_SUCCESS";
export const GET_LABEL_IMAGE_FAILURE = "GET_LABEL_IMAGE_FAILURE";
export const OPEN_LABEL_IMAGE = "OPEN_LABEL_IMAGE";

export interface ClearLabelImageAction {
  type: typeof CLEAR_LABEL_IMAGE;
  payload: {
    packageId: string;
  };
}

export interface GetLabelImageAction {
  type: typeof GET_LABEL_IMAGE;
  payload: {
    packageId: string;
    forceRefresh?: boolean;
  };
}

export interface OpenLabelImageAction {
  type: typeof OPEN_LABEL_IMAGE;
  payload: {
    packageId: string;
  };
}
export interface GetLabelImageSuccessAction {
  type: typeof GET_LABEL_IMAGE_SUCCESS;
  payload: LabelImageLoggingResponse;
}

export interface GetLabelImageFailureAction {
  type: typeof GET_LABEL_IMAGE_FAILURE;
  payload: {
    packageId: string;
  };
}

function clearLabelImage(packageId: string): ClearLabelImageAction {
  return { type: CLEAR_LABEL_IMAGE, payload: { packageId } };
}

function getLabelImage(packageId: string): GetLabelImageAction {
  return { type: GET_LABEL_IMAGE, payload: { packageId } };
}

function openLabelImage(packageId: string): OpenLabelImageAction {
  return { type: OPEN_LABEL_IMAGE, payload: { packageId } };
}

function getLabelImageSuccess(
  response: LabelImageLoggingResponse,
): GetLabelImageSuccessAction {
  return { type: GET_LABEL_IMAGE_SUCCESS, payload: response };
}

function getLabelImageFailure(packageId: string): GetLabelImageFailureAction {
  return { type: GET_LABEL_IMAGE_FAILURE, payload: { packageId } };
}

export const labelImageActions = {
  getLabelImage,
  openLabelImage,
  clearLabelImage,
  getLabelImageSuccess,
  getLabelImageFailure,
};
