import { CurrentUserState } from "slices/currentUser/currentUser.reducer";
import { UserPermissions } from "types/UserPermissions";
import { UserPrivileges } from "types/UserPrivileges";
import { assertUserHasPrivilege } from "./data/assertUserHasPrivilege.helper";
import { FeatureFlags } from "constants/feature-flags";

export function userHasFeatureFlags(
  currentUser: CurrentUserState,
  featureFlags?: FeatureFlags[],
  permitIfOneOrMoreFeatureFlagsPresent?: boolean,
): boolean {
  if (!featureFlags || featureFlags.length === 0) {
    return true;
  }
  if (permitIfOneOrMoreFeatureFlagsPresent) {
    return featureFlags.some((featureFlag) =>
      Boolean(currentUser?.features?.[featureFlag]),
    );
  } else {
    return featureFlags.every((featureFlag) =>
      Boolean(currentUser?.features?.[featureFlag]),
    );
  }
}

export function userHasPermission(
  currentUser: CurrentUserState,
  permissions: UserPermissions,
): boolean {
  if (!!currentUser) {
    return Object.keys(permissions).reduce(
      (hasPermission: boolean, userType): boolean => {
        switch (userType) {
          case "adminUser":
            if (currentUser.isAdminUser) {
              return true;
            }
            break;
          case "warehouseUser":
            if (
              currentUser.warehouseUser &&
              currentUser.warehouseUser.warehouseUserId
            ) {
              return true;
            }
            break;
          case "driverUser":
            if (currentUser.driverUser && currentUser.driverUser.driverUserId) {
              return true;
            }
            break;
          case "residentialUser":
            if (
              currentUser.residentialUser &&
              currentUser.residentialUser.residentialUserId
            ) {
              return true;
            }
            break;
        }
        return hasPermission;
      },
      false,
    );
  } else {
    return false;
  }
}

export function userHasAUserType(currentUser?: CurrentUserState): boolean {
  if (currentUser) {
    const { residentialUser, warehouseUser, driverUser, isAdminUser } =
      currentUser;

    const isResidentialUser =
      residentialUser && residentialUser.residentialUserId;
    const isWarehouseUser = warehouseUser && warehouseUser.warehouseUserId;
    const isDriverUser = driverUser && driverUser.driverUserId;

    if (isResidentialUser || isWarehouseUser || isDriverUser || isAdminUser) {
      return true;
    }
  }
  return false;
}

export function userHasPrivileges(
  currentUser: CurrentUserState,
  privileges?: UserPrivileges[],
): boolean {
  if (!privileges) return true;
  if (!currentUser) return false;
  const result = Boolean(
    privileges.reduce((previousValue, currentPrivilege) => {
      return Boolean(
        previousValue && assertUserHasPrivilege(currentUser, currentPrivilege),
      );
    }, true),
  );
  return result;
}
