import React from "react";

import { BEM } from "helpers/BEM.helper";
import { FetchConnect } from "helpers/FetchConnect";

export interface FetchTermsLinkProps {
  url: string;
}

export const FetchTermsLink: React.FC<FetchTermsLinkProps> = (
  props: FetchTermsLinkProps,
) => {
  const { url } = props;

  const classes = new BEM({
    block: { name: "FetchTermsLink" },
    elements: [{ name: "link" }],
    prefix: { name: "fetch" },
  });

  return (
    <a
      className={classes.getElementClassNames("link")}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms & Conditions
    </a>
  );
};

export default FetchConnect({
  ConnectedComponent: FetchTermsLink,
  state: { stores: ["applicationSettings"] },
});
