import { useSelector } from "react-redux";
import { IRootStateType } from "types/IRootStateType";

export function useFetchSelector<Key extends keyof IRootStateType>(
  key: Key,
  options?: {
    enableLogging?: boolean;
  },
): IRootStateType[Key] {
  const selectedValue = useSelector<IRootStateType, IRootStateType[Key]>(
    (store) => {
      const selectedValue = store?.[key];

      if (options?.enableLogging) {
        // console.group("useFetchSelector");
        // console.table({
        //   selectedValue,
        // });
        // console.groupEnd();
      }

      return selectedValue;
    },
  );
  return selectedValue;
}
