/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  IUserStoreType,
  IResidentialUserStoreType,
  AutoScheduleOptions,
} from "slices/currentUser/currentUser.reducer";
import { IDriverUserAuditHistoryStoreType } from "slices/driverUserAuditHistory/driverUserAuditHistory.reducer";

// Users
export const CLEAR_USER = "CLEAR_USER";
export const GET_USER = "GET_USER";
export const SAVE_USER = "SAVE_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_ADMIN_USER = "GET_ADMIN_USER";
export const GET_ADMIN_USER_SUCCESS = "GET_ADMIN_USER_SUCCESS";

export const GET_DRIVER_USER_AUDIT_HISTORY = "GET_DRIVER_USER_AUDIT_HISTORY";
export const GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS =
  "GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS";

export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";

export const POST_RESET_PASSWORD = "POST_RESET_PASSWORD";
export const POST_RESET_PASSWORD_SUCCESS = "POST_RESET_PASSWORD_SUCCESS";
export const POST_RESET_PASSWORD_FAILURE = "POST_RESET_PASSWORD_FAILURE";
export const ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS =
  "ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS";
export const ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS_SUCCESS =
  "ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS_SUCCESS";
export const DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID =
  "DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID";
export const DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_SUCCESS =
  "DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_SUCCESS";
export const DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_FAILURE =
  "DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_FAILURE";

export const SAVE_RESIDENTIAL_USER = "SAVE_RESIDENTIAL_USER";
export const SAVE_RESIDENTIAL_USER_SUCCESS = "SAVE_RESIDENTIAL_USER_SUCCESS";

export interface ISaveUserActionType {
  type: typeof SAVE_USER;
  payload: IUserStoreType;
}

export interface ISaveResidentialUserActionType {
  type: typeof SAVE_RESIDENTIAL_USER;
  payload: Partial<IResidentialUserStoreType>;
  systemId: string;
}
export interface ISaveResidentialUserSuccessActionType {
  type: typeof SAVE_RESIDENTIAL_USER_SUCCESS;
  payload: IResidentialUserStoreType;
}

export interface IDeleteUsersAdminByEncodedSystemIdActionType {
  type: typeof DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID;
  encodedSystemId: string;
}
export interface IDeleteUsersAdminByEncodedSystemIdSuccessActionType {
  type: typeof DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_SUCCESS;
}
export interface IDeleteUsersAdminByEncodedSystemIdFailureActionType {
  type: typeof DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_FAILURE;
}

export interface IAdminGetUserAutoScheduleOptionsActionType {
  type: typeof ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS;
  encodedSystemId: string;
}
export interface IAdminGetUserAutoScheduleOptionsSuccessActionType {
  type: typeof ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS_SUCCESS;
  response: AutoScheduleOptions;
}

export interface IPostResetPasswordPayloadType {
  temporaryPassword: string;
}
export interface IPostResetPasswordSuccessPayloadType {
  userId: number;
  temporaryPassword: null;
  cognitoStatus: "FORCE_CHANGE_PASSWORD";
  enabled: true;
}

export interface IPostResetPasswordActionType {
  type: typeof POST_RESET_PASSWORD;
  payload: IPostResetPasswordPayloadType;
}
export interface IPostResetPasswordSuccessActionType {
  type: typeof POST_RESET_PASSWORD_SUCCESS;
  payload: IPostResetPasswordSuccessPayloadType;
}
export interface IPostResetPasswordFailureActionType {
  type: typeof POST_RESET_PASSWORD_FAILURE;
}

export interface IGetDriverUserAuditHistoryActionType {
  type: typeof GET_DRIVER_USER_AUDIT_HISTORY;
  systemId: string;
}

export interface IGetDriverUserAuditHistoryPayloadType {
  systemId: string;
}

export interface IGetDriverUserAuditHistorySuccessActionType {
  type: typeof GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS;
  payload: IDriverUserAuditHistoryStoreType;
}

export interface IGetAdminUserActionType {
  type: typeof GET_ADMIN_USER;
  payload: string;
}

export interface IGetAdminUserSuccessActionType {
  type: typeof GET_ADMIN_USER_SUCCESS;
  payload: IUserStoreType;
}

export interface IGetUserActionType {
  type: string;
  payload: string;
}

function adminGetUserAutoScheduleOptions(
  encodedSystemId: string,
): IAdminGetUserAutoScheduleOptionsActionType {
  return { type: ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS, encodedSystemId };
}
function adminGetUserAutoScheduleOptionsSuccess(
  response: AutoScheduleOptions,
): IAdminGetUserAutoScheduleOptionsSuccessActionType {
  return {
    type: ADMIN_GET_USER_AUTO_SCHEDULE_OPTIONS_SUCCESS,
    response,
  };
}

function deleteUsersAdminByEncodedSystemId(encodedSystemId: string) {
  return { type: DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID, encodedSystemId };
}
function deleteUsersAdminByEncodedSystemIdSuccess() {
  return {
    type: DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_SUCCESS,
  };
}
function deleteUsersAdminByEncodedSystemIdFailure() {
  return {
    type: DELETE_USERS_ADMIN_BY_ENCODED_SYSTEM_ID_FAILURE,
  };
}

function saveUser(formData: IUserStoreType) {
  return { type: SAVE_USER, payload: guaranteeHasRoleBooleanValues(formData) };
}
function guaranteeHasRoleBooleanValues(
  formData: IUserStoreType,
): IUserStoreType {
  return {
    ...formData,
    hasRoleWarehouse: !!formData.hasRoleWarehouse,
    hasRoleDriver: !!formData.hasRoleDriver,
    hasRoleAdministrator: !!formData.hasRoleAdministrator,
    hasRoleResident: !!formData.hasRoleResident,
  };
}
function saveUserSuccess(response: IUserStoreType) {
  return { type: SAVE_USER_SUCCESS, payload: response };
}

function saveResidentialUser(
  systemId: string,
  formData: Partial<IResidentialUserStoreType>,
): ISaveResidentialUserActionType {
  return { type: SAVE_RESIDENTIAL_USER, payload: formData, systemId };
}
function saveResidentialUserSuccess(
  response: IResidentialUserStoreType,
): ISaveResidentialUserSuccessActionType {
  return { type: SAVE_RESIDENTIAL_USER_SUCCESS, payload: response };
}

function postResetPassword(
  payload: IPostResetPasswordPayloadType,
): IPostResetPasswordActionType {
  return { type: POST_RESET_PASSWORD, payload };
}
function postResetPasswordSuccess(
  response: IPostResetPasswordSuccessPayloadType,
): IPostResetPasswordSuccessActionType {
  return { type: POST_RESET_PASSWORD_SUCCESS, payload: response };
}
function postResetPasswordFailure(): IPostResetPasswordFailureActionType {
  return { type: POST_RESET_PASSWORD_FAILURE };
}

// ACTION TRIGGER
function getDriverUserAuditHistory(
  systemId: string,
): IGetDriverUserAuditHistoryActionType {
  return { type: GET_DRIVER_USER_AUDIT_HISTORY, systemId };
}

// SUCCESS HANDLER
function getDriverUserAuditHistorySuccess(
  payload: IDriverUserAuditHistoryStoreType,
): IGetDriverUserAuditHistorySuccessActionType {
  return { type: GET_DRIVER_USER_AUDIT_HISTORY_SUCCESS, payload };
}

function getUser(userId: string) {
  return { type: GET_USER, payload: userId };
}
function getUserSuccess(response: IUserStoreType) {
  return { type: GET_USER_SUCCESS, payload: response };
}

function clearUser() {
  return { type: CLEAR_USER };
}

function getAdminUser(userId: string): IGetAdminUserActionType {
  return { type: GET_ADMIN_USER, payload: userId };
}

function getAdminUserSuccess(
  response: IUserStoreType,
): IGetAdminUserSuccessActionType {
  return { type: GET_ADMIN_USER_SUCCESS, payload: response };
}

export const userActions = {
  clearUser,
  deleteUsersAdminByEncodedSystemId,
  getAdminUser,
  getUser,
  getDriverUserAuditHistory,
  postResetPassword,
  saveResidentialUser,
  saveUser,
  adminGetUserAutoScheduleOptions,
  getUserSuccess,
  getAdminUserSuccess,
  adminGetUserAutoScheduleOptionsSuccess,
  deleteUsersAdminByEncodedSystemIdSuccess,
  deleteUsersAdminByEncodedSystemIdFailure,
  saveUserSuccess,
  saveResidentialUserSuccess,
  postResetPasswordSuccess,
  postResetPasswordFailure,
  getDriverUserAuditHistorySuccess,
};
