import { all } from "redux-saga/effects";

import { authenticationSagas } from "slices/authentication/authentication.sagas";
import { batchSagas } from "slices/batch/batch.sagas";
import { batchesSagas } from "slices/batches/batches.sagas";
import { buildingSagas } from "slices/building/building.sagas";
import { buildingsSagas } from "slices/buildings/buildings.sagas";
import { loggingAnalysisJobSagas } from "slices/loggingAnalysisJob/loggingAnalysisJob.sagas";
import { myDriverBatchesSagas } from "slices/myDriverBatches/myDriverBatches.sagas";
import { uiSagas } from "slices/ui/ui.sagas";
import { packageSagas } from "slices/package/package.sagas";
import { packageAuditTrailSagas } from "slices/packageAuditTrail/packageAuditTrail.sagas";
import { packageDeliveryReceiptsSagas } from "slices/packageDeliveryReceipts/packageDeliveryReceipts.sagas";
import { packagesSagas } from "slices/packages/packages.sagas";
import { scheduledDeliverySagas } from "slices/scheduledDelivery/scheduledDelivery.sagas";
import { userSagas } from "slices/user/user.sagas";
import { usersSagas } from "slices/users/users.sagas";
import { warehouseSagas } from "slices/warehouse/warehouse.sagas";
import { warehouseLocationSagas } from "slices/warehouseLocation/warehouseLocation.sagas";
import { warehouseLocationsSagas } from "slices/warehouseLocations/warehouseLocations.sagas";
import { warehousePrintersSagas } from "slices/warehousePrinters/warehousePrinters.sagas";
import { warehousesSagas } from "slices/warehouses/warehouses.sagas";
import { quickSearchSagas } from "slices/quickSearch";
import { deliveryWindowsSagas } from "slices/deliveryWindows/deliveryWindows.sagas";
import { myPackageSagas } from "slices/myPackage/myPackage.sagas";
import { pendingRatingSagas } from "slices/pendingRating/pendingRating.sagas";
import { availableDeliveryWindowsSagas } from "slices/availableDeliveryWindows/availableDeliveryWindows.sagas";
import {
  createLabelBundleSagas,
  printLabelBundleSagas,
} from "slices/labelBundle";
import { searchHermesSagas } from "slices/searchHermes/searchHermes.sagas";
import { pricesSagas } from "slices/prices/prices.sagas";
import { couponSagas } from "slices/coupon/coupon.sagas";
import { subscriptionIntentSagas } from "slices/subscriptionIntent/subscriptionIntent.sagas";
import { subscriptionsSagas } from "slices/subscriptions/subscriptions.sagas";
import { metaSchemaSagas } from "slices/metaSchema/metaSchema.sagas";
import { buildingsByMarketSagas } from "slices/buildingsByMarket/buildingsByMarket.sagas";
import { cancelledScheduledDeliveryOutcomesSagas } from "slices/cancelledScheduledDeliveryOutcomes/cancelledScheduledDeliveryOutcomes.sagas";
import { driverUserAuditHistorySagas } from "slices/driverUserAuditHistory/driverUserAuditHistory.sagas";
import { residentialUserSagas } from "slices/residentialUser/residentialUser.sagas";
import { packageWarehouseLocationHistorySagas } from "slices/packageWarehouseLocationHistory/packageWarehouseLocationHistory.sagas";
import { myPackagesSagas } from "slices/myPackages/myPackages.sagas";
import { labelImageSagas } from "slices/labelImage/labelImage.sagas";
import { marketsSagas } from "slices/markets/markets.sagas";
import { packageDeliveryTimesSagas } from "slices/packageDeliveryTimes/packageDeliveryTimes.sagas";
import { packageDeliveryWindowOptionsSagas } from "slices/packageDeliveryWindowOptions/packageDeliveryWindowOptions.sagas";
import { warehouseUserSagas } from "slices/warehouseUser/warehouseUser.sagas";
import { residentialUserAuditHistorySagas } from "slices/residentialUserAuditHistory/residentialUserAuditHistory.sagas";
import { scheduleHistorySagas } from "slices/scheduleHistory/scheduleHistory.sagas";
import { userAuditHistorySagas } from "slices/userAuditHistory/userAuditHistory.sagas";
import { labelAnalysisQuerySagas } from "slices/labelAnalysisQuery/labelAnalysisQuery.sagas";
import { currentUserSagas } from "slices/currentUser/currentUser.sagas";
import { labelAnalysisSummarySagas } from "slices/labelAnalysisSummary/LabelAnalysisSummary.actions.sagas";
import { CSATData } from "slices/csatData";
import { wfmDeliveryWindowSchedule } from "slices/wfmDeliveryWindowSchedule";
import { getFetchPackagesByIdLabelImage } from "services/hermesEndpointSagas/getFetchPackagesByIdLabelImage";
import { myPackageLabelsByFetchPackageId } from "slices/myPackageLabelsByFetchPackageId";
import { getFetchPackagesLabelsFullTextSearchSagas } from "slices/fullTextSearch/FullTextSearch.sagas";
import { packageByIdSaga } from "slices/package/packageById.sagas";
import { getWfmResourcesTemplateWindows } from "slices/getWfmResourcesTemplateWindows";
import { postWfmResourcesTemplateWindows } from "apis/hermes/postWfmResourcesTemplateWindows";
import { automatedRoutesSagas } from "slices/automatedRoutes/automatedRoutes.sagas";
import { userRolePrivilegesSaga } from "slices/userRolePrivileges/userRolePrivilegesSaga";
import { userRolesSaga } from "slices/userRoles/userRolesSaga";
import { userRoleSaga } from "slices/userRole/userRoleSaga";
import { userRoleMembershipSaga } from "slices/userRoles/userRoleMembershipSaga";

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/

export function* rootSaga() {
  yield all([
    authenticationSagas(),
    automatedRoutesSagas(),
    batchSagas(),
    batchesSagas(),
    buildingSagas(),
    buildingsByMarketSagas(),
    buildingsSagas(),
    loggingAnalysisJobSagas(),
    myPackageSagas(),
    packageSagas(),
    cancelledScheduledDeliveryOutcomesSagas(),
    userSagas(),
    residentialUserSagas(),
    usersSagas(),
    warehouseSagas(),
    warehouseLocationSagas(),
    warehouseLocationsSagas(),
    warehousePrintersSagas(),
    warehousesSagas(),
    uiSagas(),
    packageDeliveryReceiptsSagas(),
    packageAuditTrailSagas(),
    packagesSagas(),
    scheduledDeliverySagas(),
    myDriverBatchesSagas(),
    quickSearchSagas(),
    deliveryWindowsSagas(),
    pendingRatingSagas(),
    availableDeliveryWindowsSagas(),
    printLabelBundleSagas(),
    createLabelBundleSagas(),
    searchHermesSagas(),
    pricesSagas(),
    couponSagas(),
    subscriptionIntentSagas(),
    subscriptionsSagas(),
    metaSchemaSagas(),
    driverUserAuditHistorySagas(),
    packageWarehouseLocationHistorySagas(),
    myPackagesSagas(),
    labelImageSagas(),
    marketsSagas(),
    packageDeliveryTimesSagas(),
    packageDeliveryWindowOptionsSagas(),
    warehouseUserSagas(),
    residentialUserAuditHistorySagas(),
    scheduleHistorySagas(),
    userAuditHistorySagas(),
    labelAnalysisQuerySagas(),
    currentUserSagas(),
    labelAnalysisSummarySagas(),
    CSATData.saga(),
    wfmDeliveryWindowSchedule.saga(),
    getFetchPackagesByIdLabelImage.saga(),
    myPackageLabelsByFetchPackageId.saga(),
    getFetchPackagesLabelsFullTextSearchSagas(),
    packageByIdSaga(),
    getWfmResourcesTemplateWindows.saga(),
    postWfmResourcesTemplateWindows.saga(),
    userRolePrivilegesSaga(),
    userRolesSaga(),
    userRoleSaga(),
    userRoleMembershipSaga(),
  ]);
}
