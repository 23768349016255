import {
  CLEAR_ALL_ERROR_MESSAGES,
  ADD_ERROR_MESSAGE,
  IAddErrorMessageType,
  IClearAllErrorsMessageType,
} from "./errorMessages.actions";

export interface IErrorMessagesStateType {
  [errorKey: string]: string;
}

export function errorMessages(
  state = {},
  action: ErrorMessagesActionTypes,
): Partial<IErrorMessagesStateType> {
  switch (action.type) {
    case ADD_ERROR_MESSAGE:
      return { ...state, [action.payload.errorKey]: action.payload.text };
    case CLEAR_ALL_ERROR_MESSAGES:
      return {};
    default:
      return state;
  }
}

export type ErrorMessagesActionTypes =
  | IAddErrorMessageType
  | IClearAllErrorsMessageType;
