import { defaultState } from "default-state";
import {
  CLEAR_AUTOMATED_ROUTES,
  GET_AUTOMATED_ROUTES_FAILURE,
  GET_AUTOMATED_ROUTES_SUCCESS,
  IClearAutomatedRoutesActionType,
  IGetAutomatedRoutesFailureActionType,
  IGetAutomatedRoutesSuccessActionType,
} from "./automatedRoutes.actions";

export interface IAutomatedRoute {
  routeBuildingIds: Array<number>;
  totalRouteSeconds: number;
  totalRoutePackages: number;
  totalRouteMiles: number;
}

export interface IAutomatedRoutesStoreType {
  routes: Array<IAutomatedRoute>;
  totalBuildingsRouted: number;
  totalBuildingsSkipped: number;
}

export function automatedRoutes(
  state = {},
  action: AutomatedRoutesActionTypes,
): Partial<IAutomatedRoutesStoreType> {
  switch (action.type) {
    case CLEAR_AUTOMATED_ROUTES:
      return defaultState.automatedRoutes;
    case GET_AUTOMATED_ROUTES_SUCCESS:
      return action.payload;
    case GET_AUTOMATED_ROUTES_FAILURE:
      return defaultState.automatedRoutes;
    default:
      return state;
  }
}

// type definitions
type AutomatedRoutesActionTypes =
  | IGetAutomatedRoutesFailureActionType
  | IGetAutomatedRoutesSuccessActionType
  | IClearAutomatedRoutesActionType;
