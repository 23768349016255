import React from "react";

import { Button, Form } from "reactstrap";
import { Field, InjectedFormProps } from "redux-form";

import { BEM } from "helpers/BEM.helper";
import { FetchFormConnect } from "helpers/FetchFormConnect";
import { FetchInput } from "views/common/forms/fields/FetchInput";

export type UserAttributeVerificationFormProps = InjectedFormProps<{
  code?: string;
}>;

export const UserAttributeVerificationForm: React.FunctionComponent<
  UserAttributeVerificationFormProps
> = ({ handleSubmit }: UserAttributeVerificationFormProps) => {
  const classes = new BEM({
    block: { name: "attributeVerificationForm" },
    elements: [{ name: "code" }, { name: "submit" }],
    prefix: { name: "form" },
  });

  return (
    <Form onSubmit={handleSubmit} className="flex-column">
      <Field
        {...{
          className: classes.getElementClassNames("code"),
          component: FetchInput,
          name: "code",
          type: "text",
        }}
      />
      <Button
        color="info"
        className={classes.getElementClassNames("submit")}
        type="submit"
      >
        Submit
      </Button>
    </Form>
  );
};

export default FetchFormConnect({
  FormComponent: UserAttributeVerificationForm,
  formOptions: {
    form: "verifyUserAttributeForm",
    validate: () => ({}),
  },
  state: { stores: [] },
});
