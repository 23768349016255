import { Subscription } from "./subscriptions.reducer";

export const CLEAR_SUBSCRIPTIONS = "CLEAR_SUBSCRIPTIONS";
export const GET_MY_SUBSCRIPTIONS = "GET_MY_SUBSCRIPTIONS";
export const GET_MY_SUBSCRIPTIONS_SUCCESS = "GET_MY_SUBSCRIPTIONS_SUCCESS";

export interface ClearSubscriptionsAction {
  type: typeof CLEAR_SUBSCRIPTIONS;
}

export interface GetMySubscriptionsAction {
  type: typeof GET_MY_SUBSCRIPTIONS;
}

export interface GetMySubscriptionsResponse {
  subscriptions: Subscription[];
}

export interface GetMySubscriptionsSuccessAction {
  type: typeof GET_MY_SUBSCRIPTIONS_SUCCESS;
  payload: GetMySubscriptionsResponse;
}

function clearSubscriptions(): ClearSubscriptionsAction {
  return { type: CLEAR_SUBSCRIPTIONS };
}
function getMySubscriptions(): GetMySubscriptionsAction {
  return { type: GET_MY_SUBSCRIPTIONS };
}

function getMySubscriptionsSuccess(
  response: GetMySubscriptionsResponse,
): GetMySubscriptionsSuccessAction {
  return {
    type: GET_MY_SUBSCRIPTIONS_SUCCESS,
    payload: response,
  };
}

export const subscriptionsActions = {
  getMySubscriptions,
  clearSubscriptions,
  getMySubscriptionsSuccess,
};
