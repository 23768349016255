import { ScheduleOverrideRequest } from "slices/deliveryWindows/deliveryWindows.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { Filter } from "react-table-6";
import { ISearchDeliveryWindowsPayloadType } from "slices/deliveryWindows/deliveryWindows.actions";
import { generateQueryString } from "helpers/generateQueryString.helper";

function searchDeliveryWindows(
  query: ISearchDeliveryWindowsPayloadType,
): IAuthenticatedRequestDefinition {
  const filters: Filter[] = Object.keys(query).map((key) => ({
    id: key,
    value: query[key],
  }));
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/deliveryWindows/schedule${generateQueryString({ filters })}`,
  };
}

function createScheduleOverrides(
  requestBody: ScheduleOverrideRequest,
): IAuthenticatedRequestDefinition {
  return {
    body: requestBody.scheduleOverride,
    requestOptions: { method: "PUT" },
    requestUrl: "/deliveryWindows/scheduleOverrides",
  };
}

export const deliveryWindowsServices = {
  searchDeliveryWindows,
  createScheduleOverrides,
};
