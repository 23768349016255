import { takeLatest, ForkEffect, call, put } from "redux-saga/effects";

import { loadingActions } from "slices/loading/loading.actions";
import {
  keystrokeDelay,
  networkActions,
} from "slices/authentication/network.actions";
import {
  ISearchUsersActionType,
  ISearchUsersByIdActionType,
  SEARCH_USERS,
  SEARCH_USERS_BY_ID,
  usersActions,
} from "./users.actions";
import { usersService } from "services/hermes/users";
import { IUsersStoreContentType, IUsersStoreType } from "./users.reducer";

function* searchUsersWorker(action: ISearchUsersActionType) {
  yield put(loadingActions.startLoad(action.type));
  yield put(usersActions.clearUsers());
  try {
    yield keystrokeDelay();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof usersService.searchUsers> =
      yield call(usersService.searchUsers, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IUsersStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(usersActions.searchUsersSuccess(response));
  } catch (error) {
    yield put(usersActions.searchUsersFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

function* searchUsersByIdWorker(action: ISearchUsersByIdActionType) {
  yield put(loadingActions.startLoad(action.type));
  try {
    yield keystrokeDelay();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof usersService.searchUsers> =
      yield call(usersService.searchUsersById, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IUsersStoreContentType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );

    // Assumption - any response with a userId matches IUsersStoreContentType
    if (response.userId) {
      yield put(usersActions.searchUsersByIdSuccess(response));
    } else {
      yield put(usersActions.searchUsersFailure());
    }
  } catch (error) {
    yield put(usersActions.searchUsersFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

export const usersSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(SEARCH_USERS, searchUsersWorker);
  yield takeLatest(SEARCH_USERS_BY_ID, searchUsersByIdWorker);
};
