import { call, put, takeLatest } from "redux-saga/effects";
import {
  getUserRoleMembershipFetch,
  getUserRoleMembershipFailure,
  getUserRoleMembershipSuccess,
  saveUserRoleMembershipPost,
  saveUserRoleMembershipFailure,
  saveUserRoleMembershipSuccess,
} from "./userRoleMembershipState";
import {
  UserRoleMembershipResponseDTO,
  userRolesService,
} from "services/hermes/userRoles";
import { networkActions } from "slices/authentication/network.actions";

function* workerGetUserRoleMembership(
  action: ReturnType<typeof getUserRoleMembershipFetch>,
) {
  try {
    const requestOptions = (yield call(
      userRolesService.getUserRoleMembership,
      action.payload.userId,
    )) as ReturnType<typeof userRolesService.getUserRoleMembership>;
    const response = (yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    )) as UserRoleMembershipResponseDTO[];

    yield put(
      getUserRoleMembershipSuccess({
        userId: action.payload.userId,
        roleMembership: response,
      }),
    );
  } catch (e) {
    let msg = "Error while loading User Roles";
    if (e instanceof Error || typeof e === "string") {
      msg = e.toString();
    }
    yield put(
      getUserRoleMembershipFailure({
        error: msg,
        userId: action.payload.userId,
      }),
    );
  }
}

function* workerSaveUserRoleMembership(
  action: ReturnType<typeof saveUserRoleMembershipPost>,
) {
  try {
    const requestOptions = (yield call(
      userRolesService.saveUserRoleMembership,
      action.payload.userId,
      action.payload.roleIds,
      action.payload.action,
    )) as ReturnType<typeof userRolesService.saveUserRoleMembership>;
    const response = (yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    )) as UserRoleMembershipResponseDTO[];

    yield put(
      saveUserRoleMembershipSuccess({
        userId: action.payload.userId,
        roleMembership: response,
      }),
    );
  } catch (e) {
    let msg = "Error while loading User Roles";
    if (e instanceof Error || typeof e === "string") {
      msg = e.toString();
    }
    yield put(
      saveUserRoleMembershipFailure({
        error: msg,
        userId: action.payload.userId,
      }),
    );
  }
}

export function* userRoleMembershipSaga() {
  yield takeLatest(getUserRoleMembershipFetch, workerGetUserRoleMembership);
  yield takeLatest(saveUserRoleMembershipPost, workerSaveUserRoleMembership);
}
