import {
  IDeliveryWindowSearchResponse,
  ScheduleOverrideMode,
} from "./deliveryWindows.reducers";

export const SEARCH_DELIVERY_WINDOWS = "SEARCH_DELIVERY_WINDOWS";
export const SEARCH_DELIVERY_WINDOWS_SUCCESS =
  "SEARCH_DELIVERY_WINDOWS_SUCCESS";
export const CLEAR_DELIVERY_WINDOWS = "CLEAR_DELIVERY_WINDOWS";
export const CREATE_SCHEDULE_OVERRIDES = "CREATE_SCHEDULE_OVERRIDES";

export interface IClearDeliveryWindowsActionType {
  type: typeof CLEAR_DELIVERY_WINDOWS;
}

export type ISearchDeliveryWindowsPayloadType =
  | BuildingSpecificDeliveryWindowQuery
  | WarehouseSpecificDeliveryWindowQuery;
export interface DeliveryWindowQueryBase {
  startDate: string; // YYYY-MM-DD
  numberOfDays: number;
  [key: string]: string | number;
}
export interface BuildingSpecificDeliveryWindowQuery
  extends DeliveryWindowQueryBase {
  buildingId: number;
}
export interface WarehouseSpecificDeliveryWindowQuery
  extends DeliveryWindowQueryBase {
  warehouseId: number;
}
export interface ISearchDeliveryWindowsActionType {
  type: typeof SEARCH_DELIVERY_WINDOWS;
  query: ISearchDeliveryWindowsPayloadType;
}
export interface ISearchDeliveryWindowsSuccessActionType {
  type: typeof SEARCH_DELIVERY_WINDOWS_SUCCESS;
  response: IDeliveryWindowSearchResponse;
}

export interface ICreateScheduleOverridesActionType {
  type: typeof CREATE_SCHEDULE_OVERRIDES;
  request: ScheduleOverrideRequest;
}

function clearDeliveryWindows(): IClearDeliveryWindowsActionType {
  return { type: CLEAR_DELIVERY_WINDOWS };
}

function searchDeliveryWindows(
  query: ISearchDeliveryWindowsPayloadType,
): ISearchDeliveryWindowsActionType {
  return { type: SEARCH_DELIVERY_WINDOWS, query };
}

function searchDeliveryWindowsSuccess(
  response: IDeliveryWindowSearchResponse,
): ISearchDeliveryWindowsSuccessActionType {
  return { type: SEARCH_DELIVERY_WINDOWS_SUCCESS, response };
}

export type ScheduleOverrideType =
  | "warehouse"
  | "building"
  | "global"
  | "windowGroup";

type DeliveryWindowSpec = {
  deliveryWindowId: number;
  fetchOfferingType: number;
  cutOffTime: string | null;
  cutOffVolume?: number | null;
};

interface IOverrideDetails {
  mode?: ScheduleOverrideMode;
  warehouseId?: number;
  buildingId?: number;
  deliveryWindowSpecs: DeliveryWindowSpec[];
}

export type ScheduleOverrideRequest = {
  buildingId?: number;
  warehouseId?: number;
  scheduleOverride: Record<
    string,
    { [key in ScheduleOverrideType]?: IOverrideDetails[] }
  >;
};

function createScheduleOverrides(
  request: ScheduleOverrideRequest,
): ICreateScheduleOverridesActionType {
  return { type: CREATE_SCHEDULE_OVERRIDES, request };
}

export const deliveryWindowsActions = {
  clearDeliveryWindows,
  searchDeliveryWindows,
  createScheduleOverrides,
  searchDeliveryWindowsSuccess,
};
