import { takeLeading, call, put } from "redux-saga/effects";
import moment from "moment";

import { networkActions } from "slices/authentication/network.actions";
import { ISearchDeliveryWindowsPayloadType } from "slices/deliveryWindows/deliveryWindows.actions";
import { loadingActions } from "slices/loading/loading.actions";
import { addDurationToDeliveryWindows } from "helpers/data/addDurationToDeliveryWindows";
import { IRootStateType } from "types/IRootStateType";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  availableDeliveryWindowsActions,
  DeliveryWindow,
  GET_AVAILABLE_DELIVERY_WINDOWS,
  IGetAvailableDeliveryWindowsActionType,
} from "./availableDeliveryWindows.actions";
import { deliveryWindowsServices } from "services/hermes/deliveryWindows";

function* getAvailableDeliveryWindowsWorker({
  type: actionType,
  payload: { selectedDate, entityId, entityScope },
}: IGetAvailableDeliveryWindowsActionType) {
  yield put(loadingActions.startLoad(actionType));
  yield put(availableDeliveryWindowsActions.clearAvailableDeliveryWindows());
  try {
    // Get all delivery windows
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const allDeliveryWindows: DeliveryWindow[] = yield call(
      networkActions.makeAuthenticatedRequest,
      {
        requestOptions: { method: "GET" },
        requestUrl: "/deliveryWindows",
      },
    );

    const deliveryWindowsSearchQuery = {
      startDate: moment(selectedDate).format("YYYY-MM-DD"),
      numberOfDays: 1,
      [`${entityScope}Id`]: `${entityId}`,
    } as ISearchDeliveryWindowsPayloadType;
    // Get active delivery windows schedule
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const activeDeliveryWindowsRequestOptions: IAuthenticatedRequestDefinition =
      yield call(
        deliveryWindowsServices.searchDeliveryWindows,
        deliveryWindowsSearchQuery,
      );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const activeDeliveryWindows: IRootStateType["deliveryWindows"] = yield call(
      networkActions.makeAuthenticatedRequest,
      activeDeliveryWindowsRequestOptions,
    );

    // Compute available delivery windows
    if (
      !!allDeliveryWindows &&
      allDeliveryWindows.length > 0 &&
      !!activeDeliveryWindows
    ) {
      const availableDeliveryWindows: DeliveryWindow[] =
        addDurationToDeliveryWindows(allDeliveryWindows);

      yield put(
        availableDeliveryWindowsActions.getAvailableDeliveryWindowsSuccess(
          availableDeliveryWindows,
        ),
      );
    }
  } catch (error) {}
  yield put(loadingActions.endLoad(actionType));
}

export const availableDeliveryWindowsSagas = function* () {
  yield takeLeading(
    GET_AVAILABLE_DELIVERY_WINDOWS,
    getAvailableDeliveryWindowsWorker,
  );
};
