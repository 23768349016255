import { takeLatest, takeLeading, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  ISearchDeliveryWindowsActionType,
  deliveryWindowsActions,
  ICreateScheduleOverridesActionType,
  SEARCH_DELIVERY_WINDOWS,
  CREATE_SCHEDULE_OVERRIDES,
} from "./deliveryWindows.actions";
import { deliveryWindowsServices } from "services/hermes/deliveryWindows";
import { IDeliveryWindowSearchResponse } from "./deliveryWindows.reducers";

function* searchDeliveryWindowsWorker(
  action: ISearchDeliveryWindowsActionType,
) {
  const { type, query } = action;
  yield put(loadingActions.startLoad(type));
  try {
    yield put(deliveryWindowsActions.clearDeliveryWindows());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      deliveryWindowsServices.searchDeliveryWindows,
      query,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IDeliveryWindowSearchResponse = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );

    yield put(deliveryWindowsActions.searchDeliveryWindowsSuccess(response));
  } catch (error) {
    toast.error(
      "There was an error retrieving the delivery window schedule. Please refresh the page to try again.",
    );
  }
  yield put(loadingActions.endLoad(type));
}

function* createScheduleOverridesWorker({
  type,
  request,
}: ICreateScheduleOverridesActionType) {
  // start loading
  yield put(loadingActions.startLoad(type));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<
    typeof deliveryWindowsServices.createScheduleOverrides
  > = yield call(deliveryWindowsServices.createScheduleOverrides, request);
  try {
    yield call(networkActions.makeAuthenticatedRequest, requestOptions, true);
    // extract date and warehouseId from request
    const requestDate = Object.keys(request.scheduleOverride)[0];
    const warehouseId = request.warehouseId;
    const buildingId = request.buildingId;

    if (buildingId) {
      // if we are managing windows for a single building, reload that building's windows only
      yield put(
        deliveryWindowsActions.searchDeliveryWindows({
          startDate: requestDate,
          numberOfDays: 1,
          buildingId: buildingId,
        }),
      );
    } else if (warehouseId) {
      // otherwise we are managing windows for a warehouse, reload that warehouse's windows
      yield put(
        deliveryWindowsActions.searchDeliveryWindows({
          startDate: requestDate,
          numberOfDays: 1,
          warehouseId: warehouseId,
        }),
      );
    }
    // display toast
    toast.success("Window cut-off updated");
  } catch (error) {
    toast.error(
      "There was an error submitting the override. Please try again.",
    );
  }
  // end loading
  yield put(loadingActions.endLoad(type));
}

export const deliveryWindowsSagas = function* () {
  yield takeLatest(SEARCH_DELIVERY_WINDOWS, searchDeliveryWindowsWorker);
  yield takeLeading(CREATE_SCHEDULE_OVERRIDES, createScheduleOverridesWorker);
};
