import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import { applicationSettings } from "slices/applicationSettings";
import { authentication } from "slices/authentication/authentication.reducer";
import { batch } from "slices/batch/batch.reducer";
import { batches } from "slices/batches/batches.reducer";
import { building } from "slices/building/building.reducer";
import { buildingsByMarket } from "slices/buildingsByMarket/buildingsByMarket.reducer";
import { buildings } from "slices/buildings/buildings.reducer";
import { currentUser } from "slices/currentUser/currentUser.reducer";
import { driverUserAuditHistory } from "slices/driverUserAuditHistory/driverUserAuditHistory.reducer";
import { errorMessages } from "slices/errorMessages/errorMessages.reducer";
import { loading } from "slices/loading/loading.reducer";
import { loggedPackages } from "slices/loggedPackages/loggedPackages.reducer";
import { loggingAnalysisJob } from "slices/loggingAnalysisJob/loggingAnalysisJob.reducer";
import { markets } from "slices/markets/markets.reducer";
import { metaSchema } from "slices/metaSchema/metaSchema.reducer";
import { myPackages } from "slices/myPackages/myPackages.reducer";
import { myDriverBatches } from "slices/myDriverBatches/myDriverBatches.reducers";
import { packageDeliveryTimes } from "slices/packageDeliveryTimes/packageDeliveryTimes.reducer";
import { packageWarehouseLocationHistory } from "slices/packageWarehouseLocationHistory/packageWarehouseLocationHistory.reducer";
import { packageReducer } from "slices/package/package.reducer";
import { packageByIdReducer } from "slices/package/packageById.reducer";
import { packageAuditTrail } from "slices/packageAuditTrail/packageAuditTrail.reducer";
import { packageDeliveryReceipts } from "slices/packageDeliveryReceipts/packageDeliveryReceipts.reducer";
import { packageDeliveryWindowOptions } from "slices/packageDeliveryWindowOptions/packageDeliveryWindowOptions.reducers";
import { packages } from "slices/packages/packages.reducer";
import { residentialUserAuditHistory } from "slices/residentialUserAuditHistory/residentialUserAuditHistory.reducer";
import { residentialUser } from "slices/residentialUser/residentialUser.reducer";
import { scheduledDelivery } from "slices/scheduledDelivery/scheduledDelivery.reducers";
import { ui } from "slices/ui/ui.reducer";
import { userAuditHistory } from "slices/userAuditHistory/userAuditHistory.reducer";
import { user } from "slices/user/user.reducer";
import { users } from "slices/users/users.reducer";
import { warehouseFilter } from "slices/warehouseFilter";
import { warehouseUser } from "slices/warehouseUser/warehouseUser.reducer";
import { warehouse } from "slices/warehouse/warehouse.reducer";
import { warehouseLocation } from "slices/warehouseLocation/warehouseLocation.reducer";
import { warehouseLocations } from "slices/warehouseLocations/warehouseLocations.reducer";
import { warehousePrinters } from "slices/warehousePrinters/warehousePrinters.reducer";
import { warehouses } from "slices/warehouses/warehouses.reducer";
import { deliveryWindows } from "slices/deliveryWindows/deliveryWindows.reducers";
import { scheduleHistory } from "slices/scheduleHistory/scheduleHistory.reducer";
import { myPackage } from "slices/myPackage/myPackage.reducers";
import { pendingRating } from "slices/pendingRating/pendingRating.reducers";
import { cancelledScheduledDeliveryOutcomes } from "slices/cancelledScheduledDeliveryOutcomes/cancelledScheduledDeliveryOutcomes.reducers";
import { availableDeliveryWindows } from "slices/availableDeliveryWindows/availableDeliveryWindows.reducers";
import { searchHermes } from "slices/searchHermes/searchHermes.reducers";
import { prices } from "slices/prices/prices.reducer";
import { subscriptions } from "slices/subscriptions/subscriptions.reducer";
import { subscriptionIntent } from "slices/subscriptionIntent/subscriptionIntent.reducer";
import { coupon } from "slices/coupon/coupon.reducer";
import { labelImage } from "slices/labelImage/labelImage.reducer";
import { labelAnalysisQuery } from "slices/labelAnalysisQuery/labelAnalysisQuery.reducer";
import { labelAnalysisSummary } from "slices/labelAnalysisSummary/LabelAnalysisSummary.reducer";
import { myPackageLabelsByFetchPackageId } from "slices/myPackageLabelsByFetchPackageId";
import { CSATData } from "slices/csatData";
import { reducer as wfmDeliveryWindowSchedule } from "slices/wfmDeliveryWindowSchedule/reducer";
import { fullTextSearchReducer } from "slices/fullTextSearch/FullTextSearch.reducer";
import { getWfmResourcesTemplateWindows } from "slices/getWfmResourcesTemplateWindows";
import { automatedRoutes } from "slices/automatedRoutes/automatedRoutes.reducer";
import userRolePrivileges from "slices/userRolePrivileges/userRolePrivilegesState";
import userRoles from "slices/userRoles/userRolesState";
import userRole from "slices/userRole/userRoleState";
import userRoleMembership from "slices/userRoles/userRoleMembershipState";
import navigateAway from "slices/navigateAway/navigateAwayState";

export const rootReducer = combineReducers({
  applicationSettings,
  authentication,
  automatedRoutes,
  availableDeliveryWindows,
  batch,
  batches,
  building,
  buildings,
  buildingsByMarket,
  cancelledScheduledDeliveryOutcomes,
  currentUser,
  deliveryWindows,
  driverUserAuditHistory,
  errorMessages,
  form: formReducer,
  loading,
  loggedPackages,
  loggingAnalysisJob,
  metaSchema,
  markets,
  myDriverBatches,
  myPackage,
  myPackages,
  navigateAway,
  package: packageReducer,
  packageAuditTrail,
  packageDeliveryReceipts,
  packageDeliveryTimes,
  packageDeliveryWindowOptions,
  packageWarehouseLocationHistory,
  packages,
  pendingRating,
  residentialUser,
  residentialUserAuditHistory,
  scheduledDelivery,
  scheduleHistory,
  searchHermes,
  ui,
  user,
  userAuditHistory,
  users,
  warehouse,
  warehouseFilter,
  warehouseLocation,
  warehouseLocations,
  warehousePrinters,
  warehouseUser,
  warehouses,
  prices,
  subscriptions,
  subscriptionIntent,
  coupon,
  labelImage,
  labelAnalysisQuery,
  labelAnalysisSummary,
  csatData: CSATData.reducer,
  wfmDeliveryWindowSchedule,
  myPackageLabelsByFetchPackageId: myPackageLabelsByFetchPackageId.reducer,
  fullTextSearchResults: fullTextSearchReducer,
  packagesById: packageByIdReducer,
  wfmResourcesTemplateWindows: getWfmResourcesTemplateWindows.reducer,
  userRolePrivileges,
  userRoles,
  userRole,
  userRoleMembership,
});
