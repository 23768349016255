import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IUsersStoreType, IUsersStoreContentType } from "./users.reducer";

export const CLEAR_USERS = "CLEAR_USERS";
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_BY_ID = "SEARCH_USERS_BY_ID";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_BY_ID_SUCCESS = "SEACH_USERS_BY_ID_SUCCESS";
export const SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE";

export interface IClearUsersSuccessType {
  type: typeof CLEAR_USERS;
}

export interface ISearchUsersActionType {
  type: typeof SEARCH_USERS;
  payload: IPaginatedTableSearchRequestType;
}
export interface ISearchUsersByIdActionType {
  type: typeof SEARCH_USERS_BY_ID;
  payload: number;
}
export interface ISearchUsersSuccessActionType {
  type: typeof SEARCH_USERS_SUCCESS;
  payload: IUsersStoreType;
}
export interface ISearchUsersByIdSuccessActionType {
  type: typeof SEARCH_USERS_BY_ID_SUCCESS;
  payload: IUsersStoreContentType;
}
export interface ISearchUsersFailureActionType {
  type: typeof SEARCH_USERS_FAILURE;
}

function clearUsers() {
  return { type: CLEAR_USERS };
}

function searchUsers(userSearch: IPaginatedTableSearchRequestType) {
  return { type: SEARCH_USERS, payload: userSearch };
}
function searchUsersById(userId: string | number) {
  return { type: SEARCH_USERS_BY_ID, payload: userId };
}
function searchUsersSuccess(userResponse: IUsersStoreType) {
  return { type: SEARCH_USERS_SUCCESS, payload: userResponse };
}
function searchUsersByIdSuccess(singleUser: IUsersStoreContentType) {
  return { type: SEARCH_USERS_BY_ID_SUCCESS, payload: singleUser };
}
function searchUsersFailure() {
  return { type: SEARCH_USERS_FAILURE };
}

export const usersActions = {
  clearUsers,
  searchUsers,
  searchUsersById,
  searchUsersSuccess,
  searchUsersFailure,
  searchUsersByIdSuccess,
};
