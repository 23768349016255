import { call, put, takeLeading } from "redux-saga/effects";
import {
  FetchError,
  networkActions,
} from "slices/authentication/network.actions";
import { fullfilled, rejected, actionTypes } from "./csatData.actions";
import { State } from "./csatData.reducer";
import { packagesService } from "services/hermes/packages";

export const saga = function* () {
  yield takeLeading(actionTypes.request, worker);
};

const worker = function* () {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof packagesService.getMyPackages> =
      yield call(packagesService.getMyPackages, {
        page: 0,
        pageSize: 30,
        timeScope: "historical",
      });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: State["response"] = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(
      fullfilled({
        response,
      }),
    );
  } catch (error) {
    yield put(
      rejected({
        error: error as FetchError,
      }),
    );
  }
};
