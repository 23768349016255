import { Reducer } from "redux";

import { defaultState } from "default-state";
import {
  CLEAR_MY_PACKAGE,
  GET_MY_PACKAGE_SUCCESS,
  IClearMyPackageActionType,
  IGetMyPackageSuccessActionType,
} from "slices/myPackage/myPackage.actions";
import { VerifiedDeliveryCodeDTO } from "./VerifiedDeliveryCodeDTO";

export interface IMyPackageStoreType {
  fetchPackageId: number;
  sender: string;
  senderLogoUrl: string;
  carrier: number;
  carrierTracking: string;
  carrierTrackingUrl: string | null;
  carrierName: string;
  status: number;
  packageDeliveryPreference: number;
  requestedDeliveryStartDate: string | null;
  requestedDeliveryEndDate: string | null;
  deliveryWindowId?: number | null;
  fetchPackageType: number | null;
  createdDate: string;
  lastModifiedDate: string;
  cutOffTime?: string;
  deliveryMethod: number;
  verifiedDeliveryCode: VerifiedDeliveryCodeDTO | null;
  notes: {
    residentDelivery: string | null;
    residentUser: string | null;
  };
}

function getMyPackageSuccess(
  action: IGetMyPackageSuccessActionType,
): IMyPackageStoreType {
  return action.packageResponse;
}

export const myPackage: Reducer<IMyPackageStoreType | null> = (
  state = defaultState.myPackage,
  action: MyPackageActionTypes,
): IMyPackageStoreType | null => {
  switch (action.type) {
    case CLEAR_MY_PACKAGE:
      return defaultState.myPackage;
    case GET_MY_PACKAGE_SUCCESS:
      return getMyPackageSuccess(action);
    default:
      return state;
  }
};

export type MyPackageActionTypes =
  | IClearMyPackageActionType
  | IGetMyPackageSuccessActionType;
