import React from "react";

import { toast } from "react-toastify";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { Dispatch } from "redux";
import { Field, formValueSelector, InjectedFormProps } from "redux-form";
import QueryString from "qs";

import { buildingActions } from "slices/building/building.actions";
import { uiActions } from "slices/ui/ui.actions";
import { BEM } from "helpers/BEM.helper";
import { FetchNotice } from "views/common/cards/FetchNotice";
import { FetchFieldLabel } from "views/common/forms/fields/FetchFieldLabel";
import { FetchInput } from "views/common/forms/fields/FetchInput";
import { FetchSelect } from "views/common/forms/fields/FetchSelect";
import FetchPolicyLink from "views/common/links/FetchPolicyLink";
import FetchTermsLink from "views/common/links/FetchTermsLink";
import FetchModal from "views/common/modals/FetchModal";
import LeaveLiabilityWaiver from "views/components/atoms/LeaveLiabilityWaiver";
import {
  getBuildingsByMarketOptions,
  getBuildingUnitOptions,
  getMarketOptions,
} from "views/components/forms/helpers/select-options";
import { getNowInApiDateTimeString } from "helpers/date-time.helper";
import { FetchFormConnect } from "helpers/FetchFormConnect";
import { FetchToggleSwitch } from "views/common/forms/fields/FetchToggleSwitch";
import { IRootStateType } from "types/IRootStateType";
import { buildingsByMarketActions } from "slices/buildingsByMarket/buildingsByMarket.actions";
import { marketsActions } from "slices/markets/markets.actions";
import { IMarketType } from "slices/markets/markets.reducer";
import { IApplicationSettingsStoreType } from "slices/applicationSettings";
import { IBuildingByMarketStoreContentType } from "slices/buildingsByMarket/buildingsByMarket.reducer";
import { IBuildingStoreType } from "slices/building/building.reducer";
import {
  IResidentialUserStoreType,
  IUserStoreType,
} from "slices/currentUser/currentUser.reducer";
import { copy } from "../../../constants/copy";

const classes = new BEM({
  block: { name: "ResidentialUserProfile", extras: ["mt-3"] },
  elements: [
    { name: "market" },
    { name: "building" },
    { name: "apartmentNumber" },
    { name: "registrationInstructions" },
    { name: "submit" },
    { name: "logout" },
    { name: "deliveryPreference" },
  ],
  prefix: { name: "form" },
});
const deliveryPreferencesClasses = new BEM({
  block: { name: "DeliveryPreferences" },
  elements: [
    { name: "label" },
    { name: "optionsContainer" },
    { name: "toggle" },
    { name: "setByBuilding" },
    { name: "leaveWaiverCheckbox" },
    { name: "explanation" },
  ],
});
const termsAcceptedClasses = new BEM({
  block: { name: "TermsConditionsPolicy" },
  elements: [{ name: "checkbox" }, { name: "label" }],
});
const waiverModalClasses = new BEM({
  block: { name: "RegistrationLeaveLiabilityWaiverModal" },
  elements: [
    { name: "accept", extras: ["w-100"] },
    { name: "decline", extras: ["w-100"] },
    { name: "body" },
  ],
});

type ResidentialUserFormBodyProps = InjectedFormProps<
  IResidentialUserStoreType,
  IPropTypes
> &
  IPropTypes;

interface IPropTypes {
  packageDeliveryPreference?: string;
  marketValue: string;
  buildingIdValue: string;
  building: IBuildingStoreType;
  buildingsByMarket: IBuildingByMarketStoreContentType[];
  currentUser: IUserStoreType;
  markets: IMarketType[];
  applicationSettings: IApplicationSettingsStoreType;
  hideSubmit?: boolean;
  isAdminView?: boolean;
  dispatch: Dispatch;
  packageDeliveryPreferenceValue: number;
  user: IUserStoreType;
}

interface IErrorValues {
  market?: string;
  buildingId?: string;
  buildingUnitId?: string;
  packageDeliveryPreference?: string;
  userCode?: string;
  leaveLiabilityAccepted?: string;
  termsAccepted?: string;
}

type IFieldValues = IResidentialUserStoreType;

class ResidentialUserFormBody extends React.Component<ResidentialUserFormBodyProps> {
  constructor(props: ResidentialUserFormBodyProps) {
    super(props);
    // get markets
    this.props.dispatch(marketsActions.getMarkets());
    // load buildings for market if set
    this.loadBuildings();
    // load units for building if set
    this.loadUnits();
  }
  componentDidMount = () => {
    const providedBuilding = QueryString.parse(location.search, {
      ignoreQueryPrefix: true,
    }).building;
    if (typeof providedBuilding === "string") {
      this.props.dispatch(buildingActions.preLoadBuilding(providedBuilding));
    }
  };
  componentDidUpdate = (prevProps: IPropTypes) => {
    // If important props updated take action
    if (!Object.is(this.props.buildingIdValue, prevProps.buildingIdValue)) {
      // load units for building
      this.loadUnits();
    }
    if (!Object.is(this.props.marketValue, prevProps.marketValue)) {
      // load buildings for market
      this.loadBuildings();
    }

    const deliveryPreferenceSetByBuilding =
      this.props.building.packageDeliveryPolicy === 3;
    if (
      deliveryPreferenceSetByBuilding &&
      this.props.packageDeliveryPreferenceValue !== 2
    ) {
      this.props.change("packageDeliveryPreference", 2);
    }
  };
  loadUnits = () => {
    const { dispatch, buildingIdValue } = this.props;
    if (buildingIdValue) {
      dispatch(buildingActions.getBuilding(buildingIdValue));
    }
  };
  loadBuildings = () => {
    const { dispatch, marketValue } = this.props;
    if (marketValue) {
      dispatch(buildingsByMarketActions.getBuildingsByMarket(marketValue));
    }
  };
  resetUnit = () => {
    this.props.change("buildingUnitId", "");
  };
  resetBuilding = () => {
    this.props.change("buildingId", "");
    this.resetUnit();
  };
  handleDeliveryPreferenceButtonClick = (optionValue: number) => {
    const hasAcceptedWaiver = !!this.props.initialValues.leaveLiabilityAccepted;

    if (optionValue === 1 && !hasAcceptedWaiver) {
      this.props.dispatch(
        uiActions.triggerModal("registration_leave_liability_waiver"),
      );
    } else {
      this.setPackageDeliveryPreference(optionValue);
    }
  };
  setPackageDeliveryPreference = (optionValue: number) => {
    this.props.change("packageDeliveryPreference", optionValue);
  };
  render = () => {
    const {
      isAdminView,
      building: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        meta,
        name,
        packageDeliveryPolicy,
        residentTermsAndConditionsUrl,
        residentPrivacyPolicyUrl,
        units,
      },
      buildingsByMarket,
      handleSubmit,
      hideSubmit,
      markets,
      submitting,
      invalid,
    } = this.props;

    const marketOptions = getMarketOptions(markets);
    const buildingOptions = getBuildingsByMarketOptions(buildingsByMarket);
    const buildingUnitOptions = getBuildingUnitOptions(units);
    const isViewingProfile: boolean =
      window.location.pathname === "/user-profile";
    const shouldShowDeliveryPreferences = !isViewingProfile;
    const shouldShowTCPField: boolean = !isAdminView && !isViewingProfile;
    const preferenceSetByBuilding: boolean = packageDeliveryPolicy === 3;
    const deliveryPreferenceSetToLeave: boolean =
      this.props.packageDeliveryPreferenceValue === 1;
    const deliveryPreferenceToggleIsChecked: boolean =
      this.props.packageDeliveryPreferenceValue === 1 ? true : false;

    return (
      <Form className={classes.getBlockClassNames()} onSubmit={handleSubmit}>
        <FetchModal
          className={waiverModalClasses.getBlockClassNames()}
          id="registration_leave_liability_waiver"
        >
          <LeaveLiabilityWaiver />
          <Row>
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  this.setPackageDeliveryPreference(1);
                  this.props.change(
                    "leaveLiabilityAccepted",
                    getNowInApiDateTimeString(),
                  );
                  this.props.dispatch(uiActions.clearModal());
                }}
                className={waiverModalClasses.getElementClassNames("accept")}
              >
                Accept
              </Button>
            </Col>
            <Col>
              <Button
                color="secondary"
                onClick={() => {
                  this.props.dispatch(uiActions.clearModal());
                }}
                className={waiverModalClasses.getElementClassNames("decline")}
              >
                Decline
              </Button>
            </Col>
          </Row>
        </FetchModal>
        <Field
          className={classes.getElementClassNames("market")}
          component={FetchSelect}
          label={copy.userType.resident.market}
          name="market"
          onChange={() => this.resetBuilding()}
          options={marketOptions}
          type="select"
        />
        <Field
          className={classes.getElementClassNames("building")}
          component={FetchSelect}
          label="Community"
          name="buildingId"
          onChange={() => this.resetUnit()}
          options={buildingOptions}
          type="select"
        />
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
        {meta && meta.registrationHelp && (
          <FetchNotice
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            body={meta.registrationHelp}
            icon="notice"
            title={`${name} Registration Instructions`}
            className={classes.getElementClassNames("registrationInstructions")}
          />
        )}
        <Field
          className={classes.getElementClassNames("apartmentNumber")}
          component={FetchSelect}
          label="Unit Number"
          name="buildingUnitId"
          type="select"
          options={buildingUnitOptions}
        />
        {shouldShowDeliveryPreferences && (
          <div className={deliveryPreferencesClasses.getBlockClassNames()}>
            {!preferenceSetByBuilding && (
              <div className="d-flex">
                <FetchFieldLabel
                  className={deliveryPreferencesClasses.getElementClassNames(
                    "label",
                  )}
                  label="Leave At Door: "
                />
                &nbsp;
                {deliveryPreferenceToggleIsChecked ? (
                  <span className="text-success">Enabled</span>
                ) : (
                  <span className="text-danger">Disabled</span>
                )}
              </div>
            )}
            <Row>
              <Col
                className={deliveryPreferencesClasses.getElementClassNames(
                  "optionsContainer",
                )}
              >
                {!preferenceSetByBuilding && (
                  <FetchToggleSwitch
                    className={deliveryPreferencesClasses.getElementClassNames(
                      "toggle",
                    )}
                    onChange={(isChecked: boolean) => {
                      this.handleDeliveryPreferenceButtonClick(
                        isChecked ? 1 : 2,
                      );
                    }}
                    isChecked={deliveryPreferenceToggleIsChecked}
                  />
                )}
                {preferenceSetByBuilding && (
                  <div
                    className={deliveryPreferencesClasses.getElementClassNames(
                      "setByBuilding",
                    )}
                  >
                    Your building does not allow Fetch to leave packages at the
                    door.
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col
                className={deliveryPreferencesClasses.getElementClassNames(
                  "explanation",
                )}
              >
                <strong className="pr-2">Explanation:</strong>
                <span className={"subtle"}>
                  {deliveryPreferenceSetToLeave
                    ? "If you're not home upon the first attempt, we will leave your package at your door. You are liable for packages left at your door."
                    : "If you're not home upon the first attempt, a second attempt will be made after 30 minutes. If you are not home upon the second attempt, your package will be returned to the Fetch warehouse."}
                </span>
              </Col>
            </Row>
            <Field
              name="packageDeliveryPreference"
              type="hidden"
              component={FetchInput}
            />
          </div>
        )}
        {shouldShowTCPField ? (
          <div className={termsAcceptedClasses.getBlockClassNames()}>
            <label>
              <Input
                type="checkbox"
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    this.props.change("termsAccepted", `${Date.now()}`);
                  } else {
                    this.props.change("termsAccepted", "");
                  }
                }}
                className={termsAcceptedClasses.getElementClassNames(
                  "checkbox",
                )}
              />
              <div
                className={termsAcceptedClasses.getElementClassNames("label")}
              >
                I agree to the{" "}
                <FetchTermsLink url={residentTermsAndConditionsUrl} /> and{" "}
                <FetchPolicyLink url={residentPrivacyPolicyUrl} />
              </div>
            </label>
            <Field component={FetchInput} name="termsAccepted" type="hidden" />
          </div>
        ) : (
          <div>
            <FetchTermsLink url={residentTermsAndConditionsUrl} /> |{" "}
            <FetchPolicyLink url={residentPrivacyPolicyUrl} />
          </div>
        )}
        <div>
          {!(hideSubmit === true) && ( // display unless hideSubmit prop set to true
            <Button
              color="info"
              type="submit"
              disabled={submitting || invalid}
              className={classes.getElementClassNames("submit")}
            >
              Submit
            </Button>
          )}
        </div>
      </Form>
    );
  };
}

const formOptions = {
  enableReinitialize: true,
  form: "residentialUser",
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  onSubmitFail: () => {
    // scroll to top if we have any errors
    window.scrollTo(0, 0);
    toast.error("Please fix the error(s) below and try again");
  },
  validate: (values: IFieldValues) => {
    const errors: IErrorValues = {};
    if (!values.market) {
      errors.market = "Required";
    }
    if (!values.buildingId) {
      errors.buildingId = "Required";
    }
    if (!values.buildingUnitId) {
      errors.buildingUnitId = "Required";
    }
    if (!values.packageDeliveryPreference) {
      errors.packageDeliveryPreference = "Required";
    }
    if (
      values.packageDeliveryPreference === 1 &&
      !values.leaveLiabilityAccepted
    ) {
      errors.leaveLiabilityAccepted = "Required";
    }
    if (!values.termsAccepted) {
      errors.termsAccepted = "Required";
    }
    return errors;
  },
};
const myFormOptions = {
  ...formOptions,
  form: "myResidentialUser",
};
const mySelector = formValueSelector("myResidentialUser");
export const MyResidentialUserReduxFormContainer = FetchFormConnect({
  FormComponent: ResidentialUserFormBody,
  formOptions: myFormOptions,
  state: {
    mapStateToProps: (state: IRootStateType) => ({
      applicationSettings: state.applicationSettings,
      building: state.building,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      buildingIdValue: mySelector(state, "buildingId"),
      buildingsByMarket: state.buildingsByMarket,
      initialValues: {
        ...state?.currentUser?.residentialUser,
        packageDeliveryPreference:
          state?.currentUser?.residentialUser &&
          state.currentUser.residentialUser.packageDeliveryPreference && // User has a preference
          state.building.packageDeliveryPolicy !== 3 // And is not already dictated by building policy
            ? state.currentUser.residentialUser.packageDeliveryPreference
            : 2,
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      marketValue: mySelector(state, "market"),
      markets: state.markets,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      packageDeliveryPreferenceValue: mySelector(
        state,
        "packageDeliveryPreference",
      ),
    }),
  },
});

const selector = formValueSelector("residentialUser");
export const ResidentialUserReduxFormContainer = FetchFormConnect({
  FormComponent: ResidentialUserFormBody,
  formOptions,
  state: {
    mapStateToProps: (state: IRootStateType) => ({
      applicationSettings: state.applicationSettings,
      building: state.building,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      buildingIdValue: selector(state, "buildingId"),
      buildingsByMarket: state.buildingsByMarket,
      currentUser: state.currentUser,
      initialValues: {
        ...state.residentialUser,
        packageDeliveryPreference:
          state.residentialUser.packageDeliveryPreference && // User has a preference
          state.building.packageDeliveryPolicy !== 3 // And is not already dictated by building policy
            ? state.residentialUser.packageDeliveryPreference
            : 2,
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      marketValue: selector(state, "market"),
      markets: state.markets,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      packageDeliveryPreferenceValue: selector(
        state,
        "packageDeliveryPreference",
      ),
      user: state.user,
    }),
  },
});
