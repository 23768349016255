import { State } from "./csatData.reducer";

const sliceName = "csatData";

type RequestPayload = Pick<State, "request">;
type FullfilledPayload = Pick<State, "response">;
type RejectedPayload = Pick<State, "error">;

export const actionTypes = {
  request: `${sliceName}/request`,
  fullfilled: `${sliceName}/fullfilled`,
  rejected: `${sliceName}/rejected`,
  clear: `${sliceName}/clear`,
} as const;

export type ClearAction = {
  type: typeof actionTypes.clear;
};

/**
 * @usage clear();
 */
function clear(): ClearAction {
  return {
    type: actionTypes.clear,
  };
}

export type RequestAction = {
  type: typeof actionTypes.request;
  payload: RequestPayload;
};

/**
 * @usage request({ request: { ... } });
 */
function request(payload: RequestAction["payload"]): RequestAction {
  return {
    type: actionTypes.request,
    payload,
  };
}

export type FullfilledAction = {
  type: typeof actionTypes.fullfilled;
  payload: FullfilledPayload;
};

/**
 * @usage fullfilled({  response: { ... },  status: 200, });
 */
export function fullfilled(
  payload: FullfilledAction["payload"],
): FullfilledAction {
  return {
    type: actionTypes.fullfilled,
    payload,
  };
}

export type RejectedAction = {
  type: typeof actionTypes.rejected;
  payload: RejectedPayload;
};

/**
 * @usage rejected({  error: {    fetchErrorCode: 5000,    message: 'Something Happened',    name: 'SOMETHING',  },  status: 500, });
 */
export function rejected(payload: RejectedAction["payload"]): RejectedAction {
  return {
    type: actionTypes.rejected,
    payload,
  };
}

export const actions = {
  request,
  fullfilled,
  rejected,
  clear,
};
