import { GetFetchPackagesByIdLabelImageResponseBody } from "services/hermesEndpointSagas/getFetchPackagesByIdLabelImage/service";
import { Reducer } from "redux";
import { FetchError } from "slices/authentication/network.actions";
import {
  RequestAction,
  FullfilledAction,
  RejectedAction,
  ClearAction,
  actionTypes,
} from "./actions";

export type MyPackageLabelsByFetchPackageId = {
  [fetchPackageId in number]?: GetFetchPackagesByIdLabelImageResponseBody;
};

export type MyPackageLabelsByFetchPackageIdRequest = {
  fetchPackageIds: number[];
};

export type MyPackageLabelsByFetchPackageIdState = {
  error: FetchError | null;
  loading: boolean;
  response: MyPackageLabelsByFetchPackageId | null;
  request: MyPackageLabelsByFetchPackageIdRequest | null;
};

export const defaultState: MyPackageLabelsByFetchPackageIdState = {
  error: null,
  loading: false,
  response: null,
  request: null,
};

export const reducer: Reducer<
  MyPackageLabelsByFetchPackageIdState,
  RequestAction | FullfilledAction | RejectedAction | ClearAction
> = (state = defaultState, action): MyPackageLabelsByFetchPackageIdState => {
  switch (action.type) {
    case actionTypes.request:
      return {
        ...state,
        loading: true,
        request: action.payload,
        response: null,
        error: null,
      };
    case actionTypes.fullfilled:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case actionTypes.rejected:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.clear:
      return defaultState;
    default:
      return state;
  }
};
