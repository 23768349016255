export const GET_LABEL_ANALYSIS_SUMMARY = "GET_LABEL_ANALYSIS_SUMMARY";
export const GET_LABEL_ANALYSIS_SUMMARY_SUCCESS =
  "GET_LABEL_ANALYSIS_SUMMARY_SUCCESS";
export const GET_LABEL_ANALYSIS_SUMMARY_FAILURE =
  "GET_LABEL_ANALYSIS_SUMMARY_FAILURE";

// Interfaces
export interface GetLabelAnalysisSummaryAction {
  type: typeof GET_LABEL_ANALYSIS_SUMMARY;
  payload: {
    warehouseShortCode: string | undefined;
  };
}

export interface LabelAnalysisSummaryResponse {
  statusCounts: {
    awaitingOcr: number;
    newPictureRequired: number;
    awaitingHuman: number;
  };
  unshelved: number;
}

export interface GetLabelAnalysisSummarySuccessAction {
  type: typeof GET_LABEL_ANALYSIS_SUMMARY_SUCCESS;
  payload: LabelAnalysisSummaryResponse;
}

export interface GetLabelAnalysisSummaryFailureAction {
  type: typeof GET_LABEL_ANALYSIS_SUMMARY_FAILURE;
}

// Functions
function getLabelAnalysisSummarySuccess(
  response: LabelAnalysisSummaryResponse,
): GetLabelAnalysisSummarySuccessAction {
  return {
    type: GET_LABEL_ANALYSIS_SUMMARY_SUCCESS,
    payload: response,
  };
}

function getLabelAnalysisSummary(
  warehouseShortCode?: string,
): GetLabelAnalysisSummaryAction {
  return {
    type: GET_LABEL_ANALYSIS_SUMMARY,
    payload: { warehouseShortCode },
  };
}

function getLabelAnalysisSummaryFailure(): GetLabelAnalysisSummaryFailureAction {
  return { type: GET_LABEL_ANALYSIS_SUMMARY_FAILURE };
}

export const labelAnalysisSummaryActions = {
  getLabelAnalysisSummarySuccess,
  getLabelAnalysisSummary,
  getLabelAnalysisSummaryFailure,
};
