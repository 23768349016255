import React from "react";

import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import { Dispatch } from "redux";
import { Field, formValueSelector, InjectedFormProps } from "redux-form";

import { BEM } from "helpers/BEM.helper";
import { FetchFormConnect } from "helpers/FetchFormConnect";
import { FetchInput } from "views/common/forms/fields/FetchInput";
import { PasswordValidationMessages } from "views/components/forms/PasswordValidationMessages";
import { assertPasswordIsValid } from "helpers/inputValidators/text/password";
import { IRootStateType } from "types/IRootStateType";
import { FetchFormErrors } from "types/FetchFormErrors";

const classes = new BEM({
  block: { name: "ConfirmResetPasswordForm" },
  elements: [
    { name: "temporaryPassword", extras: [] },
    { name: "confirmTemporaryPassword", extras: [] },
    { name: "invalidPasswordAlert", extras: [] },
    { name: "confirmButton", extras: ["w-100"] },
  ],
});

export interface ConfirmResetPasswordFormProps
  extends RouteComponentProps,
    InjectedFormProps<IConfirmResetPasswordFormValues> {
  dispatch: Dispatch;
  temporaryPassword?: string;
}

export type IConfirmResetPasswordFormValues = {
  temporaryPassword: string;
  confirmTemporaryPassword: string;
};

type IConfirmResetPasswordFormErrors =
  FetchFormErrors<IConfirmResetPasswordFormValues>;

export const ConfirmResetPasswordForm: React.FC<
  ConfirmResetPasswordFormProps
> = (props: ConfirmResetPasswordFormProps) => {
  const { temporaryPassword, invalid, dirty } = props;

  return (
    <Form
      className={classes.getBlockClassNames()}
      onSubmit={props.handleSubmit}
    >
      <Field
        className={classes.getElementClassNames("temporaryPassword")}
        component={FetchInput}
        label="Temporary Password"
        name="temporaryPassword"
        type="password"
      />
      <Field
        className={classes.getElementClassNames("confirmTemporaryPassword")}
        component={FetchInput}
        label="Confirm Temporary Password"
        name="confirmTemporaryPassword"
        type="password"
      />
      <PasswordValidationMessages password={temporaryPassword} />
      <Button
        type="submit"
        color="info"
        className={classes.getElementClassNames("confirmButton")}
        disabled={invalid || !dirty}
      >
        Confirm
      </Button>
    </Form>
  );
};

const selector = formValueSelector("confirmResetPassword");

export default FetchFormConnect({
  FormComponent: ConfirmResetPasswordForm,
  formOptions: {
    form: "confirmResetPassword",
    touchOnChange: true,
    onSubmitFail: () => {
      toast.error("Please fix the error(s) below and try again");
    },
    validate: (
      values: IConfirmResetPasswordFormValues,
    ): IConfirmResetPasswordFormErrors => {
      const errors: IConfirmResetPasswordFormErrors = {};
      const requiredFields: (keyof IConfirmResetPasswordFormValues)[] = [
        "temporaryPassword",
        "confirmTemporaryPassword",
      ];
      requiredFields.forEach((fieldName) => {
        if (!values[fieldName]) {
          errors[fieldName] = "Required";
        }
      });
      if (!assertPasswordIsValid(values.temporaryPassword)) {
        errors.temporaryPassword = "Enter valid password";
      }
      if (values.temporaryPassword !== values.confirmTemporaryPassword) {
        errors.confirmTemporaryPassword = "Passwords must match";
      }
      return errors;
    },
  },
  state: {
    mapStateToProps: (rootState: IRootStateType) => {
      return {
        initialValues: {},
        temporaryPassword: selector(rootState, "temporaryPassword") as
          | string
          | undefined,
      };
    },
  },
});
