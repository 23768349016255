import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserRoleDTO } from "services/hermes/userRoles";

export type UserRolesStore = {
  userRoles: UserRoleDTO[];
  isLoading: boolean;
  error: string;
};

const initialState: UserRolesStore = {
  userRoles: [],
  isLoading: false,
  error: "",
};

export const userRolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {
    getUserRolesFetch: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    getUserRolesSuccess: (state, action: PayloadAction<UserRoleDTO[]>) => {
      state.userRoles = action.payload;
      state.isLoading = false;
      state.error = "";
    },
    getUserRolesFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getUserRolesFetch, getUserRolesSuccess, getUserRolesFailure } =
  userRolesSlice.actions;

export default userRolesSlice.reducer;
