import {
  CLEAR_PACKAGE,
  GET_PACKAGE_SUCCESS,
} from "slices/package/package.actions";
import { defaultState } from "default-state";
import { IWarehouseLocationStoreType } from "slices/warehouseLocation/warehouseLocation.reducer";

//type PackageStatusEnum = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "10" | "11";
type PackageStatusEnum = 5 | 10 | 15 | 20 | number;

export interface IPackageStoreType {
  fetchPackageId: number;
  sender: string;
  carrier: number;
  carrierTracking: string;
  status: PackageStatusEnum;
  shelfLocation: string | null;
  createdDate: string; // iso ex: "2020-04-15T21:19:19.792+0000"
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  userId: number;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  locationCode: string | null;
  systemId: string;
  userCode: string;
  packageType: number | null;
  packageDeliveryPreference: number;
  unitNumber: string;
  buildingId: number;
  buildingName: string;
  buildingShortCode: string;
  warehouseId: number;
  warehouseName: string;
  warehouseShortCode: string;
  deliveryWindowId: number | null;
  warehouseLocation: Partial<IWarehouseLocationStoreType> | null;
  warehouseLocationId: number | null;
  warehouseLocationType: number | null;
  warehousePackageType: number | null;
  warehouseTimezone: string;
  driverBatchId: number | null;
  requestedDeliveryStartDate: string | null;
  requestedDeliveryEndDate: string | null;
  deliveredDate: string | null;
  deliveryMethod: number;
  notes: {
    residentDelivery: string | null;
    fetchDelivery: string | null;
    fetchUser: string | null;
    residentUser: string | null;
  };
}

export function packageReducer(
  state = defaultState.package,
  action: PackageActionsTypes,
): Partial<IPackageStoreType> {
  switch (action.type) {
    case CLEAR_PACKAGE:
      return defaultState.package;
    case GET_PACKAGE_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}

// type definitions
type PackageActionsTypes =
  | IGetPackageSuccessActionType
  | IClearPackageActionType;

interface IGetPackageSuccessActionType {
  type: typeof GET_PACKAGE_SUCCESS;
  payload: IPackageStoreType;
}
interface IClearPackageActionType {
  type: typeof CLEAR_PACKAGE;
}
