import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IPackagesStoreType } from "./packages.reducer";

export const CLEAR_PACKAGES = "CLEAR_PACKAGES";
export const SEARCH_PACKAGES = "SEARCH_PACKAGES";
export const SEARCH_PACKAGES_SUCCESS = "SEARCH_PACKAGES_SUCCESS";
export const SEARCH_PACKAGES_FAILURE = "SEARCH_PACKAGES_FAILURE";

export interface ISearchPackagesActionType {
  type: string;
  payload: IPaginatedTableSearchRequestType;
}

export interface ISearchPackagesSuccessActionType {
  type: typeof SEARCH_PACKAGES_SUCCESS;
  payload: IPackagesStoreType;
}

export interface ISearchPackageFailureActionType {
  type: typeof SEARCH_PACKAGES_FAILURE;
}

export interface IClearPackagesActionType {
  type: typeof CLEAR_PACKAGES;
}

function clearPackages() {
  return { type: CLEAR_PACKAGES };
}

function searchPackages(packageSearch: IPaginatedTableSearchRequestType) {
  return { type: SEARCH_PACKAGES, payload: packageSearch };
}

function searchPackagesSuccess(packageResponse: IPackagesStoreType) {
  return { type: SEARCH_PACKAGES_SUCCESS, payload: packageResponse };
}

function searchPackagesFailure() {
  return { type: SEARCH_PACKAGES_FAILURE };
}

export const packagesActions = {
  clearPackages,
  searchPackages,
  searchPackagesSuccess,
  searchPackagesFailure,
};
