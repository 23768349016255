import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Collapse, Nav } from "reactstrap";
import { authenticationActions } from "slices/authentication/authentication.actions";
import logoSm from "assets/img/fetch-package-logo-sm.svg";
import logo from "assets/img/fetch-package-logo.svg";
import { colorsConstants } from "constants/colors.constants";
import { userHasPermission } from "helpers/permissions.helper";
import { truncateIfNecessary } from "helpers/stringFormat.helper";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { FetchNavLinkCollapse } from "./FetchNavLinkCollapse";
import { FetchNavLink } from "./FetchNavLink";
import { getUserNavRoutes } from "helpers/getUserNavRoutes.helper";
import { useNavCollapseState } from "helpers/hooks/useNavCollapseState";
import { CurrentUserState } from "slices/currentUser/currentUser.reducer";
import { FetchIcon } from "views/common/misc/FetchIcon";

const Component: React.FC = () => {
  const dispatch = useDispatch();

  const currentUser = useFetchSelector("currentUser");

  const userNavRoutes = useMemo(() => {
    return getUserNavRoutes(currentUser);
  }, [currentUser]);

  const [navCollapseState, toggle] = useNavCollapseState(userNavRoutes);
  const [openAvatar, setOpenAvatar] = useState(false);

  const handleLogoutClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
      e.preventDefault();
      dispatch(authenticationActions.logout("Logout by Sidebar"));
    },
    [dispatch],
  );

  const handleAvatarClick = useCallback(() => {
    setOpenAvatar((prev) => !prev);
  }, []);

  const showWarehouseUserLinks = useMemo(
    () =>
      userHasPermission(currentUser, {
        warehouseUser: true,
        adminUser: true,
      }),
    [currentUser],
  );

  const navLinks = useMemo(() => {
    return userNavRoutes.map((route) => {
      if (route.children && route.children.length > 0) {
        return (
          <FetchNavLinkCollapse
            key={route.path}
            route={route}
            open={navCollapseState[route.name]}
            onToggle={toggle}
          />
        );
      }

      return <FetchNavLink key={route.path} route={route} />;
    });
  }, [userNavRoutes, navCollapseState, toggle]);

  return (
    <div
      className="sidebar"
      data-color={colorsConstants.bgColor}
      data-active-color={colorsConstants.activeColor}
    >
      <div className="logo">
        <NavLink
          to="/"
          activeClassName="simple-text logo-mini visible-on-sidebar-mini"
        >
          <div className="logo-img">
            <img src={logoSm} alt="fetch-package-logo" />
          </div>
        </NavLink>
        <NavLink to="/" activeClassName="simple-text logo-normal">
          <div className="logo-img">
            <img src={logo} alt="fetch-package-logo" />
          </div>
        </NavLink>
      </div>

      <div className="sidebar-wrapper">
        <div className="user">
          <div className="photo">
            {currentUser?.profileImageUrl ? (
              <img src={currentUser.profileImageUrl} alt="Avatar" />
            ) : (
              <FetchIcon iconType="profileBlank" />
            )}
          </div>
          <div className="info nav">
            <div
              className={"user-name"}
              data-toggle="collapse"
              aria-expanded={openAvatar}
              onClick={handleAvatarClick}
            >
              <span>
                {truncateDisplayName(currentUser)}
                <b className="caret" />
              </span>
            </div>
            <Collapse isOpen={openAvatar}>
              <ul className="nav">
                <li>
                  <NavLink to="/user-profile" activeClassName="">
                    <span className="sidebar-mini-icon" />
                    <span className="sidebar-normal">Profile</span>
                  </NavLink>
                </li>
                {showWarehouseUserLinks && (
                  <li>
                    <NavLink to="/my-warehouse" activeClassName="">
                      <span className="sidebar-mini-icon" />
                      <span className="sidebar-normal">Facility Settings</span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="#"
                    onClick={handleLogoutClick}
                    activeClassName=""
                  >
                    <span className="sidebar-mini-icon" />
                    <span className="sidebar-normal">Logout</span>
                  </NavLink>
                </li>
              </ul>
            </Collapse>
            <div className="menuBorder" />
          </div>
        </div>
        <Nav>{navLinks}</Nav>
      </div>
    </div>
  );
};

export const Sidebar = React.memo(Component);

const truncateDisplayName = (currentUser?: CurrentUserState) =>
  currentUser
    ? truncateIfNecessary(
        `${currentUser.firstName} ${currentUser.lastName}`,
        18,
      )
    : "";
