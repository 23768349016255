import { FetchError } from "slices/authentication/network.actions";
import { IPackageStoreType } from "slices/package/package.reducer";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import {
  FullfilledAction,
  RequestAction,
  RejectedAction,
  ClearAction,
  actionTypes,
} from "./csatData.actions";
import { Reducer } from "redux";

export type ResponseBody = {
  // For the time being, csatData is just package history
  content: IPackageStoreType[];
};

export type RequestBody = {
  //
};

export type State = {
  error: FetchError | null;
  loading: boolean;
  response: ResponseBody | null;
  request: {
    query?: IPaginatedTableSearchRequestType;
    body?: RequestBody;
  };
};

export const defaultState: State = {
  error: null,
  loading: false,
  response: null,
  request: { query: undefined, body: undefined },
};

export const reducer: Reducer<
  State,
  RequestAction | FullfilledAction | RejectedAction | ClearAction
> = (state = defaultState, action): State => {
  switch (action.type) {
    case actionTypes.request:
      return {
        loading: true,
        request: action.payload.request,
        response: null,
        error: null,
      };
    case actionTypes.fullfilled:
      return {
        ...state,
        loading: false,
        response: action.payload.response,
      };
    case actionTypes.rejected:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.clear:
      return defaultState;
    default:
      return state;
  }
};
