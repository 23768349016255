import { rootStore } from "rootStore";
import { CurrentUserState } from "slices/currentUser/currentUser.reducer";
import { UserPrivileges } from "types/UserPrivileges";

export const assertCurrentUserHasPrivilege = (
  privilegeId: UserPrivileges | UserPrivileges[],
): boolean => {
  const { currentUser } = rootStore.getState();

  return assertUserHasPrivilege(currentUser, privilegeId);
};

export const assertUserHasPrivilege = (
  user: CurrentUserState,
  privilegeId: UserPrivileges | UserPrivileges[],
): boolean => {
  if (!!user && !!user.privileges) {
    const { privileges } = user;

    if (Array.isArray(privilegeId)) {
      return privilegeId.reduce(
        (userHasPrivilege: boolean, privilege: UserPrivileges): boolean => {
          return userHasPrivilege && privileges.includes(privilege);
        },
        true,
      );
    } else {
      return privileges.includes(privilegeId);
    }
  } else {
    return false;
  }
};
