import { takeLeading, call, put } from "redux-saga/effects";
import { paymentsService } from "services/hermes/payments";
import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  GetAllMyPricesV2Response,
  GET_ALL_MY_PRICES_V2,
  pricesActions,
} from "./prices.actions";

function* getAllMyPricesV2Worker(action: {
  type: typeof GET_ALL_MY_PRICES_V2;
}) {
  yield put(pricesActions.clearPrices());
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof paymentsService.getAllMyPricesV2> =
      yield call(paymentsService.getAllMyPricesV2);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: GetAllMyPricesV2Response = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(pricesActions.getAllMyPricesV2Success(response));
  } catch (error) {
  } finally {
    yield put(loadingActions.endLoad(action.type));
  }
}

export const pricesSagas = function* () {
  yield takeLeading(GET_ALL_MY_PRICES_V2, getAllMyPricesV2Worker);
};
