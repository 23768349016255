import { call, takeEvery } from "redux-saga/effects";
import { networkActions } from "slices/authentication/network.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { service, GetFetchPackagesByIdLabelImageResponseBody } from "./service";
import {
  REQUEST_GET_FETCH_PACKAGES_BY_ID_LABEL_IMAGE,
  RequestGetFetchPackagesByIdLabelImage,
} from "./action";

export const saga = function* () {
  yield takeEvery(REQUEST_GET_FETCH_PACKAGES_BY_ID_LABEL_IMAGE, worker);
};

export const worker = function* (
  action: RequestGetFetchPackagesByIdLabelImage,
) {
  const request: IAuthenticatedRequestDefinition = service(action);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: GetFetchPackagesByIdLabelImageResponseBody = yield call(
    networkActions.makeAuthenticatedRequest,
    request,
    true,
  );

  return response;
};
