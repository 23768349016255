import React from "react";
import { toast } from "react-toastify";
import {
  call,
  ForkEffect,
  put,
  select,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { authenticationActions } from "slices/authentication/authentication.actions";
import { networkActions } from "slices/authentication/network.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { history } from "helpers/history";
import { loadingActions } from "slices/loading/loading.actions";
import { assertUserHasPrivilege } from "helpers/data/assertUserHasPrivilege.helper";
import { loadApplicationSettingsWorker } from "slices/applicationSettings";
import { metaSchemaActions } from "slices/metaSchema/metaSchema.action";
import { IRootStateType } from "types/IRootStateType";
import { rootStore } from "rootStore";
import {
  currentUserActions,
  GET_AUTO_SCHEDULE_OPTIONS,
  GET_MY_USER,
  ILoadMyUserSuccessType,
  IPatchMySettingsActionType,
  ISaveMyResidentialUserActionType,
  IPatchMyResidentialUserActionType,
  ISaveMyUserActionType,
  ISaveMyWarehouseUserActionType,
  LOAD_MY_USER,
  LOAD_MY_USER_SUCCESS,
  PATCH_MY_SETTINGS,
  SAVE_MY_RESIDENTIAL_USER,
  SAVE_MY_RESIDENTIAL_USER_SUCCESS,
  PATCH_MY_RESIDENTIAL_USER,
  PATCH_MY_RESIDENTIAL_USER_SUCCESS,
  SAVE_MY_USER,
  SAVE_MY_USER_SUCCESS,
  SAVE_MY_WAREHOUSE_USER,
  SAVE_MY_WAREHOUSE_USER_SUCCESS,
  ISaveMyUserSuccessActionType,
  ISaveMyResidentialUserSuccessActionType,
} from "./currentUser.actions";
import { usersService } from "services/hermes/users";
import { WarehouseUser } from "slices/warehouseUser/warehouseUser.reducer";
import { IMyPackagesStoreType } from "slices/myPackages/myPackages.reducer";
import {
  IUserStoreType,
  IResidentialUserStoreType,
  AutoScheduleOptions,
  IUserSettings,
  CurrentUserState,
} from "./currentUser.reducer";
import { packagesService } from "services/hermes/packages";
import { UserPrivileges } from "types/UserPrivileges";
import { removeWarning } from "slices/navigateAway/navigateAwayState";
import { initialize } from "redux-form";
import {
  MY_RESIDENTIAL_USER_FORMNAME,
  USER_PROFILE_FORMNAME,
} from "constants/form-names";

function* getMyUserWorker() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<typeof usersService.getMyUser> = yield call(
    usersService.getMyUser,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IUserStoreType | undefined = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  if (!!response) {
    yield call(currentUserActions.setCurrentUser, response);
    yield put(currentUserActions.loadMyUserSuccess(response));
  } else {
    yield put(
      authenticationActions.logout("No current user returned from API."),
    );
  }
}

function* getAutoScheduleOptionsWorker() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: IAuthenticatedRequestDefinition = yield call(
    usersService.getAutoScheduleOptions,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: AutoScheduleOptions = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(currentUserActions.getAutoScheduleOptionsSuccess(response));
}

function* saveMyUserWorker(action: ISaveMyUserActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      usersService.saveMyUser,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IUserStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(
      currentUserActions.saveMyUserSuccess(response, action.onSuccessConfig),
    );
  } catch (error) {
    toast.error("An error occured while submitting. Please try again.");
  }
}

function* saveMyWarehouseUserWorker(action: ISaveMyWarehouseUserActionType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: IAuthenticatedRequestDefinition = yield call(
    usersService.saveMyWarehouseUser,
    action.payload,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: WarehouseUser = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(currentUserActions.saveMyWarehouseUserSuccess(response));
}

function* patchMySettingsWorker(action: IPatchMySettingsActionType) {
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      usersService.patchMySettings,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IUserSettings = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(currentUserActions.patchMySettingsSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

function saveMyWarehouseUserSuccessWorker() {
  // redirect to users table list after success
  history.push("/");
  // display success message
  toast.success("Facility settings updated successfully");
}

function* loadMyUserWorker() {
  // get currentUser from State
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentUser: CurrentUserState = yield select(
    (state: IRootStateType) => state.currentUser,
  );
  // if we don't have a currentUser, query
  if (!currentUser) {
    yield put(currentUserActions.getMyUser());
  }
  // load applicationSettings after we have a user
  yield call(loadApplicationSettingsWorker);
}

function* loadMyUserSuccessWorker({ payload }: ILoadMyUserSuccessType) {
  // if user can see json meta fields, load json meta schema
  if (assertUserHasPrivilege(payload, UserPrivileges.GENERAL_META_SCHEMA_GET)) {
    yield put(metaSchemaActions.getMetaSchema());

    // TODO
    // * loadMetaSchema network call/workers
    // * probably add https://www.npmjs.com/package/jsonschema (seems popular/safe)
  }
}

// Both saveMyResidentialUserWorker and patchMyResidentialUserWorker need to handle
// the case where delivery preference has changed and we want the driver to be notified
// This functionality has been extracted to be updated in one place later
function* handleDriverNotificationOnPreferenceChange(
  payload: Partial<IResidentialUserStoreType>,
  currentUser: CurrentUserState,
  shouldTriggerDriverNotification: boolean | undefined,
) {
  if (
    payload.packageDeliveryPreference !==
      currentUser?.residentialUser?.packageDeliveryPreference &&
    shouldTriggerDriverNotification
  ) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const requestOption: ReturnType<typeof packagesService.getMyPackages> =
        yield call(packagesService.getMyPackages, {
          pageSize: 50,
          page: 1,
          timeScope: "today",
        });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const responses: IMyPackagesStoreType = yield call(
        networkActions.makeAuthenticatedRequest,
        requestOption,
        true,
      );

      const packagesOutForDelivery = responses.content.some(
        ({ status }) => status === 4,
      );

      if (packagesOutForDelivery) {
        toast.warning(
          () => (
            <div>
              <p>
                <strong>You have packages currently out for delivery.</strong>
              </p>
              <p>
                We will make an attempt to contact the courier to inform them
                about this change but we cannot guarantee that this preference
                change will take effect on any delivery that is currently in
                progress.
              </p>
              <p>
                For more assistance <strong>please contact support.</strong>
              </p>
            </div>
          ),
          {
            autoClose: 12000,
          },
        );
      }
    } catch (error) {
      toast.error(
        "If you have packages out for delivery, we will make an attempt to notify the driver.",
      );
    }
  }
}

function* saveMyResidentialUserWorker(
  action: ISaveMyResidentialUserActionType,
) {
  yield put(loadingActions.startLoad(action.type));
  try {
    const { currentUser } = rootStore.getState();
    const onSuccessConfig = action.onSuccessConfig;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      usersService.saveMyResidentialUser,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IResidentialUserStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield call(
      handleDriverNotificationOnPreferenceChange,
      action.payload,
      currentUser,
      action.options?.shouldTriggerDriverNotification,
    );
    yield put(
      currentUserActions.saveMyResidentialUserSuccess(
        response,
        onSuccessConfig,
      ),
    );
    if (!!action.options?.onSuccess) {
      action.options.onSuccess();
    }
  } catch (error) {
    toast.error("An error ocurred while submitting. Please try again.");
  }
  yield put(loadingActions.endLoad(action.type));
}

function* patchMyResidentialUserWorker(
  action: IPatchMyResidentialUserActionType,
) {
  try {
    const { currentUser } = rootStore.getState();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      usersService.patchMyResidentialUser,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IResidentialUserStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield call(
      handleDriverNotificationOnPreferenceChange,
      action.payload,
      currentUser,
      action.options?.shouldTriggerDriverNotification,
    );
    yield put(currentUserActions.patchMyResidentialUserSuccess(response));
  } catch (error) {
    toast.error("An error ocurred while submitting. Please try again.");
  }
}

function* saveMyResidentialUserSuccessWorker(
  action: ISaveMyResidentialUserSuccessActionType,
) {
  if (!!action.onSuccessConfig?.resetForm) {
    // reinitialize redux form
    yield put(initialize(MY_RESIDENTIAL_USER_FORMNAME, action.payload, false));
    // remove navigate away warning
    yield put(removeWarning(MY_RESIDENTIAL_USER_FORMNAME));
  }
  // display success message
  toast.success("Settings updated successfully");
  yield put(currentUserActions.getMyUser());
}

function* patchMyResidentialUserSuccessWorker() {
  // display success message
  toast.success("Settings updated successfully");
  yield put(currentUserActions.getMyUser());
}

function* saveMyUserSuccessWorker(action: ISaveMyUserSuccessActionType) {
  if (!!action.onSuccessConfig?.resetForm) {
    // remove navigate away warning
    yield put(removeWarning(USER_PROFILE_FORMNAME));
    // reinitialize redux form
    yield put(initialize(USER_PROFILE_FORMNAME, action.payload, false));
  }

  // redirect to users table list after success
  history.push("/");
  // display success message
  toast.success("Profile updated successfully");
}

export const currentUserSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(GET_MY_USER, getMyUserWorker);
  yield takeLeading(LOAD_MY_USER, loadMyUserWorker);
  yield takeLeading(LOAD_MY_USER_SUCCESS, loadMyUserSuccessWorker);
  yield takeLeading(SAVE_MY_USER, saveMyUserWorker);
  yield takeLeading(SAVE_MY_USER_SUCCESS, saveMyUserSuccessWorker);
  yield takeLeading(SAVE_MY_WAREHOUSE_USER, saveMyWarehouseUserWorker);
  yield takeLeading(
    SAVE_MY_WAREHOUSE_USER_SUCCESS,
    saveMyWarehouseUserSuccessWorker,
  );
  yield takeLeading(SAVE_MY_RESIDENTIAL_USER, saveMyResidentialUserWorker);
  yield takeLeading(
    SAVE_MY_RESIDENTIAL_USER_SUCCESS,
    saveMyResidentialUserSuccessWorker,
  );
  yield takeLeading(PATCH_MY_RESIDENTIAL_USER, patchMyResidentialUserWorker);
  yield takeLeading(
    PATCH_MY_RESIDENTIAL_USER_SUCCESS,
    patchMyResidentialUserSuccessWorker,
  );
  yield takeLatest(GET_AUTO_SCHEDULE_OPTIONS, getAutoScheduleOptionsWorker);
  yield takeLatest(PATCH_MY_SETTINGS, patchMySettingsWorker);
};
