import {
  GetMyPackageParametersType,
  IPostScheduledDeliveryPayloadType,
} from "services/hermes/packages";
import { IScheduledDeliveryStoreType } from "./scheduledDelivery.reducers";
import { FetchError } from "slices/authentication/network.actions";

export const GET_SCHEDULED_DELIVERY = "GET_SCHEDULED_DELIVERY";
export const GET_SCHEDULED_DELIVERY_SUCCESS = "GET_SCHEDULED_DELIVERY_SUCCESS";
export const GET_SCHEDULED_DELIVERY_FAILURE = "GET_SCHEDULED_DELIVERY_FAILURE";
export const CLEAR_SCHEDULED_DELIVERY = "CLEAR_SCHEDULED_DELIVERY";
export const SCHEDULE_MY_PACKAGE = "SCHEDULE_MY_PACKAGE";
export const SCHEDULE_MY_PACKAGE_SUCCESS = "SCHEDULE_MY_PACKAGE_SUCCESS";
export const ADMIN_SCHEDULE_PACKAGE = "ADMIN_SCHEDULE_PACKAGE";
export const ADMIN_SCHEDULE_PACKAGE_SUCCESS = "ADMIN_SCHEDULE_PACKAGE_SUCCESS";
export const POST_CANCEL_SCHEDULE_DELIVERY = "POST_CANCEL_SCHEDULE_DELIVERY";
export const POST_CANCEL_SCHEDULE_DELIVERY_SUCCESS =
  "POST_CANCEL_SCHEDULE_DELIVERY_SUCCESS";
export const POST_CANCEL_SCHEDULE_DELIVERY_FAILURE =
  "POST_CANCEL_SCHEDULE_DELIVERY_FAILURE";

export interface IScheduleMyPackageActionType {
  type: typeof SCHEDULE_MY_PACKAGE;
  request: IPostScheduledDeliveryPayloadType;
}
export interface IScheduleMyPackageSuccessActionType {
  type: typeof SCHEDULE_MY_PACKAGE_SUCCESS;
  fetchPackageId: number;
}

export interface IAdminScheduleDeliveryActionType {
  type: typeof ADMIN_SCHEDULE_PACKAGE;
  request: IPostScheduledDeliveryPayloadType;
}
export interface IAdminScheduleDeliverySuccessActionType {
  type: typeof ADMIN_SCHEDULE_PACKAGE_SUCCESS;
  fetchPackageId: number;
}
export interface IPostCancelScheduleDeliveryPayloadType {
  fetchPackageId: number;
}

export interface IPostCancelScheduleDeliveryFailurePayloadType {
  fetchError?: FetchError;
  message?: string;
}

type CancelDeliveryOptions = {
  toastMessages?: {
    success?: string;
    failure?: string;
  };
  refresh?: {
    getMyPackages?: GetMyPackageParametersType | boolean;
    getMyPackage?: boolean;
    getPackage?: boolean;
  };
  isPickup?: boolean;
};

export interface IPostCancelScheduleDeliveryActionType {
  type: typeof POST_CANCEL_SCHEDULE_DELIVERY;
  payload: IPostCancelScheduleDeliveryPayloadType;
  options?: CancelDeliveryOptions;
}
export interface IPostCancelScheduleDeliverySuccessActionType {
  type: typeof POST_CANCEL_SCHEDULE_DELIVERY_SUCCESS;
  fetchPackageId: number;
  options?: CancelDeliveryOptions;
}
export interface IPostCancelScheduleDeliveryFailureActionType {
  type: typeof POST_CANCEL_SCHEDULE_DELIVERY_FAILURE;
  isPickup?: boolean;
  payload?: IPostCancelScheduleDeliveryFailurePayloadType;
}

export interface IGetScheduledDeliveryPayloadType {
  fetchPackageId: number;
}

export interface IGetScheduledDeliveryActionType {
  type: typeof GET_SCHEDULED_DELIVERY;
  payload: IGetScheduledDeliveryPayloadType;
}

export interface IGetScheduledDeliverySuccessActionType {
  type: typeof GET_SCHEDULED_DELIVERY_SUCCESS;
  payload: IScheduledDeliveryStoreType;
}

export interface IGetScheduledDeliveryFailureActionType {
  type: typeof GET_SCHEDULED_DELIVERY_FAILURE;
}

export interface IClearScheduledDeliveryActionType {
  type: typeof CLEAR_SCHEDULED_DELIVERY;
}

function scheduleMyPackage(
  request: IPostScheduledDeliveryPayloadType,
): IScheduleMyPackageActionType {
  return { type: SCHEDULE_MY_PACKAGE, request };
}
function scheduleMyPackageSuccess(
  fetchPackageId: number,
): IScheduleMyPackageSuccessActionType {
  return { type: SCHEDULE_MY_PACKAGE_SUCCESS, fetchPackageId };
}

function adminSchedulePackage(
  request: IPostScheduledDeliveryPayloadType,
): IAdminScheduleDeliveryActionType {
  return { type: ADMIN_SCHEDULE_PACKAGE, request };
}
function adminSchedulePackageSuccess(
  fetchPackageId: number,
): IAdminScheduleDeliverySuccessActionType {
  return { type: ADMIN_SCHEDULE_PACKAGE_SUCCESS, fetchPackageId };
}

function postCancelScheduleDelivery(
  payload: IPostCancelScheduleDeliveryPayloadType,
  options?: CancelDeliveryOptions,
): IPostCancelScheduleDeliveryActionType {
  return { type: POST_CANCEL_SCHEDULE_DELIVERY, payload, options };
}
function postCancelScheduleDeliverySuccess(
  fetchPackageId: number,
  options?: CancelDeliveryOptions,
): IPostCancelScheduleDeliverySuccessActionType {
  return {
    type: POST_CANCEL_SCHEDULE_DELIVERY_SUCCESS,
    fetchPackageId,
    options,
  };
}
function postCancelScheduleDeliveryFailure(
  payload?: IPostCancelScheduleDeliveryFailurePayloadType,
): IPostCancelScheduleDeliveryFailureActionType {
  return {
    type: POST_CANCEL_SCHEDULE_DELIVERY_FAILURE,
    payload,
  };
}

function getScheduledDelivery(payload: IGetScheduledDeliveryPayloadType) {
  return { type: GET_SCHEDULED_DELIVERY, payload };
}

function getScheduledDeliverySuccess(response: IScheduledDeliveryStoreType) {
  return { type: GET_SCHEDULED_DELIVERY_SUCCESS, payload: response };
}

function getScheduledDeliveryFailure() {
  return { type: GET_SCHEDULED_DELIVERY_FAILURE };
}

function clearScheduledDelivery() {
  return { type: CLEAR_SCHEDULED_DELIVERY };
}

export const scheduledDeliveryActions = {
  clearScheduledDelivery,
  getScheduledDelivery,
  postCancelScheduleDelivery,
  adminSchedulePackage,
  scheduleMyPackage,
  scheduleMyPackageSuccess,
  adminSchedulePackageSuccess,
  postCancelScheduleDeliverySuccess,
  postCancelScheduleDeliveryFailure,
  getScheduledDeliverySuccess,
  getScheduledDeliveryFailure,
};
