import { Reducer } from "redux";
import {
  GetMySubscriptionsAction,
  GetMySubscriptionsSuccessAction,
  ClearSubscriptionsAction,
  CLEAR_SUBSCRIPTIONS,
  GET_MY_SUBSCRIPTIONS_SUCCESS,
} from "slices/subscriptions/subscriptions.actions";

interface SubscriptionItem {
  priceId: string;
  quantity: number;
  productId: string;
  productName: string;
  productDescription: string;
  productMeta: {
    productNameShort: string;
    productDetailDescription: string;
    iconUrl: string;
  };
  imgUrl: Array<string>;
}

type SubscriptionStatus =
  | "incomplete"
  | "incomplete_expired"
  | "trialing"
  | "active"
  | "past_due"
  | "canceled"
  | "unpaid";

export interface Subscription {
  subscriptionId: string;
  status: SubscriptionStatus;
  createdDate: string;
  items: SubscriptionItem[];
}

export type SubscriptionsStore = Subscription[];

type SubscriptionsActions =
  | ClearSubscriptionsAction
  | GetMySubscriptionsAction
  | GetMySubscriptionsSuccessAction;

export const subscriptions: Reducer<
  SubscriptionsStore,
  SubscriptionsActions
> = (state = [], action) => {
  switch (action.type) {
    case CLEAR_SUBSCRIPTIONS:
      return [];
    case GET_MY_SUBSCRIPTIONS_SUCCESS:
      return action.payload.subscriptions;
    default:
      return state;
  }
};
