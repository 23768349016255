import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "whatwg-fetch";

import "assets/scss/theme.scss";
import { history } from "helpers/history";
import { initializeSentry } from "services/sentry";
import { rootStore } from "rootStore";
import { initializeAnalytics } from "services/analytics";
import App from "App";
import { FourZeroFourPage } from "views/pages/FourZeroFourPage";

initializeSentry();
initializeAnalytics();

// render the application
export default ReactDOM.render(
  <Sentry.ErrorBoundary showDialog fallback={<FourZeroFourPage />}>
    <Provider store={rootStore}>
      <Router history={history}>
        <Sentry.ErrorBoundary showDialog fallback={<FourZeroFourPage />}>
          <App />
        </Sentry.ErrorBoundary>
      </Router>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root"),
);
