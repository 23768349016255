import { defaultState } from "default-state";
import {
  IGetUserAuditHistorySuccessType,
  IClearUserAuditHistoryType,
  CLEAR_USER_AUDIT_TRAIL,
  GET_USER_AUDIT_TRAIL_SUCCESS,
} from "./userAuditHistory.actions";

type UserAuditHistoryItem = {
  email: string;
  firstName: string;
  isAdminUser: boolean;
  isDriverUser: boolean;
  isResidentialUser: boolean;
  isWarehouseUser: boolean;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastName: string;
  phone: string;
  profileImageUrl: string | null;
  systemId: string;
  userId: number;
};

export type UserAuditHistory = UserAuditHistoryItem[];

type userAuditHistoryActionsTypes =
  | IGetUserAuditHistorySuccessType
  | IClearUserAuditHistoryType;

export function userAuditHistory(
  state: UserAuditHistory | null = null,
  action: userAuditHistoryActionsTypes,
): UserAuditHistory | null {
  switch (action.type) {
    case CLEAR_USER_AUDIT_TRAIL:
      return defaultState.userAuditHistory;
    case GET_USER_AUDIT_TRAIL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
