import { ScheduleOverrideType } from "slices/deliveryWindows/deliveryWindows.actions";

export const GET_AVAILABLE_DELIVERY_WINDOWS = "GET_AVAILABLE_DELIVERY_WINDOWS";
export const GET_AVAILABLE_DELIVERY_WINDOWS_SUCCESS =
  "GET_AVAILABLE_DELIVERY_WINDOWS_SUCCESS";
export const GET_AVAILABLE_DELIVERY_WINDOWS_FAILURE =
  "GET_AVAILABLE_DELIVERY_WINDOWS_FAILURE";
export const CLEAR_AVAILABLE_DELIVERY_WINDOWS =
  "CLEAR_AVAILABLE_DELIVERY_WINDOWS";

// types
export interface IClearAvailableDeliveryWindowsActionType {
  type: typeof CLEAR_AVAILABLE_DELIVERY_WINDOWS;
}
export interface IGetAvailableDeliveryWindowsActionType {
  type: typeof GET_AVAILABLE_DELIVERY_WINDOWS;
  payload: IGetAvailableDeliveryWindowsPayloadType;
}
export interface IGetAvailableDeliveryWindowsPayloadType {
  entityScope: ScheduleOverrideType;
  entityId: number;
  selectedDate: string; // YYYY-MM-DD
}

export type DeliveryWindow = {
  deliveryWindowId: number;
  startTime: string;
  endTime: string;
  duration?: number;
};

export interface IGetAvailableDeliveryWindowsSuccessActionType {
  type: typeof GET_AVAILABLE_DELIVERY_WINDOWS_SUCCESS;
  payload: DeliveryWindow[];
}

function clearAvailableDeliveryWindows(): IClearAvailableDeliveryWindowsActionType {
  return { type: CLEAR_AVAILABLE_DELIVERY_WINDOWS };
}

function getAvailableDeliveryWindows(
  payload: IGetAvailableDeliveryWindowsPayloadType,
): IGetAvailableDeliveryWindowsActionType {
  return { type: GET_AVAILABLE_DELIVERY_WINDOWS, payload };
}
function getAvailableDeliveryWindowsSuccess(
  payload: DeliveryWindow[],
): IGetAvailableDeliveryWindowsSuccessActionType {
  return { type: GET_AVAILABLE_DELIVERY_WINDOWS_SUCCESS, payload };
}

export const availableDeliveryWindowsActions = {
  clearAvailableDeliveryWindows,
  getAvailableDeliveryWindows,
  getAvailableDeliveryWindowsSuccess,
};
