import { GetMyPackageParametersType } from "services/hermes/packages";
import { IMyPackagesStoreType } from "./myPackages.reducer";

export const CLEAR_MY_PACKAGES = "CLEAR_MY_PACKAGES";
export const GET_MY_PACKAGES = "GET_MY_PACKAGES";
export const GET_MY_PACKAGES_SUCCESS = "GET_MY_PACKAGES_SUCCESS";

export interface IGetMyPackagesSuccessActionType {
  type: typeof GET_MY_PACKAGES_SUCCESS;
  payload: IMyPackagesStoreType;
  onSuccessConfig?: {
    getDeliveryReceipts?: boolean;
    getLabelImages?: boolean;
  };
}

export interface IClearMyPackagesActionType {
  type: typeof CLEAR_MY_PACKAGES;
}

export interface IGetMyPackagesActionType {
  type: string;
  payload: GetMyPackageParametersType;
  onSuccessConfig?: {
    getDeliveryReceipts?: boolean;
    getLabelImages?: boolean;
  };
}

function getMyPackages(
  payload: IGetMyPackagesActionType["payload"],
  onSuccessConfig?: IGetMyPackagesActionType["onSuccessConfig"],
): IGetMyPackagesActionType {
  return { type: GET_MY_PACKAGES, payload, onSuccessConfig };
}

function getMyPackagesSuccess(
  packageResponse: IMyPackagesStoreType,
  onSuccessConfig?: IGetMyPackagesSuccessActionType["onSuccessConfig"],
): IGetMyPackagesSuccessActionType {
  return {
    type: GET_MY_PACKAGES_SUCCESS,
    payload: packageResponse,
    onSuccessConfig,
  };
}

function clearMyPackages(): IClearMyPackagesActionType {
  return { type: CLEAR_MY_PACKAGES };
}

export const myPackagesActions = {
  clearMyPackages,
  getMyPackages,
  getMyPackagesSuccess,
};
