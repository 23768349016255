import React, { useState } from "react";

import { Col, Row } from "reactstrap";
import { Dispatch } from "redux";

import { FetchConnect } from "helpers/FetchConnect";
import { IRootStateType } from "types/IRootStateType";
import { IApplicationSettingsStoreType } from "slices/applicationSettings";

export interface LeaveLiabilityWaiverProps extends IRootStateType {
  dispatch: Dispatch;
  applicationSettings: IApplicationSettingsStoreType;
}

export const LeaveLiabilityWaiver: React.FC<LeaveLiabilityWaiverProps> = (
  props: LeaveLiabilityWaiverProps,
) => {
  const {
    applicationSettings: { leaveLiabilityWaiverUrl },
  } = props;

  const [waiverBody, setWaiverBody] = useState("");

  if (waiverBody.length === 0 && leaveLiabilityWaiverUrl) {
    fetch(leaveLiabilityWaiverUrl)
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        setWaiverBody(html);
      })
      .catch(() => {
        // do nothing
      });
  }

  return (
    <Row>
      <Col dangerouslySetInnerHTML={{ __html: waiverBody }} />
    </Row>
  );
};

export default FetchConnect({
  ConnectedComponent: LeaveLiabilityWaiver,
  state: { stores: ["applicationSettings"] },
});
