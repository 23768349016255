import {
  ISearchUsersSuccessActionType,
  ISearchUsersByIdSuccessActionType,
  ISearchUsersFailureActionType,
  IClearUsersSuccessType,
  CLEAR_USERS,
  SEARCH_USERS_BY_ID_SUCCESS,
  SEARCH_USERS_FAILURE,
  SEARCH_USERS_SUCCESS,
} from "slices/users/users.actions";
import { defaultState } from "default-state";
import {
  IResidentialUserStoreType,
  IWarehouseUserStoreType,
} from "slices/currentUser/currentUser.reducer";
import { IPaginatedResponseType } from "types/IPaginatedResponseType";

export interface IUsersStoreContentType {
  userId: number;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  systemId: string;
  hasRoleAdministrator: boolean;
  hasRoleWarehouse: boolean;
  hasRoleDriver: boolean;
  hasRoleResident: boolean;
  userCode: string;
  packageDeliveryPreference: number;
  unitNumber: string;
  buildingName: string;
  buildingShortCode: string;
  warehouseName: string;
  residentialUser?: IResidentialUserStoreType;
  driverUser?: {
    driverUserId: number;
    driverStatus: string;
    marketIds?: number[];
    warehouseIds?: number[];
    preferred: boolean;
  };
  warehouseUser?: IWarehouseUserStoreType;
}

export interface IUsersStoreType extends IPaginatedResponseType {
  content: IUsersStoreContentType[];
}

export function users(
  state = {},
  action: UsersActionsTypes,
): Partial<IUsersStoreType> {
  switch (action.type) {
    case CLEAR_USERS:
      return defaultState.users;
    case SEARCH_USERS_SUCCESS:
      // if there is an error with the query, return to empty state
      if (!action.payload) {
        return defaultState.users;
      }
      return action.payload;
    case SEARCH_USERS_BY_ID_SUCCESS:
      if (!action.payload) {
        return defaultState.users;
      }
      return {
        content: [action.payload],
      };
    case SEARCH_USERS_FAILURE:
      return defaultState.users;
    default:
      return state;
  }
}

// type definitions
type UsersActionsTypes =
  | ISearchUsersSuccessActionType
  | ISearchUsersByIdSuccessActionType
  | ISearchUsersFailureActionType
  | IClearUsersSuccessType;
