/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FetchError,
  networkActions,
} from "slices/authentication/network.actions";
import { deliveryWindowsServices } from "services/hermes/deliveryWindows";
import { actions, actionTypes, RequestAction } from "./actions";
import { WfmDeliveryWindowScheduleState } from "./reducer";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";

export const wfmDeliveryWindowScheduleSaga = function* () {
  yield takeLatest(actionTypes.request, worker);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const worker = function* (action: RequestAction): any {
  try {
    const request: IAuthenticatedRequestDefinition =
      deliveryWindowsServices.searchDeliveryWindows({
        warehouseId: action.payload.query.warehouseId,
        startDate: action.payload.query.startDate,
        numberOfDays: action.payload.query.numberOfDays,
      });

    const response: WfmDeliveryWindowScheduleState["response"] = yield call(
      networkActions.makeAuthenticatedRequest,
      request,
    );
    yield put(
      actions.fullfilled({
        response,
      }),
    );
  } catch (error) {
    yield put(
      actions.rejected({
        error: error as FetchError,
      }),
    );
  }
};
