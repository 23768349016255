import { Reducer } from "redux";

import { defaultState } from "default-state";
import {
  CLEAR_AVAILABLE_DELIVERY_WINDOWS,
  GET_AVAILABLE_DELIVERY_WINDOWS_SUCCESS,
  IClearAvailableDeliveryWindowsActionType,
  IGetAvailableDeliveryWindowsSuccessActionType,
} from "slices/availableDeliveryWindows/availableDeliveryWindows.actions";
import { IRootStateType } from "types/IRootStateType";

export const availableDeliveryWindows: Reducer<
  IRootStateType["availableDeliveryWindows"]
> = (
  state = defaultState.availableDeliveryWindows,
  action: AvailableDeliveryWindowsActionTypes,
): IRootStateType["availableDeliveryWindows"] => {
  switch (action.type) {
    case CLEAR_AVAILABLE_DELIVERY_WINDOWS:
      return defaultState.availableDeliveryWindows;
    case GET_AVAILABLE_DELIVERY_WINDOWS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export type AvailableDeliveryWindowsActionTypes =
  | IClearAvailableDeliveryWindowsActionType
  | IGetAvailableDeliveryWindowsSuccessActionType;
