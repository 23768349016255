import { defaultState } from "default-state";
import {
  IGetResidentialUserAuditHistorySuccessType,
  IClearResidentialUserAuditHistoryType,
  CLEAR_RESIDENTIAL_USER_AUDIT_TRAIL,
  GET_RESIDENTIAL_USER_AUDIT_TRAIL_SUCCESS,
} from "./residentialUserAuditHistory.actions";

type ResidentialUserAuditHistoryItem = {
  buildingUnitId: number;
  buildingShortCode: string;
  unitNumber: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  leaveLiabilityAccepted: null | string;
  packageDeliveryPreference: number;
  residentialUserId: number;
  termsAccepted: null | string;
  userCode: string;
};

export type ResidentialUserAuditHistory = ResidentialUserAuditHistoryItem[];

type residentialUserAuditHistoryActionsTypes =
  | IGetResidentialUserAuditHistorySuccessType
  | IClearResidentialUserAuditHistoryType;

export function residentialUserAuditHistory(
  state: ResidentialUserAuditHistory | null = null,
  action: residentialUserAuditHistoryActionsTypes,
): ResidentialUserAuditHistory | null {
  switch (action.type) {
    case CLEAR_RESIDENTIAL_USER_AUDIT_TRAIL:
      return defaultState.residentialUserAuditHistory;
    case GET_RESIDENTIAL_USER_AUDIT_TRAIL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
