import { call, put, takeLeading } from "redux-saga/effects";
import { toast } from "react-toastify";
import { reset, change } from "redux-form";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  IRequestLoggingAnalysisPhotoRetakeActionType,
  loggingAnalysisJobActions,
  IPostPublishLoggingAnalysisResultActionType,
  IPostGetNextLoggingAnalysisJobActionType,
  IPostGetNextLoggingAnalysisJobSuccessActionType,
  POST_GET_NEXT_LOGGING_ANALYSIS_JOB,
  POST_GET_NEXT_LOGGING_ANALYSIS_JOB_SUCCESS,
  CLEAR_LOGGING_ANALYSIS_JOB,
  POST_PUBLISH_LOGGING_ANALYSIS_RESULT,
  REQUEST_LOGGING_ANALYSIS_PHOTO_RETAKE,
  PostGetNextLoggingAnalysisJobResponse,
} from "./loggingAnalysisJob.actions";
import { loggingService } from "services/hermes/logging";
// import { clearLabelAnalysis } from "./clearLabelAnalysis.action";

function* requestLoggingAnalysisPhotoRetakeWorker(
  action: IRequestLoggingAnalysisPhotoRetakeActionType,
) {
  const redirectOnSuccess = action.payload.redirectOnSuccess;
  // Dont fetch a new job if we redirect the user based on packageId
  let cancelNextJob = false;

  try {
    const photoRetakeRequestBody = {
      fetchPackageId: action.payload.fetchPackageId,
      imageUrl: action.payload.imageUrl,
      userIds: [], // Hermes requires the empty array here
      carrierTracking: [], // Hermes requires the empty array here
      senders: [], // Hermes requires the empty array here
      processingStatus: 5, // 5 is a magic number for NEW_PICTURE_REQUIRED
      data: null,
    };

    // The Hermes endpoint to request a photo retake is the same as to publish
    // an analysis result, but with a different status
    const photoRetakeRequestOptions =
      loggingService.postPublishLoggingAnalysisResult(photoRetakeRequestBody);

    yield call(
      networkActions.makeAuthenticatedRequest,
      photoRetakeRequestOptions,
    );
    toast.success("Photo retake requested");
    if (redirectOnSuccess) {
      cancelNextJob = true;
      window.location.assign(redirectOnSuccess);
    }
  } catch (error) {
    toast.error("There was an error requesting a photo retake");
  }

  // Dispatches to clear logging form and get next job if not redirected
  yield put(reset("loggingAnalysis"));
  if (!cancelNextJob) {
    yield put(
      loggingAnalysisJobActions.postGetNextLoggingAnalysisJob({
        labelId: null,
      }),
    );
  }
}

function* postPublishLoggingAnalysisResultWorker(
  action: IPostPublishLoggingAnalysisResultActionType,
) {
  const redirectOnSuccess = action.payload.redirectOnSuccess;
  // Dont fetch a new job if we redirect the user based on packageId
  let cancelNextJob = false;

  try {
    const publishRequestBody = {
      fetchPackageId: action.payload.fetchPackageId,
      imageUrl: action.payload.imageUrl,
      userIds: [action.payload.userId],
      carrierTracking: [
        {
          carrier: action.payload.carrier,
          trackingNumber: action.payload.carrierTracking,
        },
      ],
      senders: [action.payload.sender],
      processingStatus: 7, // 7 is a magic number for SUCCESS_HUMAN
      data: null,
    };

    const publishAnalysisRequestOptions =
      loggingService.postPublishLoggingAnalysisResult(publishRequestBody);

    // Not using the publish response, may change soon
    // const publishAnalysisResponse: IPublishAnalysisResponseType = yield call(
    yield call(
      networkActions.makeAuthenticatedRequest,
      publishAnalysisRequestOptions,
    );
    toast.success("Logging analysis successful");
    if (redirectOnSuccess) {
      cancelNextJob = true;
      window.location.assign(redirectOnSuccess);
    }
  } catch (error) {
    toast.error("There was an error publishing the logging analysis");
  }

  // Dispatches to clear logging form and get next job if not redirected
  yield put(reset("loggingAnalysis"));
  yield put(loggingAnalysisJobActions.clearLoggingAnalysisJob());
  if (!cancelNextJob) {
    yield put(
      loggingAnalysisJobActions.postGetNextLoggingAnalysisJob({
        labelId: null,
      }),
    );
  }
}

function* postGetNextLoggingAnalysisJobWorker(
  action: IPostGetNextLoggingAnalysisJobActionType,
) {
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof loggingService.postGetNextLoggingAnalysisJob
    > = yield call(
      loggingService.postGetNextLoggingAnalysisJob,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: PostGetNextLoggingAnalysisJobResponse | string | undefined =
      yield call(networkActions.makeAuthenticatedRequest, requestOptions);
    // response could be undefined if hermes returns 204 with no info
    if (!!response && typeof response !== "string") {
      yield put(
        loggingAnalysisJobActions.postGetNextLoggingAnalysisJobSuccess(
          response,
        ),
      );
    } else {
      yield put(
        loggingAnalysisJobActions.postGetNextLoggingAnalysisJobSuccess({}),
      );
    }
  } catch (error) {
    toast.error("Error requesting the next Logging 2.0 analysis job");
  }
  yield put(loadingActions.endLoad(action.type));
}

/**
 * @description - When we get a new job, check to see if it has OCR suggestions.
 * Change carrier, tracking, and sender fields as needed by "dispatching"
 * the redux-form 'change' action creator with 'put' from redux-saga/effects
 */
function* postGetNextLoggingAnalysisJobSuccessWorker(
  action: IPostGetNextLoggingAnalysisJobSuccessActionType,
) {
  if (action.payload?.potentialTracking !== undefined) {
    yield put(
      change(
        "loggingAnalysis",
        "carrierTracking",
        action.payload.potentialTracking,
      ),
    );
  }

  if (action.payload?.potentialCarrier !== undefined) {
    yield put(
      change(
        "loggingAnalysis",
        "carrier",
        String(action.payload.potentialCarrier),
      ),
    );
  }

  if (action.payload?.potentialSender !== undefined) {
    yield put(
      change("loggingAnalysis", "sender", action.payload.potentialSender),
    );
  }
}

export const loggingAnalysisJobSagas = function* () {
  yield takeLeading(
    POST_GET_NEXT_LOGGING_ANALYSIS_JOB,
    postGetNextLoggingAnalysisJobWorker,
  );
  yield takeLeading(
    POST_GET_NEXT_LOGGING_ANALYSIS_JOB_SUCCESS,
    postGetNextLoggingAnalysisJobSuccessWorker,
  );
  yield takeLeading(
    CLEAR_LOGGING_ANALYSIS_JOB,
    loggingAnalysisJobActions.clearLoggingAnalysisJob,
  );
  yield takeLeading(
    POST_PUBLISH_LOGGING_ANALYSIS_RESULT,
    postPublishLoggingAnalysisResultWorker,
  );
  yield takeLeading(
    REQUEST_LOGGING_ANALYSIS_PHOTO_RETAKE,
    requestLoggingAnalysisPhotoRetakeWorker,
  );
};
