import { ILoggedPackageStoreType } from "./loggedPackages.reducer";

// Logged packages
export const ADD_LOGGED_PACKAGE = "ADD_LOGGED_PACKAGE";
export const CLEAR_LOGGED_PACKAGES = "CLEAR_LOGGED_PACKAGES";
export const UPDATE_LOGGED_PACKAGE = "UPDATE_LOGGED_PACKAGE";

export interface IClearLoggedPackagesActionType {
  type: typeof CLEAR_LOGGED_PACKAGES;
}

export interface IAddLoggedPackageActionType {
  type: typeof ADD_LOGGED_PACKAGE;
  payload: ILoggedPackageStoreType;
}

export interface IUpdateLoggedPackageActionType {
  type: typeof UPDATE_LOGGED_PACKAGE;
  payload: ILoggedPackageStoreType;
}

function updateLoggedPackage(loggedPackage: ILoggedPackageStoreType) {
  return { type: UPDATE_LOGGED_PACKAGE, payload: loggedPackage };
}

function addLoggedPackage(loggedPackage: ILoggedPackageStoreType) {
  return { type: ADD_LOGGED_PACKAGE, payload: loggedPackage };
}

export const loggedPackagesActions = {
  updateLoggedPackage,
  addLoggedPackage,
};
