import { takeLeading, call, put } from "redux-saga/effects";
import { paymentsService } from "services/hermes/payments";
import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  ValidateCouponAction,
  couponActions,
  VALIDATE_COUPON,
} from "./coupon.actions";
import { ValidateCouponResponse } from "./coupon.reducer";

function* validateCouponWorker(action: ValidateCouponAction) {
  yield put(couponActions.clearCoupon());
  yield put(loadingActions.startLoad(action.type));

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof paymentsService.validateCoupon> =
      yield call(paymentsService.validateCoupon, action.payload.couponId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: ValidateCouponResponse = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );

    yield put(couponActions.validateCouponSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

export const couponSagas = function* () {
  yield takeLeading(VALIDATE_COUPON, validateCouponWorker);
};
