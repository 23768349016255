import { config } from "constants/config";
import { authHeader } from "helpers/auth-header";
import { rootStore } from "rootStore";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { currentUserActions } from "slices/currentUser/currentUser.actions";
import { IAuthType } from "slices/authentication/authentication.actions";
import { localStorageGetItemSafe } from "helpers/localStorage/localStorageGetItemSafe";
import { FetchError } from "slices/authentication/network.actions";
import { captureSentryException } from "services/sentry";
import { toast } from "react-toastify";
import { history } from "helpers/history";

// fired when local storage changes
window.onstorage = () => {
  const localStorageUser = localStorageGetItemSafe("user");
  const { currentUser } = rootStore.getState();

  if (
    !currentUser ||
    !localStorageUser ||
    currentUser?.userId !== localStorageUser?.userId
  ) {
    rootStore.dispatch(currentUserActions.getMyUser());
  }
};

function tokenIsExpired(): boolean {
  const localStorageAuth = localStorageGetItemSafe("auth");

  if (!!localStorageAuth) {
    if (localStorageAuth.status === "NEW_PASSWORD_REQUIRED") {
      return false;
    }

    if ("expiresAt" in localStorageAuth && !!localStorageAuth.expiresAt) {
      return localStorageAuth.expiresAt <= new Date().getTime();
    }
  }

  return true;
}

function refreshToken(): Promise<Partial<IAuthType> | undefined> {
  let localRefreshToken = null;

  // Get refresh token if in localStorage
  const auth = localStorageGetItemSafe("auth");

  if (!!auth) {
    if ("refreshToken" in auth && !!auth.refreshToken) {
      localRefreshToken = auth.refreshToken;
    }
  }

  // Error if no refreshToken
  if (localRefreshToken === null) {
    return Promise.reject("No refreshToken");
  }

  // Build Request
  const requestOptions: RequestInit = {
    body: "{}",
    headers: { "Content-Type": "application/json", Refresh: localRefreshToken },
    method: "POST",
  };

  // Perform refresh request
  return fetch(`${config.apiUrl}/accounts/refresh`, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      return Promise.reject(error);
    });
}

function makeAuthenticatedRequest<T extends Record<string, unknown>>(
  authenticatedRequest: IAuthenticatedRequestDefinition,
  skipJsonParse = false,
): Promise<T | undefined> {
  // add in authHeaders
  const requestOptions = authenticatedRequest.requestOptions;
  requestOptions.headers = { ...authHeader(), ...requestOptions.headers };
  // Add in body if present
  if (authenticatedRequest.body) {
    requestOptions.body = JSON.stringify(authenticatedRequest.body);
  }

  try {
    return fetch(
      `${config.apiUrl}` + authenticatedRequest.requestUrl,
      requestOptions,
    )
      .then((response) => {
        const handledResponse = handleResponse<T>(response, skipJsonParse);

        return handledResponse;
      })

      .catch((error) => {
        return Promise.reject(error);
      });
  } catch (error) {
    throw Error("Native fetch failure");
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function displayNetworkError(e: any) {
  let errorString = "Network error";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (e.message && typeof e.fetchErrorCode === "number") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    errorString += `: ${e.message as string} [code: ${
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      e.fetchErrorCode as string
    }]`;
  } else {
    captureSentryException(e);
  }
  toast.error(errorString);
}

export const asyncMakeAuthenticatedRequest = async <
  T extends Record<string, unknown>,
>({
  authenticatedRequest,
  showError,
  rethrowError,
  skipJsonParse,
}: {
  authenticatedRequest: IAuthenticatedRequestDefinition;
  showError?: boolean;
  rethrowError?: boolean;
  skipJsonParse?: boolean;
}) => {
  if (tokenIsExpired()) {
    await refreshToken().catch((refreshError) => {
      displayNetworkError(refreshError);
    });
  }

  return await makeAuthenticatedRequest<T>(
    authenticatedRequest,
    skipJsonParse,
  ).catch((error) => {
    const e = error as FetchError;
    // If we have a likely token error
    if (e.hasOwnProperty("name") && e.name === "ErrorAuthenticating") {
      // Refresh token
      return refreshToken().then(() => {
        return networkService.makeAuthenticatedRequest<T>(
          authenticatedRequest,
          skipJsonParse,
        );
      });
    } else {
      showError && displayNetworkError(e);
      if (rethrowError) {
        throw Error(e.message);
      }
    }
  });
};

function handleResponse<T extends Record<string, unknown>>(
  response: Response,
  skipJsonParse = false,
): Promise<T | undefined> {
  return response
    .text()
    .then((text) => {
      let data: T | undefined;
      if (!!text) {
        data = skipJsonParse ? (text as unknown as T) : (JSON.parse(text) as T);
      }
      if (!response.ok) {
        if (response.status === 403) {
          // Navigate to invalid URL to show the 404 page
          history.push("/404");
        }

        return Promise.reject(data);
      }
      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const networkService = {
  makeAuthenticatedRequest,
  asyncMakeAuthenticatedRequest,
  refreshToken,
  tokenIsExpired,
};
