import { FetchLocalStorage } from "./LocalStorage";

export function localStorageSetItemSafe<Key extends keyof FetchLocalStorage>(
  key: Key,
  value: FetchLocalStorage[typeof key],
  win: Window = window,
): typeof value | null {
  try {
    const stringifiedValue = JSON.stringify(value);

    win.localStorage.setItem(key, stringifiedValue);

    // https://github.com/typescript-eslint/typescript-eslint/issues/2118
    // It's possible but unlikely that this could be set to another type and parsing wouldn't result in the expected type.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value;
  } catch (error) {
    return null;
  }
}
