export const getDefaultColumnsQueryParameters = (columnAccessors: string[]) => {
  return columnAccessors.reduce(
    (defaultColumnsQueryParameters: string, columnAccessor: string) => {
      defaultColumnsQueryParameters =
        defaultColumnsQueryParameters + `&defaultColumns[]=${columnAccessor}`;
      return defaultColumnsQueryParameters;
    },
    "",
  );
};
