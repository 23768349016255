import { IAuthType } from "./authentication.actions";
import { calculateExpiresAt } from "./calculateExpiresAt";

export function applyExpiresAt(auth: IAuthType): IAuthType {
  const expiresAt: number = calculateExpiresAt(auth.expiresIn);

  return {
    ...auth,
    expiresAt,
  };
}
