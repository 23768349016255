export const transformQueryParametersToString = <
  QueryParameters extends Record<string, unknown | undefined> | undefined,
>(
  queryParameters?: QueryParameters,
): string => {
  return !!queryParameters
    ? Object.keys(queryParameters as Parameters<typeof Object.keys>[0]).reduce(
        (queryString, parameterName, parameterIndex) => {
          if (queryParameters[parameterName]) {
            if (parameterIndex === 0) {
              return `?${parameterName}=${queryParameters[parameterName]}`;
            } else {
              return `${queryString}&${parameterName}=${queryParameters[parameterName]}`;
            }
          } else {
            return queryString;
          }
        },
        "",
      )
    : "";
};
