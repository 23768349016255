import { IWarehouseLocationStoreType } from "slices/warehouseLocation/warehouseLocation.reducer";
import {
  CLEAR_PACKAGE_RELOCATION_AUDIT_TRAIL,
  GET_PACKAGE_RELOCATION_AUDIT_TRAIL_SUCCESS,
  IClearPackageWarehouseLocationHistoryType,
  IGetPackageWarehouseLocationHistoryType,
} from "./packageWarehouseLocationHistory.actions";

export type PackageWarehouseLocationHistory = {
  fetchPackageId: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  warehouseLocation: IWarehouseLocationStoreType | null;
}[];

type packageWarehouseLocationHistoryActionsTypes =
  | IGetPackageWarehouseLocationHistoryType
  | IClearPackageWarehouseLocationHistoryType;

export function packageWarehouseLocationHistory(
  state: PackageWarehouseLocationHistory | null = null,
  action: packageWarehouseLocationHistoryActionsTypes,
): PackageWarehouseLocationHistory | null {
  switch (action.type) {
    case CLEAR_PACKAGE_RELOCATION_AUDIT_TRAIL:
      return null;
    case GET_PACKAGE_RELOCATION_AUDIT_TRAIL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
