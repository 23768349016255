import {
  CLEAR_BATCHES,
  IClearBatchesActionType,
  SEARCH_BATCHES_FAILURE,
  SEARCH_BATCHES_SUCCESS,
} from "slices/batches/batches.actions";
import {
  ISearchBatchesFailureActionType,
  ISearchBatchesSuccessActionType,
} from "slices/batches/batches.actions";
import { defaultState } from "default-state";
import { IBatchStoreType } from "slices/batch/batch.reducer";
import { IPaginatedResponseType } from "types/IPaginatedResponseType";

type IBatchesSearchResult = Omit<IBatchStoreType, "packages">;

export interface IBatchesStoreType extends IPaginatedResponseType {
  content: IBatchesSearchResult[];
}

export function batches(
  state = {},
  action: BatchesActionsTypes,
): Partial<IBatchesStoreType> {
  switch (action.type) {
    case CLEAR_BATCHES:
      return defaultState.batches;
    case SEARCH_BATCHES_SUCCESS:
      return action.payload;
    case SEARCH_BATCHES_FAILURE:
      return defaultState.batches;
    default:
      return state;
  }
}

// type definitions
type BatchesActionsTypes =
  | ISearchBatchesSuccessActionType
  | ISearchBatchesFailureActionType
  | IClearBatchesActionType;
