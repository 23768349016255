import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GetUserRoleMembershipParams,
  SaveUserRoleMembershipParams,
  UserRoleMembershipRequestDTO,
  UserRoleMembershipResponseDTO,
} from "services/hermes/userRoles";

export type Loadable<T> = {
  isLoading: boolean;
  error: string;
} & T;

export type FailureResponse<T> = {
  error: string;
} & T;

export type UserRoleMembership = {
  userId: number;
  roleMembership: UserRoleMembershipResponseDTO[];
};

export type UserRoleMembershipChange = Pick<UserRoleMembership, "userId"> &
  UserRoleMembershipRequestDTO;

export type UserRoleMembershipStore = Record<
  UserRoleMembership["userId"],
  Loadable<UserRoleMembership>
>;

export const initialState: UserRoleMembershipStore = {};

export const userRoleMembershipSlice = createSlice({
  name: "userRoleMembership",
  initialState,
  reducers: {
    getUserRoleMembershipFetch: (
      state,
      action: PayloadAction<GetUserRoleMembershipParams>,
    ) => {
      state[action.payload.userId] = {
        userId: action.payload.userId,
        roleMembership: [],
        isLoading: true,
        error: "",
      };
    },
    getUserRoleMembershipSuccess: (
      state,
      action: PayloadAction<UserRoleMembership>,
    ) => {
      state[action.payload.userId] = {
        ...action.payload,
        isLoading: false,
        error: "",
      };
    },
    getUserRoleMembershipFailure: (
      state,
      action: PayloadAction<
        FailureResponse<Pick<UserRoleMembership, "userId">>
      >,
    ) => {
      state[action.payload.userId] = {
        ...state[action.payload.userId],
        isLoading: false,
        error: action.payload.error,
      };
    },
    saveUserRoleMembershipPost: (
      state,
      action: PayloadAction<SaveUserRoleMembershipParams>,
    ) => {
      state[action.payload.userId] = {
        ...state[action.payload.userId],
        isLoading: true,
        error: "",
      };
    },
    saveUserRoleMembershipSuccess: (
      state,
      action: PayloadAction<UserRoleMembership>,
    ) => {
      state[action.payload.userId] = {
        ...action.payload,
        isLoading: false,
        error: "",
      };
    },
    saveUserRoleMembershipFailure: (
      state,
      action: PayloadAction<
        FailureResponse<Pick<UserRoleMembership, "userId">>
      >,
    ) => {
      state[action.payload.userId] = {
        ...state[action.payload.userId],
        isLoading: false,
        error: action.payload.error,
      };
    },
  },
});

export const {
  getUserRoleMembershipFetch,
  getUserRoleMembershipFailure,
  getUserRoleMembershipSuccess,
  saveUserRoleMembershipPost,
  saveUserRoleMembershipFailure,
  saveUserRoleMembershipSuccess,
} = userRoleMembershipSlice.actions;

export default userRoleMembershipSlice.reducer;
