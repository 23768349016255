import { Reducer } from "redux";

import {
  CLEAR_MODAL,
  IClearModalActionType,
  ITriggerModalActionType,
  TRIGGER_MODAL,
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  IAddNotificationActionType,
  IAlertUnverifiedEmailActionType,
  IAlertUnverifiedPhoneActionType,
  IClearNotificationActionType,
  IClearNotificationsActionType,
} from "slices/ui/ui.actions";
import { defaultState } from "default-state";

export interface INotificationType {
  id: string;
  title: string;
  message: string;
}

export type ModalStoreIndexType =
  | "verify_phone"
  | "verify_email"
  | "leave_liability_waiver"
  | "confirm_reset_password"
  | "change_password"
  | "resident_package_scheduler"
  | "use_our_mobile_app"
  | "leave_at_door_leave_liability_waiver"
  | "registration_leave_liability_waiver";

export interface IUIStoreType {
  modals: {
    [key in ModalStoreIndexType]?: boolean;
  };
  notifications: INotificationType[];
}

type UIActions =
  | IClearModalActionType
  | IAddNotificationActionType
  | IClearNotificationActionType
  | IClearNotificationsActionType
  | IAlertUnverifiedPhoneActionType
  | IAlertUnverifiedEmailActionType
  | ITriggerModalActionType;

export const ui: Reducer<IUIStoreType, UIActions> = (
  state = defaultState.ui,
  action,
) => {
  switch (action.type) {
    case CLEAR_MODAL:
      return { ...state, modals: {} };
    case TRIGGER_MODAL:
      const { modalId } = action;
      return { ...state, modals: { [modalId]: true } };
    case ADD_NOTIFICATION:
      const { notification } = action;
      return {
        ...state,
        notifications: [...state.notifications, notification],
      };
    case CLEAR_NOTIFICATION:
      const newNotificatons = [...state.notifications].filter(
        (notification) => {
          return notification.id !== action.payload;
        },
      );

      return { ...state, notifications: newNotificatons };
    case CLEAR_NOTIFICATIONS:
      return { ...state, notifications: [] };

    default:
      return { ...state };
  }
};
