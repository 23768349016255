import {
  FilterRuleValue,
  FilterRule,
  FilterRules,
  SortRule,
  IPaginatedTableSearchRequestType,
} from "types/IPaginatedTableSearchRequestType";

function trimString(value: string): string {
  const firstCharacterIsSpace = value[0] === " ";
  const lastCharacterIsSpace = value[value.length - 1] === " ";
  return firstCharacterIsSpace || lastCharacterIsSpace
    ? value
        .split("")
        .reduce(
          (
            finalValue: string,
            character: string,
            characterIndex: number,
            characterArray: string[],
          ) => {
            const isFirstCharacter = characterIndex === 0;
            const isLastCharacter =
              characterIndex === characterArray.length - 1;
            const shouldKeepCharacter = !(
              (isFirstCharacter || isLastCharacter) &&
              character === " "
            );

            if (shouldKeepCharacter) {
              finalValue = finalValue + character;
            }
            return finalValue;
          },
          "",
        )
    : value;
}

export function getFilterValue(value: FilterRuleValue): string {
  switch (typeof value) {
    case "string":
      return trimString(value);
    case "number":
      return `${value}`;
    case "boolean":
      return `${value ? "true" : "false"}`;
    default:
      return "";
  }
}

function getFilterParameterString({ id, value }: FilterRule): string {
  if (Array.isArray(value)) {
    return value.map((columnName: string) => `${id}[]=${columnName}`).join("&");
  } else if (typeof value === "object") {
    return `${id}=${encodeURIComponent(getFilterValue(value.value))}`;
  } else {
    return `${id}=${encodeURIComponent(getFilterValue(value))}`;
  }
}

function getFilterString(filters: FilterRules): string | undefined {
  return filters
    .map((filter: FilterRule) => {
      return getFilterParameterString(filter);
    })
    .join("&");
}

function getSortString(sort: SortRule): string {
  let sortString = `sort=${sort.id}`;

  if (sort.desc === true) {
    sortString = sortString.concat("&sortDesc=true");
  } else {
    sortString = sortString.concat("&sortDesc=false");
  }

  return sortString;
}

function getPageSizeString(pageSize: number): string {
  return `pageSize=${pageSize.toString()}`;
}

function getPageString(page: number): string {
  return `page=${page.toString()}`;
}

export function generateQueryString(
  parameters: IPaginatedTableSearchRequestType,
): string {
  let finalQueryString = "";
  const urlParameters: string[] = [];

  // Collect query parameters
  if (!!parameters.pageSize) {
    // Include pageSize
    urlParameters.push(getPageSizeString(parameters.pageSize));
  }
  if (!!parameters.page) {
    // Include page
    urlParameters.push(getPageString(parameters.page));
  }
  if (!!parameters.filters) {
    // Include filter rules
    const filterString: string | undefined = getFilterString(
      parameters.filters,
    );

    if (!!filterString) urlParameters.push(filterString);
  }
  if (!!parameters.sort && parameters.sort) {
    // Include sort rule
    urlParameters.push(getSortString(parameters.sort));
  }
  if (urlParameters.length > 0) {
    // Merge parameters
    finalQueryString = "?" + urlParameters.join("&");
  }

  return finalQueryString;
}

export function generateGenericQueryString(
  queryParams: Record<
    string,
    string | number | boolean | string[] | number[] | undefined
  >,
) {
  const queryParamArray: string[] = [];

  for (const [key, value] of Object.entries(queryParams)) {
    if (!!value) queryParamArray.push(`${key}=${value.toString()}`);
  }

  const finalQueryString = "?" + queryParamArray.join("&");
  return finalQueryString;
}
