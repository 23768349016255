import { call, put, takeLeading } from "redux-saga/effects";
import { loggingService } from "services/hermes/logging";
import { networkActions } from "../authentication/network.actions";
import { loadingActions } from "../loading/loading.actions";
import {
  GetLabelAnalysisSummaryAction,
  GET_LABEL_ANALYSIS_SUMMARY,
  labelAnalysisSummaryActions,
  LabelAnalysisSummaryResponse,
} from "./LabelAnalysisSummary.actions";

// Worker / Generator
function* labelAnalysisSummaryWorker(action: GetLabelAnalysisSummaryAction) {
  yield put(loadingActions.startLoad(action.type));

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof loggingService.getLabelAnalysisSummary
    > = yield call(
      loggingService.getLabelAnalysisSummary,
      action.payload.warehouseShortCode,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: LabelAnalysisSummaryResponse = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );

    yield put(
      labelAnalysisSummaryActions.getLabelAnalysisSummarySuccess(response),
    );
  } catch (error) {
    yield put(labelAnalysisSummaryActions.getLabelAnalysisSummaryFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

export const labelAnalysisSummarySagas = function* () {
  yield takeLeading(GET_LABEL_ANALYSIS_SUMMARY, labelAnalysisSummaryWorker);
};
