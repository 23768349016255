import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { usersService } from "services/hermes/users";
import { networkActions } from "slices/authentication/network.actions";
import { IResidentialUserStoreType } from "slices/currentUser/currentUser.reducer";
import { loadingActions } from "slices/loading/loading.actions";
import {
  GET_RESIDENTIAL_USER,
  IGetResidentialUserActionType,
  residentialUserActions,
} from "./residentialUser.actions";

function* getResidentialUserWorker(action: IGetResidentialUserActionType) {
  yield put(residentialUserActions.clearResidentialUser());
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof usersService.getResidentialUser> =
      yield call(usersService.getResidentialUser, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IResidentialUserStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(residentialUserActions.getResidentialUserSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

export const residentialUserSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(GET_RESIDENTIAL_USER, getResidentialUserWorker);
};
