export const ADD_ERROR_MESSAGE = "ADD_ERROR_MESSAGE";
export const CLEAR_ALL_ERROR_MESSAGES = "CLEAR_ALL_ERROR_MESSAGES";

// types
export const ErrorMessagesKeys = {
  REQUEST_NEW_PASSWORD_MESSAGE: "requestNewPasswordMessage",
} as const;

export interface IAddErrorMessagePayloadType {
  errorKey: (typeof ErrorMessagesKeys)[keyof typeof ErrorMessagesKeys];
  text: string;
}

export interface IClearErrorMessagePayloadType {
  errorKey: (typeof ErrorMessagesKeys)[keyof typeof ErrorMessagesKeys];
}

export interface IAddErrorMessageType {
  type: typeof ADD_ERROR_MESSAGE;
  payload: IAddErrorMessagePayloadType;
}

export interface IClearAllErrorsMessageType {
  type: typeof CLEAR_ALL_ERROR_MESSAGES;
}

function addErrorMessage(payload: IAddErrorMessagePayloadType) {
  return { type: ADD_ERROR_MESSAGE, payload };
}

function clearAllErrorMessages() {
  return { type: CLEAR_ALL_ERROR_MESSAGES };
}

export const errorMessagesActions = {
  addErrorMessage,
  clearAllErrorMessages,
};
