import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { LabelAnalysisResponse } from "./labelAnalysisQuery.reducer";

export const GET_LABEL_ANALYSIS_BY_QUERY = "GET_LABEL_ANALYSIS_BY_QUERY";
export const GET_LABEL_ANALYSIS_BY_QUERY_SUCCESS =
  "GET_LABEL_ANALYSIS_BY_QUERY_SUCCESS";
export const GET_LABEL_ANALYSIS_BY_QUERY_FAILURE =
  "GET_LABEL_ANALYSIS_BY_QUERY_FAILURE";
export const CLEAR_LABEL_ANALYSIS_QUERY_RESULTS =
  "CLEAR_LABEL_ANALYSIS_QUERY_RESULTS";

export interface QueryLabelAnalysisSuccessAction {
  type: typeof GET_LABEL_ANALYSIS_BY_QUERY_SUCCESS;
  payload: LabelAnalysisResponse;
}

export interface QueryLabelAnalysisAction {
  type: typeof GET_LABEL_ANALYSIS_BY_QUERY;
  payload: {
    paginationQuery: IPaginatedTableSearchRequestType;
  };
}

export interface QueryLabelAnalysisFailureAction {
  type: typeof GET_LABEL_ANALYSIS_BY_QUERY_FAILURE;
}

export interface ClearLabelAnalysisQueryResultsAction {
  type: typeof CLEAR_LABEL_ANALYSIS_QUERY_RESULTS;
}

function queryLabelAnalysis(
  paginationQuery: IPaginatedTableSearchRequestType,
): QueryLabelAnalysisAction {
  return {
    type: GET_LABEL_ANALYSIS_BY_QUERY,
    payload: { paginationQuery },
  };
}

function clearLabelAnalysisQueryResults(): ClearLabelAnalysisQueryResultsAction {
  return { type: CLEAR_LABEL_ANALYSIS_QUERY_RESULTS };
}

function queryLabelAnalysisSuccess(
  response: LabelAnalysisResponse,
): QueryLabelAnalysisSuccessAction {
  return {
    type: GET_LABEL_ANALYSIS_BY_QUERY_SUCCESS,
    payload: response,
  };
}

function queryLabelAnalysisFailure(): QueryLabelAnalysisFailureAction {
  return { type: GET_LABEL_ANALYSIS_BY_QUERY_FAILURE };
}

export const labelAnalysisQueryActions = {
  queryLabelAnalysis,
  clearLabelAnalysisQueryResults,
  queryLabelAnalysisSuccess,
  queryLabelAnalysisFailure,
};
