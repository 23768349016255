import { FetchError } from "slices/authentication/network.actions";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";
import { RegisterFormValues } from "views/pages/Register/RegisterForm";

export const SET_SESSION = "SET_SESSION";
export const REFRESH_SESSION = "REFRESH_SESSION";
export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT";
export const REGISTER_ACCOUNT_SUCCESS = "REGISTER_ACCOUNT_SUCCESS";
export const REQUEST_NEW_PASSWORD = "REQUEST_NEW_PASSWORD";
export const REQUEST_NEW_PASSWORD_SUCCESS = "REQUEST_NEW_PASSWORD_SUCCESS";
export const REQUEST_NEW_PASSWORD_FAILURE = "REQUEST_NEW_PASSWORD_FAILURE";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";
export const GET_USER_ATTRIBUTE_VERIFICATION_CODE =
  "GET_USER_ATTRIBUTE_VERIFICATION_CODE";
export const GET_USER_ATTRIBUTE_VERIFICATION_CODE_SUCCESS =
  "GET_USER_ATTRIBUTE_VERIFICATION_CODE_SUCCESS";
export const GET_USER_ATTRIBUTE_VERIFICATION_CODE_FAILURE =
  "GET_USER_ATTRIBUTE_VERIFICATION_CODE_FAILURE";
export const VERIFY_USER_ATTRIBUTE = "VERIFY_USER_ATTRIBUTE";
export const VERIFY_USER_ATTRIBUTE_SUCCESS = "VERIFY_USER_ATTRIBUTE_SUCCESS";
export const VERIFY_USER_ATTRIBUTE_FAILURE = "VERIFY_USER_ATTRIBUTE_FAILURE";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const POST_SET_PERMENANT_PASSWORD = "POST_SET_PERMENANT_PASSWORD";
export const POST_SET_PERMENANT_PASSWORD_SUCCESS =
  "POST_SET_PERMENANT_PASSWORD_SUCCESS";
export const POST_SET_PERMENANT_PASSWORD_FAILURE =
  "POST_SET_PERMENANT_PASSWORD_FAILURE";
export const POST_CHANGE_PASSWORD = "POST_CHANGE_PASSWORD";
export const POST_CHANGE_PASSWORD_SUCCESS = "POST_CHANGE_PASSWORD_SUCCESS";
export const POST_CHANGE_PASSWORD_FAILURE = "POST_CHANGE_PASSWORD_FAILURE";

export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const LOGOUT = "USERS_LOGOUT";
export const LOGIN_CHANGING = "USERS_LOGIN_CHANGING";

// types
export interface IGetUserAttributeVerificationCodeActionType {
  type: typeof GET_USER_ATTRIBUTE_VERIFICATION_CODE;
  payload: IGetUserAttributeVerificationCodePayloadType;
}
export interface IGetUserAttributeVerificationCodeSuccessType {
  type: typeof GET_USER_ATTRIBUTE_VERIFICATION_CODE_SUCCESS;
  payload: string;
}
export interface IGetUserAttributeVerificationCodeFailureType {
  type: typeof GET_USER_ATTRIBUTE_VERIFICATION_CODE_FAILURE;
  payload: string;
  error: { message: string };
}
export interface IGetUserAttributeVerificationCodePayloadType {
  userToken: string;
  attributeName: string;
}

interface IUserLoginRequestType {
  password: string;
  email: string;
}

export interface ILoginRequestActionType {
  type: typeof LOGIN_REQUEST;
  payload: { user: IUserLoginRequestType };
}

export type AccountsLoginResponseBody = {
  user: IUserStoreType;
  auth: IAuthType;
};

export interface ILoginSuccessActionType {
  type: typeof LOGIN_SUCCESS;
  payload: AccountsLoginResponseBody;
}
export interface ILoginFailureActionType {
  type: typeof LOGIN_FAILURE;
}
export interface ILoginChangingActionType {
  type: typeof LOGIN_CHANGING;
}
export interface ILogoutActionType {
  type: typeof LOGOUT;
  reason: string;
}

export interface IPostChangePasswordPayloadType {
  oldPassword: string;
  newPassword: string;
}

export interface IPostChangePasswordActionType {
  type: typeof POST_CHANGE_PASSWORD;
  payload: IPostChangePasswordPayloadType;
}
export interface IPostChangePasswordSuccessActionType {
  type: typeof POST_CHANGE_PASSWORD_SUCCESS;
}
export interface IPostChangePasswordFailureActionType {
  type: typeof POST_CHANGE_PASSWORD_FAILURE;
}

export interface IPostSetPermenantPasswordPayloadType {
  email: string;
  password: string;
  session: string;
}

export interface IPostSetPermenantPasswordActionType {
  type: typeof POST_SET_PERMENANT_PASSWORD;
  payload: IPostSetPermenantPasswordPayloadType;
}
export interface IPostSetPermenantPasswordSuccessActionType {
  type: typeof POST_SET_PERMENANT_PASSWORD_SUCCESS;
  payload: AccountsLoginResponseBody;
}
export interface IPostSetPermenantPasswordFailureActionType {
  type: typeof POST_SET_PERMENANT_PASSWORD_FAILURE;
}

export interface IRecoverPasswordRequestBodyType {
  email?: string;
  password?: string;
  confirmationCode?: string;
  confirmPassword?: string;
}
export interface IRecoverPasswordActionType {
  type: typeof RECOVER_PASSWORD;
  payload: IRecoverPasswordRequestBodyType;
}
export interface IRecoverPasswordSuccessActionType {
  type: typeof RECOVER_PASSWORD_SUCCESS;
  payload: { email: string; password: string };
}
export interface IRecoverPasswordFailureActionType {
  type: typeof RECOVER_PASSWORD_FAILURE;
  payload: FetchError;
}

export interface IRefreshSessionActionType {
  type: typeof REFRESH_SESSION;
}

export interface IRefreshTokenActionType {
  type: typeof REFRESH_TOKEN;
}

export interface IAuthType {
  accessToken: string | null;
  expiresAt?: number;
  expiresIn: number;
  refreshToken: string | null;
  status: string;
  userToken: string | null;
  session?: string;
  phoneVerified: boolean;
  emailVerified: boolean;
}

export interface IRefreshTokenSuccessActionType {
  type: typeof REFRESH_TOKEN_SUCCESS;
  newTokens: IAuthType;
}

export interface IRegisterAccountActionType {
  type: typeof REGISTER_ACCOUNT;
  payload: RegisterFormValues;
}
export interface IRegisterAccountSuccessActionType {
  type: typeof REGISTER_ACCOUNT_SUCCESS;
  payload: AccountsLoginResponseBody;
}

export interface IPasswordResetRequestBodyType {
  email?: string;
}
export interface IRequestNewPasswordActionType {
  type: typeof REQUEST_NEW_PASSWORD;
  payload: IPasswordResetRequestBodyType;
}
export interface IRequestNewPasswordSuccessActionType {
  type: typeof REQUEST_NEW_PASSWORD_SUCCESS;
  payload: { email: string; password: string };
}
export interface IRequestNewPasswordFailureActionType {
  type: typeof REQUEST_NEW_PASSWORD_FAILURE;
}
export interface ISetSessionActionType {
  type: typeof SET_SESSION;
  payload: AccountsLoginResponseBody;
}

export interface IVerifyUserAttributePayloadType {
  userToken: string;
  attributeName: "email" | "phone_number";
  code: string;
}
export interface IVerifyUserAttributeActionType {
  type: typeof VERIFY_USER_ATTRIBUTE;
  payload: IVerifyUserAttributePayloadType;
}
export interface IVerifyUserAttributeSuccessActionType {
  type: typeof VERIFY_USER_ATTRIBUTE_SUCCESS;
}
export interface IVerifyUserAttributeFailureActionType {
  type: typeof VERIFY_USER_ATTRIBUTE_FAILURE;
}

function getUserAttributeVerificationCode(
  payload: IGetUserAttributeVerificationCodePayloadType,
) {
  return { type: GET_USER_ATTRIBUTE_VERIFICATION_CODE, payload };
}
function getUserAttributeVerificationCodeSuccess(attributeName: string) {
  return {
    payload: attributeName,
    type: GET_USER_ATTRIBUTE_VERIFICATION_CODE_SUCCESS,
  };
}
function getUserAttributeVerificationCodeFailure(
  attributeName: string,
  error: { message: string },
) {
  return {
    payload: attributeName,
    error,
    type: GET_USER_ATTRIBUTE_VERIFICATION_CODE_FAILURE,
  };
}

function loginRequest(user: IUserLoginRequestType) {
  return { type: LOGIN_REQUEST, payload: { user } };
}
function loginSuccess(loginResponse: AccountsLoginResponseBody) {
  return { type: LOGIN_SUCCESS, payload: loginResponse };
}
function loginFailure(error: string) {
  return { type: LOGIN_FAILURE, message: error };
}
function loginChanging() {
  return { type: LOGIN_CHANGING };
}

function logout(reason: string): ILogoutActionType {
  return { type: LOGOUT, reason };
}

function postChangePassword(payload: IPostChangePasswordPayloadType) {
  return { type: POST_CHANGE_PASSWORD, payload };
}
function postChangePasswordSuccess() {
  return { type: POST_CHANGE_PASSWORD_SUCCESS };
}
function postChangePasswordFailure() {
  return { type: POST_CHANGE_PASSWORD_FAILURE };
}

function postSetPermanentPassword(
  payload: IPostSetPermenantPasswordPayloadType,
) {
  return { type: POST_SET_PERMENANT_PASSWORD, payload };
}
function postSetPermanentPasswordSuccess(response: AccountsLoginResponseBody) {
  return { type: POST_SET_PERMENANT_PASSWORD_SUCCESS, payload: response };
}
function postSetPermanentPasswordFailure() {
  return { type: POST_SET_PERMENANT_PASSWORD_FAILURE };
}

function recoverPassword(credentials: IRecoverPasswordRequestBodyType) {
  return { type: RECOVER_PASSWORD, payload: credentials };
}
function recoverPasswordSuccess(credentials: {
  email?: string;
  password?: string;
}) {
  return { type: RECOVER_PASSWORD_SUCCESS, payload: credentials };
}
function recoverPasswordFailure(error: FetchError) {
  return { type: RECOVER_PASSWORD_FAILURE, payload: error };
}

function refreshSession() {
  return { type: REFRESH_SESSION };
}

function refreshToken(): IRefreshTokenActionType {
  return { type: REFRESH_TOKEN };
}
function refreshTokenSuccess(
  newTokens: IAuthType,
): IRefreshTokenSuccessActionType {
  return { type: REFRESH_TOKEN_SUCCESS, newTokens };
}

function registerAccount(newUser: RegisterFormValues) {
  return { type: REGISTER_ACCOUNT, payload: newUser };
}
function registerAccountSuccess(response: ILoginSuccessActionType) {
  return { type: REGISTER_ACCOUNT_SUCCESS, payload: response };
}

function requestNewPassword(credentials: IPasswordResetRequestBodyType) {
  return { type: REQUEST_NEW_PASSWORD, payload: credentials };
}
function requestNewPasswordFailure() {
  return { type: REQUEST_NEW_PASSWORD_FAILURE };
}

function setSession(payload: AccountsLoginResponseBody) {
  return { type: SET_SESSION, payload };
}

function verifyUserAttribute(payload: IVerifyUserAttributePayloadType) {
  return { type: VERIFY_USER_ATTRIBUTE, payload };
}
function verifyUserAttributeSuccess() {
  return { type: VERIFY_USER_ATTRIBUTE_SUCCESS };
}
function verifyUserAttributeFailure() {
  return { type: VERIFY_USER_ATTRIBUTE_FAILURE };
}

export const authenticationActions = {
  getUserAttributeVerificationCode,
  loginChanging,
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
  postChangePassword,
  postSetPermanentPassword,
  recoverPassword,
  refreshSession,
  refreshToken,
  registerAccount,
  requestNewPassword,
  setSession,
  verifyUserAttribute,
  getUserAttributeVerificationCodeSuccess,
  getUserAttributeVerificationCodeFailure,
  postChangePasswordSuccess,
  postChangePasswordFailure,
  postSetPermanentPasswordSuccess,
  postSetPermanentPasswordFailure,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  refreshTokenSuccess,
  registerAccountSuccess,
  requestNewPasswordFailure,
  verifyUserAttributeSuccess,
  verifyUserAttributeFailure,
};
