/* tslint:disable:max-classes-per-file */
/*
  Purpose: This helper is intended to simplify and standardize BEM classname pattern usage.
    - Pattern: prefix-block__element--modifier extra
*/

const delimiterDefaultOptions: DelimiterOptionsType = {
  block: "",
  element: "__",
  modifier: "--",
  prefix: "-",
};

class Level {
  name: string;
  type: DelimiterOptionsIndexType;
  delimiter?: string;
  modifiers?: string[];
  extras?: string[];
  constructor({
    name,
    type,
    modifiers,
    extras,
  }: ILevelConstructorParametersType) {
    this.name = name;
    this.type = type;
    this.modifiers = modifiers;
    this.extras = extras;
    this.delimiter = delimiterDefaultOptions[type];
  }
}

export class BEM {
  prefix?: IPrefixType;
  block: Level;
  elements: Level[];
  constructor({ prefix, block, elements }: IBEMConstructorParametersType) {
    this.prefix = prefix ? this.initializePrefix(prefix) : undefined;
    this.block = new Level({ ...block, type: "block" });
    this.elements =
      elements && elements.length
        ? elements.map(
            (element: {
              name: string;
              modifiers?: string[];
              extras?: string[];
            }) => {
              return new Level({ ...element, type: "element" });
            },
          )
        : [];
  }
  initializePrefix(config: {
    name: PrefixNameType;
    delimiter?: string;
  }): IPrefixType | undefined {
    if (config) {
      return {
        delimiter: config.delimiter || delimiterDefaultOptions.prefix,
        name: config.name,
      };
    }
    return;
  }
  getPrefix(): IPrefixType | undefined {
    return this.prefix;
  }
  getPrefixClass(): string {
    if (this.prefix && this.prefix.delimiter) {
      return `${this.prefix.name}${this.prefix.delimiter}`;
    }
    return "";
  }
  getBlockBase(): string {
    return !!this.prefix
      ? `${this.getPrefixClass()}${this.block.name}`
      : this.block.name;
  }
  getBlockClassNames(options?: {
    modifiers?: string[];
    extras?: string[];
  }): string {
    let classNames = [this.getBlockBase()];
    if (this.block.modifiers || options?.modifiers) {
      let blockModifiers = this.block.modifiers || [];
      if (!!options && !!options.modifiers) {
        blockModifiers = blockModifiers.concat(options.modifiers);
      }
      classNames = classNames.concat(
        this.addModifiers(this.getBlockBase(), blockModifiers),
      );
    }
    if (this.block.extras || options?.extras) {
      let blockExtras = this.block.extras || [];
      if (!!options && !!options.extras) {
        blockExtras = blockExtras.concat(options.extras);
      }
      classNames = classNames.concat(this.addExtras(blockExtras));
    }
    return classNames.join(" ");
  }
  getElementClassNames(
    elementName: string,
    options?: { modifiers?: string[]; extras?: string[] },
  ): string {
    const selectedElement = this.elements.filter(
      (element: Level) => elementName === element.name,
    )[0];
    if (!!selectedElement && !!selectedElement.delimiter) {
      const base = [
        this.getBlockBase(),
        selectedElement.delimiter,
        selectedElement.name,
      ].join("");
      let classNames = [base];
      if (selectedElement.modifiers || options?.modifiers) {
        let elementModifiers = selectedElement.modifiers || [];
        if (!!options && !!options.modifiers) {
          elementModifiers = elementModifiers.concat(options.modifiers);
        }
        const addedModifierClassNames = this.addModifiers(
          base,
          elementModifiers,
        );
        classNames = classNames.concat(addedModifierClassNames);
      }
      if (selectedElement.extras || options?.extras) {
        let elementExtras = selectedElement.extras || [];
        if (!!options && !!options.extras) {
          elementExtras = elementExtras.concat(options.extras);
        }
        classNames = classNames.concat(this.addExtras(elementExtras));
      }
      return classNames.join(" ");
    } else {
      throw new Error(
        `Found no element by the name ${elementName} in provided element list.`,
      );
    }
  }
  addModifiers(base: string, modifiers: string[]): string[] {
    return modifiers.map(
      (modifier: string) =>
        `${base}${delimiterDefaultOptions.modifier}${modifier}`,
    );
  }
  addExtras(extras: string[]): string[] {
    return extras.map((extra: string) => extra);
  }
}

type PrefixNameType = "fetch" | "table" | "form" | "alert";

interface IPrefixType {
  name: PrefixNameType;
  delimiter?: string;
}

interface ILevelConstructorParametersType {
  name: string;
  type: DelimiterOptionsIndexType;
  modifiers?: string[];
  extras?: string[];
}

interface IBEMConstructorParametersType {
  prefix?: IPrefixType;
  block: {
    name: string;
    modifiers?: string[];
    extras?: string[];
  };
  elements?: {
    name: string;
    modifiers?: string[];
    extras?: string[];
  }[];
}

type DelimiterOptionsIndexType = "prefix" | "block" | "element" | "modifier";

type DelimiterOptionsType = {
  [key in DelimiterOptionsIndexType]: string;
};
