import classnames from "classnames";
import React, { useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse, Container, Nav, Navbar, NavItem } from "reactstrap";

import { BEM } from "helpers/BEM.helper";

const classes = new BEM({
  block: { name: "AuthNavbar" },
  elements: [
    { name: "login", extras: ["nav-link"] },
    { name: "register", extras: ["nav-link"] },
    { name: "forgotPassword", extras: ["nav-link"] },
  ],
});

interface AuthNavbarState {
  collapseOpen?: boolean;
  color?: string;
}

export const AuthNavbar: React.FC = () => {
  const location = useLocation();

  const [state, setState] = useState<AuthNavbarState>({
    collapseOpen: false,
    color: "navbar-transparent",
  });

  const onCollapseClick = () =>
    setState((previous) => ({
      collapseOpen: !previous.collapseOpen,
      color: !previous.collapseOpen ? "bg-white" : "navbar-transparent",
    }));

  const navItems = useMemo(() => {
    const items = [];

    if (location.pathname !== "/register") {
      items.push({
        className: classes.getElementClassNames("register"),
        icon: <i className="nc-icon nc-book-bookmark" />,
        text: "Register",
        to: "/register",
      });
    }
    if (location.pathname !== "/forgot-password") {
      items.push({
        className: classes.getElementClassNames("forgotPassword"),
        icon: <i className="nc-icon nc-key-25" />,
        text: "Forgot Password",
        to: "/forgot-password",
      });
    }
    if (location.pathname !== "/login") {
      items.push({
        className: classes.getElementClassNames("login"),
        icon: <i className="nc-icon nc-book-bookmark" />,
        text: "Login",
        to: "/login",
      });
    }

    return items.map(({ to, className, icon, text }) => (
      <NavItem key={text}>
        <NavLink to={to} className={className}>
          {icon}
          {text}
        </NavLink>
      </NavItem>
    ));
  }, [location]);

  return (
    <Navbar
      className={classnames(
        "navbar-auth navbar-absolute fixed-top",
        state.color,
      )}
      expand="lg"
    >
      <Container>
        <button
          aria-controls="navigation-index"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-toggle="collapse"
          type="button"
          onClick={onCollapseClick}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse
          isOpen={state.collapseOpen}
          className="justify-content-end"
          navbar={true}
        >
          <Nav navbar={true}>{navItems}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};
