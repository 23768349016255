import { Reducer } from "redux";

import { defaultState } from "default-state";
import {
  IGetPackageByIdScheduleHistorySuccessActionType,
  GET_PACKAGE_BY_ID_SCHEDULE_HISTORY_SUCCESS,
} from "./scheduleHistory.actions";

interface FetchPackageScheduleHistoryEntry {
  active: boolean;
  deliveryStartDate: string;
  deliveryEndDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  deliveryPreferenceOverride?: string;
  createdBy: string;
  createdDate: string;
}

export type IScheduleHistoryStoreType = FetchPackageScheduleHistoryEntry[];

export type ScheduleHistoryActionTypes =
  IGetPackageByIdScheduleHistorySuccessActionType;

export const scheduleHistory: Reducer<
  IScheduleHistoryStoreType | undefined,
  ScheduleHistoryActionTypes
> = (
  state = defaultState.scheduleHistory,
  action: ScheduleHistoryActionTypes,
): IScheduleHistoryStoreType => {
  switch (action.type) {
    case GET_PACKAGE_BY_ID_SCHEDULE_HISTORY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
