import React, { ReactNode } from "react";
import { WrappedFieldProps } from "redux-form";
import { FormGroup } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

import { FetchFieldLabel } from "views/common/forms/fields/FetchFieldLabel";

function determineLabelPosition(type: string) {
  if (type === "checkbox") {
    return false;
  }
  return true;
}

export interface FetchFieldValidationWrapperProps {
  label?: string | { text: string; tag: string };
  type: InputType;
  children?: ReactNode;
  classNames: { block: string; label: string; error: string };
  ["data-cy"]?: string;
}

export const FetchFieldValidationWrapper: React.FC<
  FetchFieldValidationWrapperProps & WrappedFieldProps
> = (
  props: FetchFieldValidationWrapperProps & Pick<WrappedFieldProps, "meta">,
) => {
  const {
    label,
    children,
    type,
    classNames: { block, label: labelClassNames, error: errorClassNames },
  } = props;

  const errorExists = !!props.meta.error;
  const labelExists = !!label;
  const labelFirst = determineLabelPosition(type);
  const shouldAppendDangerClass: boolean = props.meta.touched && errorExists;
  const blockClassNames: string = shouldAppendDangerClass
    ? block + " has-danger"
    : block;
  const Label: JSX.Element | null = labelExists ? (
    <FetchFieldLabel {...{ label, className: labelClassNames }} />
  ) : null;
  const shouldShowLabelBefore: boolean = labelExists && labelFirst;
  const shouldShowLabelAfter: boolean = labelExists && !labelFirst;
  return (
    <FormGroup
      className={blockClassNames}
      style={{ width: "100%" }}
      data-cy={props["data-cy"]}
    >
      {shouldShowLabelBefore && Label}
      {children}
      {shouldShowLabelAfter && Label}
      {(props.meta.touched ||
        (props.type === "select" && props.meta.visited)) &&
        errorExists && (
          <label className={errorClassNames + " error" + " fds-text-red-500"}>
            {props.meta.error}
          </label>
        )}
    </FormGroup>
  );
};
