import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { defaultState } from "default-state";
import { sentryReduxEnhancer } from "services/sentry";
import { rootReducer } from "rootReducer";
import { rootSaga } from "./rootSaga";
import { config } from "constants/config";

// middleware
const sagaMiddleware = createSagaMiddleware();

export const rootStore = configureStore({
  reducer: rootReducer,
  preloadedState: defaultState,
  middleware: [sagaMiddleware],
  enhancers: [sentryReduxEnhancer],
  devTools: config.apiUrl !== process.env.REACT_APP_API_URL_PROD,
  // NODE_ENV is set to development, test, or production based on the npm command invoked
  // see https://create-react-app.dev/docs/adding-custom-environment-variables/
});

// then run the saga
sagaMiddleware.run(rootSaga);
