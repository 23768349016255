/*eslint-disable*/
// used for making the prop types of this component
import React from "react";
import { Container, Row } from "reactstrap";

interface FooterProps {
  fluid: boolean;
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  return (
    <footer className={"footer"}>
      <Container fluid={props.fluid ? true : false}>
        <Row>
          <div className="credits">
            <span className="copyright text-white">
              &copy; {new Date().getFullYear()} Fetch Package, Inc. All rights
              reserved.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
