import { Reducer } from "redux";
import {
  ClearLabelImageAction,
  GetLabelImageAction,
  GetLabelImageSuccessAction,
  GetLabelImageFailureAction,
  CLEAR_LABEL_IMAGE,
  GET_LABEL_IMAGE_SUCCESS,
  GET_LABEL_IMAGE_FAILURE,
} from "./labelImage.actions";

export interface LabelImageLoggingResponse {
  fetchPackageId: number;
  imageUrl: string | null;
  warehouseId: number | null;
  packageType: number | null;
}

export type LabelImageStore = Record<
  string,
  LabelImageLoggingResponse | null
> | null;

type LabelImageActions =
  | ClearLabelImageAction
  | GetLabelImageAction
  | GetLabelImageSuccessAction
  | GetLabelImageFailureAction;

export const labelImage: Reducer<LabelImageStore, LabelImageActions> = (
  state,
  action,
) => {
  switch (action.type) {
    case CLEAR_LABEL_IMAGE:
      return { ...state, [`${action.payload.packageId}`]: null };
    case GET_LABEL_IMAGE_SUCCESS:
      return { ...state, [`${action.payload.fetchPackageId}`]: action.payload };
    case GET_LABEL_IMAGE_FAILURE:
      return { ...state, [`${action.payload.packageId}`]: null };
    default:
      return state || null;
  }
};
