import { Reducer } from "redux";

import {
  CLEAR_PACKAGE_AUDIT_TRAIL,
  GET_PACKAGE_AUDIT_TRAIL_SUCCESS,
  IClearPackageAuditTrailActionType,
  IGetPackageAuditTrailSuccessActionType,
} from "slices/packageAuditTrail/packageAuditTrail.actions";
import { defaultState } from "default-state";

export interface IPackageAudit {
  fetchPackageId: number;
  sender: string;
  carrier: number;
  carrierTracking: string;
  status: number;
  shelfLocation: string;
  user: string;
  createdDate?: string;
  createdBy?: string;
  lastModifiedDate: string;
  invocationTime: string;
  lastModifiedBy: string;
}

export type PackageAuditTrailStoreType = { content: IPackageAudit[] };

export const packageAuditTrail: Reducer<
  PackageAuditTrailStoreType | undefined
> = (
  state = defaultState.packageAuditTrail,
  action: PackageAuditTrailActionTypes,
) => {
  switch (action.type) {
    case CLEAR_PACKAGE_AUDIT_TRAIL:
      return defaultState.packageAuditTrail;
    case GET_PACKAGE_AUDIT_TRAIL_SUCCESS:
      return { content: action.payload };
    default:
      return { ...state };
  }
};

export type PackageAuditTrailActionTypes =
  | IClearPackageAuditTrailActionType
  | IGetPackageAuditTrailSuccessActionType;
