import { IPackagesStoreType } from "slices/packages/packages.reducer";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IPackageStoreType } from "./package.reducer";

// Packages
export const SEARCH_WINDOW_PACKAGES = "SEARCH_WINDOW_PACKAGES";
export const SEARCH_WINDOW_PACKAGES_SUCCESS = "SEARCH_WINDOW_PACKAGES_SUCCESS";
export const CLEAR_PACKAGE = "CLEAR_PACKAGE";

export const SAVE_PACKAGE = "SAVE_PACKAGE";
export const SAVE_PACKAGE_SUCCESS = "SAVE_PACKAGE_SUCCESS";
export const GET_PACKAGE = "GET_PACKAGE";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const REPRINT_PACKAGE_LABEL = "REPRINT_PACKAGE_LABEL";

export const POST_PICKUP_REQUESTED = "POST_PICKUP_REQUESTED";
export const POST_PICKUP_REQUESTED_SUCCESS = "POST_PICKUP_REQUESTED_SUCCESS";
export const POST_PICKUP_REQUESTED_FAILURE = "POST_PICKUP_REQUESTED_FAILURE";
export const POST_EXCEPTION_PACKAGES = "POST_EXCEPTION_PACKAGES";
export const POST_DELIVER_PACKAGE = "POST_DELIVER_PACKAGE";
export const POST_DELIVER_PACKAGE_SUCCESS = "POST_DELIVER_PACKAGE_SUCCESS";
export const POST_DELIVER_PACKAGE_FAILURE = "POST_DELIVER_PACKAGE_FAILURE";

export interface IPostExceptionPackagesActionType {
  type: typeof POST_EXCEPTION_PACKAGES;
  fetchPackageIds: number[];
}

export interface ISearchWindowPackagesSuccessActionType {
  type: typeof SEARCH_WINDOW_PACKAGES_SUCCESS;
  payload: IPackagesStoreType;
}
export interface ISavePackageActionType {
  type: typeof SAVE_PACKAGE;
  payload: IPackageStoreType;
}
export interface ISavePackageSuccessActionType {
  type: typeof SAVE_PACKAGE_SUCCESS;
  fetchPackageId?: IPackageStoreType["fetchPackageId"];
}
export interface IReprintPackageLabelActionType {
  type: typeof REPRINT_PACKAGE_LABEL;
  payload: {
    packageId: string;
  };
}
export interface IPostPickupRequestedPayloadType {
  fetchPackageId: number;
}

type PickupRequestedOptions = {
  refresh?: boolean;
  isMyPackage?: boolean;
  successHandler?: () => void;
  failureHandler?: () => void;
};

export interface IPostPickupRequestedActionType {
  type: typeof POST_PICKUP_REQUESTED;
  payload: IPostPickupRequestedPayloadType;
  options?: PickupRequestedOptions;
}
export interface IPostPickupRequestedSuccessActionType {
  type: typeof POST_PICKUP_REQUESTED_SUCCESS;
  fetchPackageId: number;
  options?: PickupRequestedOptions;
}
export interface IPostPickupRequestedFailureActionType {
  type: typeof POST_PICKUP_REQUESTED_FAILURE;
}
export interface IPostDeliverPackagePayloadType {
  fetchPackageIds: number[];
  deliveryReceiptMethod: 1 | 2;
  image: string;
}

export interface IPostDeliverPackageActionType {
  type: typeof POST_DELIVER_PACKAGE;
  payload: IPostDeliverPackagePayloadType;
}
export interface IPostDeliverPackageSuccessActionType {
  type: typeof POST_DELIVER_PACKAGE_SUCCESS;
}
export interface IPostDeliverPackageFailureActionType {
  type: typeof POST_DELIVER_PACKAGE_FAILURE;
}

export interface IGetPackageActionType {
  type: string;
  payload: {
    fetchPackageId: string;
  };
}

function postExceptionPackages(
  fetchPackageIds: number[],
): IPostExceptionPackagesActionType {
  return { type: POST_EXCEPTION_PACKAGES, fetchPackageIds };
}

function searchWindowPackages(packageSearch: IPaginatedTableSearchRequestType) {
  return { type: SEARCH_WINDOW_PACKAGES, payload: packageSearch };
}

function searchWindowPackagesSuccess(packageResponse: IPackagesStoreType) {
  return { type: SEARCH_WINDOW_PACKAGES_SUCCESS, payload: packageResponse };
}

function savePackage(formData: IPackageStoreType): ISavePackageActionType {
  return { type: SAVE_PACKAGE, payload: formData };
}

function savePackageSuccess(
  fetchPackageId: ISavePackageSuccessActionType["fetchPackageId"],
): ISavePackageSuccessActionType {
  return {
    type: SAVE_PACKAGE_SUCCESS,
    fetchPackageId,
  };
}

function reprintPackageLabel(fetchPackageId: string) {
  return {
    payload: { packageId: fetchPackageId },
    type: REPRINT_PACKAGE_LABEL,
  };
}

function postPickupRequested(
  payload: IPostPickupRequestedPayloadType,
  options?: PickupRequestedOptions,
): IPostPickupRequestedActionType {
  return { type: POST_PICKUP_REQUESTED, payload, options };
}
function postPickupRequestedSuccess(
  fetchPackageId: number,
  options?: PickupRequestedOptions,
): IPostPickupRequestedSuccessActionType {
  return {
    type: POST_PICKUP_REQUESTED_SUCCESS,
    fetchPackageId: fetchPackageId,
    options,
  };
}
function postPickupRequestedFailure(): IPostPickupRequestedFailureActionType {
  return { type: POST_PICKUP_REQUESTED_FAILURE };
}

function postDeliverPackage(payload: IPostDeliverPackagePayloadType) {
  return { type: POST_DELIVER_PACKAGE, payload };
}
function postDeliverPackageSuccess() {
  return { type: POST_DELIVER_PACKAGE_SUCCESS };
}
function postDeliverPackageFailure() {
  return { type: POST_DELIVER_PACKAGE_FAILURE };
}

function getPackage(fetchPackageId: string) {
  return { type: GET_PACKAGE, payload: { fetchPackageId } };
}

function getPackageSuccess(packageResponse: IPackageStoreType) {
  return { type: GET_PACKAGE_SUCCESS, payload: packageResponse };
}

function clearPackage() {
  return { type: CLEAR_PACKAGE };
}

export const packageActions = {
  clearPackage,
  getPackage,
  postDeliverPackage,
  postPickupRequested,
  reprintPackageLabel,
  savePackage,
  searchWindowPackages,
  postExceptionPackages,
  getPackageSuccess,
  searchWindowPackagesSuccess,
  savePackageSuccess,
  postPickupRequestedSuccess,
  postPickupRequestedFailure,
  postDeliverPackageSuccess,
  postDeliverPackageFailure,
};
