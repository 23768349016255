import {
  ISearchBuildingsFailureActionType,
  ISearchBuildingsSuccessActionType,
  IClearBuildingsActionType,
  CLEAR_BUILDINGS,
  SEARCH_BUILDINGS_FAILURE,
  SEARCH_BUILDINGS_SUCCESS,
} from "slices/buildings/buildings.actions";

import { defaultState } from "default-state";
import { IUnitType } from "slices/building/building.reducer";
import { IPaginatedResponseType } from "types/IPaginatedResponseType";

export interface IBuildingStoreContentType {
  buildingId: number;
  warehouseId: number;
  warehouseName: string;
  warehouseShortCode: string;
  name: string;
  shortCode: string;
  packageDeliveryPolicy: number;
  market: number;
  supportId?: string;
  units: IUnitType[];
  coordinates?: { center: { lat: number; lng: number } };
}

export interface IBuildingsStoreType extends IPaginatedResponseType {
  content: Array<IBuildingStoreContentType>;
}

export function buildings(
  state = {},
  action: BuildingsActionsTypes,
): Partial<IBuildingsStoreType> {
  switch (action.type) {
    case CLEAR_BUILDINGS:
      return defaultState.buildings;
    case SEARCH_BUILDINGS_SUCCESS:
      return action.payload;
    case SEARCH_BUILDINGS_FAILURE:
      return defaultState.buildings;
    default:
      return state;
  }
}

// type definitions
type BuildingsActionsTypes =
  | ISearchBuildingsSuccessActionType
  | ISearchBuildingsFailureActionType
  | IClearBuildingsActionType;
