import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";

import { NavRoute } from "routes/navRoutes";
import { generateBemClassNames } from "helpers/generateBemClassNames.helper";
import { FetchNavSubLink } from "./FetchNavSubLink";
import warningCircle from "assets/img/warning-circle.svg";
import { FetchIcon } from "views/common/misc/FetchIcon";

const classes = generateBemClassNames({
  block: { name: "Sidebar" },
  elements: [{ name: "link" }, { name: "viewSubmenuCaret", extras: ["caret"] }],
});

interface FetchNavLinkCollapseProps {
  route: NavRoute;
  open: boolean;
  onToggle: (navRoute: NavRoute) => void;
}

const Component: React.FC<FetchNavLinkCollapseProps> = ({
  route,
  open,
  onToggle,
}: FetchNavLinkCollapseProps) => {
  const location = useLocation();
  const parentActive = location.pathname === route.path;

  const childNavLinks = useMemo(
    () =>
      route?.children?.map((childRoute) => {
        return <FetchNavSubLink key={childRoute.path} route={childRoute} />;
      }) || [],
    [route],
  );

  return (
    <li
      className={classes.elements.link({
        extras: [parentActive ? "active" : ""],
      })}
    >
      <NavLink
        data-cy={`navLink-${route.name}`}
        to={route.path}
        data-toggle="collapse"
        aria-expanded={open}
        onClick={() => {
          onToggle(route);
        }}
      >
        {route.icon !== undefined ? (
          <>
            <FetchIcon
              iconType={route.icon}
              size="lg"
              className="fa-pull-left"
            />
            <p>
              {route.name}
              {route.inErrorState && (
                <img
                  className="sidebar-error"
                  style={{ marginLeft: "10px" }}
                  src={warningCircle}
                />
              )}
              <b
                className={classes.elements.viewSubmenuCaret()}
                onClick={(e) => {
                  e.preventDefault();
                  onToggle(route);
                }}
              />
            </p>
          </>
        ) : (
          <>
            <span className="sidebar-mini-icon" />
            <span className="sidebar-normal">
              {route.name}
              <b
                className={classes.elements.viewSubmenuCaret()}
                onClick={(e) => {
                  e.preventDefault();
                  onToggle(route);
                }}
              />
            </span>
          </>
        )}
      </NavLink>
      <Collapse isOpen={open}>
        <ul className="nav">{childNavLinks}</ul>
      </Collapse>
    </li>
  );
};

export const FetchNavLinkCollapse = React.memo(Component);
