import { generateGenericQueryString } from "helpers/generateQueryString.helper";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { UserPrivileges } from "types/UserPrivileges";

export type UserRoleDTO = {
  roleId: number;
  roleName: string;
  privileges: UserPrivileges[];
  requiredRoleIds: number[];
};

//to have the variable options and the type agree
//https://stackoverflow.com/questions/44480644/string-union-to-string-array
export const USER_ROLE_MEMBERSHIP_ACTIONS = ["ADD", "REMOVE", "SET"] as const;
export type UserRoleMembershipAction =
  (typeof USER_ROLE_MEMBERSHIP_ACTIONS)[number];

//UserRoleMembershipDTO
export type UserRoleMembershipRequestDTO = {
  roleIds: number[];
  action: UserRoleMembershipAction;
};

//UserRoleAssignmentResponseDTO
export type UserRoleMembershipResponseDTO = {
  userRole: UserRoleDTO;
  userHasRole: boolean;
  assignable: boolean;
  cogRole: boolean;
};

export type UserRolePrivilegeDTO = {
  privilegeId: number;
  privilegeName: string;
};

function getUserRolePrivileges(): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/admin/roles/privileges",
  };
}

function getUserRole(roleId: number): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/admin/roles/${roleId}`,
  };
}

function saveUserRole(body: UserRoleDTO): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "POST" },
    requestUrl: "/admin/roles/",
    body: body,
  };
}

export type GetUserRoleMembershipParams = {
  userId: number;
};

function getUserRoleMembership(
  userId: number,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/admin/roles/membership/${userId}`,
  };
}

export type SaveUserRoleMembershipParams = {
  userId: number;
  roleIds: number[];
  action: UserRoleMembershipAction;
};

function saveUserRoleMembership(
  userId: number,
  roleIds: number[],
  action: UserRoleMembershipAction,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "POST" },
    requestUrl: `/admin/roles/membership/${userId}`,
    body: { roleIds, action },
  };
}

function searchUserRoles(queryParmeters: {
  roleId?: number;
  roleName?: string;
  assignable?: boolean;
  privilegeId?: number[];
  includePrivileges?: boolean;
  includeRequiredRoles?: boolean;
}): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/admin/roles/search${generateGenericQueryString(
      queryParmeters,
    )}`,
  };
}

export const userRolesService = {
  getUserRolePrivileges,
  getUserRole,
  saveUserRole,
  searchUserRoles,
  getUserRoleMembership,
  saveUserRoleMembership,
};
