import { IRootStateType } from "types/IRootStateType";
import { toast } from "react-toastify";
import { put, ForkEffect, takeLatest, call } from "redux-saga/effects";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  CancelScheduledDeliveriesActionType,
  CANCEL_SCHEDULED_DELIVERIES,
  cancelledScheduledDeliveryOutcomesActions,
} from "./cancelledScheduledDeliveryOutcomes.actions";
import { packagesService } from "services/hermes/packages";

function* cancelScheduledDeliveriesWorker(
  action: CancelScheduledDeliveriesActionType,
) {
  try {
    yield put(loadingActions.startLoad(action.type));
    yield put(
      cancelledScheduledDeliveryOutcomesActions.clearCancelScheduledDeliveries(),
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      packagesService.cancelScheduledDeliveries,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IRootStateType["cancelledScheduledDeliveryOutcomes"] =
      yield call(networkActions.makeAuthenticatedRequest, requestOptions, true);
    yield put(
      cancelledScheduledDeliveryOutcomesActions.cancelScheduledDeliveriesSuccess(
        response,
      ),
    );
  } catch (error) {
    toast.error(
      "An error occurred while attempting to cancel. Please try again.",
    );
  }
  yield put(loadingActions.endLoad(action.type));
}

export const cancelledScheduledDeliveryOutcomesSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    CANCEL_SCHEDULED_DELIVERIES,
    cancelScheduledDeliveriesWorker,
  );
};
