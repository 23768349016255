import { ILoggingAnalysisJobType } from "./loggingAnalysisJob.reducer";

export const POST_GET_NEXT_LOGGING_ANALYSIS_JOB =
  "POST_GET_NEXT_LOGGING_ANALYSIS_JOB";
export const POST_GET_NEXT_LOGGING_ANALYSIS_JOB_SUCCESS =
  "POST_GET_NEXT_LOGGING_ANALYSIS_JOB_SUCCESS";
export const POST_GET_NEXT_LOGGING_ANALYSIS_JOB_FAILURE =
  "POST_GET_NEXT_LOGGING_ANALYSIS_JOB_FAILURE";
export const CLEAR_LOGGING_ANALYSIS_JOB = "CLEAR_LOGGING_ANALYSIS_JOB";
export const POST_PUBLISH_LOGGING_ANALYSIS_RESULT =
  "POST_PUBLISH_LOGGING_ANALYSIS_RESULT";
export const REQUEST_LOGGING_ANALYSIS_PHOTO_RETAKE =
  "REQUEST_LOGGING_ANALYSIS_PHOTO_RETAKE";
export const REDIRECT_AFTER_ANALYSIS = "REDIRECT_AFTER_ANALYSIS";

export interface IGetLoggingAnalysisJobSuccessActionType {
  type: typeof POST_GET_NEXT_LOGGING_ANALYSIS_JOB_SUCCESS;
  payload: ILoggingAnalysisJobType;
}

export interface IClearLoggingAnalysisJobActionType {
  type: typeof CLEAR_LOGGING_ANALYSIS_JOB;
}

export interface IRedirectAfterAnalysisJobActionType {
  type: typeof REDIRECT_AFTER_ANALYSIS;
  payload: string;
}

export interface IPostGetNextLoggingAnalysisJobActionType {
  type: string;
  payload: PostGetNextLoggingAnalysisJobRequestType;
}

type PostGetNextLoggingAnalysisJobRequestType = {
  labelId: number | null;
  includeOCR?: boolean;
};

export interface IPostGetNextLoggingAnalysisJobSuccessActionType {
  type: string;
  payload?: PostGetNextLoggingAnalysisJobResponse;
}

export type PostGetNextLoggingAnalysisJobResponse = Partial<{
  fetchPackageId: number;
  warehouseId: number;
  lastUpdatedTimestamp: string; // UTC timestamp
  createdTimestamp: string; // UTC timestamp
  status: number;
  potentialCarrier: 1 | 2 | 3 | 4 | 5 | 6; // 1 - Amazon, 2 - Usps, 3 - Fedex, 4 - Ups, 5 - Dhl, 6 - other
  potentialSender: string;
  potentialTracking: string;
  images: {
    lastModifiedDate: number | string | null;
    lastModifiedDateOverride: number | string | null;
    createdDate: number | string | null;
    imageDataKey: {
      imgUrl: string;
    };
    status: number;
    meta: Record<string, unknown>;
    createdBy: string;
    lastModifiedBy: string; // system ID
  }[];
}>;

export interface IPostPublishLoggingAnalysisResultActionType {
  type: string;
  payload: PostPublishLoggingAnalysisResultRequestType;
}

type PostPublishLoggingAnalysisResultRequestType = {
  carrier: number;
  carrierTracking: string;
  fetchPackageId: number;
  imageUrl: string;
  sender: string;
  userId: number;
  redirectOnSuccess?: string;
};

export interface IRequestLoggingAnalysisPhotoRetakeActionType {
  type: string;
  payload: RequestLoggingAnalysisPhotoRetakeRequestType;
}

type RequestLoggingAnalysisPhotoRetakeRequestType = {
  fetchPackageId: number;
  imageUrl: string;
  redirectOnSuccess?: string;
};

function postGetNextLoggingAnalysisJob(
  jobRequestData: PostGetNextLoggingAnalysisJobRequestType,
): IPostGetNextLoggingAnalysisJobActionType {
  return {
    type: POST_GET_NEXT_LOGGING_ANALYSIS_JOB,
    payload: jobRequestData,
  };
}

function postGetNextLoggingAnalysisJobSuccess(
  analysisJobResponse: PostGetNextLoggingAnalysisJobResponse,
): IPostGetNextLoggingAnalysisJobSuccessActionType {
  return {
    type: POST_GET_NEXT_LOGGING_ANALYSIS_JOB_SUCCESS,
    payload: analysisJobResponse,
  };
}

function postPublishLoggingAnalysisResult(
  analysisData: PostPublishLoggingAnalysisResultRequestType,
) {
  return { type: POST_PUBLISH_LOGGING_ANALYSIS_RESULT, payload: analysisData };
}

// Uncomment if/when we use publish response for more than just logging
// interface IPublishAnalysisResponseType {
//   status: number;
// }

function requestLoggingAnalysisPhotoRetake(
  analysisData: RequestLoggingAnalysisPhotoRetakeRequestType,
) {
  return { type: REQUEST_LOGGING_ANALYSIS_PHOTO_RETAKE, payload: analysisData };
}

function clearLoggingAnalysisJob(): IClearLoggingAnalysisJobActionType {
  return { type: CLEAR_LOGGING_ANALYSIS_JOB };
}

function redirectAfterAnalysis(redirectLocation: string) {
  return { type: REDIRECT_AFTER_ANALYSIS, payload: redirectLocation };
}

export const loggingAnalysisJobActions = {
  postGetNextLoggingAnalysisJob,
  postPublishLoggingAnalysisResult,
  clearLoggingAnalysisJob,
  requestLoggingAnalysisPhotoRetake,
  postGetNextLoggingAnalysisJobSuccess,
  redirectAfterAnalysis,
};
