import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";

function getWarehousePrinters(
  warehouseId: string,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/printers?warehouseId=" + warehouseId,
  };
}

export const printersService = {
  getWarehousePrinters,
};
