import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { actions } from "./actions";
import { MyPackageLabelsByFetchPackageIdState } from "./reducer";

export function useMyPackageLabelsByFetchPackageId(): [
  MyPackageLabelsByFetchPackageIdState | undefined,
  {
    request: (...requestValues: Parameters<typeof actions.request>) => void;
  },
] {
  const dispatch = useDispatch();
  const myPackageLabelsByFetchPackageId = useFetchSelector(
    "myPackageLabelsByFetchPackageId",
  );

  return useMemo(
    () => [
      myPackageLabelsByFetchPackageId,
      {
        request: (...requestValues: Parameters<typeof actions.request>) => {
          dispatch(actions.request(...requestValues));
        },
      },
    ],
    [myPackageLabelsByFetchPackageId, dispatch],
  );
}
