import { IWarehousePrintersStoreType } from "./warehousePrinters.reducer";

export const GET_WAREHOUSE_PRINTERS = "GET_WAREHOUSE_PRINTER";
export const GET_WAREHOUSE_PRINTERS_SUCCESS = "GET_WAREHOUSE_PRINTER_SUCCESS";

export interface IGetWarehousePrintersActionType {
  type: string;
  payload: {
    warehouseId: string;
  };
}

export interface IGetWarehousePrintersSuccessActionType {
  type: typeof GET_WAREHOUSE_PRINTERS_SUCCESS;
  payload: IWarehousePrintersStoreType;
}

function getWarehousePrinters(
  warehouseId: string,
): IGetWarehousePrintersActionType {
  return { type: GET_WAREHOUSE_PRINTERS, payload: { warehouseId } };
}

function getWarehousePrintersSuccess(
  warehousePrinterResponse: IWarehousePrintersStoreType,
): IGetWarehousePrintersSuccessActionType {
  return {
    payload: warehousePrinterResponse,
    type: GET_WAREHOUSE_PRINTERS_SUCCESS,
  };
}

export const warehousePrintersActions = {
  getWarehousePrinters,
  getWarehousePrintersSuccess,
};
