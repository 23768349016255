import React from "react";
import { Route, Switch } from "react-router-dom";

import { DashboardLayout } from "views/layouts/DashboardLayout";
import { AuthLayout } from "views/layouts/AuthLayout";

export const LayoutSwitch: React.FC = () => {
  return (
    <Switch>
      <Route
        path={["/login", "/register", "/forgot-password", "/recover-password"]}
        render={() => <AuthLayout />}
      />
      <Route path="/" render={() => <DashboardLayout />} />
    </Switch>
  );
};
