export const copy = {
  feature: {
    asap: {
      alwaysUseFeatureIsOn: {
        title: "Always Use feature is on",
        noticeText:
          "All future packages will now be defaulted to ASAP. You can change this at any time on your preferences page.",
        goToHomeButton: "Go to home",
        goToPreferencesButton: "Go to preferences page",
      },
      confirmationForm: {
        title: "Notice for ASAP Deliveries",
        noticeText:
          "ASAP deliveries will be placed in the fastest route to your home. These deliveries are not eligible for Verified Delivery and will be left at your door.",
        acknowledgeButton: "Acknowledge",
        dontRemindAgainButton: "Don't remind me again",
      },
    },
    wfm: {
      recurringSchedule: {
        pageTitle: "Edit Recurring Schedule",
        updateEffectDate: (date: string): string =>
          `Updates will be visible starting the week of ${date}`,
        cancelPublishButton: "Cancel",
        publishButton: "Save Changes",
        editBlockPayButton: "Edit Block Pay",
        createDriverWindowButton: "Create Driver Window",
      },
      payment: {
        addTransactionModal: {
          title: "Add Transactions",
          cancelButton: "Cancel",
          submitButton: "Submit",
          uploadMessage: "Upload a .CSV file",
          templateLink:
            "https://docs.google.com/spreadsheets/d/1z5ssKdWv2MknNYJ0JrJE5JXuX2CtrpFRJ_Cfg_16tSw",
          templateLinkText: "CSV Template",
          headerCountError: (
            expectedLength: number,
            actualLength: number,
          ): string =>
            `Expected ${expectedLength} headers (columns in the first row) but found ${actualLength}.`,
          headerFieldError: (
            columnIndex: number,
            expectedHeader: string,
            actualHeader: string,
          ): string =>
            `Unexpected header in row 1, column ${
              columnIndex + 1
            }. Expected "${expectedHeader}" but found "${actualHeader}."`,
          uploadErrorDirections:
            "Please upload an updated .CSV file to proceed.",
          loadingSuccess:
            'File succesfully validated. Review details below before clicking "SUBMIT" to process payments.',
          submitSuccess: (transactionCount: number) =>
            `${transactionCount} transactions have successfully been submitted.`,
          submitError: `Submission error.  No transactions have been submitted and you must resolve the issue(s) below.`,
          submitTransactionError: (statusDetails: string[], row: number) =>
            `Error(s) on row ${row} : ${statusDetails.join(", ")}`,
        },
      },
      blockSchedule: {
        today: "Today",
        editRecurringSchedule: "Edit Recurring Schedule",
        editThisWeek: "Edit This Week",
        weekPay: "Week Pay",
        windowFillRate: "Window Fill Rate",
        dedicatedDriverFillRate: "Dedicated Driver Fill Rate ",
        deliveryPartnerFillRate: "Delivery Partner Fill Rate",
        createCustomDriverWindow: {
          modalTitle: "Create Custom Window",
          date: "Date",
          startTime: "Start Time",
          endTime: "End Time",
          dedicatedDrivers: "How many Dedicated Drivers (DD) for this window",
          deliveryPartners: "How many Delivery Partners (DP) for this window",
          totalBlocks: "Total blocks for this window",
          cancelButton: "Cancel",
          createButton: "Create Window",
          defaultPay: "Default pay for this block",
          header:
            "Custom windows are only visible for drivers, they are not visible for resident delivery times.",
        },
      },
      blockScheduleDetailPage: {
        weekViewButton: "Week View",
        editWindowButton: "Edit Window",
        addDDBlockButton: "Add DD Block",
        addDPBlockButton: "Add DP Block",
        dpBlocks: "Delivery Partner (DP) blocks",
        ddBlocks: "Delivery Driver (DD) blocks",
        totalBlocksFilled: "Total blocks filled",
        windowPay: "Window Pay",
      },
    },
  },
  userType: {
    resident: {
      market: "Metropolitan Area",
    },
    admin: {
      exampleCopy: "Example copy",
      totalBlockCount: "Total blocks for this window",
      dedicatedDriversValue: "How many Dedicated Drivers (DD) for this window",
      deliveryPartnersValue: "How many Delivery Partners (DP) for this window",
      deliveryPartnersValueTooltip:
        "Delivery Partners can't be used to delivery oversize packages",
      blockPay: "Default pay for this block",
      editAllTemplateWindows: "Save for all windows",
      editEquivalentTimeWindows: "Save for windows of the same time",
    },
  },
  wfm: {
    twoHourBlocks: "2 hour blocks",
    threeHourBlocks: "3 hour blocks",
    maxValueAnnotation: (value: string): string => `(max value ${value})`,
    editDefaultPayForm: {
      cancelButton: "Cancel",
      updatePayButton: "Update Pay",
    },
  },
};
