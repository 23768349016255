import {
  takeEvery,
  takeLatest,
  takeLeading,
  ForkEffect,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import { call, put, select } from "redux-saga/effects";

import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import { myDriverBatchesActions } from "slices/myDriverBatches/myDriverBatches.actions";
import { myPackageActions } from "slices/myPackage/myPackage.actions";
import { history } from "helpers/history";
import { IRootStateType } from "types/IRootStateType";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { ISearchPackagesActionType } from "slices/packages/packages.actions";
import { uuid } from "helpers/uuid.helper";
import {
  GET_PACKAGE,
  IGetPackageActionType,
  IPostDeliverPackageActionType,
  IPostExceptionPackagesActionType,
  IPostPickupRequestedActionType,
  IPostPickupRequestedSuccessActionType,
  IReprintPackageLabelActionType,
  ISavePackageActionType,
  ISavePackageSuccessActionType,
  packageActions,
  POST_DELIVER_PACKAGE,
  POST_DELIVER_PACKAGE_FAILURE,
  POST_DELIVER_PACKAGE_SUCCESS,
  POST_EXCEPTION_PACKAGES,
  POST_PICKUP_REQUESTED,
  POST_PICKUP_REQUESTED_SUCCESS,
  REPRINT_PACKAGE_LABEL,
  SAVE_PACKAGE,
  SAVE_PACKAGE_SUCCESS,
  SEARCH_WINDOW_PACKAGES,
} from "./package.actions";
import { myPackagesActions } from "slices/myPackages/myPackages.actions";
import { loggedPackagesActions } from "slices/loggedPackages/loggedPackages.actions";

import { packagesService } from "services/hermes/packages";
import { IPackagesStoreType } from "slices/packages/packages.reducer";
import { IPackageStoreType } from "./package.reducer";
import {
  IUsersStoreType,
  IUsersStoreContentType,
} from "slices/users/users.reducer";
import { ILoggedPackageStoreType } from "slices/loggedPackages/loggedPackages.reducer";

function savePackageSuccessWorker(action: ISavePackageSuccessActionType) {
  const { fetchPackageId } = action;

  toast.success(
    `Package ${
      !!fetchPackageId ? `${fetchPackageId} updated` : "logged"
    } successfully`,
  );
}

function* reprintPackageLabelWorker(action: IReprintPackageLabelActionType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<typeof packagesService.reprintPackageLabel> =
    yield call(packagesService.reprintPackageLabel, action.payload.packageId);
  yield call(networkActions.makeAuthenticatedRequest, requestOptions);
  // display success message
  toast.success(
    "Package #" +
      action.payload.packageId +
      " label has been resent to the printer.",
  );
}

function* searchWindowPackagesWorker(action: ISearchPackagesActionType) {
  yield put(loadingActions.startLoad(action.type));
  // add in additional filters
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<typeof packagesService.searchPackages> =
    yield call(packagesService.searchPackages, action.payload);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IPackagesStoreType = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(packageActions.searchWindowPackagesSuccess(response));
  yield put(loadingActions.endLoad(action.type));
}

function* postExceptionPackagesWorker(
  action: IPostExceptionPackagesActionType,
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      packagesService.postExceptionPackages,
      action.fetchPackageIds,
    );
    yield call(networkActions.makeAuthenticatedRequest, requestOptions, true);
    toast.success("Attempt logged");
  } catch (error) {
    toast.error("There was an error. Please try again!");
  }
}

function* savePackageWorker(action: ISavePackageActionType) {
  let loggedPackage = null;
  // add package to logged package list if new
  if (!action.payload.fetchPackageId) {
    const getStateUsers = (state: IRootStateType) => state.users;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const users: IUsersStoreType = yield select(getStateUsers);
    loggedPackage = convertPackageToLoggedPackage(action.payload, users);
    yield put(loggedPackagesActions.addLoggedPackage(loggedPackage));
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: IAuthenticatedRequestDefinition = yield call(
    packagesService.savePackage,
    action.payload,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IPackageStoreType = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(packageActions.savePackageSuccess(action.payload.fetchPackageId));
  // If this is a new package, handle completion
  if (loggedPackage) {
    loggedPackage.status = "COMPLETED";
    loggedPackage.fetchPackageId = response.fetchPackageId;
    yield put(loggedPackagesActions.updateLoggedPackage(loggedPackage));
  } else {
    // redirect to packages table if updating existing package
    history.push("/packages");
  }
}

function* postPickupRequestedWorker(action: IPostPickupRequestedActionType) {
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof packagesService.postPickupRequested
    > = yield call(packagesService.postPickupRequested, action.payload);
    yield call(networkActions.makeAuthenticatedRequest, requestOptions);
    yield put(
      packageActions.postPickupRequestedSuccess(
        action.payload.fetchPackageId,
        action.options,
      ),
    );
    if (action.options?.successHandler) {
      action.options.successHandler();
    }
  } catch (error) {
    yield put(packageActions.postPickupRequestedFailure());
    if (action.options?.failureHandler) {
      action.options.failureHandler();
    }
  }
  yield put(loadingActions.endLoad(action.type));
}

function* postPickupRequestedSuccessWorker(
  action: IPostPickupRequestedSuccessActionType,
) {
  const { options, fetchPackageId } = action;

  if (!!options && options.refresh) {
    if (options.isMyPackage) {
      yield put(myPackageActions.getMyPackage(fetchPackageId));
      yield put(
        myPackagesActions.getMyPackages({
          pageSize: 100,
          page: 0,
          timeScope: "today",
        }),
      );
    } else {
      yield put(packageActions.getPackage(`${fetchPackageId}`));
    }
  }
  toast.success("Package successfully set up for pickup!");
}

function* postDeliverPackageWorker(action: IPostDeliverPackageActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof packagesService.postDeliverPackage
    > = yield call(packagesService.postDeliverPackage, action.payload);
    yield call(networkActions.makeAuthenticatedRequest, requestOptions, true);
    yield put(packageActions.postDeliverPackageSuccess());
  } catch (error) {
    yield put(packageActions.postDeliverPackageFailure());
  }
}

function* postDeliverPackageSuccessWorker() {
  toast.success("Package successfully delivered!");
  yield put(myDriverBatchesActions.getMyDriverBatches());
}

function* postDeliverPackageFailureWorker() {
  toast.error("There was an error delivering the package! Please try again!");
  yield put(myDriverBatchesActions.getMyDriverBatches());
}

function* getPackageWorker(action: IGetPackageActionType) {
  yield put(packageActions.clearPackage());
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof packagesService.getPackage> =
      yield call(packagesService.getPackage, action.payload.fetchPackageId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IPackageStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(packageActions.getPackageSuccess(response));
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

export const packageSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(REPRINT_PACKAGE_LABEL, reprintPackageLabelWorker);
  yield takeLatest(SEARCH_WINDOW_PACKAGES, searchWindowPackagesWorker);
  yield takeLeading(GET_PACKAGE, getPackageWorker);
  yield takeEvery(SAVE_PACKAGE, savePackageWorker);
  yield takeLeading(SAVE_PACKAGE_SUCCESS, savePackageSuccessWorker);
  yield takeLeading(POST_PICKUP_REQUESTED, postPickupRequestedWorker);
  yield takeLeading(
    POST_PICKUP_REQUESTED_SUCCESS,
    postPickupRequestedSuccessWorker,
  );
  yield takeLatest(POST_DELIVER_PACKAGE, postDeliverPackageWorker);
  yield takeLatest(
    POST_DELIVER_PACKAGE_SUCCESS,
    postDeliverPackageSuccessWorker,
  );
  yield takeLatest(
    POST_DELIVER_PACKAGE_FAILURE,
    postDeliverPackageFailureWorker,
  );
  yield takeLatest(POST_EXCEPTION_PACKAGES, postExceptionPackagesWorker);
};

function convertPackageToLoggedPackage(
  packageData: IPackageStoreType,
  userPage: IUsersStoreType,
): ILoggedPackageStoreType {
  // get users from state
  // find matching user
  const users = userPage.content;
  let selectedUser: IUsersStoreContentType | null = null;
  for (const user of users) {
    if (user.userId.toString(10) === packageData.userId.toString(10)) {
      selectedUser = user;
    }
  }
  return {
    carrier: packageData.carrier,
    carrierTracking: packageData.carrierTracking,
    fetchPackageId: packageData.fetchPackageId,
    sender: packageData.sender,
    status: "SUBMITTED",
    statusMessage: "",
    uniqueIdentifier: uuid(),
    userCode:
      selectedUser !== null && selectedUser.residentialUser
        ? selectedUser.residentialUser.userCode
        : "",
    userFirstName: selectedUser !== null ? selectedUser.firstName : "",
    userLastName: selectedUser !== null ? selectedUser.lastName : "",
  };
}
