import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import QueryString from "qs";

import { authRoutes } from "routes/pageRoutes";
import { BackgroundOverlays } from "views/components/atoms/BackgroundOverlays";
import Footer from "views/components/footers/Footer";
import { AuthNavbar } from "views/components/navbars/AuthNavbar";
import { LoadingPage } from "views/pages/LoadingPage";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { SentryRoute } from "services/sentry";

const routes = authRoutes.map(({ path, component }) => (
  <SentryRoute key={path} path={path} component={component} />
));

export const AuthLayout: React.FC = () => {
  const authentication = useFetchSelector("authentication");

  if (authentication?.loggedIn) {
    const providedBuilding = QueryString.parse(location.search, {
      ignoreQueryPrefix: true,
    }).building;
    let loggedInUrl = "/";

    if (typeof providedBuilding === "string") {
      loggedInUrl = loggedInUrl.concat(`?building=${providedBuilding}`);
    }
    return <Redirect to={{ pathname: loggedInUrl }} />;
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <AuthNavbar />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image">
          <Switch>{routes}</Switch>
          <Footer fluid={true} />
          <BackgroundOverlays />
        </div>
      </div>
    </Suspense>
  );
};
