import { call, put, ForkEffect, takeLeading } from "redux-saga/effects";
import { packagesService } from "services/hermes/packages";
import { networkActions } from "slices/authentication/network.actions";
import {
  IGetPackageDeliveryWindowOptionsActionType,
  packageDeliveryWindowOptionsActions,
  GET_PACKAGE_DELIVERY_WINDOW_OPTIONS,
} from "./packageDeliveryWindowOptions.actions";
import { PackageDeliveryWindowOptionsStoreType } from "./packageDeliveryWindowOptions.reducers";

function* getPackageDeliveryWindowOptionsWorker(
  action: IGetPackageDeliveryWindowOptionsActionType,
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof packagesService.getPackageDeliveryWindowOptions
    > = yield call(
      packagesService.getPackageDeliveryWindowOptions,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: PackageDeliveryWindowOptionsStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(
      packageDeliveryWindowOptionsActions.getPackageDeliveryWindowOptionsSuccess(
        response,
      ),
    );
  } catch (error) {
    yield put(
      packageDeliveryWindowOptionsActions.getPackageDeliveryWindowOptionsFailure(),
    );
  }
}

export const packageDeliveryWindowOptionsSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(
    GET_PACKAGE_DELIVERY_WINDOW_OPTIONS,
    getPackageDeliveryWindowOptionsWorker,
  );
};
