import { Reducer } from "redux";
import {
  LabelAnalysisSummaryResponse,
  GetLabelAnalysisSummaryAction,
  GetLabelAnalysisSummarySuccessAction,
  GetLabelAnalysisSummaryFailureAction,
  GET_LABEL_ANALYSIS_SUMMARY_SUCCESS,
  GET_LABEL_ANALYSIS_SUMMARY_FAILURE,
} from "./LabelAnalysisSummary.actions";

export type LabelAnalysisSummaryStore = LabelAnalysisSummaryResponse | null;

type LabelAnalysisSummaryActions =
  | GetLabelAnalysisSummaryAction
  | GetLabelAnalysisSummarySuccessAction
  | GetLabelAnalysisSummaryFailureAction;

export const labelAnalysisSummary: Reducer<
  LabelAnalysisSummaryStore,
  LabelAnalysisSummaryActions
> = (state = null, action: LabelAnalysisSummaryActions) => {
  switch (action.type) {
    case GET_LABEL_ANALYSIS_SUMMARY_SUCCESS:
      return action?.payload;
    case GET_LABEL_ANALYSIS_SUMMARY_FAILURE:
      return null;
    default:
      return state;
  }
};
