import { Reducer } from "redux";

import { defaultState } from "default-state";

import {
  CLEAR_PENDING_RATING,
  GET_PENDING_RATING_SUCCESS,
  IClearPendingRatingActionType,
  IGetPendingRatingSuccessActionType,
  IPostRatingActionType,
} from "slices/pendingRating/pendingRating.actions";
import { IResidentialUserStoreType } from "slices/currentUser/currentUser.reducer";

export interface Notes {
  residentDelivery: string;
  residentUser?: IResidentialUserStoreType | null;
}

export interface NegativeFeedbackCode {
  reasonCodeId: number;
  reasonCodeText: string;
}

interface Driver {
  driverFirstName: string;
  driverLastInitial: string;
  driverUserProfileImageUrl?: string | null;
}

export interface PendingRating {
  driver: Driver;
  driverBatchFetchPackageId: number;
  negativeFeedbackCodes: NegativeFeedbackCode[];
  packages: {
    fetchPackageId: number;
    sender: string;
    senderLogoUrl: string;
    carrier: number;
    carrierTracking: string;
    carrierTrackingUrl: string;
    carrierName: string;
    status: number;
    packageDeliveryPreference: number;
    deliveryWindowId: number;
    requestedDeliveryStartDate: string;
    requestedDeliveryEndDate: string;
    createdDate: string;
    lastModifiedDate: string;
    notes: Notes;
    deliveryMethod: number;
  }[];
  reviewUrl?: string;
  timestamp: string;
}

export const pendingRating: Reducer<PendingRating | null> = (
  state = defaultState.pendingRating,
  action: PendingRatingActionTypes,
): PendingRating | null => {
  switch (action.type) {
    case CLEAR_PENDING_RATING:
      return defaultState.pendingRating;
    case GET_PENDING_RATING_SUCCESS:
      return action.pendingRating;
    default:
      return state;
  }
};

export type PendingRatingActionTypes =
  | IClearPendingRatingActionType
  | IGetPendingRatingSuccessActionType
  | IPostRatingActionType;
