import { call, put, ForkEffect, takeLeading } from "redux-saga/effects";
import { usersService } from "services/hermes/users";
import { networkActions } from "slices/authentication/network.actions";
import { loadingActions } from "slices/loading/loading.actions";
import {
  IGetDriverUserAuditHistoryActionType,
  GET_DRIVER_USER_AUDIT_HISTORY,
  driverUserAuditHistoryActions,
} from "./driverUserAuditHistory.actions";
import { IDriverUserAuditHistoryStoreType } from "./driverUserAuditHistory.reducer";

function* getDriverUserAuditHistoryWorker(
  action: IGetDriverUserAuditHistoryActionType,
) {
  yield put(loadingActions.startLoad(action.type));

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof usersService.getDriverUserAuditHistory
    > = yield call(usersService.getDriverUserAuditHistory, action.systemId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IDriverUserAuditHistoryStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(
      driverUserAuditHistoryActions.getDriverUserAuditHistorySuccess(response),
    );
  } catch (error) {}
  yield put(loadingActions.endLoad(action.type));
}

export const driverUserAuditHistorySagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(
    GET_DRIVER_USER_AUDIT_HISTORY,
    getDriverUserAuditHistoryWorker,
  );
};
