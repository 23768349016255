import { defaultState } from "default-state";
import {
  CLEAR_MARKETS,
  GET_MARKETS_SUCCESS,
  IClearMarketsActionType,
  IGetMarketsSuccessActionType,
} from "./markets.actions";

export interface IMarketType {
  marketId: number;
  marketName: string;
}

type MarketActionsTypes =
  | IGetMarketsSuccessActionType
  | IClearMarketsActionType;

export function markets(
  state: IMarketType[] | null = null,
  action: MarketActionsTypes,
): IMarketType[] | null {
  switch (action.type) {
    case CLEAR_MARKETS:
      return defaultState.markets;
    case GET_MARKETS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
