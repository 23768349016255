import React from "react";
import { IconTypes } from "constants/icons";
import { generateBemClassNames } from "helpers/generateBemClassNames.helper";
import { FetchIcon } from "../misc/FetchIcon";

const {
  block,
  elements: { title: titleClasses, body: bodyClasses },
} = generateBemClassNames({
  block: {
    name: "FetchNotice",
  },
  elements: [{ name: "title" }, { name: "body" }],
  prefix: "fetch",
});
interface FetchNoticeProps {
  icon: IconTypes;
  title: string;
  body: string;
  className?: string;
}

export const FetchNotice: React.FC<FetchNoticeProps> = (
  props: FetchNoticeProps,
) => {
  const { icon, title, body } = props;

  return (
    <div
      className={block({
        extras: !!props.className ? [props.className] : undefined,
      })}
    >
      <div className={titleClasses()}>
        <FetchIcon iconType={icon} /> {title}
      </div>
      <div
        className={bodyClasses()}
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
    </div>
  );
};
