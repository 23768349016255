import { takeLatest, takeLeading, call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { history } from "helpers/history";
import { uiActions } from "slices/ui/ui.actions";
import { FetchError } from "slices/authentication/network.actions";
import { networkActions } from "slices/authentication/network.actions";
import { IRootStateType } from "types/IRootStateType";
import {
  IGetUserAttributeVerificationCodeActionType,
  IGetUserAttributeVerificationCodeSuccessType,
  IGetUserAttributeVerificationCodeFailureType,
  ILoginRequestActionType,
  ILoginSuccessActionType,
  IPostChangePasswordActionType,
  IPostSetPermenantPasswordActionType,
  IPostSetPermenantPasswordSuccessActionType,
  IRecoverPasswordSuccessActionType,
  authenticationActions,
  IRegisterAccountActionType,
  IVerifyUserAttributeActionType,
  ISetSessionActionType,
  REGISTER_ACCOUNT,
  REGISTER_ACCOUNT_SUCCESS,
  REQUEST_NEW_PASSWORD,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN,
  GET_USER_ATTRIBUTE_VERIFICATION_CODE,
  GET_USER_ATTRIBUTE_VERIFICATION_CODE_SUCCESS,
  GET_USER_ATTRIBUTE_VERIFICATION_CODE_FAILURE,
  VERIFY_USER_ATTRIBUTE,
  VERIFY_USER_ATTRIBUTE_SUCCESS,
  VERIFY_USER_ATTRIBUTE_FAILURE,
  SET_SESSION,
  REFRESH_SESSION,
  POST_SET_PERMENANT_PASSWORD,
  POST_SET_PERMENANT_PASSWORD_SUCCESS,
  POST_SET_PERMENANT_PASSWORD_FAILURE,
  POST_CHANGE_PASSWORD,
  POST_CHANGE_PASSWORD_SUCCESS,
  POST_CHANGE_PASSWORD_FAILURE,
  IRecoverPasswordActionType,
  IRequestNewPasswordActionType,
  IAuthType,
  AccountsLoginResponseBody,
} from "./authentication.actions";
import {
  errorMessagesActions,
  ErrorMessagesKeys,
} from "slices/errorMessages/errorMessages.actions";
import { currentUserActions } from "slices/currentUser/currentUser.actions";
import { networkService } from "services/hermes/config";
import { accountsService } from "services/hermes/accounts";
import { localStorageSetItemSafe } from "helpers/localStorage/localStorageSetItemSafe";
import { localStorageGetItemSafe } from "helpers/localStorage/localStorageGetItemSafe";
import { applyExpiresAt } from "./applyExpiresAt";

function* getUserAttributeVerificationCodeWorker(
  action: IGetUserAttributeVerificationCodeActionType,
) {
  try {
    yield call(
      accountsService.getUserAttributeVerificationCode,
      action.payload,
    );
    yield put(
      authenticationActions.getUserAttributeVerificationCodeSuccess(
        action.payload.attributeName,
      ),
    );
  } catch (error) {
    yield put(
      authenticationActions.getUserAttributeVerificationCodeFailure(
        action.payload.attributeName,
        error as { message: string },
      ),
    );
  }
}

function getUserAttributeVerificationCodeSuccessWorker({
  payload,
}: IGetUserAttributeVerificationCodeSuccessType) {
  let message;
  switch (payload) {
    case "email":
      message = "A verification code was sent to your email address!";
      break;
    case "phone_number":
      message = "A verification code was sent by text to your phone!";
      break;
  }
  toast.success(message);
}

function getUserAttributeVerificationCodeFailureWorker({
  payload,
  error,
}: IGetUserAttributeVerificationCodeFailureType) {
  if (error.message === "rate limit exceeded") {
    let message;
    switch (payload) {
      case "email":
        message =
          "A verification code was already sent. Please check your email!";
        break;
      case "phone_number":
        message =
          "A verification code was already sent. Please check your phone!";
        break;
    }
    toast.warning(message);
  } else {
    toast.error("It looks like there was a problem. Please try again!");
  }
}

function* loginWorker(action: ILoginRequestActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: AccountsLoginResponseBody = yield call(
      accountsService.login,
      action.payload.user.email,
      action.payload.user.password,
    );
    yield put(authenticationActions.loginSuccess(response));
  } catch (_e) {
    const e = _e as FetchError;
    yield put(authenticationActions.loginFailure(e.message));
  }
}

function* loginSuccessWorker({
  payload: { user, auth },
}: ILoginSuccessActionType) {
  yield put(authenticationActions.setSession({ user, auth }));
  yield put(uiActions.triggerNewSessionAlerts());
}

function* logoutWorker() {
  yield call(accountsService.logout);
  yield call(uiActions.clearNotifications);
}

function* postChangePasswordWorker(action: IPostChangePasswordActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const {
      tokens: { userToken },
    } = yield select((state: IRootStateType) => state.authentication);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof accountsService.postChangePassword
    > = yield call(accountsService.postChangePassword, {
      newPassword: action.payload.newPassword,
      oldPassword: action.payload.oldPassword,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      userToken,
    });
    yield call(networkActions.makeAuthenticatedRequest, requestOptions);
    yield put(authenticationActions.postChangePasswordSuccess());
  } catch (error) {
    yield put(authenticationActions.postChangePasswordFailure());
  }
}

function postChangePasswordSuccessWorker() {
  toast.success("Successfully changed password!");
}

function postChangePasswordFailureWorker() {
  toast.error(
    "There was an error attempting to change your password. Please try again!",
  );
}

function* postSetPermanentPasswordWorker(
  action: IPostSetPermenantPasswordActionType,
) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof accountsService.postSetPermanentPassword
    > = yield call(accountsService.postSetPermanentPassword, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: AccountsLoginResponseBody = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    if (response) {
      yield put(
        authenticationActions.postSetPermanentPasswordSuccess(response),
      );
    } else {
      yield put(authenticationActions.postSetPermanentPasswordFailure());
    }
  } catch (error) {
    toast.error(
      "There was an error attempting to reset your password. Please try again!",
    );
  }
}

function* postSetPermanentPasswordSuccessWorker({
  payload: { user, auth },
}: IPostSetPermenantPasswordSuccessActionType) {
  yield put(authenticationActions.setSession({ user, auth }));
  toast.success("New password saved!");
}

function* postSetPermanentPasswordFailureWorker() {
  toast.error(
    "There was an error attempting to reset your password. Please try again!",
  );
}

function* recoverPasswordWorker(action: IRecoverPasswordActionType) {
  delete action.payload.confirmPassword;
  try {
    yield call(accountsService.recoverPassword, action.payload);
    yield put(
      authenticationActions.recoverPasswordSuccess({
        email: action.payload.email,
        password: action.payload.password,
      }),
    );
  } catch (_error) {
    const error = _error as FetchError;
    yield put(authenticationActions.recoverPasswordFailure(error));
  }
}

function* recoverPasswordSuccessWorker({
  payload: { email, password },
}: IRecoverPasswordSuccessActionType) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: AccountsLoginResponseBody = yield call(
      accountsService.login,
      email,
      password,
    );
    yield put(authenticationActions.loginSuccess(response));
  } catch (_e) {
    const e = _e as FetchError;
    yield put(authenticationActions.loginFailure(e.message));
  }
}

function* refreshSessionWorker() {
  yield put(currentUserActions.loadMyUser());
  yield put(authenticationActions.refreshToken());
}

function* refreshTokenWorker() {
  // Get refresh token if in localStorage
  const auth = localStorageGetItemSafe("auth");

  if (!!auth) {
    try {
      if (auth.status !== "NEW_PASSWORD_REQUIRED") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const authResponse: IAuthType = yield call(networkService.refreshToken);
        localStorageSetItemSafe("auth", applyExpiresAt(authResponse));
        // Handle successful refresh
        yield put(authenticationActions.refreshTokenSuccess(authResponse));
        if (authResponse.phoneVerified) {
          yield put(uiActions.clearNotification("alertUnverifiedPhone"));
        }
        if (authResponse.emailVerified) {
          yield put(uiActions.clearNotification("alertUnverifiedEmail"));
        }
      } else {
        localStorageSetItemSafe("auth", applyExpiresAt(auth));
        yield put(
          authenticationActions.refreshTokenSuccess({
            ...auth,
          }),
        );
      }
    } catch (e) {
      // Error refreshing, logout
      yield put(authenticationActions.logout("Failed to refresh token."));
    }
  } else {
    // Logout when the auth token doesnt exist in localstorage
    yield put(authenticationActions.logout("Failed to refresh token."));
  }
}

function* registerAccountWorker(action: IRegisterAccountActionType) {
  delete action.payload.confirmPassword;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: ILoginSuccessActionType = yield call(
      accountsService.registerAccount,
      action.payload,
    );
    yield put(authenticationActions.registerAccountSuccess(response));
  } catch (_e) {
    const e = _e as FetchError;
    let message;
    if (e.fetchErrorCode === 2008) {
      message =
        "An account using that email already exists. Did you mean to login instead?";
    } else {
      message =
        "There seems to have been an error. Please try again or contact support.";
    }
    toast.error(message);
    yield put(authenticationActions.loginFailure(message));
  }
}

function* registerAccountSuccessWorker({
  payload: { user, auth },
}: ILoginSuccessActionType) {
  yield put(authenticationActions.setSession({ user, auth }));
  yield put(uiActions.triggerNewSessionAlerts());
}

function* verifyUserAttributeWorker(action: IVerifyUserAttributeActionType) {
  try {
    yield call(accountsService.verifyUserAttribute, action.payload);
    yield put(authenticationActions.verifyUserAttributeSuccess());
  } catch (error) {
    yield put(authenticationActions.verifyUserAttributeFailure());
  }
}

function* verifyUserAttributeSuccessWorker() {
  toast.success("Successfully updated!");
  yield put(uiActions.clearModal());
  yield put(authenticationActions.refreshSession());
}

function verifyUserAttributeFailureWorker() {
  toast.error("It looks as though there was a problem. Please try again.");
}

function* setSessionWorker({ payload: { user, auth } }: ISetSessionActionType) {
  yield call(currentUserActions.setCurrentUser, user);
  localStorageSetItemSafe("auth", applyExpiresAt(auth));
}

function* requestNewPasswordWorker(action: IRequestNewPasswordActionType) {
  yield put(errorMessagesActions.clearAllErrorMessages());
  try {
    yield call(accountsService.requestNewPassword, action.payload);
    if (!!action.payload.email)
      history.push(`/recover-password?email=${action.payload.email}`);
    toast.success(
      "A verification code has been sent to your phone or email if we found a matching account",
    );
  } catch (error) {
    const e = error as FetchError;
    if (e.fetchErrorCode === 5008) {
      yield put(
        errorMessagesActions.addErrorMessage({
          errorKey: ErrorMessagesKeys.REQUEST_NEW_PASSWORD_MESSAGE,
          text: e.message,
        }),
      );
    }
    yield put(authenticationActions.requestNewPasswordFailure());
    toast.error("An error occurred. Please try again.");
  }
}
export const authenticationSagas = function* () {
  yield takeLatest(REGISTER_ACCOUNT, registerAccountWorker);
  yield takeLeading(REGISTER_ACCOUNT_SUCCESS, registerAccountSuccessWorker);
  yield takeLatest(REQUEST_NEW_PASSWORD, requestNewPasswordWorker);
  yield takeLatest(RECOVER_PASSWORD, recoverPasswordWorker);
  yield takeLatest(RECOVER_PASSWORD_SUCCESS, recoverPasswordSuccessWorker);
  yield takeLatest(LOGIN_REQUEST, loginWorker);
  yield takeLeading(LOGIN_SUCCESS, loginSuccessWorker);
  yield takeLeading(LOGOUT, logoutWorker);
  yield takeLeading(REFRESH_TOKEN, refreshTokenWorker);
  yield takeLatest(
    GET_USER_ATTRIBUTE_VERIFICATION_CODE,
    getUserAttributeVerificationCodeWorker,
  );
  yield takeLatest(
    GET_USER_ATTRIBUTE_VERIFICATION_CODE_SUCCESS,
    getUserAttributeVerificationCodeSuccessWorker,
  );
  yield takeLatest(
    GET_USER_ATTRIBUTE_VERIFICATION_CODE_FAILURE,
    getUserAttributeVerificationCodeFailureWorker,
  );
  yield takeLatest(VERIFY_USER_ATTRIBUTE, verifyUserAttributeWorker);
  yield takeLatest(
    VERIFY_USER_ATTRIBUTE_SUCCESS,
    verifyUserAttributeSuccessWorker,
  );
  yield takeLatest(
    VERIFY_USER_ATTRIBUTE_FAILURE,
    verifyUserAttributeFailureWorker,
  );
  yield takeLatest(SET_SESSION, setSessionWorker);
  yield takeLatest(REFRESH_SESSION, refreshSessionWorker);
  yield takeLatest(POST_SET_PERMENANT_PASSWORD, postSetPermanentPasswordWorker);
  yield takeLatest(
    POST_SET_PERMENANT_PASSWORD_SUCCESS,
    postSetPermanentPasswordSuccessWorker,
  );
  yield takeLatest(
    POST_SET_PERMENANT_PASSWORD_FAILURE,
    postSetPermanentPasswordFailureWorker,
  );
  yield takeLatest(POST_CHANGE_PASSWORD, postChangePasswordWorker);
  yield takeLatest(
    POST_CHANGE_PASSWORD_SUCCESS,
    postChangePasswordSuccessWorker,
  );
  yield takeLatest(
    POST_CHANGE_PASSWORD_FAILURE,
    postChangePasswordFailureWorker,
  );
};
