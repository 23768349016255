import { IPackageStoreType } from "./package.reducer";

export const CLEAR_PACKAGE_IDS = "CLEAR_PACKAGE_IDS";
export const GET_PACKAGE_BY_ID = "GET_PACKAGE_BY_ID";
export const GET_PACKAGE_BY_ID_SUCCESS = "GET_PACKAGE_BY_ID_SUCCESS";

function getPackageById(fetchPackageId: string) {
  return { type: GET_PACKAGE_BY_ID, payload: { fetchPackageId } };
}

function getPackageByIdSuccess(packageResponse: IPackageStoreType) {
  return { type: GET_PACKAGE_BY_ID_SUCCESS, payload: packageResponse };
}

export const packageByIdActions = {
  getPackageById,
  getPackageByIdSuccess,
};
