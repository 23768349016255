import { networkActions } from "slices/authentication/network.actions";
import { call, put, takeLeading } from "redux-saga/effects";
import { Schema } from "jsonschema";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { metaSchemaActions, GET_META_SCHEMA } from "./metaSchema.action";

function* getMetaSchemaWorker() {
  try {
    const requestOptions: IAuthenticatedRequestDefinition = {
      requestUrl: "/general/meta_schema",
      requestOptions: {
        method: "GET",
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: Schema = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );

    yield put(metaSchemaActions.getMetaSchemaSuccess(response));
  } catch (error) {}
}

export const metaSchemaSagas = function* () {
  yield takeLeading(GET_META_SCHEMA, getMetaSchemaWorker);
};
