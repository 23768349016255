import { action } from "./action";
import { saga } from "./saga";
import { service } from "./service";

export const getFetchPackagesByIdLabelImage = {
  service,
  action,
  saga,
};

/*
USAGE:
  const repsonse = yield call(getFetchPackagesByIdLabelImage.action, ...args)
  or
  const repsonse = yield put(getFetchPackagesByIdLabelImage.action(...args))
 */
