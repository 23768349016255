import {
  CLEAR_WAREHOUSES,
  IClearWarehousesActionType,
  ISearchWarehousesFailureActionType,
  ISearchWarehousesSuccessActionType,
  SEARCH_WAREHOUSES_FAILURE,
  SEARCH_WAREHOUSES_SUCCESS,
} from "slices/warehouses/warehouses.actions";
import { defaultState } from "default-state";
import { IPaginatedResponseType } from "types/IPaginatedResponseType";

export interface IWarehouseStoreContentType {
  warehouseId: number;
  name: string;
  shortcode: string;
  timezone: string;
}

export interface IWarehousesStoreType extends IPaginatedResponseType {
  content: IWarehouseStoreContentType[];
}

export function warehouses(
  state = {},
  action: WarehousesActionsTypes,
): Partial<IWarehousesStoreType> {
  switch (action.type) {
    case CLEAR_WAREHOUSES:
      return defaultState.warehouses;
    case SEARCH_WAREHOUSES_SUCCESS:
      return action.payload;
    case SEARCH_WAREHOUSES_FAILURE:
      return { ...defaultState.warehouses };
    default:
      return state;
  }
}

// type definitions
type WarehousesActionsTypes =
  | ISearchWarehousesSuccessActionType
  | ISearchWarehousesFailureActionType
  | IClearWarehousesActionType;
