import { IDriverBatch } from "./myDriverBatches.reducers";

export const CLEAR_MY_DRIVER_BATCHES = "CLEAR_MY_DRIVER_BATCHES";
export const GET_MY_DRIVER_BATCHES = "GET_MY_DRIVER_BATCHES";
export const GET_MY_DRIVER_BATCHES_SUCCESS = "GET_MY_DRIVER_BATCHES_SUCCESS";
export const GET_MY_DRIVER_BATCHES_FAILURE = "GET_MY_DRIVER_BATCHES_FAILURE";

export interface IClearMyDriverBatchesActionType {
  type: typeof CLEAR_MY_DRIVER_BATCHES;
}

export interface IGetMyDriverBatchesActionType {
  type: typeof GET_MY_DRIVER_BATCHES;
}
export interface IGetMyDriverBatchesSuccessActionType {
  type: typeof GET_MY_DRIVER_BATCHES_SUCCESS;
  payload: IDriverBatch[];
}
export interface IGetMyDriverBatchesFailureActionType {
  type: typeof GET_MY_DRIVER_BATCHES_FAILURE;
}

function clearMyDriverBatches() {
  return { type: CLEAR_MY_DRIVER_BATCHES };
}

function getMyDriverBatches() {
  return { type: GET_MY_DRIVER_BATCHES };
}
function getMyDriverBatchesSuccess(response: IDriverBatch[]) {
  return { type: GET_MY_DRIVER_BATCHES_SUCCESS, payload: response };
}
function getMyDriverBatchesFailure() {
  return { type: GET_MY_DRIVER_BATCHES_FAILURE };
}

export const myDriverBatchesActions = {
  clearMyDriverBatches,
  getMyDriverBatches,
  getMyDriverBatchesSuccess,
  getMyDriverBatchesFailure,
};
