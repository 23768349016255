import { call, put, takeLatest } from "redux-saga/effects";
import { buildingsService } from "services/hermes/buildings";

import { networkActions } from "slices/authentication/network.actions";
import {
  GET_BUILDING_DELIVERY_TIMES,
  IGetBuildingDeliveryWindowsActionType,
  packageDeliveryTimesActions,
} from "./packageDeliveryTimes.actions";
import { IPackageDeliveryTimesStoreType } from "./packageDeliveryTimes.reducer";

function* getBuildingDeliveryTimesWorker(
  action: IGetBuildingDeliveryWindowsActionType,
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const requestOptions: ReturnType<
    typeof buildingsService.getBuildingDeliveryTimes
  > = yield call(
    buildingsService.getBuildingDeliveryTimes,
    action.payload.buildingId,
    action.payload.timezone,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response: IPackageDeliveryTimesStoreType = yield call(
    networkActions.makeAuthenticatedRequest,
    requestOptions,
  );
  yield put(
    packageDeliveryTimesActions.getBuildingDeliveryTimesSuccess(response),
  );
}

export const packageDeliveryTimesSagas = function* () {
  yield takeLatest(GET_BUILDING_DELIVERY_TIMES, getBuildingDeliveryTimesWorker);
};
