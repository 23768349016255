import { defaultState } from "default-state";
import { appendOrUpdateLoggedPackage } from "./appendOrUpdateLoggedPackage";
import {
  IClearLoggedPackagesActionType,
  IAddLoggedPackageActionType,
  IUpdateLoggedPackageActionType,
} from "./loggedPackages.actions";

// Logged packages
export const ADD_LOGGED_PACKAGE = "ADD_LOGGED_PACKAGE";
export const CLEAR_LOGGED_PACKAGES = "CLEAR_LOGGED_PACKAGES";
export const UPDATE_LOGGED_PACKAGE = "UPDATE_LOGGED_PACKAGE";

export interface ILoggedPackageStoreType {
  fetchPackageId?: number;
  sender: string;
  carrier: number | string;
  carrierTracking: string;
  status: string;
  statusMessage: string;
  uniqueIdentifier: string;
  userFirstName: string;
  userLastName: string;
  userCode: string;
}

export type ILoggedPackagesStoreType = ILoggedPackageStoreType[];

type LoggedPackagesActionsTypes =
  | IClearLoggedPackagesActionType
  | IAddLoggedPackageActionType
  | IUpdateLoggedPackageActionType;

export function loggedPackages(
  state: ILoggedPackageStoreType[] = defaultState.loggedPackages,
  action: LoggedPackagesActionsTypes,
): ILoggedPackageStoreType[] {
  switch (action.type) {
    case CLEAR_LOGGED_PACKAGES:
      return defaultState.loggedPackages;
    case ADD_LOGGED_PACKAGE:
      return appendOrUpdateLoggedPackage(state, action.payload);
    case UPDATE_LOGGED_PACKAGE:
      return appendOrUpdateLoggedPackage(state, action.payload);
    default:
      return state;
  }
}
