import { takeLatest, ForkEffect, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { loadingActions } from "slices/loading/loading.actions";
import {
  keystrokeDelay,
  networkActions,
} from "slices/authentication/network.actions";
import {
  ISearchPackagesActionType,
  packagesActions,
  SEARCH_PACKAGES,
} from "./packages.actions";
import { packagesService } from "services/hermes/packages";
import { IPackagesStoreType } from "./packages.reducer";

function* searchPackagesWorker(action: ISearchPackagesActionType) {
  yield put(loadingActions.startLoad(action.type));
  yield put(packagesActions.clearPackages());
  try {
    yield keystrokeDelay();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof packagesService.searchPackages> =
      yield call(packagesService.searchPackages, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IPackagesStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(packagesActions.searchPackagesSuccess(response));
  } catch (error) {
    toast.error("There was an error loading this page. Please try refreshing.");
    yield put(packagesActions.searchPackagesFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

export const packagesSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(SEARCH_PACKAGES, searchPackagesWorker);
};
