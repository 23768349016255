import React, { useState } from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Tooltip } from "reactstrap";

import { BEM } from "../../../helpers/BEM.helper";
import { IconTypes, icons } from "../../../constants/icons";

const classes = new BEM({
  block: { name: "FetchIcon", extras: ["d-flex", "align-items-center"] },
  elements: [
    { name: "icon", extras: [] },
    { name: "message", extras: [] },
    { name: "tooltip", extras: [] },
  ],
});

export interface FetchIconProps extends Partial<FontAwesomeIconProps> {
  iconType: IconTypes;
  color?: string;
  options?: {
    customContainerClasses?: string;
    message?: string;
    hoverColor?: string;
    tooltip?: { message: string; id: string };
  };
}

/**
 * @deprecated Use src/views/fetch-design/Icon/Icon.tsx instead
 *
 * ---
 *
 * Fetch Design System icon component with customizable options
 *
 * For more detailed information on how to add new icons,
 * and discussion of this pattern,
 * refer to the documentation at:
 * [Creating new FetchIcon options based on custom SVGs](https://fetchpackage.atlassian.net/wiki/spaces/PLAT/pages/2752446465/Creating+new+fetchIcon+options+based+on+custom+SVGs)
 *
 * @param props The properties passed to the FetchIcon component. See FetchIconProps.
 * @returns An icon component with either a Font Awesome duotone or custom svg IconDefinition
 */
export const FetchIcon: React.FC<FetchIconProps> = (props: FetchIconProps) => {
  const { iconType, options, ...passThroughProps } = props;
  // Handle hover
  const [isHovering, setIsHovering] = useState(false);
  // Handle tooltip
  const tooltip = !!options ? options.tooltip : undefined;
  const id = !!tooltip ? tooltip.id : "";
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  // Handle hover color
  const hoverColor = !!options ? options.hoverColor : undefined;
  // Handle message
  const message = !!options ? options.message : undefined;
  // Handle classnames
  const blockExtras: string[] = [];
  if (!!props.className) {
    blockExtras.push(props.className);
  }
  if (!!props.color) {
    blockExtras.push(props.color);
  }

  return !!props.options ? (
    <>
      <div
        className={
          options?.customContainerClasses
            ? options?.customContainerClasses
            : classes.getBlockClassNames({
                extras: [
                  ...blockExtras,
                  isHovering && !!hoverColor ? hoverColor : "",
                ],
              })
        }
        id={id}
        onMouseEnter={!!hoverColor ? () => setIsHovering(true) : undefined}
        onMouseLeave={!!hoverColor ? () => setIsHovering(false) : undefined}
      >
        <FontAwesomeIcon
          icon={icons[iconType]}
          {...{
            className: classes.getElementClassNames("icon", {
              extras: ["mr-1", props.size ? "" : "fa-sm"],
            }),
            ...passThroughProps,
          }}
        />
        {!!message && (
          <div className={classes.getElementClassNames("message")}>
            {message}
          </div>
        )}
      </div>
      {!!tooltip && !!tooltip.id && (
        <Tooltip
          delay={0}
          target={id}
          className={classes.getElementClassNames("tooltip")}
          onMouseEnter={!!hoverColor ? () => setIsHovering(true) : undefined}
          onMouseLeave={!!hoverColor ? () => setIsHovering(false) : undefined}
          isOpen={tooltipOpen}
          toggle={toggle}
        >
          {!!tooltip ? tooltip.message : undefined}
        </Tooltip>
      )}
    </>
  ) : (
    <FontAwesomeIcon
      icon={icons[iconType]}
      {...{
        ...passThroughProps,
        className: classes.getElementClassNames("icon", {
          extras: blockExtras,
        }),
      }}
      onMouseEnter={!!hoverColor ? () => setIsHovering(true) : undefined}
      onMouseLeave={!!hoverColor ? () => setIsHovering(false) : undefined}
      id={!!props.id ? props.id : id}
    />
  );
};
