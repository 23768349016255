import {
  ISearchWarehouseLocationsSuccessActionType,
  ISearchWarehouseLocationsFailureActionType,
  IClearWarehouseLocationsActionType,
  CLEAR_WAREHOUSE_LOCATIONS,
  SEARCH_WAREHOUSE_LOCATIONS_FAILURE,
  SEARCH_WAREHOUSE_LOCATIONS_SUCCESS,
} from "slices/warehouseLocations/warehouseLocations.actions";
import { defaultState } from "default-state";
import { IWarehouseLocationStoreType } from "slices/warehouseLocation/warehouseLocation.reducer";
import { IPaginatedResponseType } from "types/IPaginatedResponseType";

export interface IWarehouseLocationsStoreType extends IPaginatedResponseType {
  content: Array<IWarehouseLocationStoreType>;
}

export function warehouseLocations(
  state = {},
  action: WarehouseLocationsActionsTypes,
): Partial<IWarehouseLocationsStoreType> {
  switch (action.type) {
    case CLEAR_WAREHOUSE_LOCATIONS:
      return defaultState.warehouseLocations;
    case SEARCH_WAREHOUSE_LOCATIONS_SUCCESS:
      return action.payload;
    case SEARCH_WAREHOUSE_LOCATIONS_FAILURE:
      return defaultState.warehouseLocations;
    default:
      return state;
  }
}

// type definitions
type WarehouseLocationsActionsTypes =
  | ISearchWarehouseLocationsSuccessActionType
  | ISearchWarehouseLocationsFailureActionType
  | IClearWarehouseLocationsActionType;
