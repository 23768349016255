import { Schema } from "jsonschema";
import { Reducer } from "react";
import {
  ClearMetaSchemaAction,
  CLEAR_META_SCHEMA,
  GetMetaSchemaAction,
  GetMetaSchemaSuccessAction,
  GET_META_SCHEMA_SUCCESS,
} from "slices/metaSchema/metaSchema.action";

export type MetaSchemaStore = Schema | null;

type MetaSchemaActions =
  | GetMetaSchemaAction
  | GetMetaSchemaSuccessAction
  | ClearMetaSchemaAction;

export const metaSchema: Reducer<MetaSchemaStore, MetaSchemaActions> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case CLEAR_META_SCHEMA:
      return null;
    case GET_META_SCHEMA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
