import React from "react";

import { Button } from "reactstrap";
import { Dispatch } from "redux";

import { BEM } from "helpers/BEM.helper";
import { FetchConnect } from "helpers/FetchConnect";
import { authenticationActions } from "slices/authentication/authentication.actions";
import { IVerifyUserAttributePayloadType } from "slices/authentication/authentication.actions";
import { IAuthenticationStateType } from "slices/authentication/authentication.reducer";

export interface VerifyContactDetailsButtonsProps {
  authentication: IAuthenticationStateType;
  dispatch: Dispatch;
  attributeName: IVerifyUserAttributePayloadType["attributeName"];
  code: IVerifyUserAttributePayloadType["code"];
}

const VerifyContactDetailsButtons: React.FC<
  VerifyContactDetailsButtonsProps
> = (props: VerifyContactDetailsButtonsProps) => {
  const {
    dispatch,
    authentication: { tokens },
    attributeName,
    code,
  } = props;

  const classes = new BEM({
    block: { name: "FetchVerifyContactDetailsButtons" },
    prefix: { name: "fetch" },
  });

  return (
    <Button
      size="md"
      className={classes.getBlockClassNames()}
      onClick={() => {
        if (tokens && tokens.userToken && code) {
          dispatch(
            authenticationActions.verifyUserAttribute({
              attributeName: attributeName,
              code,
              userToken: tokens.userToken,
            }),
          );
        }
      }}
      color="info"
    >
      Verify
    </Button>
  );
};

export default FetchConnect({
  ConnectedComponent: VerifyContactDetailsButtons,
  state: { stores: ["authentication"] },
});
