import { takeLatest, ForkEffect, call, put } from "redux-saga/effects";

import { loadingActions } from "slices/loading/loading.actions";
import {
  keystrokeDelay,
  networkActions,
} from "slices/authentication/network.actions";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import {
  ISearchWarehouseLocationsActionType,
  warehouseLocationsActions,
  SEARCH_WAREHOUSE_LOCATIONS,
} from "./warehouseLocations.actions";
import { IWarehouseLocationsStoreType } from "./warehouseLocations.reducer";
import { warehousesService } from "services/hermes/warehouses";

function* searchWarehouseLocationsWorker(
  action: ISearchWarehouseLocationsActionType,
) {
  yield put(loadingActions.startLoad(action.type));
  yield put(warehouseLocationsActions.clearWarehouseLocations());
  try {
    yield keystrokeDelay();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: IAuthenticatedRequestDefinition = yield call(
      warehousesService.searchWarehouseLocations,
      action.payload,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IWarehouseLocationsStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(
      warehouseLocationsActions.searchWarehouseLocationsSuccess(response),
    );
  } catch (error) {
    yield put(warehouseLocationsActions.searchWarehouseLocationsFailure());
  }
  yield put(loadingActions.endLoad(action.type));
}

export const warehouseLocationsSagas = function* sagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(SEARCH_WAREHOUSE_LOCATIONS, searchWarehouseLocationsWorker);
};
