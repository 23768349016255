import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { config } from "../constants/config";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";

const enableAnalytics =
  config.apiUrl === process.env.REACT_APP_API_URL_PROD &&
  config.isPipeline !== "true";

export const initializeAnalytics = (): void => {
  if (enableAnalytics) {
    const app = initializeApp({
      apiKey: "AIzaSyDRUoq0qjZ0NRilSmPLU9BQ9BgXFEZRTEk",
      authDomain: "fetch-package-prod.firebaseapp.com",
      databaseURL: "https://fetch-package-prod.firebaseio.com",
      projectId: "fetch-package-prod",
      storageBucket: "fetch-package-prod.appspot.com",
      messagingSenderId: "880227005212",
      appId: "1:880227005212:web:056a4559299f28ce3a00d9",
      measurementId: "G-2G153LLLDX",
    });
    getAnalytics(app);
  }
};

const getUserProperties = ({
  userId,
  residentialUser,
  warehouseUser,
  isAdminUser,
  driverUser,
}: IUserStoreType) => {
  return {
    user_id: userId,
    is_admin_user: isAdminUser,
    is_driver_user: Boolean(driverUser),
    is_residential_user: Boolean(residentialUser),
    is_warehouse_user: Boolean(warehouseUser),
    residential_user_building_id: residentialUser?.buildingId,
    residential_user_warehouse_id: residentialUser?.warehouseId,
    warehouse_user_preferred_warehouse_short_code:
      warehouseUser?.preferredWarehouseShortcode,
  };
};

export const setAnalyticsUser = (currentUser: IUserStoreType): void => {
  if (enableAnalytics) {
    const analytics = getAnalytics();
    const properties = getUserProperties(currentUser);
    setUserProperties(analytics, properties);
  }
};

export const logAnalyticsEvents = (
  eventName: string,
  eventParams?: {
    [key: string]: unknown;
  },
): void => {
  if (enableAnalytics) {
    const analytics = getAnalytics();
    logEvent(analytics, eventName, eventParams);
  }
};
