import React from "react";

import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";

import { authenticationActions } from "slices/authentication/authentication.actions";
import { IPostChangePasswordPayloadType } from "slices/authentication/authentication.actions";
import { uiActions } from "slices/ui/ui.actions";
import { FetchConnect } from "helpers/FetchConnect";
import ChangePasswordForm, {
  IChangePasswordFormValues,
} from "views/components/forms/ChangePasswordForm";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { IRootStateType } from "types/IRootStateType";

export interface ChangePasswordModalProps
  extends IRootStateType,
    RouteComponentProps {
  text: string;
  dispatch: Dispatch;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = (
  props: ChangePasswordModalProps,
) => {
  const { dispatch } = props;
  return (
    <Modal isOpen={true}>
      <ModalHeader className="bg-primary text-white">
        Change Password
      </ModalHeader>
      <ModalBody>
        <ChangePasswordForm
          onSubmit={(values: IChangePasswordFormValues) => {
            if (values.newPassword && values.oldPassword) {
              props.dispatch(uiActions.clearModal());
              dispatch(
                authenticationActions.postChangePassword(
                  values as IPostChangePasswordPayloadType,
                ),
              );
            }
          }}
        />
        <Button
          color="primary"
          className="closeButton w-100"
          onClick={() => props.dispatch(uiActions.clearModal())}
        >
          Close
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default FetchConnect({
  ConnectedComponent: ChangePasswordModal,
  state: { stores: [] },
});
