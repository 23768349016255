import {
  initializeAnalytics as firebaseInitializeAnalytics,
  setAnalyticsUser as firebaseSetAnalyticsUser,
  logAnalyticsEvents as firebaseLogAnalyticsEvents,
} from "./firebase";
import { config } from "../constants/config";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";

const enableAnalytics = config.apiUrl === process.env.REACT_APP_API_URL_PROD;

export const initializeAnalytics = (): void => {
  if (enableAnalytics) {
    firebaseInitializeAnalytics();
  }
};

export const setAnalyticsUser = (currentUser: IUserStoreType): void => {
  if (enableAnalytics) {
    firebaseSetAnalyticsUser(currentUser);
  }
};

const _logAnalyticsEvents = (
  eventName: string,
  eventParams?: {
    [key: string]: unknown;
  },
): void => {
  if (enableAnalytics) {
    firebaseLogAnalyticsEvents(eventName, eventParams);
  }
};

declare global {
  interface Window {
    Cypress?: unknown;
    logAnalyticsEvents?: typeof _logAnalyticsEvents;
  }
}
if (window.Cypress) {
  window.logAnalyticsEvents = _logAnalyticsEvents;
}

export const logAnalyticsEvents = (
  eventName: string,
  eventParams?: {
    [key: string]: unknown;
  },
): void => {
  // Stub logAnalyticsEvents by passing it to and from window
  // https://glebbahmutov.com/blog/stub-via-window/#override-the-config-object
  // https://docs.cypress.io/api/commands/stub#Replace-built-in-window-methods-like-prompt

  if (!!window.logAnalyticsEvents && !!window.Cypress) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.logAnalyticsEvents(eventName, eventParams);
  } else {
    _logAnalyticsEvents(eventName, eventParams);
  }
};
