import {
  ActionType,
  ClearAction,
  FullfilledAction,
  RejectedAction,
  RequestAction,
  actionTypes,
} from "./types";

export const GET_WFM_RESOURCES_TEMPLATE_WINDOWS =
  "GET_WFM_RESOURCES_TEMPLATE_WINDOWS";

export function getWfmResourcesTemplateWindows(
  payload: ActionType["payload"],
): ActionType {
  return {
    type: GET_WFM_RESOURCES_TEMPLATE_WINDOWS,
    payload,
  };
}

function clear(): ClearAction {
  return {
    type: actionTypes.clear,
  };
}

function request(payload: RequestAction["payload"]): RequestAction {
  return {
    type: actionTypes.request,
    payload,
  };
}

export function fullfilled(
  payload: FullfilledAction["payload"],
): FullfilledAction {
  return {
    type: actionTypes.fullfilled,
    payload,
  };
}

export function rejected(payload: RejectedAction["payload"]): RejectedAction {
  return {
    type: actionTypes.rejected,
    payload,
  };
}

export const actions = {
  request,
  fullfilled,
  rejected,
  clear,
};
