import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { IWarehousesStoreType } from "./warehouses.reducer";

export const CLEAR_WAREHOUSES = "CLEAR_WAREHOUSES";
export const SEARCH_WAREHOUSES = "SEARCH_WAREHOUSES";
export const SEARCH_WAREHOUSES_SUCCESS = "SEARCH_WAREHOUSES_SUCCESS";
export const SEARCH_WAREHOUSES_FAILURE = "SEARCH_WAREHOUSES_FAILURE";

export interface ISearchWarehousesActionType {
  type: typeof SEARCH_WAREHOUSES;
  payload: IPaginatedTableSearchRequestType;
}
export interface ISearchWarehousesSuccessActionType {
  type: typeof SEARCH_WAREHOUSES_SUCCESS;
  payload: IWarehousesStoreType;
}
export interface ISearchWarehousesFailureActionType {
  type: typeof SEARCH_WAREHOUSES_FAILURE;
}

export interface IClearWarehousesActionType {
  type: typeof CLEAR_WAREHOUSES;
}

function clearWarehouses(): IClearWarehousesActionType {
  return {
    type: CLEAR_WAREHOUSES,
  };
}

function searchWarehouses(
  warehouseSearch: IPaginatedTableSearchRequestType,
): ISearchWarehousesActionType {
  return { type: SEARCH_WAREHOUSES, payload: warehouseSearch };
}

function searchWarehousesSuccess(
  warehouseResponse: IWarehousesStoreType,
): ISearchWarehousesSuccessActionType {
  return { type: SEARCH_WAREHOUSES_SUCCESS, payload: warehouseResponse };
}
function searchWarehousesFailure(): ISearchWarehousesFailureActionType {
  return { type: SEARCH_WAREHOUSES_FAILURE };
}

export const warehousesActions = {
  clearWarehouses,
  searchWarehouses,
  searchWarehousesSuccess,
  searchWarehousesFailure,
};
