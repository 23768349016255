import { takeLeading, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { networkActions } from "slices/authentication/network.actions";
import {
  buildingsByMarketActions,
  GET_BUILDINGS_BY_MARKET,
  IGetBuildingsByMarketActionType,
} from "./buildingsByMarket.actions";
import { IBuildingStoreType } from "slices/building/building.reducer";
import { buildingsService } from "services/hermes/buildings";
import { loadingActions } from "slices/loading/loading.actions";

function* getBuildingsByMarketWorker(action: IGetBuildingsByMarketActionType) {
  yield put(loadingActions.startLoad(action.type));
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof buildingsService.getBuildingsByMarket
    > = yield call(
      buildingsService.getBuildingsByMarket,
      action.payload.marketId,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IBuildingStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(buildingsByMarketActions.getBuildingsByMarketSuccess(response));
  } catch (error) {
    toast.error(
      "There was an error loading the page. Please refresh and try again.",
    );
  }
  yield put(loadingActions.endLoad(action.type));
}

export const buildingsByMarketSagas = function* () {
  yield takeLeading(GET_BUILDINGS_BY_MARKET, getBuildingsByMarketWorker);
};
