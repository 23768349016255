import { actions } from "./actions";
import { defaultState, reducer } from "./reducer";
import { wfmDeliveryWindowScheduleSaga as saga } from "./saga";

export const wfmDeliveryWindowSchedule = {
  reducer: reducer,
  actions: actions,
  saga: saga,
  defaultState: defaultState,
};
