import { takeLeading, call, put } from "redux-saga/effects";
import { driverBatchesServices } from "services/hermes/driverBatches";

import { networkActions } from "slices/authentication/network.actions";
import {
  GET_MY_DRIVER_BATCHES,
  myDriverBatchesActions,
} from "./myDriverBatches.actions";
import { MyDriverBatchesStoreType } from "./myDriverBatches.reducers";

function* getMyDriverBatchesWorker() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<
      typeof driverBatchesServices.getMyDriverBatches
    > = yield call(driverBatchesServices.getMyDriverBatches);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: MyDriverBatchesStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
    );
    yield put(myDriverBatchesActions.getMyDriverBatchesSuccess(response));
  } catch (error) {
    yield put(myDriverBatchesActions.getMyDriverBatchesFailure());
  }
}

export const myDriverBatchesSagas = function* () {
  yield takeLeading(GET_MY_DRIVER_BATCHES, getMyDriverBatchesWorker);
};
