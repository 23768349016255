import React from "react";

import Select, { Options, Props } from "react-select";
import { WrappedFieldProps } from "redux-form";

import {
  FetchFieldValidationWrapper,
  FetchFieldValidationWrapperProps,
} from "views/common/forms/fields/FetchFieldValidationWrapper";
import { generateBemClassNames } from "helpers/generateBemClassNames.helper";
import { ISelectListOptionType } from "types/ISelectListOptionType";
import { FetchSelectSelectors } from "./FetchSelectSelectors";

const {
  block,
  elements: { select, error, label: labelClassNames },
} = generateBemClassNames({
  block: { name: "select" },
  elements: [{ name: "select" }, { name: "error" }, { name: "label" }],
  prefix: "fetch",
});

type FetchSelectProps = Partial<WrappedFieldProps> &
  Partial<Omit<Props, "onChange">> &
  FetchFieldValidationWrapperProps & {
    className?: string;
    options: ISelectListOptionType[];
    isDisabled: boolean;
    notFoundOption?: ISelectListOptionType;
    placeholder?: string;
    classNamePrefix?: string;
  };

export const FetchSelect: React.FC<FetchSelectProps> = (
  props: FetchSelectProps,
) => {
  const {
    input,
    isDisabled = false,
    label,
    meta,
    options,
    placeholder,
    type,
    className,
    notFoundOption,
  } = props;
  if (!!input && !!meta && !!options) {
    // Determine selected option
    const selectedOption: ISelectListOptionType | null = options.reduce(
      (
        selected: ISelectListOptionType | null,
        option: ISelectListOptionType,
      ) => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        if (`${option.value}` === `${input.value}`) {
          return option;
        }

        return selected;
      },
      notFoundOption || null,
    );

    const selectOptions: Options<ISelectListOptionType> = options;
    return (
      <FetchFieldValidationWrapper
        {...{
          input,
          meta,
          label,
          type,
          classNames: {
            block: block({ extras: [className ? className : ""] }),
            label: labelClassNames(),
            error: error(),
          },
        }}
        data-cy={FetchSelectSelectors.block}
      >
        <Select
          {...input}
          className={select()}
          isDisabled={isDisabled !== undefined && isDisabled}
          classNamePrefix={props.classNamePrefix || block()}
          options={selectOptions}
          value={selectedOption}
          onChange={(_value) => {
            if (!!_value) {
              const value = _value;
              input.onChange(value.value);
            }
          }}
          placeholder={placeholder}
          onBlur={undefined}
        />
      </FetchFieldValidationWrapper>
    );
  } else {
    return null;
  }
};
