import { localStorageGetItemSafe } from "./localStorage/localStorageGetItemSafe";

export function authHeader(): HeadersInit {
  const headers: HeadersInit = { "Content-Type": "application/json" };
  // return authorization header with jwt token
  const localAuthData = localStorageGetItemSafe("auth");

  if (!!localAuthData) {
    if ("accessToken" in localAuthData && !!localAuthData.accessToken) {
      headers.Authorization = localAuthData.accessToken;
    }
  }

  return headers;
}
