import { Reducer } from "redux";

import { defaultState } from "default-state";
import {
  IClearPackageDeliveryWindowOptionsActionType,
  IGetPackageDeliveryWindowOptionsSuccessActionType,
  CLEAR_PACKAGE_DELIVERY_WINDOW_OPTIONS,
  GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_SUCCESS,
} from "./packageDeliveryWindowOptions.actions";

interface IDeliveryTime {
  deliveryWindowId: number;
  cutOffTime: string;
  startTime: string;
  endTime: string;
  fetchOfferingType: number;
}

export interface IDeliveryWindow {
  fetchPackageId: number;
  warehouseId: number;
  warehouseTimezone: string;
  deliveryDate: string;
  deliveryTimes: IDeliveryTime[];
}

export type PackageDeliveryWindowOptionsStoreType = IDeliveryWindow[];

type PackageDeliveryWindowOptionsActionTypes =
  | IClearPackageDeliveryWindowOptionsActionType
  | IGetPackageDeliveryWindowOptionsSuccessActionType;

export const packageDeliveryWindowOptions: Reducer<
  PackageDeliveryWindowOptionsStoreType | undefined
> = (
  state = defaultState.packageDeliveryWindowOptions,
  action: PackageDeliveryWindowOptionsActionTypes,
) => {
  switch (action.type) {
    case CLEAR_PACKAGE_DELIVERY_WINDOW_OPTIONS:
      return defaultState.packageDeliveryWindowOptions;
    case GET_PACKAGE_DELIVERY_WINDOW_OPTIONS_SUCCESS:
      return action.payload;
    default:
      return { ...state };
  }
};
